jQuery(document).ready(function($) {

  // Form Submissions


  // Forgot Username Form
  $('#ns-forgot-username-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-forgot-username-form').foundation("validateForm");
  });

  $('form#ns-forgot-username-form').bind("formvalid.zf.abide", function(e,target) {

    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "forgot_username",
        account: $('#ns-forgot-username-account').val(),
        pin: $('#ns-forgot-username-pin').val()
      },
      success: function(result) {
        // console.log(result);
        var response = $.parseJSON(result);
        if(response.Data) {
          $('#ns-forgot-username-form .success').show();
          $('#ns-forgot-username-form .alert').hide();

          $('#ns-forgot-username-account').val('');
          $('#ns-forgot-username-pin').val('');

          $('.full-loader').addClass('hide');
        } else {
          $('#ns-forgot-username-form .success').hide();
          $('#ns-forgot-username-form .alert').show();

          $('#ns-forgot-username-account').val('');
          $('#ns-forgot-username-pin').val('');

          $('.full-loader').addClass('hide');
        }
      },
      error: function() {
        $('#ns-forgot-username-form .success').hide();
        $('#ns-forgot-username-form .alert').show();

        $('#ns-forgot-username-account').val('');
        $('#ns-forgot-username-pin').val('');

        $('.full-loader').addClass('hide');
      }
    });
  });


  // Forgot Password Form
  $('#ns-forgot-password-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-forgot-password-form').foundation("validateForm");
  });

  $('form#ns-forgot-password-form').bind("formvalid.zf.abide", function(e,target) {

    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "forgot_password",
        username: $('#ns-forgot-password-email').val()
      },
      success: function(result) {
        // console.log(result);
        var response = $.parseJSON(result);
        if(response.Status == 'success') {
          $('#ns-forgot-password-form .success').show();
          $('#ns-forgot-password-form .alert').hide();

          $('#ns-forgot-password-email').val('');

          $('.full-loader').addClass('hide');
        } else {
          $('#ns-forgot-password-form .success').hide();
          $('#ns-forgot-password-form .alert').show();

          $('#ns-forgot-password-email').val('');

          $('.full-loader').addClass('hide');
        }
      },
      error: function() {
        $('#ns-forgot-password-form .success').hide();
        $('#ns-forgot-password-form .alert').show();

        $('#ns-forgot-password-email').val('');

        $('.full-loader').addClass('hide');
      }
    });
  });


  // Create Account Login Forms (NC)
  // $('#ns-create-account-submit-1').click(function(e) {
  //   e.preventDefault();
  //   $('form#ns-create-account-form-1').foundation("validateForm");
  // });
  //
  // $('#ns-create-account-submit-2').click(function(e) {
  //   e.preventDefault();
  //   $('form#ns-create-account-form-2').foundation("validateForm");
  // });
  //
  // $('#ns-create-account-submit-3').click(function(e) {
  //   e.preventDefault();
  //   $('form#ns-create-account-form-3').foundation("validateForm");
  // });

  $('form#ns-create-account-form-1').bind("formvalid.zf.abide", function(e,target) {

    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_pending_user_registration",
        accountId: $('#ns-create-account-account').val(),
      },
      success: function(result) {
        var response = $.parseJSON(result);
        if(response.Status == 'success') {

          $('*[data-account-creation-options] input[type="radio"]').each(function() {
            if($(this).val() !== '-1') {
              $(this).parent('label').remove();
            }
          });

          for(var i = 0; i < response.Data.length; i++) {
            var sendMethod = '<label><input type="radio" name="selectTokenContact" value="'+response.Data[i].id+'" data-type="'+response.Data[i].type+'" required /> '+response.Data[i].type+': '+response.Data[i].value+'</label>';
            $('*[data-account-creation-options]').prepend(sendMethod);
          }

          $('div[data-page-order="2"]').hide();
          $('div[data-page-order="3"]').fadeIn();

          $('.full-loader').addClass('hide');
        } else {

          if(response.Message) {
            // Clean it up if needed
            var eemessage = response.Message.replace( /(<([^>]+)>)/ig, '');

            // Expressions to add links
            var linkexp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            var phoneexp = /((\d[\d\-.]*){9,})/g;
            var emailexp = /([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/ig;

            // Convert strings to links
	          eemessage = eemessage.replace(linkexp, "<a href=\"$1\">$1</a>");
            eemessage = eemessage.replace(phoneexp, "<a href=\"tel:$1\">$1</a>");
            eemessage = eemessage.replace(emailexp, "<a href=\"mailto:$1\">$1</a>");

            $('#ns-create-account-form-1 [data-abide-error] p').html(eemessage);
            $('#ns-create-account-form-1 [data-abide-error] p').show();
          }


          $('form#ns-create-account-form-1').foundation('addErrorClasses', $('#ns-create-account-account'));
          $('form#ns-create-account-form-1 div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });

  $('form#ns-create-account-form-2').bind("formvalid.zf.abide", function(e,target) {

    e.preventDefault();

    $('.full-loader').removeClass('hide');

    console.log($('input[type="radio"][name="selectTokenContact"]:checked').val());

    if($('input[type="radio"][name="selectTokenContact"]:checked').val() !== "-1") {
      var contact_id   = $('input[type="radio"][name="selectTokenContact"]:checked').val();
      var contact_type = $('input[type="radio"][name="selectTokenContact"]:checked').attr('data-type');

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_pending_user_sendtoken",
          id: contact_id,
          type: contact_type
        },
        success: function(result) {
          var response = $.parseJSON(result);
          if(response.Status == 'success' && response.Data) {
            $('div[data-page-order="3"]').hide();
            $('div[data-page-order="4"]').fadeIn();

            $('.full-loader').addClass('hide');
          } else {
            $('form#ns-create-account-form-2 div[data-abide-error]').show();
            $('.full-loader').addClass('hide');
          }
        }
      });
    } else {
      $('div[data-page-order="3"]').hide();
      $('div[data-page-order="4"]').fadeIn();

      $('.full-loader').addClass('hide');
    }

  });

  $('form#ns-create-account-form-3').bind("formvalid.zf.abide", function(e,target) {

    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_username_add",
        token: $('#ns-create-account-token').val(),
        accountId: $('#ns-create-account-account').val(),
        Username: $('#ns-create-account-email').val(),
        Password: $('#ns-create-account-password').val()
      },
      success: function(result) {
        console.log(result);

        var response = $.parseJSON(result);

        if(response.Status == 'success') {
          $('div[data-page-order="4"]').hide();
          $('div[data-page-order="5"]').fadeIn();

          $('.full-loader').addClass('hide');
        } else {
          if(response.Entity == 'PendingRegistration') {
            $('form#ns-create-account-form-3').foundation('addErrorClasses', $('#ns-create-account-token'));
          } else {
            $('form#ns-create-account-form-3').foundation('addErrorClasses', $('#ns-create-account-email'));
            $('form#ns-create-account-form-3').foundation('addErrorClasses', $('#ns-create-account-password'));
            $('form#ns-create-account-form-3').foundation('addErrorClasses', $('#ns-create-account-password-confirm'));
          }

          $('form#ns-create-account-form-4 div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });

    // $.ajax({
    //   url: '/wp-content/themes/Lumos/api_helper.php',
    //   method: 'POST',
    //   contentType: 'application/x-www-form-urlencoded',
    //   data: {
    //     action: "ns_pending_user_validatetoken",
    //     accountId: $('#ns-create-account-account').val(),
    //     token: $('#ns-create-account-token').val()
    //   },
    //   success: function(result) {
    //     var response = $.parseJSON(result);
    //     if(response.Status == 'success' && response.Data) {
    //       $('#ns-create-account-valid-token').val('true');
    //
    //       $('div[data-page-order="7"]').hide();
    //       $('div[data-page-order="8"]').fadeIn();
    //
    //       $('.full-loader').addClass('hide');
    //     } else {
    //       $('form#ns-create-account-form-3 div[data-abide-error]').show();
    //       $('.full-loader').addClass('hide');
    //     }
    //   }
    // });
  });


  // Create Account Login Forms (VA)
  // $('#ns-create-account-submit-1-va').click(function(e) {
  //   e.preventDefault();
  //   $('form#ns-create-account-form-1-va').foundation("validateForm");
  //   console.log('clicked');
  // });

  // $('#ns-create-account-submit-2-va').click(function(e) {
  //   e.preventDefault();
  //   $('form#ns-create-account-form-2-va').foundation("validateForm");
  // });

  // Validate Account # and PIN
  $('form#ns-create-account-form-1-va').bind("formvalid.zf.abide", function(e,target) {
    console.log('submitted');
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_validate_token_va",
        accountId: $('#ns-create-account-va-account').val(),
        token: $('#ns-create-account-va-pin').val(),
      },
      success: function(result) {
        var response = $.parseJSON(result);
        if(response.Data) {
          $('div[data-page-order="6"]').hide();
          $('div[data-page-order="7"]').fadeIn();

          $('.full-loader').addClass('hide');
        } else {

          $('form#ns-create-account-form-1-va').foundation('addErrorClasses', $('#ns-create-account-va-account'));
          $('form#ns-create-account-form-1-va').foundation('addErrorClasses', $('#ns-create-account-va-pin'));
          $('form#ns-create-account-form-1-va div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });

  // Create Account
  $('form#ns-create-account-form-2-va').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_username_add_va",
        accountId: $('#ns-create-account-va-account').val(),
        token: $('#ns-create-account-va-pin').val(),
        Username: $('#ns-create-account-va-email').val(),
        Password: $('#ns-create-account-va-password').val(),
        FirstName: $('#ns-create-account-va-first-name').val(),
        LastName: $('#ns-create-account-va-last-name').val(),
        Phone: $('#ns-create-account-va-phone').val(),
        Address: $('#ns-create-account-va-street-address').val(),
        Zip:  $('#ns-create-account-va-zip-code').val(),
      },
      success: function(result) {
        var response = $.parseJSON(result);
        if(response.Status == 'success' && response.Data) {
          $('div[data-page-order="7"]').hide();
          $('div[data-page-order="5"]').fadeIn();

          $('.full-loader').addClass('hide');
        } else {
          $('form#ns-create-account-form-2-va').foundation('addErrorClasses', $('#ns-create-account-va-first-name'));
          $('form#ns-create-account-form-2-va').foundation('addErrorClasses', $('#ns-create-account-va-last-name'));
          $('form#ns-create-account-form-2-va').foundation('addErrorClasses', $('#ns-create-account-va-phone'));
          $('form#ns-create-account-form-2-va').foundation('addErrorClasses', $('#ns-create-account-va-street-address'));
          $('form#ns-create-account-form-2-va').foundation('addErrorClasses', $('#ns-create-account-va-zip-code'));

          $('form#ns-create-account-form-2-va').foundation('addErrorClasses', $('#ns-create-account-va-email'));
          $('form#ns-create-account-form-2-va').foundation('addErrorClasses', $('#ns-create-account-va-password'));
          $('form#ns-create-account-form-2-va').foundation('addErrorClasses', $('#ns-create-account-va-password-confirm'));

          $('form#ns-create-account-form-2-va div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });

  });


  // Add Associated Account
  $('form[data-add-assoc-account] input[type="submit"]').click(function(e) {
    e.preventDefault();
    $('form[data-add-assoc-account]').foundation("validateForm");
  });

  $('form[data-add-assoc-account]').bind("formvalid.zf.abide", function(e, target) {

    $('.full-loader').removeClass('hide');

    var formData = $('form[data-add-assoc-account]').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_add_username_delegates';
    formDataObj.sessionId = ns_session_id;
    console.log(formDataObj);

    sessionStorage.removeItem('lumos_login_account_id_group');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        console.log(result);
        var delegateInfo = JSON.parse(result);

        if(delegateInfo.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_accounts_a'});
        } else {
          $('form[data-add-assoc-account]').foundation('addErrorClasses', $('#account-number'));
          $('form[data-add-assoc-account]').foundation('addErrorClasses', $('#security-pin'));
          $('form[data-add-assoc-account] div[data-abide-error]').html('<p>'+delegateInfo.Message+'</p>');
          $('form[data-add-assoc-account] div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });

  // Remove Scheduled Payment
  $('form[data-delete-scheduled-payment] input[type="submit"]').click(function(e) {
    e.preventDefault();
    $('form[data-delete-scheduled-payment]').foundation("validateForm");
  });

  $('form[data-delete-scheduled-payment]').bind("formvalid.zf.abide", function(e, target) {

    $('.full-loader').removeClass('hide');

    var formData = $('form[data-delete-scheduled-payment]').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_schedule_payment_delete';
    formDataObj.sessionId = ns_session_id;
    console.log(formDataObj);

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        console.log(result);
        var scheduledInfo = JSON.parse(result);

        if(scheduledInfo.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_scheduled_d'});
        } else {
          $('form[data-delete-scheduled-payment] div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      },
      error: function(result) {
        console.log(result);
        $('form[data-delete-scheduled-payment] div[data-abide-error]').show();
        $('.full-loader').addClass('hide');
      }
    });

  });

  // Remove Associated Account
  $('form[data-delete-assoc-account] input[type="submit"]').click(function(e) {
    e.preventDefault();
    $('form[data-delete-assoc-account]').foundation("validateForm");
  });

  $('form[data-delete-assoc-account]').bind("formvalid.zf.abide", function(e, target) {

    $('.full-loader').removeClass('hide');

    var formData = $('form[data-delete-assoc-account]').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_delete_username_delegates';
    formDataObj.sessionId = ns_session_id;
    console.log(formDataObj);

    sessionStorage.removeItem('lumos_login_account_id_group');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        console.log(result);
        var delegateInfo = JSON.parse(result);

        if(delegateInfo.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_accounts_d'});
        } else {
          $('form[data-add-assoc-account] div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });


  // Add Email Account
  $('form[data-add-email-account] input[type="submit"]').click(function(e) {
    e.preventDefault();
    $('form[data-add-email-account]').foundation("validateForm");
  });

  $('form[data-add-email-account]').bind("formvalid.zf.abide", function(e, target) {

    $('.full-loader').removeClass('hide');

    var formData = $('form[data-add-email-account]').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_add_email_address';
    formDataObj.sessionId = ns_session_id;
    // console.log(formDataObj);

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        // console.log(result);
        var emailInfo = JSON.parse(result);

        if(emailInfo.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_emails_a'});
        } else {
          $('form[data-add-email-account] div[data-abide-error]').text(emailInfo.Message);
          $('form[data-add-email-account] div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
        // $('.full-loader').addClass('hide');
      }
    });
  });


  // Remove Email Account
  $('form[data-delete-email-account] input[type="submit"]').click(function(e) {
    e.preventDefault();
    $('form[data-delete-email-account]').foundation("validateForm");
  });

  $('form[data-delete-email-account]').bind("formvalid.zf.abide", function(e, target) {

    $('.full-loader').removeClass('hide');

    var formData = $('form[data-delete-email-account]').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_remove_email_address';
    formDataObj.sessionId = ns_session_id;
    // console.log(formDataObj);

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        console.log(result);
        var emailInfo = JSON.parse(result);

        if(emailInfo.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_emails_d'});
        } else {
          $('form[data-delete-email-account] div[data-abide-error]').text(emailInfo.Message);
          $('form[data-delete-email-account] div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
        // $('.full-loader').addClass('hide');
      }
    });
  });


  // Statement Delivery - Mail
  $('#ns-update-statement-delivery-address-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-update-statement-delivery-address').foundation("validateForm");
  });

  $('form#ns-update-statement-delivery-address button.cancel').click(function(e) {
    e.preventDefault();
  });

  $('form#ns-update-statement-delivery-address').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    // Grab Form Data
    var formData = $('form#ns-update-statement-delivery-address').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_update_billing_contact';
    formDataObj.sessionId = ns_session_id;

    console.log(formDataObj);

    // Update Contact
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        console.log(result);
        var submissionResponse = JSON.parse(result);
        // console.log(submissionResponse);

        if(submissionResponse.Status == 'success') {
          if(submissionResponse.Data) {
            window.location.href = window.location.pathname+"?"+$.param({'status_m':'b_statement_a'});
          } else {
            window.location.href = window.location.pathname+"?"+$.param({'status_m':'b_statement_a_err'});
          }

        } else {
          $('form#ns-update-statement-delivery-address div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }

      }
    });
  });


  // Statement Delivery - Email
  $('#ns-update-statement-delivery-email-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-update-statement-delivery-email').foundation("validateForm");
  });

  $('form#ns-update-statement-delivery-email button.cancel').click(function(e) {
    e.preventDefault();
  });

  $('form#ns-update-statement-delivery-email').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    // Grab Form Data
    var formData = $('form#ns-update-statement-delivery-email').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_update_billing_contact';
    formDataObj.sessionId = ns_session_id;

    console.log(formDataObj);

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        console.log(result);
        var submissionResponse = JSON.parse(result);

        if(submissionResponse.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'b_statement_e'});
        } else {
          $('form#ns-update-statement-delivery-email').foundation('addErrorClasses', $('#ns-billing-email'));
          $('.full-loader').addClass('hide');
        }
      }
    });
  });


  // Statement Delivery - Switch
  $('#ns-update-statement-type-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-update-statement-type').foundation("validateForm");
  });

  $('form#ns-update-statement-type').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    console.log(localStorage.getItem('lumos_login_account_id_current'), $('input[name="ns-statement-type"]:checked').val());

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: 'ns_update_statement_delivery',
        AccountID: localStorage.getItem('lumos_login_account_id_current'),
        StatementDelivery: $('input[name="ns-statement-type"]:checked').val(),
        sessionId: ns_session_id
      },
      success: function(result) {
        console.log(result);
        var updateSDResponse = JSON.parse(result);
        // console.log(updatePinResponse);

        if(updateSDResponse.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'b_statement_p'});
        }
      }
    });

  });


  // Toggle Edit Authorized users
  $('#ns-auth-user-cancel-edit').click(function(e) {
    e.preventDefault();
    $('div[data-edit-authorized-users]').toggleClass('hide');
    $('div[data-edit-authorized-user]').toggleClass('hide');
  });


  // Add Authorized Users
  $('#ns-auth-user-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-auth-user-form').foundation("validateForm");
  });

  $('form#ns-auth-user-form button').click(function(e) {
    e.preventDefault();
  });

  $('form#ns-auth-user-form').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "add_authorized_user",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        accountId: localStorage.getItem('lumos_login_account_id_current'),
        NameFirst: $('#ns-auth-user-firstname').val(),
        NameMiddle: $('#ns-auth-user-mi').val(),
        NameLast: $('#ns-auth-user-lastname').val(),
        NameSuffix: $('#ns-auth-user-suffix').val(),
        Email: $('#ns-auth-user-email').val(),

        AddressHouseNum: $('#ns-auth-user-address-street-number').val(),
        AddressApartmentNum: $('#ns-auth-user-address-street-apt').val(),
        AddressPoBox: $('#ns-auth-user-address-street-pobox').val(),

        AddressStreetID: $('#ns-auth-user-address-street-name-id').val(),
        AddressCityID: $('#ns-auth-user-address-city-id').val(),
        AddressStateID: $('#ns-auth-user-address-state-id').val(),
        AddressZip: $('#ns-auth-user-address-zip').val(),

        SSN: $('#ns-auth-user-ssn').val(),

        Phone: $('#ns-auth-user-contact-number').val(),
        PhoneExt: $('#ns-auth-user-contact-number-ext').val(),
        PhoneAlt: $('#ns-auth-user-alt-contact-number').val(),
        PhoneAltExt: $('#ns-auth-user-alt-contact-number-ext').val()
      },
      success: function(result) {
        // console.log(result);
        var contactAddData = JSON.parse(result);

        if(contactAddData.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_user'});
        } else {
          $('form#ns-auth-user-form div[data-abide-error]').html($('form#ns-auth-user-form div[data-abide-error]').text() + '<br>' + contactAddData.Message);
          $('form#ns-auth-user-form div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });


  // Edit Authorized Users
  $('#ns-auth-user-submit-edit').click(function(e) {
    e.preventDefault();
    $('form#ns-auth-user-form-edit').foundation("validateForm");
  });

  $('form#ns-auth-user-form-edit button').click(function(e) {
    e.preventDefault();
  });

  $('form#ns-auth-user-form-edit').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "update_authorized_user",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        accountId: localStorage.getItem('lumos_login_account_id_current'),
        contactID: $('#ns-auth-user-contact-id-edit').val(),
        NameFirst: $('#ns-auth-user-firstname-edit').val(),
        NameMiddle: $('#ns-auth-user-mi-edit').val(),
        NameLast: $('#ns-auth-user-lastname-edit').val(),
        NameSuffix: $('#ns-auth-user-suffix-edit').val(),
        Email: $('#ns-auth-user-email-edit').val(),

        AddressHouseNum: $('#ns-auth-user-address-street-number-edit').val(),
        AddressApartmentNum: $('#ns-auth-user-address-street-apt-edit').val(),
        AddressPoBox: $('#ns-auth-user-address-street-pobox-edit').val(),

        AddressStreetID: $('#ns-auth-user-address-street-name-id-edit').val(),
        AddressCityID: $('#ns-auth-user-address-city-id-edit').val(),
        AddressStateID: $('#ns-auth-user-address-state-id-edit').val(),
        AddressZip: $('#ns-auth-user-address-zip-edit').val(),

        SSN: $('#ns-auth-user-ssn-edit').val(),

        Phone: $('#ns-auth-user-contact-number-edit').val(),
        PhoneExt: $('#ns-auth-user-contact-number-ext-edit').val(),
        PhoneAlt: $('#ns-auth-user-alt-contact-number-edit').val(),
        PhoneAltExt: $('#ns-auth-user-alt-contact-number-ext-edit').val()
      },
      success: function(result) {
        // console.log(result);
        var contactAddData = JSON.parse(result);

        if(contactAddData.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_user'});
        } else {
          $('form#ns-auth-user-form-edit div[data-abide-error]').html($('form#ns-auth-user-form-edit div[data-abide-error]').text() + '<br>' + contactAddData.Message);
          $('form#ns-auth-user-form-edit div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });

  // Remove Authorized User
  $('#ns-delete-contact-id').click(function() {
    var contactID = $(this).attr('data-remove-contact-id');
    $('.full-loader').removeClass('hide');
    // console.log(contactID);

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "delete_authorized_user",
        sessionId: ns_session_id,
        contactID: contactID
      },
      success: function(result) {
        // console.log(result);
        var contactDeleteResult = JSON.parse(result);

        if(contactDeleteResult.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_user'});
        } else {
          $('.full-loader').addClass('hide');
        }
      }
    });
  });

  // Add Automatic Payments
  $('#ns-auto-payment-submit').click(function(e) {
    e.preventDefault();
    $('form#automatic-payment').foundation("validateForm");
  });

  $('form#automatic-payment').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_automatic_payment",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        accountId: localStorage.getItem('lumos_login_account_id_current'),
        PaymentProfileId: $('#ns-auto-payment-method-card').val(),
        CustomerProfileId: $('#ns-auto-payment-method-card').find(':selected').attr('data-customer-profile-id'),
        PaymentProfileClassId: 1,
        // ExpirationDate: $('#ns-auto-payment-method-card').find(':selected').attr('data-cc-expiration'),
        LastFour: $('#ns-auto-payment-method-card').find(':selected').attr('data-cc-lastfour'),
        PaymentProfileClassId: $('#ns-auto-payment-method-card').find(':selected').attr('data-payment-profile-class-id'),
        Description: $('#ns-auto-payment-method-card').find(':selected').attr('data-cc-description'),
        Recurring: true,
        RecurringStartDate: formatDateAPI($('#ns-auto-payment-date').val()),
        CreditCardTypeId: $('#ns-auto-payment-method-card').find(':selected').attr('data-cc-type-id')
      },
      success: function(result) {
        // console.log(result);
        var paymentData = JSON.parse(result);

        if(paymentData.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_payment'});
        } else {
          $('form#automatic-payment div[data-abide-error]').html($('form#automatic-payment div[data-abide-error]').text() + '<br>' + paymentData.Message);
          $('form#automatic-payment div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });


  // Add Bank Draft Automatic Payment
  $('#ns-auto-payment-bank-submit').click(function(e) {
    e.preventDefault();
    $('form#automatic-payment-bank-draft').foundation("validateForm");
  });

  $('form#automatic-payment-bank-draft').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    // Grab Form Data
    var formData = $('form#automatic-payment-bank-draft').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_bank_draft';
    formDataObj.sessionId = ns_session_id;
    formDataObj.PaymentDate = formatDateAPI(formDataObj.PaymentDate);

    console.log(formDataObj);

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        // console.log(result);
        var paymentData = JSON.parse(result);
        // console.log(paymentData.Data);

        if(paymentData.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_payment_bd'});
        } else {
          $('form#automatic-payment-bank-draft div[data-abide-error]').html("Oops! It looks like something went wrong. Please contact us at <a href=\"tel:3368863600\">336.886.3600</a> for futher assistance. ");
          $('form#automatic-payment-bank-draft div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });


  // Disabled Automatic Payments
  $('#ns-auto-payment-submit-disable').click(function(e) {
    e.preventDefault();
    $('form#automatic-payment-disable').foundation("validateForm");
  });

  $('form#automatic-payment-disable').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_automatic_payment",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        accountId: localStorage.getItem('lumos_login_account_id_current'),
        PaymentProfileId: $('#ns-auto-payment-profile-id-disable').val(),
        CustomerProfileId: $('#ns-auto-payment-customer-id-disable').val(),
        PaymentProfileClassId: 1,
        // ExpirationDate: $('#ns-auto-payment-method-card').find(':selected').attr('data-cc-expiration'),
        LastFour: $('#ns-auto-payment-last-four-disable').val(),
        PaymentProfileClassId: $('#ns-auto-payment-payment-profile-class-id-disable').val(),
        Description: $('#ns-auto-payment-description-disable').val(),
        Recurring: false,
        RecurringStartDate: null,
        CreditCardTypeId: $('#ns-auto-payment-credit-card-type-disable').val()
      },
      success: function(result) {
        console.log(result);
        var paymentData = JSON.parse(result);

        if(paymentData.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_payment'});
        } else {
          $('form#automatic-payment div[data-abide-error]').html($('form#automatic-payment div[data-abide-error]').text() + '<br>' + paymentData.Message);
          $('form#automatic-payment div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });


  // Disabled Automatic Bank draft
  $('#ns-auto-payment-bank-submit-disable').click(function(e) {
    e.preventDefault();
    $('form#automatic-payment-bank-disable').foundation("validateForm");
  });

  $('form#automatic-payment-bank-disable').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_delete_bank_draft",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        BankAccountID: $('#ns-auto-payment-bank-id').val(),
      },
      success: function(result) {
        // console.log(result);
        var draftData = JSON.parse(result);

        if(draftData.Status == 'success' && draftData.Data) {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_payment_bdd'});
        } else {
          $('form#automatic-payment-bank-disable div[data-abide-error]').html("Oops! It looks like something went wrong. Please contact us at <a href=\"tel:3368863600\">336.886.3600</a> for futher assistance. ");
          $('form#automatic-payment-bank-disable div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }

      }
    });

  });


  // Remove Payment Profile
  $('#ns-payent-methods-submit-remove').click(function() {
    $('*[data-delete-payment-account-name]').text($('#ns-payment-methods-list-remove option:selected').text());
    $('#ns-payent-methods-confirm-remove').attr('data-remove-profile-id', $('#ns-payment-methods-list-remove option:selected').val());
  });

  $('#ns-payent-methods-confirm-remove').click(function() {

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_delete_payment_profile",
        accountId: localStorage.getItem('lumos_login_account_id_current'),
        sessionId: ns_session_id,
        PaymentProfileId: $('#ns-payent-methods-confirm-remove').attr('data-remove-profile-id'),
      },
      success: function(result) {
        console.log(result);
        var removeConfirm = JSON.parse(result);

        if(removeConfirm.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_payment_d'});
        } else {
          window.location = window.location.href.split('?')[0];
        }
      }
    });
  });


  // Edit Payment Profile
  $('#ns-payment-method-edit').click(function(e) {
    e.preventDefault();
    $('form#edit-payment-method').foundation("validateForm");
  });

  $('#ns-payment-method-cancel').click(function(e) {
    e.preventDefault();
    $('#edit-method').addClass('hide');
  });

  $('form#edit-payment-method').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    console.log('Edit Payment');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_get_payment_profile",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        accountId: localStorage.getItem('lumos_login_account_id_current'),
        PaymentProfileId: $('#ns-payment-methods-list-edit').val(),
      },
      success: function(result) {

        var profileData = JSON.parse(result);

        if(profileData.Status == 'success') {

          var profileRequest = {
            action: "ns_update_payment",
            sessionId: ns_session_id,
            // accountId: window.localStorage.getItem('lumos_login_account_id_current')
            accountId: localStorage.getItem('lumos_login_account_id_current'),
            PaymentProfileId: profileData.Data.PaymentProfileId,
            CustomerProfileId: profileData.Data.CustomerProfileId,
            PaymentProfileClassId: profileData.Data.PaymentProfileClassId,
            LastFour: profileData.Data.LastFour,
            Description: $('#ns-payment-methods-nickname-edit').val(),
            Recurring: profileData.Data.Recurring,
            RecurringStartDate: profileData.Data.RecurringStartDate,
            CreditCardTypeId: profileData.Data.CreditCardTypeId
          };

          if(profileData.Data.CreditCardProfile !== null) {
            profileRequest.ExpirationDate = $('#ns-payment-methods-month-edit').val()+$('#ns-payment-methods-year-edit').val();
            // profileData.Data.CreditCardProfile.CardCode = $('#ns-payment-methods-cvv-edit').val();
            profileRequest.CreditCardProfile = {
              CardCode: $('#ns-payment-methods-cvv-edit').val()
            };
          } else {
            profileRequest.ExpirationDate = null;
          }

          // console.log(profileRequest);

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: profileRequest,
            success: function(result) {
              console.log(result);
              var pData = JSON.parse(result);

              // console.log(pData);

              if(pData.Status == 'success') {
                window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_payment_e'});
              } else {
                $('form#edit-payment-method div[data-abide-error]').html($('form#edit-payment-method div[data-abide-error]').text() + '<br>' + pData.Message);
                $('form#edit-payment-method div[data-abide-error]').show();
                $('.full-loader').addClass('hide');
              }
            }
          });
        } else {
          $('form#edit-payment-method div[data-abide-error]').html($('form#edit-payment-method div[data-abide-error]').text() + '<br>' + pData.Message);
          $('form#edit-payment-method div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      }
    });
  });

  // Show Add Payment Method Forms
  $('#ns-add-new-payment-method').click(function(e) {
    e.preventDefault();

    $(this).addClass('hide');
    $('#add-new-method').removeClass('hide');
  });

  // Hide Add Payment Method Forms
  $('#ns-cancel-add-bank-account-submit, #ns-cancel-add-cc-account-submit').click(function(e) {
    e.preventDefault();
    $('#add-new-method').addClass('hide');
    $('#ns-add-new-payment-method').removeClass('hide');
  });


  // Save Payment Profile - CC
  $('#ns-add-cc-account-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-add-cc-account-form').foundation("validateForm");
  });

  $('form#ns-add-cc-account-form').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_create_cc_payment_profile",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        accountId: localStorage.getItem('lumos_login_account_id_current'),

        FirstName: $('#ns-add-cc-full-name').val().split(' ').slice(0, -1).join(' '),
        LastName:  $('#ns-add-cc-full-name').val().split(' ').slice(-1).join(' '),
        NameOnAccount: $('#ns-add-cc-full-name').val(),
        Company: $('#ns-add-cc-account-company').val(),
        Address: $('#ns-add-cc-account-address').val(),
        City: $('#ns-add-cc-account-city').val(),
        State: $('#ns-add-cc-account-state').val(),
        Zip: $('#ns-add-cc-account-zip').val(),
        Country: $('#ns-add-cc-account-country').val(),
        PhoneNumber: $('#ns-add-cc-account-phonenumber').val(),

        CreditCardTypeId: $('#ns-add-cc-account-type').val(),
        CardNumber: $('#ns-add-cc-account-cardnumber').val(),
        CardCode: $('#ns-add-cc-account-cvv').val(),
        ExpirationDate: $('#ns-add-cc-account-cardmonth').val()+$('#ns-add-cc-account-cardyear').val(),

        Description: $('#ns-add-cc-nickname').val(),

        Recurring: false,
        RecurringStartDate: null
      },
      success: function(result) {
        // console.log(result);
        var profileData = JSON.parse(result);

        if(profileData.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_payment_a'});
        } else {
          $('form#ns-add-cc-account-form div[data-abide-error]').html($('form#ns-add-cc-account-form div[data-abide-error]').text() + '<br>' + profileData.Message);
          $('form#ns-add-cc-account-form div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      },
      error: function() {
        $('form#ns-add-cc-account-form div[data-abide-error]').show();
        $('.full-loader').addClass('hide');
      }
    });
  });


  // Save Payment Profile - Bank
  $('#ns-add-bank-account-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-add-bank-account-form').foundation("validateForm");
  });

  $('form#ns-add-bank-account-form').bind("formvalid.zf.abide", function(e,target) {
    e.preventDefault();
    $('.full-loader').removeClass('hide');
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_create_bank_payment_profile",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        accountId: localStorage.getItem('lumos_login_account_id_current'),

        FirstName: $('#ns-add-bank-full-name').val().split(' ').slice(0, -1).join(' '),
        LastName:  $('#ns-add-bank-full-name').val().split(' ').slice(-1).join(' '),
        NameOnAccount: $('#ns-add-bank-full-name').val(),
        Company: $('#ns-add-bank-account-company').val(),
        Address: $('#ns-add-bank-account-address').val(),
        City: $('#ns-add-bank-account-city').val(),
        State: $('#ns-add-bank-account-state').val(),
        Zip: $('#ns-add-bank-account-zip').val(),
        Country: $('#ns-add-bank-account-country').val(),
        PhoneNumber: $('#ns-add-bank-account-phonenumber').val(),

        RoutingNumber: $('#ns-add-bank-account-routingnumber').val(),
        AccountNumber: $('#ns-add-bank-account-accountnumber').val(),
        BankAccountType: $('#ns-add-bank-account-type').val(),
        BankName: $('#ns-add-bank-account-bankname').val(),

        Description: $('#ns-add-bank-nickname').val()
      },
      success: function(result) {
        // console.log(result);
        var profileData = JSON.parse(result);

        if(profileData.Status == 'success') {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_payment_a'});
        } else {
          $('form#ns-add-bank-account-form div[data-abide-error]').html($('form#ns-add-bank-account-form div[data-abide-error]').text() + '<br>' + profileData.Message);
          $('form#ns-add-bank-account-form div[data-abide-error]').show();
          $('.full-loader').addClass('hide');
        }
      },
      error: function() {
        $('form#ns-add-bank-account-form div[data-abide-error]').show();
        $('.full-loader').addClass('hide');
      }
    });
  });


  // Save profile Info
  $('#profile-save').click(function(e) {
    e.preventDefault();
    $('form#profile-info').foundation("validateForm");
  });

  $('form#profile-info').on('formvalid.zf.abide.zf.abide', function(e,target) {

    $('form#profile-info').parent('.callout').addClass('loading submit');

    // Grab Form Data
    var formData = $('form#profile-info').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj){
      formDataObj[obj.name] = obj.value;
    });
    formDataObj.action = 'ns_update_billing_contact';
    formDataObj.sessionId = ns_session_id;

    // console.log(formDataObj);

    // Update Contact
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        var submissionResponse = JSON.parse(result);
        // console.log(submissionResponse);
        // console.log(result);

        formDataObj.action = 'ns_update_pin';
        formDataObj.sessionId = ns_session_id;

        // Update Pin
        $.ajax({
          url: '/wp-content/themes/Lumos/api_helper.php',
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          data: formDataObj,
          success: function(result) {
            var updatePinResponse = JSON.parse(result);
            // console.log(updatePinResponse);
            // console.log(result);

            // location.reload();
            window.location.href = window.location.pathname+"?"+$.param({'status_m':'p_info'});
            // $('form#profile-info').parent('.callout').removeClass('loading submit');
          }
        });
      }
    });
  });


  // Save password change
  $('#password-save').click(function(e) {
    e.preventDefault();
    $('form#profile-pass').foundation("validateForm");
  });

  $('form#profile-pass').on('formvalid.zf.abide.zf.abide', function(e,target) {

    $('form#profile-pass').parent('.callout').addClass('loading submit');

    // Grab Form Data
    var formData = $('form#profile-pass').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj) {
      formDataObj[obj.name] = obj.value;
    });
    // formDataObj.action = 'ns_update_password';
    formDataObj.action = 'ns_password_check';

    // console.log(formDataObj);

    // Check Current Password
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        var ns_login_check = JSON.parse(result);

        // console.log(ns_login_check);

        if(ns_login_check.Status === "failure") {
          $('form#profile-pass div[data-abide-error]').show();
          $('form#profile-pass').foundation('addErrorClasses', $('#ns-password-old'));
          $('form#profile-pass').parent('.callout').removeClass('loading submit');
        } else if(ns_login_check.Status === "success") {

          // Update User Password
          formDataObj.action = 'ns_update_password';
          formDataObj.sessionId = ns_session_id;

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: formDataObj,
            success: function(result) {
              var ns_password_reset = JSON.parse(result);
              if(ns_login_check.Status === "failure") {
                $('form#profile-pass div[data-abide-error]').show();
                $('form#profile-pass').foundation('addErrorClasses', $('#ns-password-new'));
                $('form#profile-pass').foundation('addErrorClasses', $('#ns-password-new-retype'));
                $('form#profile-pass').parent('.callout').removeClass('loading submit');
              } else if(ns_login_check.Status === "success") {
                // location.reload();
                window.location.href = window.location.pathname+"?"+$.param({'status_m':'pass'});
              }
            }
          });
        }
      }
    });

  });


  // Save security question change
  $('#question-save').click(function(e) {
    e.preventDefault();
    $('form#profile-question').foundation("validateForm");
  });

  $('form#profile-question').on('formvalid.zf.abide.zf.abide', function(e,target) {

    $('form#profile-question').parent('.callout').addClass('loading submit');

    // Grab Form Data
    var formData = $('form#profile-question').serializeArray();
    var formDataObj = {};
    $(formData).each(function(index, obj) {
      formDataObj[obj.name] = obj.value;
    });
    // formDataObj.action = 'ns_update_password';

    // Check current question
    formDataObj.action = 'ns_check_security_question';

    // console.log(formDataObj);

    // Check Current Password
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: formDataObj,
      success: function(result) {
        var ns_question = JSON.parse(result);

        // console.log(ns_question);

        if(ns_question.Status === 'success') {
          if(ns_question.Data.Answer !== formDataObj['ns-security-answer-current']) {
            $('form#profile-question div[data-abide-error]').show();
            $('form#profile-question').foundation('addErrorClasses', $('#ns-security-answer-current'));
            $('form#profile-question').parent('.callout').removeClass('loading submit');
          } else {
            // Set question
            formDataObj.action = 'ns_update_security_question';
            formDataObj.sessionId = ns_session_id;

            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: formDataObj,
              success: function(result) {
                var ns_question_update = JSON.parse(result);

                // console.log(ns_question_update);

                if(ns_question_update.Status !== 'success') {
                  $('form#profile-question div[data-abide-error]').show();
                  $('form#profile-question').parent('.callout').removeClass('loading submit');
                } else {
                  // Reload on success to display new data
                  // location.reload();
                  window.location.href = window.location.pathname+"?"+$.param({'status_m':'s_quest'});
                }
              }
            });
          }
        }

      }
    });

  });

  if($('#review-payment').length) {
    $('#otheramount-total').on('blur change', function() {
      // console.log($(this).val(), parseFloat($(this).val()), parseFloat($(this).val()).toFixed(2));
      $(this).val(parseFloat($(this).val()).toFixed(2));
    });
  }

  // Review Payment
  $('#review-payment').click(function(e) {
    e.preventDefault();
    $('form#make-a-payment').foundation("validateForm");
  });

  $('form#make-a-payment').on('formvalid.zf.abide.zf.abide', function(e,target) {
    e.preventDefault();

    $('div[data-account-screen="payment-form"]').addClass('hide');
    $('div[data-account-screen="payment-form-confirm"]').removeClass('hide');

    var currentDate = new Date();

    var paymentAmount = 0;
    var paymentMethod = '';

    if($('input[name="selectedamount"]:checked').val() == 'totaldue') {
      paymentAmount = $('#totaldue-amount').val();
    } else {
      paymentAmount = $('#otheramount-total').val();
    }

    if($('input[name=paymentmethod]:checked').val() == 'bank') {
      paymentMethod = 'ECHECK';
    } else if($('input[name=paymentmethod]:checked').val() == 'card') {
      paymentMethod = 'CC';
    }

    $('#ns-payment-amount').text("$" + parseFloat(paymentAmount).toFixed(2));
    $('strong[data-payment-amount]').text("$" + parseFloat(paymentAmount).toFixed(2));
    $('#ns-payment-method').text(paymentMethod);

    switch($('input[name="schedulepayment"]:checked').val()) {
      case "paynow":
        $('#ns-payment-date').text((currentDate.getMonth()+1) + '/' + currentDate.getDate() + '/' + currentDate.getFullYear());
        $('#ns-payment-type').text('Instant Payment');
        break;
      case "schedulepayment":
        $('#ns-payment-date').text($('#schedule-payment-date').val());
        $('#ns-payment-type').text('Scheduled Payment');
        break;
      case "automaticpayment":
        $('#ns-payment-date').text($('#ns-auto-payment-date').val());
        $('#ns-payment-type').text('Automatic Recurring Payment');
        break;
    }

  });


  // Promise to Pay
  $('#submit-promise').click(function(e) {
    e.preventDefault();
    $('form#promise-to-pay').foundation("validateForm");
  });

  $('form#promise-to-pay').on('formvalid.zf.abide.zf.abide', function(e,target) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    var promiseDate = formatDateAPI($('#promise-payment-date').val()),
        promiseAmount = $('#promise-amount').val();

    console.log(localStorage.getItem('lumos_login_account_id_current'), promiseDate, promiseAmount);

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_promise_to_pay",
        sessionId: ns_session_id,
        // accountId: window.localStorage.getItem('lumos_login_account_id_current')
        accountId: localStorage.getItem('lumos_login_account_id_current'),

        PromiseDate: promiseDate,
        PromiseAmount: promiseAmount
      },
      success: function(result) {
        console.log(result);
        var promiseData = JSON.parse(result);

        if(promiseData.Status == 'success') {
          $('*[data-promise-confirmation]').text(promiseData.Data.PromiseToPayID);

          $('div[data-account-screen="promise-form"]').addClass('hide');
          $('div[data-account-screen="promise-form-success"]').removeClass('hide');
        } else {
          $('div[data-account-screen="promise-form"]').addClass('hide');
          $('div[data-account-screen="promise-form-failure"]').removeClass('hide');
        }

        $('.full-loader').addClass('hide');
      },
      error: function() {
        $('div[data-account-screen="promise-form"]').addClass('hide');
        $('div[data-account-screen="promise-form-failure"]').removeClass('hide');

        $('.full-loader').addClass('hide');
      }
    });
  });
});
