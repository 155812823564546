jQuery(document).ready(function($) {
  var timerInterval = 0;

  $('.fiber-speed-feature .spinner .spinner-reset').click(function() {

    clearInterval(timerInterval);

    $('.fiber-speed-feature .feature-progress').stop();
    $('.fiber-speed-feature .feature-progress').css({width: '0%'});
    $('.fiber-speed-feature .spinner').removeClass('complete');

    $('.fiber-speed-feature .spinner').fadeOut(600, function() {
      $('.fiber-speed-feature .start-timer').blur();
      $('.fiber-speed-feature .start-timer').fadeIn(600, function() {

      });
    });
  });

  $('.fiber-speed-feature .start-timer').click(function() {
    var spinnerStart = 0,
        timeLimit = 0,
        $progress_circle = $('.progress__value'),
        radius = $progress_circle.attr('r'),
        circumference = radius * 2 * Math.PI;

        $('.fiber-speed-feature .feature-item').each(function() {
          if(parseInt($(this).attr('data-time-span')) > timeLimit) {
            timeLimit = parseInt($(this).attr('data-time-span'));
          }
        });

        console.log(timeLimit);

        $progress_circle.css({
          'strokeDasharray' : circumference + ' ' + circumference,
          'strokeDashoffset': circumference - spinnerStart / (timeLimit-1) * circumference
        });

    setTimeout(function() {

      $('.fiber-speed-feature .start-timer').fadeOut(600, function() {
        $('.fiber-speed-feature .spinner').find('span').html(spinnerStart);

        $('.fiber-speed-feature .spinner').fadeIn(600, function() {
          // var timerInterval = 0;

          $('.fiber-speed-feature .feature-item').each(function() {
            //console.log(parseInt($(this).attr('data-time-span')) * 1000);
            $(this).find('.feature-progress').animate({
              width: '100%'
            }, parseInt($(this).attr('data-time-span')) * 1000);
          });

          spinnerStart++;
          $('.fiber-speed-feature .spinner').find('span').html(spinnerStart);
          $progress_circle.css({
            'strokeDasharray' : circumference + ' ' + circumference,
            'strokeDashoffset': circumference - spinnerStart / (timeLimit-1) * circumference
          });

          timerInterval = setInterval( function() {
            if(spinnerStart > timeLimit-1) {
              clearInterval(timerInterval);  // stop
              $('.fiber-speed-feature .spinner').addClass('complete');
            } else {
              spinnerStart++;
              $('.fiber-speed-feature .spinner').find('span').html(spinnerStart);
              if(spinnerStart < timeLimit) {
                $progress_circle.css({
                  'strokeDasharray' : circumference + ' ' + circumference,
                  'strokeDashoffset': circumference - spinnerStart / (timeLimit-1) * circumference
                });
              }
            }
          }, 1000 );  // run

        });
      });

    }, 1000);
  });
});
