// Checkout steps
var orderStep = 1,
  orderStepMax = 0,
  cookieLabel_product_selection = "lumos_product_selection",
  creditCheckAmount = 0,
  creditCheckDepositAmount = 0,
  creditCheckType = '',
  isProcessingOrder = false,
  ns_ecomm_session_id_name = '',
  nsTimer = 0,
  scheduleTries = 0,
  orderaccountid = -1,
  orderserviceid = -1,
  orderStreet = '',
  orderCity = '',
  orderState = '',
  orderZIP = '';

var hasClickedContinueShopping = false;

$('#exit-message-continue-shopping').click(function() {
  hasClickedContinueShopping = true;
});

jQuery(document).ready(function($) {

});

// (function($) {

  // Load product listing template
  reloadEcommProducts();

  // Only perform actions when ecomm element is available
  if ($('#order-info').length) {

    console.log('lets do ecomm');

    // Order Status Modal
    var orderModal = new Foundation.Reveal($('#orderSubmitModal'));
    var orderModal_NonAPI = new Foundation.Reveal($('#orderSubmitModal-nonapi'));

    // Set up sorting grid w/ Isotope
    var $product_grid = $('#ns_available_products').isotope({
      itemSelector: '.product-row',
      getSortData: {
        price: function(itemElem) {
          var price = $(itemElem).attr('data-product-price');
          return parseFloat(price.replace(/[\(\)]/g, ''));
        }
      }
    });

    $(window).bind('beforeunload', function() {
      if (isProcessingOrder) {
        return "We haven't completed processing your order. Are you sure you want to leave?";
      }
    });

    $('#account_service_check_address').change(function() {
      if ($(this).val()) {
        $('#account_service_check_address').text('Change Address');
      }
    });

    // Open/Close mobile price tray
    $('[data-expand-mobile-price]').click(function(e) {
      e.preventDefault();
      $('#pricing-detail-accordion').foundation('toggle', $('#price-detail'));
      // $('.mobile-bottom-price .expand-button').toggleClass('closed');
    });

    // Control for the accordion button state (up)
    $('#pricing-detail-accordion').on('up.zf.accordion', function() {
      $('.mobile-bottom-price-new .expand-button').addClass('closed');
      $('.reveal-overlay[data-mobile-bottom-price-overlay]').hide();
    });

    // Control for the accordion button state (down)
    $('#pricing-detail-accordion').on('down.zf.accordion', function() {
      $('.mobile-bottom-price-new .expand-button').removeClass('closed');
      $('.reveal-overlay[data-mobile-bottom-price-overlay]').show();
    });

    $('.reveal-overlay[data-mobile-bottom-price-overlay]').click(function() {
      $('#pricing-detail-accordion').foundation('up', $('#price-detail'));
    });

    // Credit check payment form
    $('#credit-check-deposit-cancel, #ns-credit-check-bank-payment-cancel, #ns-credit-check-cc-payment-cancel').click(function(e) {
      cancelOrder();
    });

    $('#credit-check-deposit-continue').click(function(e) {
      $('#credit-check-deposit').addClass('hide');
      $('#credit-check-payment').removeClass('hide');
    });

    $('#ns-credit-check-cc-cardmonth, #ns-credit-check-cc-cardyear').change(function() {
      $('#ns-credit-check-cc-cardexp').val($('#ns-credit-check-cc-cardyear').val() + $('#ns-credit-check-cc-cardmonth').val());
    });

    // Credit Check CC Payment
    $('form#ns-credit-check-cc-payment').submit(function(e){
      e.preventDefault();
    });

    $('form#ns-credit-check-cc-payment').bind("formvalid.zf.abide", function(e, target) {
      // logNSEvent("ecomm - order processing - Run Credit Check: Submit Payment (Processing Step 4)");
      e.preventDefault();
      // $(this).find('.full-loader').removeClass('hide');

      // hide form
      $('#credit-check-payment').addClass('hide');

      // timeline spinner
      $('.submission-indicator li').eq(2).removeClass('alert');
      $('.submission-indicator li').eq(2).addClass('current');

      var formData = $(this).serializeArray();
      var formDataObj = {};
      $(formData).each(function(index, obj) {
        formDataObj[obj.name] = obj.value;
      });
      formDataObj.action = 'ns_make_payment_cc';
      formDataObj.FirstName = formDataObj.NameOnAccount.split(' ').slice(0, -1).join(' ');
      formDataObj.LastName = formDataObj.NameOnAccount.split(' ').slice(-1).join(' ');

      var paymentDate = new Date();
      formDataObj.PaymentDate = formatDateAPI(paymentDate);
      formDataObj.sessionId = localStorage.getItem(ns_ecomm_session_id_name);

      formDataObj.ExpirationDate = formDataObj.CardMonth + formDataObj.CardYear;

      console.log(formDataObj);

      var $formElem = $(this);

      var serviceOrderID = $('#ns-credit-check-cc-serviceid').val();

      // NS GTM Timer Start
      startNSTimer();

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: formDataObj,
        success: function(result) {
          console.log(result);
          var response = $.parseJSON(result);

          if (response.Status == 'success') {
            // NS GTM Timer End
            sendNSTimer('Step 3.3 - ns_make_payment_cc - POST: Customer/Account/[AccountID]/Billing/Payment/InstantCreditCard/');

            // logNSEvent("ecomm - order processing - Run Credit Check: Submit Payment: Payment Success (Processing Step 4)");
            var additionalInfo = 'Credit Check Results: $' + creditCheckAmount + ' via ' + creditCheckType + '';

            // NS GTM Timer Start
            startNSTimer();

            // createServiceRequest(accountID);
            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "set_service_comments",
                serviceOrderID: serviceOrderID,
                comment: additionalInfo,
                sessionId: localStorage.getItem(ns_ecomm_session_id_name)
              },
              success: function(result) {
                console.log(result);

                var response = $.parseJSON(result);

                console.log(response);

                if (response.Status === "success") {
                  // NS GTM Timer End
                  sendNSTimer('Step 3.4 - set_service_comments - POST: Service/ServiceOrder/Comment/');
                  // scheduleInstallation(serviceID, serviceOrderID);
                  scheduleInstallation($('#ns-credit-check-cc-serviceid').val(), $('#ns-credit-check-cc-serviceorderid').val());
                } else {
                  // NS GTM Timer End
                  sendNSError('Step 3.4 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Error: ' + response.Message);
                  scheduleInstallation($('#ns-credit-check-cc-serviceid').val(), $('#ns-credit-check-cc-serviceorderid').val());
                }
              },
              error: function(result) {
                // NS GTM Timer End
                sendNSError('Step 3.4 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Comm Error');
                scheduleInstallation($('#ns-credit-check-cc-serviceid').val(), $('#ns-credit-check-cc-serviceorderid').val());
              }
            });

          } else {
            // NS GTM Timer End
            sendNSError('Step 3.3 - Error - ns_make_payment_cc - POST: Customer/Account/[AccountID]/Billing/Payment/InstantCreditCard/ - API Error: ' + response.Message);

            // logNSEvent("ecomm - order processing - Error: Run Credit Check: Submit Payment: Payment Error - "+response.Message+" (Processing Step 4)");

            $('.submission-indicator li').eq(2).removeClass('current');
            $('.submission-indicator li').eq(2).addClass('alert');

            $('#credit-check-payment').removeClass('hide');

            $formElem.find('[data-abide-error]').text('We encountered the following error: ' + response.Message);
            $formElem.find('[data-abide-error]').show();
            // $formElem.find('.full-loader').addClass('hide');
          }
        },
        error: function(result) {
          // NS GTM Timer End
          sendNSError('Step 3.3 - Error - ns_make_payment_cc - POST: Customer/Account/[AccountID]/Billing/Payment/InstantCreditCard/ - API Comm Error');

          $('.submission-indicator li').eq(2).removeClass('current');
          $('.submission-indicator li').eq(2).addClass('alert');

          $('#credit-check-payment').removeClass('hide');

          $formElem.find('[data-abide-error]').text('We encountered an error. Please try again later or you may contact our customer support at 866.542.5900');
          $formElem.find('[data-abide-error]').show();
        }
      });

    });

    // Schedule Installation
    $('form#ns-schedule-install').submit(function(e){
      e.preventDefault();
    });

    $('form#ns-schedule-install').bind("formvalid.zf.abide", function(e, target) {

      e.preventDefault();

      scheduleTries++; // Add 1 to try counter

      $('.submission-indicator li').eq(2).addClass('current');
      $('.submission-indicator li').eq(2).removeClass('error');
      $('.submission-indicator li').eq(2).removeClass('alert');

      $('#schedule-installation-date').addClass('hide');
      $('form#ns-schedule-install').find('[data-abide-error]').hide();

      var formData = $(this).serializeArray();
      var formDataObj = {};
      $(formData).each(function(index, obj) {
        formDataObj[obj.name] = obj.value;
      });
      formDataObj.action = 'schedule_service_installation';
      formDataObj.sessionId = localStorage.getItem(ns_ecomm_session_id_name);

      console.log(formDataObj);

      // NS GTM Timer Start
      startNSTimer();

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: formDataObj,
        success: function(result) {

          // logNSEvent("ecomm - order processing - Schedule Installation: Install Service Scheduled (Processing Step 4)");
          console.log(result);
          var response = $.parseJSON(result);

          if (response.Status == 'success') {
            // NS GTM Timer End
            if(scheduleTries > 0) {
              sendNSTimer('Step 4.3 - schedule_service_installation - POST: ScheduleItem/ - Attempt: ' + scheduleTries);
            } else {
              sendNSTimer('Step 4.3 - schedule_service_installation - POST: ScheduleItem/');
            }

            // NS GTM Timer Start
            startNSTimer();

            // Add service install comment
            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "set_service_comments",
                serviceOrderID: $('#ns-schedule-install-service-order-id').val(),
                comment: 'Special Installation Instructions: ' + $('#ns-installation-instructions').val(),
                sessionId: localStorage.getItem(ns_ecomm_session_id_name)
              },
              success: function(result) {
                // NS GTM Timer End
                if(scheduleTries > 0) {
                  sendNSTimer('Step 4.4 - set_service_comments - POST: Service/ServiceOrder/Comment/ - Attempt: ' + scheduleTries);
                } else {
                  sendNSTimer('Step 4.4 - set_service_comments - POST: Service/ServiceOrder/Comment/');
                }

                console.log(result);

                // NS GTM Timer Start
                startNSTimer();

                // Move Order Forward
                $.ajax({
                  url: '/wp-content/themes/Lumos/api_helper.php',
                  method: 'POST',
                  contentType: 'application/x-www-form-urlencoded',
                  data: {
                    action: "move_order_forward_alt",
                    serviceOrderId: $('#ns-schedule-install-service-order-id').val(),
                    sessionId: localStorage.getItem(ns_ecomm_session_id_name)
                  },
                  success: function(result) {
                    // NS GTM Timer End
                    if(scheduleTries > 0) {
                      sendNSTimer('Step 4.5 - move_order_forward_alt - GET: /ServiceOrder/MoveOrderForwardAlt/[ServiceOrderID] - Attempt: ' + scheduleTries);
                    } else {
                      sendNSTimer('Step 4.5 - move_order_forward_alt - GET: /ServiceOrder/MoveOrderForwardAlt/[ServiceOrderID]');
                    }

                    // logNSEvent("ecomm - order processing - Schedule Installation: Move Order Forward Alt (Processing Step 4)");
                    console.log('move order forward alt', $('#ns-schedule-install-service-order-id').val(), result);

                    // NS GTM Timer Start
                    startNSTimer();

                    $.ajax({
                      url: '/wp-content/themes/Lumos/api_helper.php',
                      method: 'POST',
                      contentType: 'application/x-www-form-urlencoded',
                      data: {
                        action: "move_order_forward",
                        serviceOrderId: $('#ns-schedule-install-service-order-id').val(),
                        sessionId: localStorage.getItem(ns_ecomm_session_id_name)
                      },
                      success: function(result) {
                        // NS GTM Timer End
                        if(scheduleTries > 0) {
                          sendNSTimer('Step 4.6 - move_order_forward - GET: /ServiceOrder/MoveOrderForward/[ServiceOrderID] - Attempt: ' + scheduleTries);
                        } else {
                          sendNSTimer('Step 4.6 - move_order_forward - GET: /ServiceOrder/MoveOrderForward/[ServiceOrderID]');
                        }

                        // logNSEvent("ecomm - order processing - Schedule Installation: Move Order Forward (Processing Step 4)");
                        console.log('move order forward', $('#ns-schedule-install-service-order-id').val(), result);
                        orderComplete();
                      },
                      error: function(result) {
                        // NS GTM Timer End
                        if(scheduleTries > 0) {
                          sendNSTimer('Step 4.6 - Error - move_order_forward - GET: /ServiceOrder/MoveOrderForward/[ServiceOrderID] - API Comm Error - Attempt: ' + scheduleTries);
                        } else {
                          sendNSTimer('Step 4.6 - Error - move_order_forward - GET: /ServiceOrder/MoveOrderForward/[ServiceOrderID] - API Comm Error');
                        }
                        // stopNSTimer();

                        // logNSEvent("ecomm - order processing - Error: Schedule Installation: Cannot Move Order Forward, Sent To Order Complete Screen (Processing Step 4)");
                        console.log('move order forward-err', $('#ns-schedule-install-service-order-id').val(), result);
                        orderComplete();
                      }
                    });
                  },
                  error: function(result) {
                    // NS GTM Timer End
                    if(scheduleTries > 0) {
                      sendNSError('Step 4.5 - Error - move_order_forward_alt - GET: /ServiceOrder/MoveOrderForwardAlt/[ServiceOrderID] - API Comm Error - Attempt: ' + scheduleTries);
                    } else {
                      sendNSError('Step 4.5 - Error - move_order_forward_alt - GET: /ServiceOrder/MoveOrderForwardAlt/[ServiceOrderID] - API Comm Error');
                    }
                    // stopNSTimer();

                    // logNSEvent("ecomm - order processing - Error: Schedule Installation: Cannot Move Order Forward Alt, Sent To Order Complete Screen (Processing Step 4)");
                    console.log('move order forward alt-err', $('#ns-schedule-install-service-order-id').val(), result);
                    orderComplete();
                  }
                });

              },
              error: function(result) {
                // NS GTM Timer End
                if(scheduleTries > 0) {
                  sendNSError('Step 4.4 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Comm Error - Attempt: ' + scheduleTries);
                } else {
                  sendNSError('Step 4.4 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Comm Error');
                }
                // stopNSTimer();

                // logNSEvent("ecomm - order processing - Error: Schedule Installation: Cannot Move Order Forward Alt - API Error, Sent To Order Complete Screen (Processing Step 4)");
                console.log('service comments err');
                orderComplete();
              }
            });

          } else {
            // NS GTM Timer End
            if(scheduleTries > 0) {
              sendNSError('Step 4.3 - Error - schedule_service_installation - POST: ScheduleItem/ - API Error: ' + response.Message + ' - Attempt: ' + scheduleTries);
            } else {
              sendNSError('Step 4.3 - Error - schedule_service_installation - POST: ScheduleItem/ - API Error: ' + response.Message);
            }
            // stopNSTimer();

            // logNSEvent("ecomm - order processing - Error: Schedule Installation: API Error - " +response.Message+ " (Processing Step 4)");

            // Only display eror if this is not the second try
            if(scheduleTries < 2) {

              // Show error
              $('form#ns-schedule-install').find('[data-abide-error]').text("The date/time selected is no longer available. Please select another date/time.");
              $('form#ns-schedule-install').find('[data-abide-error]').removeClass('hide');
              $('form#ns-schedule-install').find('[data-abide-error]').show();
              // re-fetch Available Dates
              // getScheduleDateTime($('#ns-schedule-install-service-id').val());
              getFacilitySplitterCheck($('#ns-schedule-install-service-id').val());

              // $('#schedule-installation-date').removeClass('hide');

            } else {
              // NS GTM Timer Start
              startNSTimer();

              $.ajax({
                url: '/wp-content/themes/Lumos/api_helper.php',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: {
                  action: "set_service_comments",
                  serviceOrderID: $('#ns-schedule-install-service-order-id').val(),
                  comment: 'Auto Scheduling Failed - Requested Date & Time: ' + $('#ns-installation-date').val() + ' ' + $('#ns-installation-time').find('option[value="'+$('#ns-installation-time').val()+'"]:first').text(),
                  sessionId: localStorage.getItem(ns_ecomm_session_id_name)
                },
                success: function(result) {
                  sendNSTimer('Step 4.7 - set_service_comments - POST: Service/ServiceOrder/Comment/ - Attempt: ' + scheduleTries);
                  orderComplete();
                },
                error: function(result) {
                  sendNSError('Step 4.7 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Comm Error - Attempt: ' + scheduleTries);
                  orderComplete();
                }
              });
            }

          }

        },
        error: function(result) {
          // NS GTM Timer End
          if(scheduleTries > 0) {
            sendNSError('Step 4.3 - Error - schedule_service_installation - POST: ScheduleItem/ - API Comm Error - Attempt: ' + scheduleTries);
          } else {
            sendNSError('Step 4.3 - Error - schedule_service_installation - POST: ScheduleItem/ - API Comm Error');
          }
          // stopNSTimer();

          // NS GTM Timer Start
          startNSTimer();

          // logNSEvent("ecomm - order processing - Error: Schedule Installation: API Error, Error Message Shown (Processing Step 4)");

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "set_service_comments",
              serviceOrderID: $('#ns-schedule-install-service-order-id').val(),
              comment: 'Auto Scheduling Failed - Could not retrieve available dates from website API.',
              sessionId: localStorage.getItem(ns_ecomm_session_id_name)
            },
            success: function(result) {
              sendNSTimer('Step 4.7 - set_service_comments - POST: Service/ServiceOrder/Comment/ - Attempt: ' + scheduleTries);
              orderComplete();
            },
            error: function(result) {
              sendNSError('Step 4.7 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Comm Error - Attempt: ' + scheduleTries);
              orderComplete();
            }
          });
        }
      });

    });

    $('#orderSubmitModal .close-reveal-modal').click(function() {
      // logNSEvent("ecomm - button click - Order Processing Button: "+$(this).text());
      orderModal.close();
      orderModal_NonAPI.close();
    });

    // Set delay and relayout Isotope when expandin accordion
    $('#ns_available_products .accordion-item').click(function(e) {
      setTimeout(function() {
        $product_grid.isotope('layout');
      }, 200);
    });

    // Price sorting update grid
    $('#lumos_product_sort_price').on('change', function() {
      switch ($(this).val()) {
        case 'asc':
          $product_grid.isotope({
            sortBy: 'price',
            sortAscending: true
          });
          break;
        case 'desc':
          $product_grid.isotope({
            sortBy: 'price',
            sortAscending: false
          });
          break;
        default:
          break;
      }
    });

    // Service filter actions
    $('#lumos_product_filter_services input[type=checkbox]').on('change', function() {

      var servicesSortValues = [];

      $('#lumos_product_filter_services input[type=checkbox]:checked').each(function() {
        servicesSortValues.push($(this).attr('data-filter'));
      });

      $product_grid.isotope({
        filter: servicesSortValues.join(", ")
      });
    });

    // Update fiber indictor/sort on change
    $('#fiber_speed').change(updateFiberSpeedSort);

    // Sort Actions
    $('.fiber-sort input').change(function() {
      if ($(this).is(':checked')) {
        $(this).parent('label').addClass('highlight');
      } else {
        $(this).parent('label').removeClass('highlight');
      }
    }); // end $('.fiber-sort input').change

    $('#account_service_check_address, label[for="address_service_geocoded"], #address_service_geocode_mask').click(function() {
      $('#address-checker').toggleClass('open');
      $('#street1').focus();
      // logNSEvent('ecomm - button click - Open Address Checker from Form');
    });

    $('#account_service_prefix, #account_service_first_name, #account_service_middle_name, #account_service_last_name, #account_service_suffix').on('change keyup', function() {
      // var accountName = $('#account_service_prefix').val() + ' ' + $('#account_service_first_name').val() + ' ' + $('#account_service_middle_name').val() + ' ' + $('#account_service_last_name').val() + ' ' + $('#account_service_suffix').val();
      var accountName = $('#account_service_first_name').val() + ' ' + $('#account_service_last_name').val();
      $('#service_account_final').text(accountName);
    });

    $('#account_service_contact_phone, #account_service_contact_phone_ext').on('change keyup', function() {
      // $('#service_contact_final').text($('#account_service_contact_phone').val() + ' ' + $('#account_service_contact_phone_ext').val());
      $('#service_contact_final').text($('#account_service_contact_phone').val());
    });

    $('#account_service_contact_email').on('change keyup', function() {
      if($('#account_service_email_address_use_contact').prop('checked')) {
        $('#account_service_email_address').val($(this).val());
      }
      $('#service_email_final').text($(this).val());
    });

    // Step 1 validation
    $('.order-step input[name="schedule-availability"]').change(function() {
      if ($(this).val() === "schedule") {
        $('#schedule-availability-date-day').prop('required', true);
        $('#schedule-availability-date-time').prop('required', true);
        $('#schedule-availability-date-day').prop('disabled', false);
        $('#schedule-availability-date-time').prop('disabled', false);
      } else {
        $('#schedule-availability-date-day').prop('required', false);
        $('#schedule-availability-date-time').prop('required', false);
        $('#schedule-availability-date-day').prop('disabled', true);
        $('#schedule-availability-date-time').prop('disabled', true);
        updateInstallationSchedule('At the first available time.');
      }
    });

    $('#schedule-availability-date-day, #schedule-availability-date-time').change(function() {
      var installText = 'On a ' + $('#schedule-availability-date-day').find("option:selected").text() + ' ' + $('#schedule-availability-date-time').find("option:selected").text();
      updateInstallationSchedule(installText);
    });

    $("#order-schedule-installation").bind("formvalid.zf.abide", function(e, target) {
      orderAdvance();
    });

    // Step 2 validation
    $("#order-customer-info").bind("forminvalid.zf.abide", function(e, target) {
      // logNSEvent("ecomm - form validation error - Correct Customer Info (Step 2) ");
      $([document.documentElement, document.body]).animate({
        scrollTop: $('#order-info').offset().top - ($('.top-nav').outerHeight() || 0)
      }, 400);
    });

    $("#order-customer-info").bind("formvalid.zf.abide", function(e, target) {

      // $('#account_service_email_address').val($('#account_service_contact_email').val());
      if($('#account_service_email_address_use_contact').prop('checked')) {
        $('#account_service_email_address').val($('#account_service_contact_email').val());
      }

      $('[data-ecomm-working]').removeClass('hide');

      // Set service address
      $('#service_address_final').text($('#account_service_geocoded').val());

      // Set billing address
      if ($('#address_service_billing_same').prop('checked')) {
        $('#service_billing_final').text($('#account_service_geocoded').val());
      } else {
        var billAddress = $('#address_service_billing_different_city').val() + ' ' + $('#address_service_billing_different_zip').val();

        if ($('#address_service_billing_physical').prop('checked')) {
          var aptNumber = '';
          if ($('#address_service_billing_different_apartment').val().length) {
            aptNumber = $('#address_service_billing_different_apartment').val();
          }
          billAddress = $('#address_service_billing_different_street_number').val() + ' ' + $('#address_service_billing_different_street_name').val() + ' ' + aptNumber + ' ' + billAddress;
        } else {
          billAddress = 'PO Box ' + $('#address_service_billing_different_pobox').val() + ' ' + billAddress;
        }

        $('#service_billing_final').text(billAddress);
      }

      // Set account information
      $('#service_username_final').text($('#account_service_email_address').val());
      $('#service_question_final').text($('#account_service_security_question').val());
      $('#service_answer_final').text($('#account_service_security_answer').val());

      // Send data to Hubspot
      // if(!$('[data-hubspot-multigig]').hasClass('hide') && $('[name="account_service_multigig_available"]:checked').val() == "YES") {
      //   console.log('Authorized to send info to Hubspot');
      //
      //   var formData = {
      //     "fields": [
      //       {
      //         "name": "firstname",
      //         "value": $('#account_service_first_name').val()
      //       },
      //       {
      //         "name": "lastname",
      //         "value": $('#account_service_last_name').val()
      //       },
      //       {
      //         "name": "phone",
      //         "value": $('#account_service_contact_phone').val()
      //       },
      //       {
      //         "name": "email",
      //         "value": $('#account_service_contact_email').val()
      //       },
      //       {
      //         "name": "address",
      //         "value": localStorage.getItem('lumos_address_street')
      //       },
      //       {
      //         "name": "city",
      //         "value": localStorage.getItem('lumos_address_city')
      //       },
      //       {
      //         "name": "state",
      //         "value": localStorage.getItem('lumos_address_state')
      //       },
      //     ],
      //     "legalConsentOptions": {
      //       "consent": { // Include this object when GDPR options are enabled
      //         "consentToProcess": true,
      //         "text": "I agree to allow Example Company to store and process my personal data.",
      //         "communications": [
      //           {
      //             "value": true,
      //             "subscriptionTypeId": 999,
      //             "text": "I agree to receive marketing communications from Example Company."
      //           }
      //         ]
      //       }
      //     }
      //   };
      //
      //   $.ajax({
      //     type: "POST",
      //     url: "https://api.hsforms.com/submissions/v3/integration/submit/20327025/4aea9f56-6d08-43b8-8854-805a263fb111",
      //     cache: false,
      //     contentType: 'application/json',
      //     processData: false,
      //     data: JSON.stringify(formData),
      //     success: function (data) {
      //       console.log('Hubspot Submission was successful.');
      //       console.log(data);
      //     },
      //     error: function (data) {
      //       console.log('An error occurred with Hubspot.');
      //       console.log(data);
      //     },
      //   });
      // }

      $('[data-ecomm-working]').addClass('hide');
      orderAdvance();

      /*
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        type: 'GET',
        // dataType: "json",
        data: {
          "action": "check_writeoff",
          "name_first": $('#account_service_first_name').val(),
          "name_middle": $('#account_service_middle_name').val(),
          "name_last": $('#account_service_last_name').val(),
          "ssn": $('#address_service_ssn').val(),
          "email": $('#account_service_contact_email').val(),
          "phone": $('#account_service_contact_phone').val(),
          "address_1": localStorage.getItem('lumos_address_street'),
          "city": localStorage.getItem('lumos_address_city'),
          "state": localStorage.getItem('lumos_address_state'),
          "zip": localStorage.getItem('lumos_address_zip')
        },
        success: function(result) {
          console.log(result);
          var writeoffData = JSON.parse(result);

          if (writeoffData.Status == 'success') {
            if (writeoffData.Data) {

              $('[data-customer-information]').addClass('hide');
              $('[data-writeoff-message]').removeClass('hide');

              $('#sticky-progress').addClass('hide');

              $([document.documentElement, document.body]).animate({
                scrollTop: $('#order-info').offset().top - ($('.top-nav').outerHeight() || 0)
              }, 400);

            } else {
              orderAdvance();
            }
          } else {
            orderAdvance();
          }

          $('[data-ecomm-working]').addClass('hide');
        }
      });
      */

      // orderAdvance();
    });

    // NS ID Email Address checkbox
    $('#account_service_email_address_use_contact').change(function() {
      if ($(this).prop('checked')) {
        $('#account_service_email_address').val($('#account_service_contact_email').val());
        $('#account_service_email_address').prop('readonly', true);
      } else {
        $('#account_service_email_address').val('');
        $('#account_service_email_address').prop('readonly', false);
      }
    });

    // Step 3 validation
    $('.order-step input#termsconditions, input#termsconditions-mobile').change(function() {
      var tcID = $(this).attr('ID');
      var termsChecked = $(this).prop('checked');
      $('input#termsconditions, input#termsconditions-mobile').each(function() {
        if($(this).attr('ID') !== tcID ) {
          $(this).prop('checked', termsChecked);
        }
      });
      $('.order-step[data-order-step="3"] button.next-step').prop('disabled', !termsChecked);
    });

    // Next button actions
    $('.order-step button.next-step, .mobile-bottom-price button.next-step, .mobile-bottom-price-new button.next-step').click(function() {

      console.log("Step", $(this).parent('.order-step').attr('data-order-step'));

      switch ($(this).parent('.order-step').attr('data-order-step')) {
        case "1":
          //$('#order-schedule-installation').foundation("validateForm");
          $('[data-ecomm-working]').removeClass('hide');
          var feature_stash = $.parseJSON(localStorage.getItem(cookieLabel_product_selection));


          if(!uses_nsapi) {
            $('[data-ecomm-working]').addClass('hide');
            orderAdvance();
          } else {
            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "get_fees",
                productID: feature_stash.product_ns_id
              },
              success: function(result) {
                var response = $.parseJSON(result);

                console.log('Success', response, result);

                if (response.Status === "success") {

                  $('#additionalCharges').text('');

                  $('#serviceFeesModal span[data-fee-cost]').each(function() {
                    $(this).text('');
                    $(this).parent('h5').parent('div').addClass('hide');
                  });

                  var ns_fees = 0;
                  var hasVoice = $('#feature_30_1').prop('checked');

                  for (var i = 0; i < response.Data.length; i++) {
                    if (response.Data[i].Type == "Voice" && !hasVoice) {
                      continue;
                    }

                    // Grab only positive and Active
                    if(response.Data[i].Price > 0 && response.Data[i].Active == 1) {
                      ns_fees += response.Data[i].Price;

                      var formattedFee = '';

                      if (response.Data[i].Price !== 0) {
                        formattedFee = '( $' + (parseFloat(response.Data[i].Price).toFixed(2)) + ' )';
                      }
                      switch (response.Data[i].Name) {
                        // case "RES ACCESS RECOVERY CHARGE-FIBER":
                        //   break;
                        case "Broadcast Surcharge":
                          $('#broadcastSurcharge span[data-fee-cost]').text(formattedFee);
                          $('#broadcastSurcharge').removeClass('hide');
                          break;
                        case "Video Recovery Fee":
                          $('#videoRecoveryFee span[data-fee-cost]').text(formattedFee);
                          $('#videoRecoveryFee').removeClass('hide');
                          break;
                        case "Internet Recovery Fee":
                          $('#internetRecoveryFee span[data-fee-cost]').text(formattedFee);
                          $('#internetRecoveryFee').removeClass('hide');
                          break;
                        case "FCC Regulatory Fee":
                          $('#fccRegulatoryFee span[data-fee-cost]').text(formattedFee);
                          $('#fccRegulatoryFee').removeClass('hide');
                          break;
                        default:
                          if (response.Data[i].Price !== 0) {
                            $('#additionalCharges').append('<h5>' + response.Data[i].Name + ' <span data-fee-cost>' + formattedFee + '</span></h5>');
                          }
                          break;
                      }
                    }
                  }
                  $('#ns-regulatory-fees').val(ns_fees);
                  $('#ns-regulatory-fees').attr('data-price', ns_fees);

                  if (ns_fees > 0) {
                    $('.disclaimer.fees span[data-fee-total]').text((ns_fees.toFixed(2)));

                    $('.disclaimer.nofees').addClass('hide');
                    $('.disclaimer.fees:not(.wifi):not(.install)').removeClass('hide');

                  } else {
                    $('.disclaimer.nofees').removeClass('hide');
                    $('.disclaimer.fees:not(.wifi):not(.install)').addClass('hide');
                  }

                  if($('.product-wrap.product-select input[type=radio][data-waive-installation-fee]:checked').length) {
                    $('.disclaimer.fees.install').addClass('hide');
                  } else {
                    $('.disclaimer.fees.install').removeClass('hide');
                  }


                  // Display Wifi qty
                  if(parseInt($('#feature__6').val()) > 0) {
                    var wifiTotal = 0;

                    if($('#feature_3925_7').prop('checked')) {
                       wifiTotal += parseInt($('#feature_3925_7').attr('data-price'));
                    }
                    if($('#feature_3929_8').val() !== '0') {
                      wifiTotal += parseInt($('#feature_3929_8').val()) * parseInt($('#feature_3929_8').attr('data-price'));
                    }

                    if(parseInt($('#feature__6').val()) > 1) {
                      $('.disclaimer.fees.wifi span[data-wifi-point]').text('points');
                    } else {
                      $('.disclaimer.fees.wifi span[data-wifi-point]').text('point');
                    }

                    $('.disclaimer.fees.wifi span[data-wifi-qty]').text($('#feature__6').val());
                    $('.disclaimer.fees.wifi span[data-wifi-price]').text(wifiTotal);
                    $('.disclaimer.fees.wifi').removeClass('hide');
                  } else {
                    $('.disclaimer.fees.wifi span[data-wifi-qty]').text('0');
                    $('.disclaimer.fees.wifi span[data-wifi-price]').text('0');
                    $('.disclaimer.fees.wifi').addClass('hide');
                  }

                  updatePrice();
                }
                $('[data-ecomm-working]').addClass('hide');
                orderAdvance();
              },
              error: function(result) {
                $('[data-ecomm-working]').addClass('hide');
                orderAdvance();
              }
            });
          }
          break;
        case "2":
          $('#order-customer-info').foundation("validateForm");
          break;
        case "3":
        //   $('#order-account-info').foundation("validateForm");
        //   break;
        // case "4":
          if(!uses_nsapi) {
            submitOrderCapture();
          } else {
            createAccount();
          }
          break;
        default:
          orderAdvance();
      }

    });

    // Service billing
    $('input[name="address_service_billing"]').change(function() {
      if ($('#address_service_billing_diff').prop('checked')) {
        $('#address_service_billing_different').show();

        $('#address_service_billing_different_zip').prop('required', true);
        $('#address_service_billing_different_city').prop('required', true);

        $('#address_service_billing_different_street_name').prop('required', true);
        $('#address_service_billing_different_street_number').prop('required', true);

      } else {
        $('#address_service_billing_different').hide();

        $('#address_service_billing_different_zip').prop('required', false);
        $('#address_service_billing_different_city').prop('required', false);

        $('#address_service_billing_different_street_name').prop('required', false);
        $('#address_service_billing_different_street_number').prop('required', false);

        $('#address_service_billing_different_pobox').prop('required', false);

        $('#service_billing_final').text(localStorage.getItem('lumos_address_full_address'));

      }
    }); // end $('input[name="address_service_billing"]').change

    $('input[name="address_service_type"]').change(function() {
      if ($('#address_service_billing_physical').prop('checked')) {
        $('#address_service_billing_physical_form').show();
        $('#address_service_billing_pobox_form').hide();

        $('#address_service_billing_different_street_name').prop('required', true);
        $('#address_service_billing_different_street_number').prop('required', true);

        $('#address_service_billing_different_pobox').prop('required', false);

      } else {
        $('#address_service_billing_physical_form').hide();
        $('#address_service_billing_pobox_form').show();

        $('#address_service_billing_different_street_name').prop('required', false);
        $('#address_service_billing_different_street_number').prop('required', false);

        $('#address_service_billing_different_pobox').prop('required', true);
      }
    }); // end $('input[name="address_service_type"]').change


    // Go to a specific step
    $('.order-step-indicator li a').click(function(e) {
      e.preventDefault();
      if ($(this).parent('li').hasClass('complete')) {
        var gotoStep = $(this).attr('data-goto-step');
        // logNSEvent("ecomm - button click - Go Back To Step: " + $(this).text());
        orderToStep(parseInt(gotoStep));
      }
    }); // end $('.order-step-indicator li a').click

    $('#review-package button, #review-schedule button').click(function() {
      // logNSEvent('ecomm - button click - Edit Package (Step 4)');
      orderToStep(1);
    });

    $('#review-contact button, #review-billing button').click(function() {
      // logNSEvent('ecomm - button click - Edit Contact/Billing Info (Step 4)');
      orderToStep(2);
    });

    $('#review-account button').click(function() {
      // logNSEvent('ecomm - button click - Edit Account Info (Step 4)');
      orderToStep(2);
    });

  }

  function loadProductEvents() {

    if($('#transfer-help').length) {
      var phonehelp = new Foundation.Dropdown($('#transfer-help'), {});
    }

    // Call to order button
    // Phone Number Reveal Button
    $('a[data-reveal-number]').click(function() {
      var origButtonText = $(this).text();
      var origButtonNumber = $(this).attr('href').replace('tel:', '').replace(/(\d{3})(\d{3})(\d{4})/,"$1.$2.$3");
      if(typeof $(this).attr('data-orig-num') == 'undefined' || $(this).attr('data-orig-num') == false) {
        $(this).attr('data-orig-num', origButtonText);
      }
      $(this).text('Call: ' + origButtonNumber);
    });

    // Hide multigig things
    $('[data-show-for-cookie]').each(function() {
      var dataCookie = $(this).attr('data-show-for-cookie');

      if(localStorage.getItem(dataCookie) && localStorage.getItem(dataCookie) !== 'null') {
        $(this).removeClass('hide');
        // Alert
        $('[data-cookie-alert="'+dataCookie+'"]').addClass('hide');
      } else {
        $(this).addClass('hide');
        if($(this).find('input').prop('checked')) {
          if($(this).prev().find('input').length) {
            $(this).prev().find('input').prop('checked', true).change();
          }
          // Alert
          $('[data-cookie-alert="'+dataCookie+'"]').removeClass('hide');
        }
      }
    });

    // Checkout Actions
    $('.product-wrap.product-select input, .product-wrap.product-select select').change(function() {

      console.log('change product');

      // Hide Alert
      $('[data-product-alert]').addClass('hide');

      // Adjust WiFi for MultiGig
      if($('[data-product-feature-id="3946"]').prop('checked')) {
        $('#feature__6').find('option[value="0"]').addClass('hide');
        if($('#feature__6').val() == "0") {
          $('#feature__6').val('1');
          // console.log('reset to 1');
        }
        if(!localStorage.getItem('lumos_address_multigig')) {
          $('[data-multigig-notice]').removeClass('hide');
        }

      } else {
        $('#feature__6').find('option[value="0"]').removeClass('hide');
        $('[data-multigig-notice]').addClass('hide');
      }

      // Wifi messaging

      // Update Sub Type Categories
      updateSubTypes();
      // console.log('subtypes');
      // Update Price bubble
      updatePrice();
      // console.log('updateprice');
      // Update selection cookie
      updateProductOrderCookie();
      // console.log('productordercookie');
      // Update Input styles
      updateInputStyle();
      // console.log('updateinputstyle');
    }); // end $('.product-wrap.product-select input, .product-wrap.product-select select').change

    $('#voice_comments input[type=radio]').change(function() {
      console.log('changed');
      if ($('#transfer_number_no').prop('checked')) {
        $('#transfer_number_phone').addClass('hide');
        $('#transfer_number_carrier').addClass('hide');
        $('#transfer_number_account').addClass('hide');
      }
      if ($('#transfer_number_yes').prop('checked')) {
        $('#transfer_number_phone').removeClass('hide');
        $('#transfer_number_carrier').removeClass('hide');
        $('#transfer_number_account').removeClass('hide');
      }
    });

  }

  function sendNSPopupConversion() {
    if(hasClickedContinueShopping) {
      window.dataLayer.push({
        'event': 'exitPopUpConversion',
      });
      console.log('GTM: exitPopUpConversion');
    }
  }

  function startNSTimer() {
    nsTimer = (new Date).getTime();
  }

  function stopNSTimer() {
    nsTimer = 0;
  }

  function sendNSTimer(stepLabel) {
    var endTime = (new Date).getTime();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'checkoutProcessingStepComplete',
      'stepLabel': stepLabel,
      'stepElapsedTime': (endTime - nsTimer)
    });
    console.log('GTM: ' + stepLabel, (endTime - nsTimer));
    nsTimer = 0;
  }

  function sendNSError(errorLabel) {
    var endTime = (new Date).getTime();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'checkoutProcessingStepError',
      'stepLabel': errorLabel,
      'stepElapsedTime': (endTime - nsTimer)
    });
    console.log('GTM: ' + errorLabel, (endTime - nsTimer));
    nsTimer = 0;
  }

  function reloadEcommProducts() {
    var $ = jQuery;
    $('[data-ecomm-products]').empty();
    $('.order-step-column').addClass('shadow');
    $('[data-ecomm-loading]').removeClass('hide');

    $('[data-ecomm-set-address]').addClass('hide');
    $('[data-ecomm-container ]').removeClass('hide');

    console.log(localStorage.getItem('lumos_address_market'));

    if(localStorage.getItem('lumos_address_market')) {
      $.ajax({
        url:'/wp-content/themes/Lumos/parts/ecommtemplates/content-product-order-step-one-promo.php',
        method:'post',
        data: {
          'customer_segment': localStorage.getItem('lumos_address_market')
        },
        success: function(response) {
          $('[data-ecomm-products]').append(response);
          $('.order-step-column').removeClass('shadow');
          $('[data-ecomm-loading]').addClass('hide');
          $('[data-ecomm-set-address]').addClass('hide');
          loadEcomm();
          loadProductEvents();
        },
        fail: function(response) {
          console.log(response);
        }
      });
    } else {
      $('[data-ecomm-loading]').addClass('hide');
      $('[data-ecomm-set-address]').removeClass('hide');
      $('[data-ecomm-container ]').addClass('hide');
    }
  }


  function loadEcomm() {

    var $ = jQuery;

    orderToStep(1);

    // Old cookie name. Kill it if it's there.
    if (localStorage.getItem('lumos_ecomm_session_id')) {
      localStorage.removeItem('lumos_ecomm_session_id');
    }

    // Credit Check CC Date actions
    $('#ns-credit-check-cc-cardexp').val($('#ns-credit-check-cc-cardyear').val() + $('#ns-credit-check-cc-cardmonth').val());

    // $('#order-step-column').foundation('_destroy');

    // Product Selection Actions
    if ($('#ns_available_products').length) {

      // On initial load, trigger default price sorting
      $('#lumos_product_sort_price').trigger('change');

      // Set fiber indicator on load
      updateFiberSpeedIndicator();

    } // endif - Product Selection Actions

    // update on page load
    updateSubTypes();

    // Product order feature selection action
    if ($('#order-info').length) {

      // logNSEvent('ecomm - entry - Open Ecommerce Page');

      $('.top-nav').addClass('non-sticky');
      $('.top-nav-spacer').addClass('non-sticky');

      // Hide promo banner
      $('#address-checker .promo-space').addClass('hide');

      // Load Product selection
      // loadProductSelection();

      if (!localStorage.getItem('lumos_address_full_address')) {
        $('#account_service_check_address').text('Set Address');
      }

      updatePrice();

      // Set cookie for product Selection
      updateProductOrderCookie();
      // console.log('first call');

      // Set service address if set
      if ($('#account_service_geocoded').length && localStorage.getItem('lumos_address_full_address') && localStorage.getItem('lumos_address_check_decline') !== "1") {
        $('#account_service_geocoded').val(localStorage.getItem('lumos_address_full_address'));
      }

      // Set max order sequence
      orderStepMax = $('.order-step-column .order-step').length;

      updateInputStyle();

    } // endif - product feature selection action

  }

  // Update the fiber speed sort indicator
  function updateFiberSpeedIndicator() {
    var $ = jQuery;

    var speedVal = $('#fiber_speed').val(),
      gigaBit = ' Gbps',
      megaBit = ' Mbps',
      displayVal = '';

    if (speedVal > 999) {
      displayVal = (speedVal / 1000);
      displayVal += gigaBit;
    } else {
      displayVal = speedVal + megaBit;
    }

    $('.price_option[data-fiber-speed]').hide();
    $('.price_option[data-fiber-speed=' + speedVal + ']').show();

    $product_grid.isotope('layout');

    $('#fiber_speed_indicator').text(displayVal);

  }

  // Update the fiber speed sort
  function updateFiberSpeedSort() {
    // TODO: Adjust price depending on speed selected
    updateFiberSpeedIndicator();
  }

  // Set enable/disable sub types
  // Sub Types:
  //  Internet Main (1) - Internet Feature (2)
  //  Video Main (3) - Video Feature (5)
  //  Voice Main (8) - Voice Feature (9)
  function updateSubTypes() {
    var $ = jQuery;

    $('.product-wrap.product-select label').each(function() {

      var $featureCheckbox = $(this).find('input'),
        sub_type_id = '';

      switch ($(this).attr('data-feature-sub-type')) {
        case '8': // Voice Main
          sub_type_id = '9';
          break;
      }

      if (sub_type_id.length) {

        var $sub_elem = $('label[data-feature-sub-type="' + sub_type_id + '"] input');

        if ($(this).find('input').prop('checked')) {
          $sub_elem.prop('disabled', false);
          $('#voice_comments').removeClass('hide');
        } else {
          $sub_elem.prop('disabled', true);
          $sub_elem.prop('checked', false);
          // $sub_elem.parent('label').removeClass('highlight');
          $('#voice_comments').addClass('hide');
        }
      }

    });
  } // end updateSubTypes()


  function clearFinalScreenProducts() {
    var $ = jQuery;

    $('#product_selected_features li').each(function() {
      $(this).remove();
    });
  }

  function updateFinalScreenProducts(qty, feature_label) {
    var $ = jQuery;

    if (qty > 0) {
      var $featureItem = $('<li>(' + qty + ') ' + feature_label + '</li>');
      $featureItem.find('.button.call').remove();
      $featureItem.find('a').contents().unwrap();
      $('#product_selected_features').append($featureItem);
    }
  }

  function updatePrice() {
    var $ = jQuery;

    var totalPrice = 0,
      start_price = parseFloat($('.price-bubble .price').attr('data-start-price')),
      additional_amount = 0;


    clearFinalScreenProducts();

    $('.product-wrap.product-select h3, .product-wrap.product-select p, .product-wrap.product-select input[type=checkbox], .product-wrap.product-select input[type=radio], .product-wrap.product-select select').each(function() {

      // console.log($(this).attr('data-dependent-on'));

      if (typeof $(this).attr('data-dependent-on') !== 'undefined' && $(this).attr('data-dependent-on').length) {
        var fieldDependents = $(this).attr('data-dependent-on').split(' ');
        // console.log(fieldDependents);

        if (fieldDependents) {
          for (var d = 0; d < fieldDependents.length; d++) {
            if ($('input[data-product-feature-id="' + fieldDependents[d] + '"]').prop('checked')) {

              if ($(this).is('h3') || $(this).is('p')) {
                $(this).removeClass('hide');
              }
              if ($(this).attr('data-is-default') === '1' && !$(this).prop('checked') && $(this).parent('label').hasClass('hide')) {
                $(this).prop('checked', true);
              }
              $(this).parent('label').removeClass('hide');
              break;

            } else {

              if ($(this).is('h3') || $(this).is('p')) {
                $(this).addClass('hide');
              }
              // $(this).prop('checked', false);
              if ($(this).is('select')) {
                $(this).val($(this).find("option:first").val());
              } else {
                $(this).prop('checked', false);
              }
              $(this).parent('label').addClass('hide');
            }
          }
        }

      }

    });

    // Input & select field
    var priceBubbleTermChanged = false;

    var internetTotal = 0;
    var internetOrig = 0;
    var internetwifiQty = 0;
    var internetwifiTotal = 0;

    var tvTotal = 0;
    var voiceTotal = 0;
    $('[data-price-details] [data-tv-row]').addClass('hide');
    $('[data-price-details] [data-voice-row]').addClass('hide');

    var waiveInstallationFee = false;

    // Review fields
    $('.product-wrap.product-select input[type=checkbox]:checked, .product-wrap.product-select input[type=radio]:checked, .product-wrap.product-select select, #ns-regulatory-fees').each(function() {
      if(typeof $(this).attr('data-waive-installation-fee') !== 'undefined') {
        console.log('Install Fee Waived');
        waiveInstallationFee = true;
      }

      if (typeof $(this).attr('data-doesnt-change-total') === 'undefined') {

        if (typeof $(this).attr('data-logic-override') === 'undefined' || $(this).attr('data-logic-override') == 'no-logic' || $(this).attr('data-logic-override') == '') {

          if ($(this).is('input')) {
            // input
            if ($(this).attr('data-update-base-price') === 'true') {
              start_price = parseFloat($(this).attr('data-price'));
              // console.log('Start Price: ', start_price);
            } else {
              var addonPrice = parseFloat($(this).attr('data-price'));
              if (!isNaN(addonPrice)) {
                additional_amount += addonPrice;
                // console.log('Additional: ', additional_amount, $(this).attr('data-price'));
              }
            }

            if ($(this).attr('data-promo-duration-alt')) {
              // console.log($(this).attr('data-promo-duration-alt'));
              $('.price-bubble .pricing-term').text($(this).attr('data-promo-duration-alt'));
              $('.mobile-bottom-price .price-container .pricing-term').text($(this).attr('data-promo-duration-alt'));
              $('.mobile-bottom-price-new .price-container .pricing-term').text($(this).attr('data-promo-duration-alt'));
              priceBubbleTermChanged = true;
            } else if(!priceBubbleTermChanged){
              $('.price-bubble .pricing-term').text('per month');
              $('.mobile-bottom-price .price-container .pricing-term').text('per month');
              $('.mobile-bottom-price-new .price-container .pricing-term').text('per month');
            }

            if ($(this).attr('name') !== 'transfer_number' && !$(this).parent('label').hasClass('perma-hide')) {
              // console.log($(this).siblings('.product-desc-promo'));

              var productListItem = $(this).next('.feature-desc').html();

              if ($(this).siblings('.product-desc-promo').length) {
                productListItem += '<div class="selected_feature_desc">' + $(this).siblings('.product-desc-promo').html().replace(/(<br\s*\/?\s*>\s*)+$/, '') + "</div>";
              }
              if (productListItem !== null && typeof productListItem !== 'undefined') {
                updateFinalScreenProducts(1, productListItem);
              }
            }
          } else {
            // select
            var selectPrice = parseFloat($(this).attr('data-price'));
            additional_amount += selectPrice * $(this).val();
            // console.log('Additional: ', additional_amount);

            if ($(this).val() > 0) {
              $(this).parent('label').addClass('highlight');
            } else {
              $(this).parent('label').removeClass('highlight');
            }

            if ($(this).next('.feature-desc').length) {
              // console.log($(this).next('.feature-desc'), $(this).next('.feature-desc').html());
              updateFinalScreenProducts($(this).val(), $(this).next('.feature-desc').html());
            }

          }

        } else {
          if ($(this).attr('data-logic-override') == 'special-wifi-logic') {
            var basePrice = 0;
            var additionalQtyPrice = 0;

            if ($(this).val() > 0) {
              $(this).parent('label').addClass('highlight');
            } else {
              $(this).parent('label').removeClass('highlight');
            }

            var selectedQuantity = parseInt($(this).val());

            var $ns200Element = $('input[data-product-feature-id="3233"]'), // NS 200 Element
                $freePremuimElement = $('input[data-product-feature-id="3653"]'),// Free Premium
                $freeTotalElement = $('input[data-product-feature-id="3656"]'), // Free Total
                $freeAdditionalElement = $('input[data-product-feature-id="3659"]'); // Free Additional

            $freePremuimElement.prop('checked', false); // Free Premium
            $freeTotalElement.prop('checked', false); // Free Total
            $freeAdditionalElement.prop('checked', false); // Free Additional

            $freePremuimElement.parents('label').addClass('perma-hide');
            $freeTotalElement.parents('label').addClass('perma-hide');
            $freeAdditionalElement.parents('label').addClass('perma-hide');

            // var $premiumElement = $('input[data-product-feature-id="3582"]'),
            //     $totalElement = $('input[data-product-feature-id="3585"]'),
            //     $additionalElement = $('select[data-product-feature-id="3588"]');

            var $totalElement = $('input[data-product-feature-id="3925"]'),
                $additionalElement = $('select[data-product-feature-id="3929"]');

            // $premiumElement.prop('checked', false); // Premium
            $totalElement.prop('checked', false); // Total
            $additionalElement.val('0'); // Additional


            switch ($(this).val()) {
              case '0':
                // console.log('no wifi');
                break;
              case '1':
                basePrice = parseFloat($totalElement.attr('data-price'));
                $totalElement.prop('checked', true);
                // console.log('Add 2 wifi');
                if($ns200Element.length && $ns200Element.prop('checked')) { // NS 200 selected
                  if($freeTotalElement.length) {
                    $freeTotalElement.prop('checked', true);
                    basePrice += parseFloat($freeTotalElement.attr('data-price'));

                    $freeTotalElement.parents('label').removeClass('perma-hide');
                  }
                }
                break;
              case '2':
              default:
                selectedQuantity -= 1;
                basePrice = parseFloat($('input[data-product-feature-id="3925"]').attr('data-price'));
                additionalQtyPrice = parseFloat($additionalElement.attr('data-price'));
                $totalElement.prop('checked', true);
                $additionalElement.val(selectedQuantity);
                // console.log('Add 2 wifi + '+selectedQuantity+' additional', $('select[data-product-feature-id="3588"]').val());

                if($ns200Element.length && $ns200Element.prop('checked')) { // NS 200 selected

                  if($freeTotalElement.length) {
                    $freeTotalElement.prop('checked', true);
                    basePrice += parseFloat($freeTotalElement.attr('data-price'));

                    $freeTotalElement.parents('label').removeClass('perma-hide');
                  }

                  if($freeAdditionalElement.length) {
                    $freeAdditionalElement.prop('checked', true);
                    additionalQtyPrice += parseFloat($freeAdditionalElement.attr('data-price'));

                    $freeAdditionalElement.parents('label').removeClass('perma-hide');
                  }

                }
                // console.log(selectedQuantity, basePrice, additionalQtyPrice);

                break;
            } // end switch

            var selectPrice = basePrice;
            selectPrice += (additionalQtyPrice * selectedQuantity)
            additional_amount += selectPrice;

            // console.log("Wifi Cost: " + selectPrice);

            if ($(this).next('.feature-desc').length) {
              // console.log($(this).next('.feature-desc'), $(this).next('.feature-desc').html());
              updateFinalScreenProducts($(this).val(), $(this).next('.feature-desc').html());
            }
          } // end if
        } // end ifelse

      } // end if

      if ($(this).is('input:not([name="transfer_number"])')) {
        // build mobile details - internet
        if($(this).parents('.order-step').attr('data-group-type') === 'internet') {
          if($(this).parent('label').attr('data-feature-sub-type') == 1) {
            var desc = $(this).parent('label').find('.feature-desc').html();
            desc = desc.replace('<br>', ' - ').replace(/<\/?[^>]+(>|$)/g, "").replace('Symmetrical', '');

            // console.log(desc);

            var currPrice = $(this).attr('data-price');
            var originalPrice =  $(this).attr('data-original-price');
            var includedMessaging =  $(this).attr('data-included-messaging');

            if(typeof originalPrice === 'undefined' || originalPrice === false) {
              originalPrice = 0;
            }

            $('[data-price-details] [data-internet-row] [data-detail-name]').text(desc);
            $('[data-price-details] [data-internet-row] [data-detail-includes]').html(includedMessaging);

            internetTotal += Number(currPrice);
            internetOrig += Number(originalPrice);
            console.log(currPrice, internetOrig);
          }
        }

        // console.log($(this).parents('.order-step').attr('data-group-type'), $(this));

        // build mobile details - tv
        if($(this).parents('.order-step').attr('data-group-type') === 'tv') {
          $('[data-price-details] [data-tv-row]').removeClass('hide');
          if($(this).attr('data-price')) {
            tvTotal += Number($(this).attr('data-price'));
            internetOrig += Number($(this).attr('data-price'));
            // console.log(internetOrig, tvTotal);
          }
        }

        // build mobile details - voice
        if($(this).parents('.order-step').attr('data-group-type') === 'voice') {
          $('[data-price-details] [data-voice-row]').removeClass('hide');
          if($(this).attr('data-price')) {
            voiceTotal += Number($(this).attr('data-price'));
            internetOrig += Number($(this).attr('data-price'));
            // console.log(internetOrig);
          }
        }
      }

      if($(this).is('select[data-logic-override="no-logic"]')) {

        if($(this).parents('.order-step').attr('data-group-type') === 'tv') {
          tvQty = parseInt($(this).val());
          if(tvQty > 0) {
            tvTotal += parseFloat(tvQty * parseFloat($(this).attr('data-price')));
          }
        }

        if($(this).parents('.order-step').attr('data-group-type') === 'voice') {
          voiceQty = parseInt($(this).val());
          if(voiceQty > 0) {
            voiceTotal += parseFloat(voiceQty * parseFloat($(this).attr('data-price')));
          }
        }

      }

      if($(this).is('select[data-logic-override="special-wifi-logic"]')) {
        internetwifiQty = $(this).val();
        if($('#feature_3925_7').prop('checked')) {
          internetwifiTotal += parseInt($('#feature_3925_7').attr('data-price'));
        }
        if($('#feature_3929_8').val() !== '0') {
          internetwifiTotal += parseInt($('#feature_3929_8').val()) * parseInt($('#feature_3929_8').attr('data-price'));
        }

        internetTotal += internetwifiTotal;
        internetOrig += internetwifiTotal;
        // console.log(internetwifiTotal);
      }

    }); // end each

    $('[data-installation-fee]').text('$'+$('#order-step-one').attr('data-installation-fee-total'));

    if(waiveInstallationFee) {
      $('[data-waived-install-fee]').removeClass('hide');
      $('[data-show-install-fee]').addClass('hide');
    } else {
      $('[data-waived-install-fee]').addClass('hide');
      $('[data-show-install-fee]').removeClass('hide');
    }

    console.log("Installation fee:" + waiveInstallationFee);

    $('[data-price-details] [data-pre-fee-price]').text(Number(internetTotal + tvTotal + voiceTotal).toFixed(2));

    // Update price bubble
    $('.price-bubble .price').text(Number(start_price + additional_amount).toFixed(2));
    $('.mobile-bottom-price .price-container .price').text(Number(start_price + additional_amount).toFixed(2));

    if(internetwifiQty > 0) {
      $('[data-price-details] [data-detail-wifi] [data-wifi-qty]').text(internetwifiQty);
      $('[data-price-details] [data-detail-wifi] [data-wifi-price]').text(internetwifiTotal);
      if(internetwifiQty > 1) {
        $('[data-price-details] [data-detail-wifi] [data-wifi-point]').text('points');
      } else {
        $('[data-price-details] [data-detail-wifi] [data-wifi-point]').text('point');
      }
      $('[data-price-details] [data-detail-wifi]').removeClass('hide');
    } else {
      $('[data-price-details] [data-detail-wifi] ').addClass('hide');
    }

    $('[data-price-details] .price-container .price').text(Number(start_price + additional_amount).toFixed(2));
    $('[data-price-details] [data-internet-row] [data-detail-price] span').text(Number(internetTotal).toFixed(2));
    $('[data-price-details] [data-tv-row] [data-detail-price] span').text(Number(tvTotal).toFixed(2));
    $('[data-price-details] [data-voice-row] [data-detail-price] span').text(Number(voiceTotal).toFixed(2));

    if((internetOrig - additional_amount) > 0) {
      $('[data-price-details] [data-monthly-row] [data-old-price]').text(Number(internetOrig).toFixed(2));
      $('[data-price-details] [data-monthly-row] [data-old-price]').parent().removeClass('hide');
    } else {
      $('[data-price-details] [data-monthly-row] [data-old-price]').parent().addClass('hide');
      $('[data-price-details] [data-monthly-row] [data-old-price]').text(Number(0).toFixed(2));
    }

    $('[data-price-details] [data-monthly-row] [data-sale-price]').text(Number(start_price + additional_amount).toFixed(2));

    if($('[data-fee-total]').is(':visible')) {
      $('[data-price-details] [data-monthly-row] [data-old-price]').text(Number(internetOrig + Number($('[data-fee-total]:first').text())).toFixed(2));
    }

    console.log(internetTotal, tvTotal, voiceTotal, internetOrig, additional_amount);

  }

  function updateInputStyle() {
    var $ = jQuery;
    $('.product-wrap.product-select input').each(function() {
      if ($(this).prop('checked')) {
        $(this).parent('label').addClass('highlight');
      } else {
        $(this).parent('label').removeClass('highlight');
      }
    });
  }

  function updateInstallationSchedule(scheduleText) {
    var $ = jQuery;
    $('#product_service_install_final').text(scheduleText);
  }

  function updateProductOrderCookie() {
    var $ = jQuery;

    var product_ns_id = $('#product-selection').attr('data-ns-product-id'),
      product_ns_promotion_id = [],
      product_features = [];

    // Update Product ID if feature changes product ID
    var useAlternateFeature = false;
    $('input[data-changes-product]:checked').each(function() {
      // console.log($('input[data-changes-product]:checked').attr('value'));
      if ($(this).attr('data-changes-product') !== '' && typeof $(this).attr('data-changes-product') !== 'undefined') {
        product_ns_id = $(this).attr('data-changes-product');
        $('#product-selection').attr('data-ns-product-id', product_ns_id);
        useAlternateFeature = true;
        return false;
      } else {
        product_ns_id = $('#product-selection').attr('data-ns-product-id-orig');
        $('#product-selection').attr('data-ns-product-id', $('#product-selection').attr('data-ns-product-id-orig'));
      }

    });

    // Iterate through product features
    $('#order-info .order-step[data-order-step="1"] input, #order-info .order-step[data-order-step="1"] select').each(function() {

      var product_feature_id = $(this).attr('data-product-feature-id'),
        product_feature_checked = false,
        product_feature_qty = 1,
        product_feature_name = '',
        product_feature_price = 0;

      if ($(this).prop('checked')) {
        if ($(this).attr('data-product-promotion-id') && !useAlternateFeature) {
          product_ns_promotion_id.push({
            'id': $(this).attr('data-product-promotion-id')
          });
        }
      }

      if (useAlternateFeature && $(this).attr('data-product-alt-feature-for-ns-id') == product_ns_id) {
        product_feature_id = $(this).attr('data-product-alt-feature-id');
        if ($(this).prop('checked')) {
          if ($(this).attr('data-product-alt-promotion-id')) {
            product_ns_promotion_id.push({
              'id': $(this).attr('data-product-alt-promotion-id')
            });
          }
        }
      }

      if ($(this).is('select')) {
        product_feature_qty = parseInt($(this).val());
        if (product_feature_qty > 0) {
          product_feature_checked = true;
        }
      } else {
        product_feature_checked = $(this).prop('checked');
      }

      product_feature_name = $(this).parent().find('.feature-desc').text().replace(/^\s+|\s+$/g, '').replace(/(<([^>]+)>)/gi, "");
      if(typeof $(this).parent().find('.feature-desc').attr('data-legacy-name') !== 'undefined' && $(this).parent().find('.feature-desc').attr('data-legacy-name').length) {
        product_feature_name = $(this).parent().find('.feature-desc').attr('data-legacy-name').replace(/^\s+|\s+$/g, '').replace(/(<([^>]+)>)/gi, "");
      }

      if($(this).parent().find('[data-product-price]').length) {
        product_feature_price = $(this).parent().find('[data-product-price]').text();
      }

      if (product_feature_id) {
        if(product_feature_checked) {
          product_features.push({
            'product_feature_id': product_feature_id,
            'product_feature_checked': product_feature_checked,
            'product_feature_qty': product_feature_qty,
            'product_feature_name': product_feature_name,
            'product_feature_price': parseFloat(product_feature_price)
          });
        }
      }
    });

    var productSelection = {
      'product_ns_id': product_ns_id,
      'product_feature_selection': product_features
    };

    if (product_ns_promotion_id.length) {
      productSelection.product_ns_promotion_id = product_ns_promotion_id;
    }

    console.log(productSelection);
    // console.log(JSON.stringify(productSelection));

    localStorage.setItem(cookieLabel_product_selection, JSON.stringify(productSelection));
  } // end updateProductOrderCookie()


  function loadProductSelection() {
    var $ = jQuery;

    if (localStorage.getItem(cookieLabel_product_selection)) {
      var productSelection = JSON.parse(localStorage.getItem(cookieLabel_product_selection));

      if ($('#product-selection').attr('data-ns-product-id') === productSelection.product_ns_id) {
        var feature_selection = productSelection.product_feature_selection;

        for (var i = 0; i < feature_selection.length; i++) {
          // console.log(feature_selection[i], $('#feature_'+feature_selection[i].product_feature_id).attr('id'));
          console.log('[value="' + feature_selection[i].product_feature_id + '"]', $('[value="' + feature_selection[i].product_feature_id + '"]'));

          if (feature_selection[i].product_feature_checked && typeof $('#feature_' + feature_selection[i].product_feature_id)[0] !== 'undefined') {
            // console.log('feature selection id', i, feature_selection[i].product_feature_id);
            $('[value="' + feature_selection[i].product_feature_id + '"]')[0].click();
            $('[value="' + feature_selection[i].product_feature_id + '"]').trigger('change');
          }
          if (feature_selection[i].product_feature_qty > 0) {
            $('[value="' + feature_selection[i].product_feature_id + '"]').val(feature_selection[i].product_feature_qty);
            $('[value="' + feature_selection[i].product_feature_id + '"]').trigger('change');
          }
        }
      } // endif
    } // endif

    if (urlParamNS('feature_selected') !== null && urlParamNS('feature_selected').length) {
      $('#feature_' + urlParamNS('feature_selected'))[0].click();
      // $('#feature_'+urlParamNS('feature_selected')).trigger('change');
    }
  }


  // Advance to step
  function orderAdvance() {
    orderToStep(orderStep + 1);
  } // end orderAdvance()

  // Go to a specific step
  function orderToStep(stepIndex) {

    var $ = jQuery;

    var $ = jQuery;

    // console.log('Go To Step: ' + stepIndex, 'Current Step: '+orderStep);

    // $('#order-step-one').attr('data-order-step', stepIndex);

    if (stepIndex === 1) {
      $('.disclaimer.nofees').removeClass('hide');
      $('.disclaimer.fees').addClass('hide');
      $('#ns-regulatory-fees').val(0);
      $('#ns-regulatory-fees').attr('data-price', 0);
      updatePrice();
    }

    // Only advance if it doesn't exceed max steps
    if (stepIndex <= orderStepMax) {

      // Set Order Step
      orderStep = stepIndex;

      // remove current indicator
      $('.order-step-indicator').each(function() {

        $(this).find('li').each(function(index) {
          var eachIndex = index + 1;

          // console.log('Current EQ: '+eachIndex);

          $(this).removeClass('current');
          $(this).removeClass('complete');

          // Update complete indicator
          if (eachIndex < stepIndex) {
            $(this).addClass('complete');
            // console.log('Is Complete');
          }

          // Hide if not current
          if (eachIndex !== stepIndex) {
            $('.order-step[data-order-step="' + eachIndex + '"]').hide();
            // console.log('Not current');
          }

          // Set current
          if (eachIndex === stepIndex) {
            $('.order-step[data-order-step="' + eachIndex + '"]').show();
            $(this).addClass('current');
            // console.log('Is Current');
          }
        });

      });


      $([document.documentElement, document.body]).animate({
        scrollTop: $('#order-info').offset().top - ($('.top-nav').outerHeight() || 0)
      }, 400);
    } // endif
  } // end orderToStep()

  function submitOrderCapture() {
    var $ = jQuery;

    orderStreet = localStorage.getItem('lumos_address_street');
    orderCity   = localStorage.getItem('lumos_address_city');
    orderState  = localStorage.getItem('lumos_address_state');
    orderZIP    = localStorage.getItem('lumos_address_zip');

    sendNSPopupConversion();

    isProcessingOrder = true;

    $('#orderSubmitModal-nonapi [data-order-processing]').removeClass('hide');
    $('#orderSubmitModal-nonapi [data-order-error]').addClass('hide');

    orderModal_NonAPI.open();

    var submitFormAction = "nonapi_submit_order";

    console.log(submitFormAction);

    var form_data = [],
        form_product_selections = '',
        form_product_total = 0,
        feature_stash = $.parseJSON(localStorage.getItem(cookieLabel_product_selection));

    console.log('Feature Stash: ', feature_stash.product_feature_selection);

    for(var i = 0; i < feature_stash.product_feature_selection.length; i++) {
      form_product_selections += '[' + feature_stash.product_feature_selection[i].product_feature_qty + '] ' + feature_stash.product_feature_selection[i].product_feature_name + ' : $' + feature_stash.product_feature_selection[i].product_feature_price.toFixed(2) + '\n';
      form_product_total += feature_stash.product_feature_selection[i].product_feature_price
    }

    // Add Telephone order notes
    if($('#feature_30_1').prop('checked')) {
      form_product_selections += '\n';

      if($('#transfer_number_yes').prop('checked')) {
        form_product_selections += 'Customer would like to transfer a number from another carrier. \n';
        form_product_selections += 'Phone Number: ' + $('#transfer_number_phone').val() + '\nCarrier: ' + $('#transfer_number_carrier').val() + '\nCurrent Provider Account Number: ' + $('#transfer_number_account').val() + '\n';
      }

      if($('#transfer_number_message').length && $('#transfer_number_message').val().length) {
        form_product_selections += '\nCustomer Comments: ' + $('#transfer_number_message').val() + '\n';
      }
    }

    if(!$('#address_service_billing_same').prop('checked')) {
      var billAddress = $('#address_service_billing_different_city').val() + ' ' + $('#address_service_billing_different_zip').val();

      if ($('#address_service_billing_physical').prop('checked')) {
        var aptNumber = '';
        if ($('#address_service_billing_different_apartment').val().length) {
          aptNumber = $('#address_service_billing_different_apartment').val();
        }
        billAddress = $('#address_service_billing_different_street_number').val() + ' ' + $('#address_service_billing_different_street_name').val() + ' ' + aptNumber + ' ' + billAddress;
      } else {
        billAddress = 'PO Box ' + $('#address_service_billing_different_pobox').val() + ' ' + billAddress;
      }

      form_product_selections += '\nCustomer specified a different billing address: \n\n' + billAddress + '\n';
    }

    form_data = [
      {
        // Full Name
        id: 'input_1',
        value: $('#account_service_first_name').val() + ' ' + $('#account_service_last_name').val()
      },
      {
        // First Name
        id: 'input_12',
        value: $('#account_service_first_name').val()
      },
      {
        // Last Name
        id: 'input_13',
        value: $('#account_service_last_name').val()
      },
      {
        // Contact Number
        id: 'input_2',
        value: $('#account_service_contact_phone').val()
      },
      {
        // Email Address
        id: 'input_4',
        value: $('#account_service_contact_email').val()
      },
      {
        // Service Address
        id: 'input_6',
        value: $('#account_service_geocoded').val()
      },
      {
        // Service Selections
        id: 'input_10',
        value: form_product_selections
      },
      {
        // Service Total
        id: 'input_11',
        value: form_product_total
      }
    ];

    console.log(form_data);

    $.ajax({
      url:  '/wp-content/themes/Lumos/api_helper.php',
      type: 'GET',
      data: {
        "action" : submitFormAction,
        "form_data" : JSON.stringify(form_data)
      },
      success: function(result) {
        console.log(result);

        if(result == "true") {
          // GTM Submission
          // var gtmDLProducts = [];
          // var productSelections = $.parseJSON(localStorage.getItem(cookieLabel_product_selection));
          // for (var i = 0; i < productSelections.product_feature_selection.length; i++) {
          //   if (productSelections.product_feature_selection[i].product_feature_checked) {
          //     if(productSelections.product_feature_selection[i].product_feature_price !== 0) { // Exclude $0 products
          //       gtmDLProducts.push(
          //         {
          //           'name': productSelections.product_feature_selection[i].product_feature_name.replace(/^\s+|\s+$/g, '').replace(/(<([^>]+)>)/gi, ""),
          //           'sku': productSelections.product_feature_selection[i].product_feature_id,
          //           'price': productSelections.product_feature_selection[i].product_feature_price,
          //           'quantity': productSelections.product_feature_selection[i].product_feature_qty,
          //         }
          //       );
          //     }
          //   }
          // }
          //
          // var gtmDL = {
          //   'event': 'nsPurchase', // Residential - eCommerce - Order Captured
          //   'transactionId': new Date().toISOString(),
          //   'transactionTotal': parseFloat($('[data-total-price]').text()),
          //   'transactionTax': parseFloat($('[data-fee-total]').text()),
          //   'transactionProducts': gtmDLProducts
          // };
          //
          // // console.log('dataLayer Data:', gtmDL);
          // window.dataLayer = window.dataLayer || [];
          // window.dataLayer.push(gtmDL);

          isProcessingOrder = false;

          clearAddressSelection();

          finalizeOrder();

        } else {

          // Show error state
          $('#orderSubmitModal-nonapi [data-order-processing]').addClass('hide');
          $('#orderSubmitModal-nonapi [data-order-error]').removeClass('hide');

        }
      }
    });
  }

  // **
  // * Create an Account with supplied customer info
  // **
  function createAccount() {

    var $ = jQuery;

    // logNSEvent("ecomm - order processing - Creating Account (Processing Step 1)");

    isProcessingOrder = true;

    $('.submission-indicator li').each(function() {
      $(this).removeClass('current');
      $(this).removeClass('error');
      $(this).removeClass('alert');
      $(this).removeClass('complete')
    });

    $('#termsconditions').prop('disabled', true);
    $('.order-step[data-order-step="3"] button').prop('disabled', true);
    $('.close-reveal-modal').addClass('hide');

    $('#submission_message').text('');
    $('#submission_message').addClass('hide');

    $('#submission_error_message').addClass('hide');

    orderModal.open();

    $('#orderSubmitModal .progress-order').removeClass('error');

    // $('#submission_message').text('Step 1 of 3: Creating Your Account');

    $('.submission-indicator li').eq(0).addClass('current');

    $('#orderSubmitModal .progress-order').attr('data-percent', '25');

    // NS GTM Timer Start
    startNSTimer();

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "add_account",

        username: $('#account_service_email_address').val(),
        password: $('#account_service_password').val(),

        security_pin: $('#account_service_pin').val(),
        security_question: $('#account_service_security_question').val(),
        security_answer: $('#account_service_security_answer').val(),

        name_full: $('#service_account_final').text(),
        name_prefix: '', //$('#account_service_prefix').val(),
        name_first: $('#account_service_first_name').val(),
        name_middle: '', //$('#account_service_middle_name').val(),
        name_last: $('#account_service_last_name').val(),
        name_suffix: '', //$('#account_service_suffix').val(),

        email_address: $('#account_service_contact_email').val(),

        // ssn: $('#address_service_ssn').val(),
        ssn: '',

        address_full: $('#account_service_geocoded').val(),
        address_house_number: localStorage.getItem('lumos_address_house_num'),
        address_apt_number: localStorage.getItem('lumos_address_apt_num'),
        address_po_box: '',

        address_street_id: localStorage.getItem('lumos_address_street_id'),
        address_city_id: localStorage.getItem('lumos_address_city_id'),
        address_state_id: localStorage.getItem('lumos_address_state_id'),
        address_zip: localStorage.getItem('lumos_address_zip'),

        billing_address_full: ($('#account_service_geocoded').val() !== $('#service_billing_final').text()) ? $('#service_billing_final').text() : '',
        billing_address_house_number: $('#address_service_billing_different_street_number').val(),
        billing_address_apt_number: $('#address_service_billing_different_apartment').val(),
        billing_address_po_box: $('#address_service_billing_different_pobox').val(),

        billing_address_street_id: $('#address_service_billing_different_street_name_id').val(),
        billing_address_city_id: $('#address_service_billing_different_city_id').val(),
        billing_address_state_id: $('#address_service_billing_different_state_id').val(),
        billing_address_zip: $('#address_service_billing_different_zip').val(),

        address_phone: $('#account_service_contact_phone').val(),
        address_phone_ext: '', //$('#account_service_contact_phone_ext').val(),
        address_phone_alt: '', //$('#account_service_alt_phone').val(),
        address_phone_alt_ext: '', //$('#account_service_alt_phone_ext').val(),

        service_address_id: localStorage.getItem('lumos_address_id')
      },
      success: function(result) {
        // NS GTM Timer End
        var response = $.parseJSON(result);

        // console.log('Success', response, result);
        console.log(response.Status, response.Entity);

        if (response.Status === "success") {

          sendNSTimer('Step 1.1 - add_account - POST: Customer/Account/');

          $('#orderSubmitModal .progress-order').attr('data-percent', '75');
          // $('#submission_message').text('Step 2 of 3: Submitting Your Order');

          // $('.submission-indicator li').eq(0).addClass('complete');
          // $('.submission-indicator li').eq(1).addClass('current');

          // createServiceRequest(response.Data.AccountID);

          var newAccountID = response.Data.AccountID;

          // NS GTM Timer Start
          startNSTimer();

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_login",
              username: $('#account_service_email_address').val(),
              password: $('#account_service_password').val()
            },
            success: function(result) {
              // NS GTM Timer End
              var response = $.parseJSON(result);
              if (response.Status === "success") {

                sendNSTimer('Step 1.2 - ns_login - POST: Username/Authenticate/');

                $('.submission-indicator li').eq(0).addClass('complete');
                $('.submission-indicator li').eq(1).addClass('current');

                var date = new Date();
                var timestamp = date.getTime();
                ns_ecomm_session_id_name = 'ns_ecomm_session_id_' + timestamp;
                localStorage.setItem(ns_ecomm_session_id_name, response.Data.SessionId);

                // runCreditCheck(newAccountID);
                createServiceRequest(newAccountID);

              } else {

                sendNSError('Step 1.2 - Error - ns_login - POST: Username/Authenticate/ - API Error: ' + response.Message);
                // stopNSTimer();

                // logNSEvent("ecomm - order processing - ERROR: Creating Account: Unable To Login New User (Processing Step 1)");
                $('#submission_error_message').html('We encountered an error: <strong>' + response.Message + '</strong>. <br /> <br />Please contact our customer support at <strong>866.542.5900</strong>');
                $('#submission_error_message').removeClass('hide');
              }
            },
            error: function(result) {
              sendNSError('Step 1.2 - Error - ns_login - POST: Username/Authenticate/ - API Comm Error');
              // stopNSTimer();

              console.log('Nope', result);
              // logNSEvent("ecomm - order processing - Error: Creating Account: API Failure, Error Shown (Processing Step 1)");

              $('.submission-indicator li').eq(0).addClass('error');

              // $('#submission_message').html('We encountered an error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');

              $('#submission_error_message').html('We encountered an error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
              $('#submission_error_message').removeClass('hide');

              $('#termsconditions').prop('disabled', false);
              $('.order-step[data-order-step="3"] button').prop('disabled', false);
              $('.close-reveal-modal').removeClass('hide');
            }
          });

        } else {

          sendNSError('Step 1.1 - Error - add_account - POST: Customer/Account/ - API Error: ' + response.Message);

          // stopNSTimer();

          // logNSEvent("ecomm - order processing - Error: Creating Account (Processing Step 1)");

          $('#orderSubmitModal .progress-order').attr('data-percent', '100');
          $('#orderSubmitModal .progress-order').addClass('error');

          $('.submission-indicator li').eq(0).addClass('error');

          $('#termsconditions').prop('disabled', false);
          $('.order-step[data-order-step="3"] button').prop('disabled', false);
          $('.close-reveal-modal').removeClass('hide');

          if (response.Message === "User exists") {

            // logNSEvent("ecomm - order processing - Error: Creating Account: Account Already Exists (Processing Step 1)");
            // $('#submission_message').html('Oops! It looks like that account already exists. <br /> Please contact our customer support at <strong>866.542.5900</strong>');
            $('#submission_error_message').html('Oops! It looks like that account already exists. <br /> Please contact our customer support at <strong>866.542.5900</strong>');
            $('#submission_error_message').removeClass('hide');
          } else {
            // logNSEvent("ecomm - order processing - Error: Creating Account: API Returned Error - "+response.Message+" (Processing Step 1)");
            // $('#submission_message').html('We encountered an error: <strong>' + response.Message + '</strong>. Please correct your information or you may contact our customer support at <strong>866.542.5900</strong>');
            $('#submission_error_message').html('We encountered an error: <strong>' + response.Message + '</strong>. <br /> <br />Please contact our customer support at <strong>866.542.5900</strong>');
            $('#submission_error_message').removeClass('hide');
          }
        }

        // if(response.Status)
      },
      error: function(result) {

        sendNSError('Step 1.1 - Error - add_account - POST: Customer/Account/ - API Comm Error');
        // stopNSTimer();

        console.log('Nope', result);
        // logNSEvent("ecomm - order processing - Error: Creating Account: API Failure, Error Shown (Processing Step 1)");

        $('.submission-indicator li').eq(0).addClass('error');

        // $('#submission_message').html('We encountered an error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');

        $('#submission_error_message').html('We encountered an error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
        $('#submission_error_message').removeClass('hide');

        $('#termsconditions').prop('disabled', false);
        $('.order-step[data-order-step="3"] button').prop('disabled', false);
        $('.close-reveal-modal').removeClass('hide');
      }
    });
  }

  // **
  // * Once an account is created, create the service request
  // **
  function createServiceRequest(accountID) {

    var $ = jQuery;

    // logNSEvent("ecomm - order processing - Create Service Request (Processing Step 2)");

    $('.submission-indicator li').eq(0).addClass('complete');
    $('.submission-indicator li').eq(0).removeClass('current');

    // $('.submission-indicator li').eq(1).addClass('complete');
    // $('.submission-indicator li').eq(1).removeClass('current');

    // $('.submission-indicator li').eq(2).addClass('current');

    $('.submission-indicator li').eq(1).addClass('current');

    console.log('Account ID Received: ' + accountID);

    var feature_list = [],
      feature_stash = $.parseJSON(localStorage.getItem(cookieLabel_product_selection));

    console.log('Feature Stash: ', feature_stash.product_feature_selection);

    for (var i = 0; i < feature_stash.product_feature_selection.length; i++) {
      // console.log(feature_stash.product_feature_selection[i]);
      if (feature_stash.product_feature_selection[i].product_feature_checked) {
        var f_temp = {
          "ItemID": 0,
          "ParentItemID": null,
          "ServiceID": null,
          "LocationID": 0,
          "ComponentID": null,
          "CompnentCode": null,
          "Component": null,
          "Quantity": parseInt(feature_stash.product_feature_selection[i].product_feature_qty),
          "ActivationDate": null,
          "DeactivationDate": null,
          "Status": null,
          "FeatureID": parseInt(feature_stash.product_feature_selection[i].product_feature_id),
          "ServiceItemAttributes": [],
          "DefaultPrice": null,
          "GroupID": null,
          "PackageID": null
        };

        feature_list.push(f_temp);
      }
    }

    console.log('Feature list:', feature_list);

    var additionalInfo = '';

    additionalInfo += 'Service Address: ' + localStorage.getItem('lumos_address_full_address') + ' | ';

    // additionalInfo += 'Rent or Own Property: ' + $('[name="account_service_address_ownership"]:checked').val() + ' | ';

    // additionalInfo += 'Credit Check Results: $'+creditCheckAmount+' via '+creditCheckType+' | ';

    additionalInfo += 'Contact Phone: ' + $('#account_service_contact_phone').val() + ' ' + $('#account_service_contact_phone_ext').val() + ' | ';

    // if ($('#account_service_alt_phone').val().length) {
    //   additionalInfo += 'Alternate Phone: ' + $('#account_service_alt_phone').val() + ' ' + $('#account_service_alt_phone_ext').val() + ' | ';
    // }

    additionalInfo += 'Contact Email: ' + $('#account_service_contact_email').val() + ' | ';

    // additionalInfo += 'Preferred Contact: ' + $('#account_service_best_contact').val() + ' | Best Time: ' + $('#account_service_best_time').val() + ' ';

    if ($('#transfer_number_yes').prop('checked')) {
      additionalInfo += ' | Customer would like to transfer a number from another carrier. ';
      additionalInfo += ' | Phone Number: ' + $('#transfer_number_phone').val() + ' | Carrier: ' + $('#transfer_number_carrier').val() + ' | Current Provider Account Number: ' + $('#transfer_number_account').val() + ' ';
    }

    if ($('#transfer_number_message').length && $('#transfer_number_message').val().length) {
      additionalInfo += '| Customer Comments: ' + $('#transfer_number_message').val() + ' ';
    }

    // if($('#schedule-availability-firstavailable').prop('checked')) {
    //   additionalInfo += 'Preferred Installation Date: First Available ';
    // } else {
    //   additionalInfo += 'Preferred Installation Date:' + $('#schedule-availability-date-day').val() + ' ' + $('#schedule-availability-date-time').val() + ' ';
    // }

    // if( $('#schedule-availability-instructions').val().length ) {
    //   additionalInfo += 'Special Installation Instructions: ' + $('#schedule-availability-instructions').val();
    // }

    $transfer_phone = null;
    if ($('#transfer_number_phone').val().length && $('#transfer_number_yes').prop('checked')) {
      $transfer_phone = parseInt($('#transfer_number_phone').val());
    }

    console.log($transfer_phone, additionalInfo);

    console.log('Product ID', feature_stash.product_ns_id);

    var isInstallCharge = true;
    if($('[data-show-install-fee]').hasClass('hide')) {
      isInstallCharge = false;
    }

    var serviceData = {
      action: "create_service",
      accountID: accountID,
      locationID: localStorage.getItem('lumos_location_id'),
      service_phone: $transfer_phone,
      feature_list: JSON.stringify({
        featureParams: feature_list
      }),
      // additionalInfo: additionalInfo,
      // productID: $('#order-info').attr('data-ns-product-id')
      productID: feature_stash.product_ns_id,
      deposit_amount: creditCheckDepositAmount,
      applyInstallCharge: isInstallCharge,
      sessionId: localStorage.getItem(ns_ecomm_session_id_name)
    };

    if (feature_stash.product_ns_promotion_id && feature_stash.product_ns_promotion_id.length) {
      serviceData.ProductPromotion = feature_stash.product_ns_promotion_id;
    }

    console.log('Service Data:', serviceData);

    // NS GTM Timer Start
    startNSTimer();

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: serviceData
    });

    checkForService(accountID, additionalInfo);
  }

  function checkForService(accountID, additionalInfo, serviceCheckCounter = 0) {
    var $ = jQuery;

    console.log('checking for service');
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_services",
        sessionId: localStorage.getItem(ns_ecomm_session_id_name),
        accountId: accountID
      },
      success: function(result) {
        var newServiceCheckCounter = serviceCheckCounter + 1;

        console.log('Service Status: ' + result);

        // console.log("refreshId:", refreshId);
        var response = $.parseJSON(result);
        response.Data = response.Data[0];

        if(typeof response.Data === 'object' && (response.Data.hasOwnProperty('ServiceID') && response.Data.hasOwnProperty('ServiceOrderID')) && response.Data.ServiceID > 0 && response.Data.ServiceOrderID > 0) {
          console.log("service status: ", response.Data.ServiceID, response.Data.ServiceOrderID);
          console.log("service was found");
          gotService(result, additionalInfo);
        } else if (serviceCheckCounter > 20) {
          console.log("service not found");
          noService(result)
        } else {
          // Run the Check Again after 8 seconds
          setTimeout(function () {
            checkForService(accountID, additionalInfo, newServiceCheckCounter);
          }, 8000);
        }
      },
      error: function(result) {

        sendNSError('Step 2.1 - Error - create_service - POST: Service/ - API Comm Error');

        $('.submission-indicator li').eq(1).removeClass('current');
        $('.submission-indicator li').eq(1).addClass('error');

        $('#submission_message').html('We encountered an error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
        $('#submission_message').removeClass('hide');

        $('#termsconditions').prop('disabled', false);
        $('.order-step[data-order-step="3"] button').prop('disabled', false);
        $('.close-reveal-modal').removeClass('hide');
      }
    });
  }

  function noService(result) {

    var $ = jQuery;

    sendNSError('Step 2.1 - Error - create_service - POST: Service/ - API Error: ' + result.Message);
    // stopNSTimer();

    console.log('Nope', result);
    // logNSEvent("ecomm - order processing - Create Service Request: No Service Order Created - Error Shown");

    // $('.submission-indicator li').eq(0).addClass('error');
    // $('.submission-indicator li').eq(1).addClass('error');
    $('.submission-indicator li').eq(1).removeClass('current');
    $('.submission-indicator li').eq(1).addClass('error');

    $('#submission_message').html('We encountered an error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
    $('#submission_message').removeClass('hide');

    $('#termsconditions').prop('disabled', false);
    $('.order-step[data-order-step="3"] button').prop('disabled', false);
    $('.close-reveal-modal').removeClass('hide');
  }

  function gotService(result, additionalInfo) {

    var $ = jQuery;

    console.log(result);
    var response = $.parseJSON(result);
    if (Array.isArray(response.Data)) {
      response.Data = response.Data[0];
    }

    if (response.Status === "success") {
      // NS GTM Timer End
      sendNSTimer('Step 2.1 - create_service - POST: Service/');

      setHubspotPartialContact(true);

      // logNSEvent("ecomm - order processing - Create Service Request: Service Order Created - Service ID: " +response.Data.ServiceID+ " - Service Order ID: " +response.Data.ServiceOrderID+ " (Processing Step 2)");

      // GTM ECOMM - SERVICE ORDER CREATED
      // var gtmDLProducts = [];
      // var productSelections = $.parseJSON(localStorage.getItem(cookieLabel_product_selection));
      // for (var i = 0; i < productSelections.product_feature_selection.length; i++) {
      //   if (productSelections.product_feature_selection[i].product_feature_checked) {
      //     if(productSelections.product_feature_selection[i].product_feature_price !== 0) { // Exclude $0 products
      //       gtmDLProducts.push(
      //         {
      //           'name': productSelections.product_feature_selection[i].product_feature_name.replace(/^\s+|\s+$/g, '').replace(/(<([^>]+)>)/gi, ""),
      //           'sku': productSelections.product_feature_selection[i].product_feature_id,
      //           'price': productSelections.product_feature_selection[i].product_feature_price,
      //           'quantity': productSelections.product_feature_selection[i].product_feature_qty,
      //         }
      //       );
      //     }
      //   }
      // }
      //
      // var gtmDL = {
      //   'event': 'nsPurchase', // Residential - eCommerce - Order Captured
      //   'transactionId': response.Data.ServiceOrderID,
      //   'transactionTotal': parseFloat($('[data-total-price]').text()),
      //   'transactionTax': parseFloat($('[data-fee-total]').text()),
      //   'transactionProducts': gtmDLProducts
      // };
      //
      // // console.log('dataLayer Data:', gtmDL);
      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push(gtmDL);

      // console.log('dataLayer:', dataLayer);

      console.log('Service ID ', response.Data.ServiceID);
      console.log('Service Order ID ', response.Data.ServiceOrderID);

      // Tune promo trigger
      if(sessionStorage.getItem('tune_offer_id')) {
        var tune_offer_id = sessionStorage.getItem('tune_offer_id');
        $.ajax({
          url:  'https://clearlink.go2cloud.org/aff_lsr',
          type: 'GET',
          data: {
            "offer_id" : '1900',
            "transaction_id" : tune_offer_id
          },
          success: function(result) {
            console.log(result);
            sessionStorage.removeItem('tune_offer_id');
          }
        });
      }

      sendNSPopupConversion();

      orderStreet = localStorage.getItem('lumos_address_street');
      orderCity   = localStorage.getItem('lumos_address_city');
      orderState  = localStorage.getItem('lumos_address_state');
      orderZIP    = localStorage.getItem('lumos_address_zip');

      clearAddressSelection();

      var accountID = response.Data.AccountID,
        serviceID = response.Data.ServiceID,
        serviceOrderID = response.Data.ServiceOrderID;

        orderaccountid = accountID;
        orderserviceid = serviceOrderID;

      // NS GTM Timer Start
      startNSTimer();

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "set_service_comments",
          serviceOrderID: serviceOrderID,
          comment: additionalInfo,
          sessionId: localStorage.getItem(ns_ecomm_session_id_name)
        },
        success: function(result) {

          console.log(result);

          var response = $.parseJSON(result);

          console.log(response);

          if (response.Status === "success") {
            // NS GTM Timer End
            sendNSTimer('Step 2.2 - set_service_comments - POST: Service/ServiceOrder/Comment/');

            // runCreditCheck(accountID, serviceID, serviceOrderID);
            scheduleInstallation(serviceID, serviceOrderID);

          } else {

            sendNSError('Step 2.2 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Error: ' + response.Message);
            // stopNSTimer();

            $('.submission-indicator li').eq(1).addClass('error');

            $('#submission_message').html('We encountered an error: <strong>' + response.Message + '</strong> <br /> Please contact our customer support at <strong>866.542.5900</strong> for further assistance.');
            $('#submission_message').removeClass('hide');

            console.log(result, 'ServiceOrderID: ', serviceOrderID, 'Comments: ', additionalInfo);
          }
        },
        error: function(result) {

          sendNSError('Step 2.2 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Comm Error');
          // stopNSTimer();

          orderComplete();

          console.log(result, {
            featureParams: feature_list
          });

        }
      });

      // console.log(response);

      // TODO: Redirect to Thank You

    } else {
      // hide service creation failure
      // $('#orderSubmitModal .progress-order').attr('data-percent','100');
      // $('#orderSubmitModal .progress-order').addClass('success');
      // $('#submission_message').text('Step 3 of 3: You\'re all set! ');

      // logNSEvent("ecomm - order processing - Create Service Request: API Error - No Service Order Created - Send to Order Complete");

      sendNSError('Step 2.1 - Error - create_service - POST: Service/ - API Error: ' + response.Message);

      orderComplete();

      // $('#orderSubmitModal .view-order').removeClass('hide');
      // $('#processing-message').text('Thank you! Your order has been received.');
      // $('#submission_message').text('We\'ll be contacting you via email shortly with details of your order. Welcome to the NorthState family!');
      // $('#order-processing-instructions').hide();

      console.log(result, {
        featureParams: feature_list
      });
    }

  }

  // **
  // * Once service request has been created, run credit check
  // **
  // function runCreditCheck(accountID, serviceID, serviceOrderID) {
  function runCreditCheck(accountID, serviceID, serviceOrderID) {

    var $ = jQuery;

    // logNSEvent("ecomm - order processing - Run Credit Check (Processing Step 3)");

    $('.submission-indicator li').eq(0).addClass('complete');
    $('.submission-indicator li').eq(0).removeClass('current');

    // $('.submission-indicator li').eq(1).addClass('complete');
    // $('.submission-indicator li').eq(1).removeClass('current');

    // $('.submission-indicator li').eq(1).addClass('current');

    $('.submission-indicator li').eq(1).addClass('complete');
    $('.submission-indicator li').eq(1).removeClass('current');

    $('.submission-indicator li').eq(2).addClass('current');


    $('#submission_message').html('This step may take up to a few minutes to complete. <br /> Thank you for your patience.');
    $('#submission_message').removeClass('hide');

    console.log(accountID, serviceID, serviceOrderID);

    // NS GTM Timer Start
    startNSTimer();

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "account_credit_check",
        accountId: accountID,
        sessionId: localStorage.getItem(ns_ecomm_session_id_name)
      },
      success: function(result) {
        // NS GTM Timer End

        console.log(result);

        var response;

        try {
          response = $.parseJSON(result);
        } catch (e) {

          sendNSError('Step 3.2 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Comm Error 2');

          // logNSEvent("ecomm - order processing - Error: Run Credit Check: Unable to run credit check via API (Processing Step 3)");

          $('.submission-indicator li').eq(2).addClass('error');
          $('.submission-indicator li').eq(2).removeClass('current');

          $('#submission_error_message').html('We encountered an error with the credit checking service. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
          $('#submission_error_message').removeClass('hide');

          $('#termsconditions').prop('disabled', false);
          $('.order-step[data-order-step="3"] button').prop('disabled', false);
          $('#submission_message').addClass('hide');
          $('.close-reveal-modal').removeClass('hide');
        }

        if (response.Status == 'success') {

          sendNSTimer('Step 3.1 - account_credit_check - GET: /Account/Oue/[AccountID]/DepositAmount/');

          creditCheckAmount = response.Data.amount;
          creditCheckDepositAmount = response.Data.amount;
          creditCheckType = response.Data.type;

          if (response.Data.amount > 0) {

            // logNSEvent("ecomm - order processing - Run Credit Check: Type: " +response.Data.type+ ", Amount: " +response.Data.amount+ " (Processing Step 3)");

            // Do Deposit/Advance Payment
            // $('.submission-indicator li').eq(1).addClass('alert');
            // $('.submission-indicator li').eq(1).removeClass('current');
            $('.submission-indicator li').eq(2).addClass('alert');
            $('.submission-indicator li').eq(2).removeClass('current');

            // Change language for apay
            if (response.Data.type == 'deposit') {
              $('#submission_message').html('A deposit of <strong>$' + parseFloat(response.Data.amount).toFixed(2) + '</strong> is required to continue processing your order.');
            } else {
              creditCheckDepositAmount = 0;
              $('#submission_message').html('An advance payment of <strong>$' + parseFloat(response.Data.amount).toFixed(2) + '</strong> is required to continue processing your order.');
            }

            $('#submission_message').removeClass('hide');

            // Show Payment Sequence
            $('#credit-check-deposit-cancel').attr('data-account-id', accountID);
            $('#credit-check-deposit-cancel').attr('data-service-id', serviceID);

            $('#ns-credit-check-bank-routingnumber').attr('data-account', accountID);

            $('#ns-credit-check-bank-amount').val(response.Data.amount);
            $('#ns-credit-check-cc-amount').val(response.Data.amount);

            $('#ns-credit-check-bank-accountid').val(accountID);
            $('#ns-credit-check-bank-serviceid').val(serviceID);
            $('#ns-credit-check-bank-serviceorderid').val(serviceOrderID);

            $('#ns-credit-check-cc-accountid').val(accountID);
            $('#ns-credit-check-cc-serviceid').val(serviceID);
            $('#ns-credit-check-cc-serviceorderid').val(serviceOrderID);

            $('#credit-check-deposit').removeClass('hide');

          } else {
            // Continue w/o deposit
            console.log('No Deposit Required');
            // logNSEvent("ecomm - order processing - Run Credit Check: No Deposit Required (Processing Step 3)");

            var additionalInfo = 'Credit Check Results: $' + creditCheckAmount + ' via ' + creditCheckType + '';

            // NS GTM Timer Start
            startNSTimer();
            // createServiceRequest(accountID);
            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "set_service_comments",
                serviceOrderID: serviceOrderID,
                comment: additionalInfo,
                sessionId: localStorage.getItem(ns_ecomm_session_id_name)
              },
              success: function(result) {

                console.log(result);

                var response = $.parseJSON(result);

                console.log(response);

                if (response.Status === "success") {
                  // NS GTM Timer End
                  sendNSTimer('Step 3.2 - set_service_comments - POST: Service/ServiceOrder/Comment/');
                  scheduleInstallation(serviceID, serviceOrderID);
                } else {
                  sendNSError('Step 3.2 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Error: ' + response.Message);

                  $('.submission-indicator li').eq(2).addClass('error');
                  $('.submission-indicator li').eq(2).removeClass('current');

                  $('#submission_error_message').html('We encountered an error. ' + response.Message + ' Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
                  $('#submission_error_message').removeClass('hide');

                  $('#termsconditions').prop('disabled', false);
                  $('.order-step[data-order-step="3"] button').prop('disabled', false);
                  $('#submission_message').addClass('hide');
                  $('.close-reveal-modal').removeClass('hide');

                }
              },
              error: function(results) {
                sendNSError('Step 3.2 - Error - set_service_comments - POST: Service/ServiceOrder/Comment/ - API Comm Error');

                $('.submission-indicator li').eq(2).addClass('error');
                $('.submission-indicator li').eq(2).removeClass('current');

                $('#submission_error_message').html('We encountered an internal communications error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
                $('#submission_error_message').removeClass('hide');

                $('#termsconditions').prop('disabled', false);
                $('.order-step[data-order-step="3"] button').prop('disabled', false);
                $('.close-reveal-modal').removeClass('hide');
              }
            });

          }

        } else {

          sendNSError('Step 3.1 - Error - account_credit_check - GET: /Account/Oue/[AccountID]/DepositAmount/ - API Error: ' + response.Message);
          // stopNSTimer();

          // logNSEvent("ecomm - order processing - Error: Run Credit Check: API Returned Error - "+response.Message+" (Processing Step 3)");

          // $('.submission-indicator li').eq(1).addClass('error');
          // $('.submission-indicator li').eq(1).removeClass('current');

          $('.submission-indicator li').eq(2).addClass('error');
          $('.submission-indicator li').eq(2).removeClass('current');

          $('#submission_error_message').html('We encountered an error. ' + response.Message + ' Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
          $('#submission_error_message').removeClass('hide');

          $('#termsconditions').prop('disabled', false);
          $('.order-step[data-order-step="3"] button').prop('disabled', false);
          $('#submission_message').addClass('hide');
          $('.close-reveal-modal').removeClass('hide');
        }

      },
      error: function(result) {
        console.log('Nope', result);

        sendNSError('Step 3.1 - Error - account_credit_check - GET: /Account/Oue/[AccountID]/DepositAmount/ - API Comm Error');
        // stopNSTimer();

        // logNSEvent("ecomm - order processing - Error: Run Credit Check: API Failure, Error Shown  (Processing Step 3)");

        $('.submission-indicator li').eq(2).addClass('error');
        $('.submission-indicator li').eq(2).removeClass('current');

        $('#submission_error_message').html('We encountered an internal communications error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
        $('#submission_error_message').removeClass('hide');

        $('#termsconditions').prop('disabled', false);
        $('.order-step[data-order-step="3"] button').prop('disabled', false);
        $('.close-reveal-modal').removeClass('hide');
      }
    });
  }

  // **
  // * Pending successful credit check, schedule installation
  // **
  function scheduleInstallation(serviceID, serviceOrderID) {
    var $ = jQuery;

    $('#submission_message').addClass('hide');

    $('.submission-indicator li').eq(1).removeClass('current');
    $('.submission-indicator li').eq(1).removeClass('alert');
    $('.submission-indicator li').eq(1).addClass('complete');
    $('.submission-indicator li').eq(2).addClass('current');

    $('#ns-schedule-install-service-id').val(serviceID);
    $('#ns-schedule-install-service-order-id').val(serviceOrderID);

    // NS GTM Timer Start
    startNSTimer();

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "auto_assign",
        serviceOrderID: serviceOrderID,
        sessionId: localStorage.getItem(ns_ecomm_session_id_name)
      }
    });

    checkAssign(serviceID);

  }

  function checkAssign(serviceID, checkCounter = 0) {
    var $ = jQuery;

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "autoassignstatus",
        sessionId: localStorage.getItem(ns_ecomm_session_id_name),
        serviceID: serviceID
      },
      success: function(result) {
        var newCheckCounter = checkCounter + 1;

        var response = $.parseJSON(result);

        console.log("autoassign status: ", response.Data);

        if (response.Data == "completed") {
          console.log("autoassign completed!");

          // NS GTM Timer End
          sendNSTimer('Step 4.1 - auto_assign - GET: /AutoAssign/[ServiceOrderID]');

          autoAssignCompleted(serviceID);
        } else if (checkCounter > 30) {
          console.log("autoassign failed");

          // NS GTM Timer End
          sendNSError('Step 4.1 - Error - auto_assign - GET: /AutoAssign/[ServiceOrderID] - API Error: ' + response.Message);

          autoAssignFailed();
        } else {
          // Run the Check Again after 8 seconds
          setTimeout(function () {
            checkAssign(serviceID, newCheckCounter);
          }, 8000);
        }
      },
      error: function(result) {
        sendNSError('Step 4.1 - Error - auto_assign - GET: /AutoAssign/[ServiceOrderID] - API Comm Error');
      }
    });
  }

  function autoAssignFailed() {
    var $ = jQuery;

    // logNSEvent("ecomm - order processing - Schedule Installation: AutoAssign Failed, Error Shown (Processing Step 4)");
    $('.submission-indicator li').eq(2).addClass('error');
    // console.log(result);

    $('#submission_error_message').html('We encountered an error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
    $('#submission_error_message').removeClass('hide');

    // $('#termsconditions').prop('disabled', false);
    $('.order-step[data-order-step="3"] button').prop('disabled', false);
    $('.close-reveal-modal').removeClass('hide');
  }

  function autoAssignCompleted(serviceID) {
    var $ = jQuery;
    // logNSEvent("ecomm - order processing - Schedule Installation: AutoAssign Complete (Processing Step 4)");
    // getScheduleDateTime(serviceID);
    getFacilitySplitterCheck(serviceID)
  }

  function getFacilitySplitterCheck(serviceID) {
    var $ = jQuery;

    // NS GTM Timer Start
    startNSTimer();

    // The current date
    var startDate = new Date().toISOString().split('T')[0];

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "facility_splitter_check_service",
        serviceID: serviceID,
        sessionId: localStorage.getItem(ns_ecomm_session_id_name)
      },
      success: function(result) {

        console.log(result);
        var response = $.parseJSON(result);

        if(response.Status == 'success') {

          if(scheduleTries > 0) {
            sendNSTimer('Step 4.1.1 - facility_splitter_check_service - GET: ScheduleItem/FacilitySplitterCheckService/ - Attempt: ' + scheduleTries);
          } else {
            sendNSTimer('Step 4.1.1 - facility_splitter_check_service - GET: ScheduleItem/FacilitySplitterCheckService/');
          }

          var responseDate = '';

          if(response.Data.ScheduleAfterDate !== null) {
            responseDate = response.Data.ScheduleAfterDate.split(' ')[0];

            console.log("Schedule After Date: " + responseDate);

            if(startDate < responseDate) {
              console.log('Update Start Date from ' + startDate + ' to ' + responseDate);
              startDate = responseDate;
            }
          } else {
            console.log("Schedule After Date was NULL");
          }

        } else {
          // NS GTM Timer End
          if(scheduleTries > 0) {
            sendNSError('Step 4.1.1 - Error - facility_splitter_check_service - GET: ScheduleItem/FacilitySplitterCheckService/ - API Error: ' + response.Message + ' - Attempt: ' + scheduleTries);
          } else {
            sendNSError('Step 4.1.1 - Error - facility_splitter_check_service - GET: ScheduleItem/FacilitySplitterCheckService/ - API Error: ' + response.Message);
          }
        }

        getScheduleDateTime(serviceID, startDate);

      },
      error: function(result) {
        if(scheduleTries > 0) {
          sendNSError('Step 4.1.1 - Error - facility_splitter_check_service - GET: ScheduleItem/FacilitySplitterCheckService/ - API Comm Error - Attempt: ' + scheduleTries);
        } else {
          sendNSError('Step 4.1.1 - Error - facility_splitter_check_service - GET: ScheduleItem/FacilitySplitterCheckService/ - API Comm Error');
        }
        // stopNSTimer();
        // logNSEvent("ecomm - order processing - Error: Schedule Installation: API Failure, Error Shown (Processing Step 4)");

        getScheduleDateTime(serviceID, startDate);
      }
    });
  }

  function getScheduleDateTime(serviceID, startDate) {
    var $ = jQuery;

    // NS GTM Timer Start
    startNSTimer();

    console.log('Schedule start date: ' + startDate);

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "service_installation_schedule",
        serviceID: serviceID,
        startDate: startDate,
        sessionId: localStorage.getItem(ns_ecomm_session_id_name)
      },
      success: function(result) {

        console.log(result);

        var response = $.parseJSON(result);

        if (response.Status == 'success') {
          // NS GTM Timer End
          if(scheduleTries > 0) {
            sendNSTimer('Step 4.2 - service_installation_schedule - GET: ScheduleItem/Available/ - Attempt: ' + scheduleTries);
          } else {
            sendNSTimer('Step 4.2 - service_installation_schedule - GET: ScheduleItem/Available/');
          }

          $('.submission-indicator li').eq(2).removeClass('current');

          if(scheduleTries > 0) {
            $('.submission-indicator li').eq(2).addClass('error');
          } else {
            $('.submission-indicator li').eq(2).addClass('alert');
          }

          $('#schedule-installation-date').removeClass('hide');

          // var timeSlots = JSON.parse(result);

          var nowTemp = new Date();
          var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

          var availableDates = [];

          // console.log(countProperties(response.Data));
          // console.log(response.Data);

          for (var d = 0; d < countProperties(response.Data); d++) {
            if (typeof response.Data[d] !== 'undefined' && response.Data[d].TimeSlotType == "AVAILABLE") {
              var formatDate = new Date(response.Data[d].StartTime.split(' ')[0]);
              // formatDate.setDate(formatDate.getDate() - 1);
              // formatDate.setUTCHours(0,0,0,0);

              // console.log(formatDate, response.Data[d].StartTime);
              var formattedDate = formatDate.getFullYear().toString() + formatDate.getMonth().toString() + formatDate.getDate().toString();

              if (!Array.isArray(availableDates[formattedDate])) {
                availableDates[formattedDate] = [];
              }
              // var scheduleItem = response.Data[d].ScheduleItems[0];
              // scheduleItem.TimeSlotConfigDescription = response.Data[d].TimeSlotConfigDescription;

              availableDates[formattedDate].push(response.Data[d]);
            }
          }

          // console.log(availableDates);

          // Clear scheduling fields
          $('#ns-installation-date').val('');
          $('#ns-installation-time').val('');

          // Set Available Dates
          $('#ns-installation-date').fdatepicker({
            format: 'yyyy-mm-dd',
            disableDblClickSelection: true,
            pickTime: false,
            // leftArrow:'&larr;',
            // rightArrow:'&rarr;',
            closeButton: false,
            onRender: function(date) {
              var disabled = 'disabled',
                cDateKey = date.getFullYear().toString() + date.getMonth().toString() + date.getDate().toString();

              if (cDateKey in availableDates) {
                disabled = '';
              }

              // console.log(date, disabled, availableDates[date.valueOf()]);
              return disabled;
            }
          });

          // Set Available Times
          $('#ns-installation-date').change(function() {
            // Clear Available Times
            $('#ns-installation-time option').each(function() {
              if ($(this).val() !== '') {
                $(this).remove();
              }
            });

            // Clear schedule item in form
            $('#ns-schedule-item').val('');

            if ($(this).val() !== '') {
              $('#ns-installation-time').parent().addClass('loading');
              $('#ns-installation-time').prop('disabled', true);

              // console.log('date selected', $(this).val());
              // TODO: Get available times

              var selectedDate = new Date($(this).val());
              var cDateKey = selectedDate.getFullYear().toString() + selectedDate.getMonth().toString() + selectedDate.getDate().toString();

              for (var t = 0; t < availableDates[cDateKey].length; t++) {
                console.log(availableDates[cDateKey]);
                $('#ns-installation-time').append('<option value="' + availableDates[cDateKey][t].ScheduleItems[0].TimeSlotID + '">' + availableDates[cDateKey][t].TimeSlotConfigDescription + '</option>');
              }

              $('#ns-installation-time').parent().removeClass('loading');
              $('#ns-installation-time').prop('disabled', false);

            } else {
              $('#ns-installation-time').val('');
            }
          });

          // Select Time
          $('#ns-installation-time').change(function() {
            var selectedDate = new Date($('#ns-installation-date').val());
            var cDateKey = selectedDate.getFullYear().toString() + selectedDate.getMonth().toString() + selectedDate.getDate().toString();

            $('#ns-schedule-item').val(JSON.stringify(availableDates[cDateKey][$(this).prop('selectedIndex') - 1].ScheduleItems[0]));
          });
        } else {
          // NS GTM Timer End
          if(scheduleTries > 0) {
            sendNSError('Step 4.2 - Error - service_installation_schedule - GET: ScheduleItem/Available/ - API Error: ' + response.Message + ' - Attempt: ' + scheduleTries);
          } else {
            sendNSError('Step 4.2 - Error - service_installation_schedule - GET: ScheduleItem/Available/ - API Error: ' + response.Message);
          }
          // stopNSTimer();
          // logNSEvent("ecomm - order processing - Error: Schedule Installation: API Message: " +response.Message+ " (Processing Step 4)");

          $('.submission-indicator li').eq(2).addClass('error');

          $('#submission_error_message').html('We encountered an error. ' + response.Message + ' Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
          $('#submission_error_message').removeClass('hide');

          $('.order-step[data-order-step="3"] button').prop('disabled', false);
          $('.close-reveal-modal').removeClass('hide');
        }

      },
      error: function(result) {
        if(scheduleTries > 0) {
          sendNSError('Step 4.2 - Error - service_installation_schedule - GET: ScheduleItem/Available/ - API Comm Error - Attempt: ' + scheduleTries);
        } else {
          sendNSError('Step 4.2 - Error - service_installation_schedule - GET: ScheduleItem/Available/ - API Comm Error');
        }
        // stopNSTimer();
        // logNSEvent("ecomm - order processing - Error: Schedule Installation: API Failure, Error Shown (Processing Step 4)");

        $('.submission-indicator li').eq(2).addClass('error');
        console.log(result);

        $('#submission_error_message').html('We encountered an error. Please try again later or you may contact our customer support at <strong>866.542.5900</strong>');
        $('#submission_error_message').removeClass('hide');

        // $('#termsconditions').prop('disabled', false);
        $('.order-step[data-order-step="2"] button').prop('disabled', false);
        $('.close-reveal-modal').removeClass('hide');
      }
    });
  }

  // **
  // * Final Step. Order has completed.
  // **
  function orderComplete() {

    var $ = jQuery;

    // logNSEvent("ecomm - order processing - Order Complete! (Processing Step 5)");

    isProcessingOrder = false;

    // Delete API Session & Cookie
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_session_delete",
        sessionId: localStorage.getItem(ns_ecomm_session_id_name)
      },
      success: function(result) {
        console.log(result);

        localStorage.removeItem(ns_ecomm_session_id_name);

        $('.submission-indicator li').eq(0).removeClass('current');
        $('.submission-indicator li').eq(1).removeClass('current');
        $('.submission-indicator li').eq(2).removeClass('current');
        // $('.submission-indicator li').eq(3).removeClass('current');
        // $('.submission-indicator li').eq(4).removeClass('current');
        //
        $('.submission-indicator li').eq(0).addClass('complete');
        $('.submission-indicator li').eq(1).addClass('complete');
        $('.submission-indicator li').eq(2).addClass('complete');
        // $('.submission-indicator li').eq(3).addClass('complete');
        // $('.submission-indicator li').eq(4).addClass('complete');
        //
        // $('#processing-message').text('Thank you! Your order has been received.');
        // $('#submission_message').text('We\'ll be contacting you via email shortly with details of your order. Welcome to the NorthState family!');
        //
        // $('#submission_message').removeClass('hide');
        // $('#order-processing-instructions').hide();
        //
        // $('#order-complete-button').removeClass('hide');

        $('.submission-indicator li').eq(3).addClass('current');

        finalizeOrder();

      },
      error: function() {

        localStorage.removeItem(ns_ecomm_session_id_name);

        $('.submission-indicator li').eq(0).removeClass('current');
        $('.submission-indicator li').eq(1).removeClass('current');
        $('.submission-indicator li').eq(2).removeClass('current');
        // $('.submission-indicator li').eq(3).removeClass('current');
        // $('.submission-indicator li').eq(4).removeClass('current');
        //
        $('.submission-indicator li').eq(0).addClass('complete');
        $('.submission-indicator li').eq(1).addClass('complete');
        $('.submission-indicator li').eq(2).addClass('complete');
        // $('.submission-indicator li').eq(3).addClass('complete');
        // $('.submission-indicator li').eq(4).addClass('complete');
        // $('.submission-indicator li').eq(4).addClass('finalOrderStepComplete');
        //
        // $('#processing-message').text('Thank you! Your order has been received.');
        // $('#submission_message').text('We\'ll be contacting you via email shortly with details of your order. Welcome to the NorthState family!');
        //
        // $('#submission_message').removeClass('hide');
        // $('#order-processing-instructions').hide();
        //
        // $('#order-complete-button').removeClass('hide');

        $('.submission-indicator li').eq(3).addClass('current');

        finalizeOrder();

      }
    });


  }

  function finalizeOrder() {
    var $ = jQuery;

    var url = '/shop-fiber/order-complete/';
    var orderDetails = encodeURIComponent($('#product_selected_features').html().replace(/•/g, "&#8226;"));

    var orderTotal = parseFloat($('[data-total-price]').text()),
        orderEmail = $('#account_service_email_address').val(),
        orderPhone = $('#account_service_contact_phone').val(),
        orderFirstName = $('#account_service_first_name').val(),
        orderLastName = $('#account_service_last_name').val(),
        orderCountry = 'US';


    var orderCompleteForm = '<form action="' + url + '" method="post">' +

      '<input type="hidden" name="ordertotal" value="' + orderTotal + '" />' +
      '<input type="hidden" name="orderemail" value="' + orderEmail + '" />' +
      '<input type="hidden" name="orderphone" value="' + orderPhone + '" />' +
      '<input type="hidden" name="orderfirstname" value="' + orderFirstName + '" />' +
      '<input type="hidden" name="orderlastname" value="' + orderLastName + '" />' +
      '<input type="hidden" name="orderstreet" value="' + orderStreet + '" />' +
      '<input type="hidden" name="ordercity" value="' + orderCity + '" />' +
      '<input type="hidden" name="orderstate" value="' + orderState + '" />' +
      '<input type="hidden" name="orderzip" value="' + orderZIP + '" />' +
      '<input type="hidden" name="ordercountry" value="' + orderCountry + '" />' +

      '<input type="hidden" name="orderitems" value="' + escape(localStorage.getItem(cookieLabel_product_selection)) + '" />' +

      '<input type="hidden" name="ordertax" value="' + parseFloat($('[data-fee-total]').text()) + '" />' +
      '<input type="hidden" name="orderdetails" value="' + orderDetails + '" />' +
      '<input type="hidden" name="orderaccountid" value="' + orderaccountid + '" />' +
      '<input type="hidden" name="orderserviceid" value="' + orderserviceid + '" />' +
      '<input type="hidden" name="ordermonthly" value="' + parseFloat($('[data-total-price]').text()) + '" />' +
      '</form>';

    console.log(orderCompleteForm);

    var $orderCompleteObj = $(orderCompleteForm)
    $('body').append($orderCompleteObj);
    $orderCompleteObj.submit();
  }

  // **
  // * Cancel order action
  // **
  function cancelOrder() {

    var $ = jQuery;

    // logNSEvent("ecomm - order processing - Order Cancelled via Processing Screen Option");
    $('#processing-message').text('Your Order Has Been Cancelled');
    $('#order-processing-instructions').html('Please contact customer support at <a href="tel:3368863600">336.886.3600</a> if you require further assistance.');

    $('.progress-order').addClass('hide');
    $('#submission_error_message').addClass('hide');
    $('#submission_message').addClass('hide');
    $('#credit-check-deposit').addClass('hide');
    $('#order-cancel-button').addClass('hide');
    $('#credit-check-payment').addClass('hide');
    $('#schedule-installation-date').addClass('hide');

    $('.close-reveal-modal').addClass('hide');
    $('.view-order').addClass('hide');

    $('#order-cancel-button').removeClass('hide');
  }

  function clearAddressSelection() {
    var $ = jQuery;

    var addressLS = [
      'lumos_address_full_address',
      'lumos_address_place_id',
      'lumos_location_id',
      'lumos_address_id',
      'lumos_address_status',
      'lumos_address_check_decline',
      'lumos_address_city',
      'lumos_address_city_id',
      'lumos_address_state',
      'lumos_address_state_id',
      'lumos_address_street',
      'lumos_address_street_id',
      'lumos_address_zip',
      'lumos_address_house_num',
      'lumos_address_apt_num',
      'lumos_address_multigig',
      'lumos_address_market'
    ];

    for (var a = 0; a < addressLS.length; a++) {
      // console.log(addressCookies[a]);
      localStorage.removeItem(addressLS[a]);
    }
  }

// })(jQuery);
