jQuery(document).ready(function($) {
  if($('#order-info').length) {

    $('#account_service_first_name, #account_service_last_name, #account_service_contact_phone, #account_service_contact_email').on('blur', function() {
      if(checkHubspotFields()) {
        console.log('All Required Fields for Hubspot Submission are complete');
        setHubspotPartialContact();
      } else {
        console.log('Fields Required to Complete Hubspot Submission');
      }
    });

    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if(checkHubspotFields() && checkHubspotAddressFields()) {
          console.log('All Required Fields + Address for Hubspot Submission are complete');
          setHubspotPartialContact();
        } else {
          console.log('Fields Required + Address to Complete Hubspot Submission');
        }
      });
    });

    // Look for class changes on address checker container
    observer.observe($('#address-checker')[0], {
      attributes: true,
      attributeFilter: ['class']
    });

  }

});


function checkHubspotFields() {
  var $ = jQuery;
  if($('#order-customer-info').length) {
    var nameValid = false;
    var phoneValid = ($('#account_service_contact_phone').valid() && $('#account_service_contact_phone').val());
    var emailValid = ($('#account_service_contact_email').valid() && $('#account_service_contact_email').val());

    if(($('#account_service_first_name').valid() && $('#account_service_first_name').val()) && ($('#account_service_last_name').valid() && $('#account_service_last_name').val())) {
      nameValid = true;
    }
    return nameValid && phoneValid && emailValid;
  }
  return false;
}

function checkHubspotAddressFields() {
  if($('#order-customer-info').length) {
    return localStorage.getItem('lumos_address_full_address') && localStorage.getItem('lumos_address_street') && localStorage.getItem('lumos_address_city') && localStorage.getItem('lumos_address_state');
  }
  return false;
}

function setHubspotPartialContact(isCustomer = false) {

  if($('#order-customer-info').length) {
    // Set Customer Type
    var customerType = "Opportunity";
    if(isCustomer) {
      customerType = "Registered Customer";
    }

    // Submit contact data to hubspot
    var hsFormData = {
      "fields": [
        {
          "name": "firstname",
          "value": $('#account_service_first_name').val()
        },
        {
          "name": "lastname",
          "value": $('#account_service_last_name').val()
        },
        {
          "name": "phone",
          "value": $('#account_service_contact_phone').val()
        },
        {
          "name": "email",
          "value": $('#account_service_contact_email').val()
        },
        {
          "name": "customer_type",
          "value": customerType
        }
      ],
      "legalConsentOptions": {
        "consent": { // Include this object when GDPR options are enabled
          "consentToProcess": true,
          "text": "I agree to allow Example Company to store and process my personal data.",
          "communications": [
            {
              "value": true,
              "subscriptionTypeId": 999,
              "text": "I agree to receive marketing communications from Example Company."
            }
          ]
        }
      }
    };

    // Get address data if available
    if(localStorage.getItem('lumos_address_full_address') && localStorage.getItem('lumos_address_street') && localStorage.getItem('lumos_address_city') && localStorage.getItem('lumos_address_state')) {
      hsFormData.fields.push({
        "name": "address",
        "value": localStorage.getItem('lumos_address_street')
      });
      hsFormData.fields.push({
        "name": "city",
        "value": localStorage.getItem('lumos_address_city')
      });
      hsFormData.fields.push({
        "name": "state",
        "value": localStorage.getItem('lumos_address_state')
      });
    }

    // Send Form Data
    sendHubspotFormData(hsFormData);
  }
}

function sendHubspotFormData(formData) {
  $.ajax({
    type: "POST",
    url: "https://api.hsforms.com/submissions/v3/integration/submit/20327025/3b88e2ed-3df0-44be-bffc-bcd98cc8ac64",
    cache: false,
    contentType: 'application/json',
    processData: false,
    data: JSON.stringify(formData),
    success: function (data) {
      console.log('Hubspot capture was successful.');
      console.log(data);
      hasInitialContactCapture = true;
    }
  });
}
