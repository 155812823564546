jQuery(document).ready(function($) {

  $(window).resize(function(e){
    planBlockSliderInit();
  });

  planBlockSliderInit();

  function planBlockSliderInit() {
    if(window.innerWidth < 781) {
      if(!$('.wp-block-columns.plan-slider').hasClass('slick-initialized')){

        var ind = 1;
        var initSlide = 1;
        $('.wp-block-column.plan-column').each(function() {
          if($(this).hasClass('recommended')) {
            initSlide = ind;
            return;
          }
          ind++;
        });

        $('.wp-block-columns.plan-slider').slick({
          arrows: true,
          dots: false,
          centerMode: true,
          focusOnSelect: true,
          centerPadding: '30px',
          infinite: false
        });

        $('.wp-block-columns.plan-slider').slick('slickGoTo', initSlide, false);

      }
    } else{
      if($('.wp-block-columns.plan-slider').hasClass('slick-initialized')){
        $('.wp-block-columns.plan-slider').slick('unslick');
      }
    }
  }


  $('form[novalidate]').submit(function(e) {
    var attr = $(this).attr('action');
    if(typeof attr !== 'undefined' && attr !== false) {
      console.log('action');
    } else {
      e.preventDefault();
      console.log('no action');
    }
  });

  $('[data-toggle="off-canvas-search"]').click(function() {
    $('.off-canvas-search input[type="search"]').focus();
  });

  $('#off-canvas-search').on('openedEnd.zf.offCanvas', function() {
    $('#off-canvas-search input[type="search"]').focus();
  });

  // refresh page after 2 hours
  var refreshTime = 2 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

  // refreshTime = 5 * 1000; // Testing

  setTimeout(function() {
    // console.log('fire');
    location.reload(true);
  }, refreshTime);

  // Auto Open Chat
  setTimeout(function() {
    if(!localStorage.getItem('lumos_chat_autopop') && $('#five9-maximize-button').length && $('#order-info').length === 0) {
      $('#five9-maximize-button').click();
      localStorage.setItem('lumos_chat_autopop', '1'); // Expires in 1 day
      console.log('open chat');
    }
  }, (2 * 60 * 1000)); // 2 minutes

  // Resize header spacing
  function resizeHeaderSpacer() {
    var navHeight      = ($('.top-nav').outerHeight() || 0),
        wpAdminBar     = ($('#wpadminbar').outerHeight() || 0),
        topNavWidth    = ($('.top-nav .logo-row').outerWidth() || 0);
        topNavHeight   = ($('.top-nav .logo-row').outerHeight() || 0);
        utilNavHeight  = ($('#utility-nav').outerHeight() || 0);
        subMenuHeight  = ($('.submenublock').outerHeight() || 0);
        navPromoBanner = ($('.nav-promo-banner').outerHeight() || 0);
        navChatBanner  = ($('.account-banner').outerHeight() || 0);
        custAlert      = ($('.customer-alert:visible').outerHeight() || 0);

    navHeight += wpAdminBar + custAlert;

    // $('.top-nav').css({'top': wpAdminBar + custAlert});
    //
    // $('.main-submenu').css({'top':navHeight});
    // $('.regular-menu').css({'top':navHeight});
    // $('.normal-menu').css({'top':navHeight});
    $('#off-canvas').css({
      'top':navHeight,
      'height': 'calc(100vh - '+navHeight+'px)'
    });

    $('#off-canvas-search').css({
      'height':topNavHeight,
      'width': '100%'
    });
    // $('.top-nav-spacer').css({'height':navHeight-wpAdminBar});
    $('#address-checker').css({'top':navHeight});
    $('.address-checker-wrap').css({'max-height':($(window).height() - navHeight)});
    // $('.submenublock').css({'top':navHeight});
    //
    // $('.nav-promo-banner').css({'top':navHeight + subMenuHeight});
    // $('.account-banner').css({'top':navHeight + subMenuHeight});
    // $('.alert-banner').css({'top':navHeight + subMenuHeight});

    if((utilNavHeight) > 0) {
      $('#ns-account-login').css({'top': (utilNavHeight + wpAdminBar)});
    } else {
      $('#ns-account-login').css({'top': navHeight});
    }


    // console.log('headcalc');
  }
  resizeHeaderSpacer();

  $(window).load(function(){
    resizeHeaderSpacer();
  });

  // Smooth scroll for anchor links
  // $(document).on('click', 'a[href*="#"]:not(.noscroll)', function (event) {
  $('a[href*="#"]:not(.noscroll):not([data-expand-mobile-price])').click(function (event) {
    var pagePath = window.location.pathname,
        linkPath = $.attr(this, 'href').substring(0, $.attr(this, 'href').indexOf("#"));
        elementID = $.attr(this, 'href').substring($.attr(this, 'href').indexOf("#"));

    // console.log(pagePath, linkPath, linkPath.length);

    if( (pagePath === linkPath || linkPath.length === 0) && elementID.length > 1) {
      event.preventDefault();

      if($(elementID).length) {
        $('html, body').animate({
            scrollTop: $(elementID).offset().top - ($('.top-nav').outerHeight()  || 0) - ($('.submenublock').outerHeight()  || 0)
        }, 500);
      }
    }


  });

  $('.customer-alert .alert-close, .customer-alert .alert-open').click(function() {
    $(this).parents('.customer-alert').toggleClass('open');
    resizeHeaderSpacer();

    if($(this).parents('.customer-alert').hasClass('open')) {
      localStorage.setItem('close_alert_'+$(this).parents('.customer-alert').attr('data-alert-id'), '');
    } else {
      localStorage.setItem('close_alert_'+$(this).parents('.customer-alert').attr('data-alert-id'), 'closed');
    }
  });

  $('.customer-alert').each(function() {
    var alertid = $(this).attr('data-alert-id');

    if(typeof localStorage.getItem('close_alert_'+alertid) !== 'undefined' && localStorage.getItem('close_alert_'+alertid).length) {
      $(this).removeClass('open');
    } else {
      $(this).addClass('open');
      resizeHeaderSpacer();
    }

  });


  $('.nav-promo-banner .promo-close, .nav-promo-banner .promo-open').click(function() {
    $(this).parents('.nav-promo-banner').toggleClass('open');

    if($(this).parents('.nav-promo-banner').hasClass('open')) {
      localStorage.setItem('close_promo_'+$(this).parents('.nav-promo-banner').attr('data-promo-id'), '');
    } else {
      localStorage.setItem('close_promo_'+$(this).parents('.nav-promo-banner').attr('data-promo-id'), 'closed');
    }
  });

  $('.nav-promo-banner').each(function() {
    var promoid = $(this).attr('data-promo-id');

    if(typeof localStorage.getItem('close_promo_'+promoid) !== 'undefined' && localStorage.getItem('close_promo_'+promoid).length) {
      $(this).removeClass('open');
    } else {
      $(this).addClass('open');
    }
  });

  $('.account-banner .assistant-close').click(function() {
    $(this).parents('.account-banner').toggleClass('open');

    if($(this).parents('.account-banner').hasClass('open')) {
      localStorage.setItem('close_account_banner', '');
    } else {
      localStorage.setItem('close_account_banner', 'closed');
    }
  });

  $('.account-banner').each(function() {
    if(typeof localStorage.getItem('close_account_banner') !== 'undefined' && localStorage.getItem('close_account_banner').length) {
      $(this).removeClass('open');
    } else {
      $(this).addClass('open');
    }
  });


  // Auto-close Address Checker and Promo Banner if on specific pages
  if(window.location.pathname.includes('help-center') ||
     window.location.pathname.includes('shop-fiber') ||
     window.location.pathname.includes('order') ||
     window.location.pathname.includes('my-account')) {
    $('.nav-promo-banner').each(function() {
      $(this).removeClass('open');
    });
    $('#address-checker').removeClass('open');
  }

  // console.log(window.location.pathname);



  $('a[data-toggle="off-canvas"]').click(function() {
    resizeHeaderSpacer();
    // console.log('resize-it', $('.off-canvas').outerHeight());
  });

  function resizeFooterSpacer() {
    var navHeight = ($('.footer').outerHeight() || 0);
    $('.footer-spacer').css({'height':navHeight});
  }
  resizeFooterSpacer();

  $(window).on('resize', function() {
    resizeHeaderSpacer();
    resizeFooterSpacer();
  });

  $('.to-next-section').click(function(e) {
    e.preventDefault();

    var $nextElement = $(this).parents('div.wp-block-lazyblock-homepage-header-image').next('div');

    if($(this).hasClass('business-header')) {
      $nextElement = $(this).parents('div.business-slider').nextAll('div').not('.hide').first();
    }

    $('html, body').animate({
        scrollTop: $nextElement.offset().top - ($('.top-nav').outerHeight() || 0)
    }, 700);
  });

  $('.to-top-section').click(function(e) {
    e.preventDefault();

    $('html, body').animate({
        scrollTop: 0
    }, 700);
  });

  $('.close-menu-accordion').click(function() {
    $(this).parent().parent().find('a').click();
  });

  $('.services-button .button').hover(function() {
    var imageSwap = $(this).attr('data-swap-service-image');
    $('.services-photo').css('background-image', 'url('+imageSwap+')');
  });

  $('.services-info .slider-controls').slick({
    arrows: true,
    dots: true,
    fade: true,
    asNavFor: '.services-image-slider .slider-images'
  });

  $('.services-image-slider .slider-images').slick({
     arrows: false,
     dots: false,
     asNavFor: '.services-info .slider-controls'
  });

  if($('.services-info .slider-controls .slick-dots li').length <= 1) {
    $('.services-info .slider-controls .slick-dots').addClass('hide');
  }


  // friggin' tabs

  $('.available-features').each(function() {

    var $availfeatures = $(this);

    $availfeatures.find('.af-tabs button').eq(0).addClass('active');

    $availfeatures.find('.af-tabs button').each(function() {
      $(this).click(function() {

        $availfeatures.find('.af-tabs button').each(function() {
          $(this).removeClass('active');
        });

        $(this).addClass('active');

        $availfeatures.find('.af-content div.af-panel').each(function() {
          $(this).addClass('hide');
        });

        $availfeatures.find('.af-content div.af-panel').eq($(this).index()).removeClass('hide');
      });
    });

    $availfeatures.find('.af-content div.af-panel').each(function() {
      var $featurerow = $(this).find('.feature-row'),
          $featureselector = $(this).find('.af-select'),
          $featurecontent = $(this).find('.feature-content');

      $featurerow.find('button').eq(0).addClass('active');
      $featurecontent.find('.af-bubble').eq(0).removeClass('hide');

      $(this).addClass('hide');

      $featurerow.find('button').click(function() {

        $featurerow.find('button').each(function() {
          $(this).removeClass('active');
        });

        $(this).addClass('active');

        $featurecontent.find('.af-bubble').each(function() {
          $(this).addClass('hide');
        });

        $featurecontent.find('.af-bubble').eq($(this).index()).removeClass('hide');
      });

      $featureselector.find('.af-selector').change(function() {
        console.log($(this)[0].selectedIndex);
        $featurecontent.find('.af-bubble').each(function() {
          $(this).addClass('hide');
        });
        $featurecontent.find('.af-bubble').eq($(this)[0].selectedIndex).removeClass('hide');
      });

    });

    $availfeatures.find('.af-content div.af-panel').eq(0).removeClass('hide');
  });

  if($('.available-features').length) {
    // console.log(window.location.hash);
    // if(window.location.hash == '#fiber-tv' || window.location.hash == '#fiber-internet') {
    if(urlParamNS('fiber-tv') || urlParamNS('fiber-internet')) {

      $('html, body').animate({
          scrollTop: $('.available-features').offset().top - ($('.top-nav').outerHeight() || 0)
      }, 700);

      if(urlParamNS('fiber-tv')) {
        console.log('bwamp');
        $('button[data-slide-index="1"]').click();
      }
    }
  }

  if($('.contact-us-block').length) {
    console.log(window.location.hash);
    if(window.location.hash.length) {
      var $scrollToSelect = $('a[data-hash="'+window.location.hash+'"]');

      $scrollToSelect.click();

      $('html, body').animate({
          scrollTop: $('.contact-us-block').offset().top - ($('.top-nav').outerHeight() || 0)
      }, 700);
    }
  }

  $('.subservice-select').change(function(e) {
    var url = $(this.options[e.target.selectedIndex]).attr('data-url');
    if(typeof url !== "undefined" ) {
      window.location.href = url;
    }
  });

  $('form#blog-sort select').change(function() {
    $('form#blog-sort').submit();
  });

  $('form#blog-sort-m select').change(function() {
    $('form#blog-sort-m').submit();
  });





  if(urlParamNS('open-checker') !== null) {
    $('#address-checker').addClass('open');
  }

  if(urlParamNS('sort-year') !== null || urlParamNS('sort-month') !== null || urlParamNS('page-index') !== null) {

    if(urlParamNS('sort-year') !== null && urlParamNS('sort-year').length) {
      $('select#sort-year').val(urlParamNS('sort-year'));
      $('select#sort-year-m').val(urlParamNS('sort-year'));
    }

    if(urlParamNS('sort-month') !== null && urlParamNS('sort-month').length) {
      $('select#sort-month').val(urlParamNS('sort-month'));
    }

    $('html, body').animate({
        scrollTop: $('#basic-posts-block').offset().top - ($('.top-nav').outerHeight() || 0)
    }, 700);

  }

  if(urlParamNS('order-date') !== null || urlParamNS('sort-by-topic') !== null) {
    if(urlParamNS('order-date') !== null && urlParamNS('order-date').length) {
      $('select#order-date').val(urlParamNS('order-date'));
    }
    if(urlParamNS('sort-by-topic') !== null && urlParamNS('sort-by-topic').length) {
      $('select#sort-by-topic').val(urlParamNS('sort-by-topic'));
    }
  }

  if(urlParamNS('tag-select') || urlParamNS('search')) {
    if(urlParamNS('tag-select')) {
      $('select[name=tag-select]').val(urlParamNS('tag-select'));
      $('button[name=tag-select][value='+urlParamNS('tag-select')+']').addClass('selected');
    }
    if(urlParamNS('search')) {
      $('input[name=search]').val(urlParamNS('search'));
    }

    $('html, body').animate({
        scrollTop: $('.helpcenterblock-search').offset().top - ($('.top-nav').outerHeight() || 0)
    }, 500);
  }

  $('#chat-button, #chat-button-mobile, a[href="#open-chat"]').click(function(e) {
    e.preventDefault();
    if($('#five9-maximize-button').length) {
      $('#five9-maximize-button').click();
    }
  });


  // TechSol Form autocheck
  if(window.location.href.includes('/our-solutions/')) {

    if(window.location.href.includes('/security/')) {
      $('input[type="checkbox"][value="Cybersecurity"]').prop('checked', true);
    }

    if(window.location.href.includes('/data-center/')) {
      $('input[type="checkbox"][value="Data Center"]').prop('checked', true);
    }

    if(window.location.href.includes('/cloud/')) {
      $('input[type="checkbox"][value="Cloud"]').prop('checked', true);
    }

    if(window.location.href.includes('/professional-services/')) {
      $('input[type="checkbox"][value="Professional Services"]').prop('checked', true);
    }

    if(window.location.href.includes('/managed-services/')) {
      $('input[type="checkbox"][value="Managed Services"]').prop('checked', true);
    }
  }


  // Business form autocheck
  if(window.location.href.includes('/business/')) {

    if(window.location.href.includes('/fiber-internet/')) {
      $('input[type="checkbox"][value="Fiber Internet"]').prop('checked', true);
    }

    if(window.location.href.includes('/voice/')) {
      $('input[type="checkbox"][value="Voice"]').prop('checked', true);
    }

    if(window.location.href.includes('/bundles/')) {
      $('input[type="checkbox"][value="Bundles"]').prop('checked', true);
    }

    if(window.location.href.includes('/equipment/')) {
      $('input[type="checkbox"][value="Equipment"]').prop('checked', true);
    }

    if(window.location.href.includes('/wholesale/')) {
      $('input[type="checkbox"][value="Wholesale"]').prop('checked', true);
    }
  }

  $('button[data-open-target="tv2go"]').click(function() {
    // var url = $('#tv2go').val();
    var url = $(this).parent('div').prev().find('select[data-tv2go]').val();
    console.log(url);
    // console.log($(this), $(this).siblings('.select-wrap'), $(this).siblings('.select-wrap').find('select[data-tv2go]'));
    var win = window.open(url, '_blank');
    win.focus();
  });

});


function logNSEvent(event_label) {
  var $ = jQuery;

  $.ajax({
    url: '/wp-content/themes/Lumos/api_helper.php',
    method: 'POST',
    contentType: 'application/x-www-form-urlencoded',
    data: {
      'action': 'ns_event_log',
      'event': event_label,
      'event_url': window.location.href
    },
    success: function(result) {
      console.log(result);
    }
  });
}


function toggleAddressChecker() {
  var $ = jQuery;

  var isHidden = 'true';
  if($('#address-checker').attr('aria-hidden') == "true") {
    isHidden = 'false';
  }

  console.log(isHidden);

  $('#address-checker').toggleClass('open');
  $('#address-checker').attr('aria-hidden', isHidden);
}
