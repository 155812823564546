jQuery(document).ready(function($) {

  var ns_account_session,
      ns_username,
      ns_cookie_expire,
      ns_account_submit_lock = false; // Lock is true if you need to auth through old my account

  // var ns_cookie_options = { secure: true, domain: window.location.hostname };
  var ns_cookie_options = {domain: window.location.hostname};

  // Account Login
  $('#ns-login-account').submit(function(e) {
    if(ns_account_submit_lock) {
      e.preventDefault();
      console.log('submit lock');
    }
  });

  $('#ns-login-account').bind("formvalid.zf.abide", function(e,target) {
    // Clear banner cookie
    localStorage.setItem('close_account_banner', '');

    var ns_un = $("#ns_username").val().trim();
    var ns_pw = $("#ns_password").val();
    //
    // nsLoginSession(ns_un, ns_pw);
    $('.full-loader').removeClass('hide');

    if(ns_account_submit_lock) {
      console.log('submit lock');
      $.ajax({
        url:  '/wp-content/themes/Lumos/api_helper.php',
        type: 'GET',
        // dataType: "json",
        data: {
          "action": "get_ns_myaccount_url"
        },
        success: function(result){
          // console.log(result);
          var accountURL = result;

          var accountAuthURL = accountURL+"api/user/Authenticate";
          // var accountPageURL = accountURL+"MyAccount";

          var accountLoginURL = accountAuthURL + "?logIn=" + encodeURIComponent(ns_un) + "&password=" + encodeURIComponent(ns_pw);

          $.ajax({
            type: "GET",
            url: accountLoginURL,
            dataType: 'jsonp',
            xhrFields: {
                 withCredentials: true
            },
            crossDomain: true
          }).done(function() {
            alert("success");
          }).fail(function(e) {
            // console.log(e);
            ns_account_submit_lock = false;
            $('#ns-login-account').submit();
          });
        }
      });
    }
  });

  // Switch to Old My Account
  $('[data-switch-old-my-account]').click(function(e) {
    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: 'ns_username_remove_experience_role',
        roleName: 'WPMyAccount',
        sessionId: ns_session_id,
      },
      success: function(result) {
        console.log(result);
        // Reload without query string
        window.location = window.location.href.split("?")[0];
      }
    });
  });

  // Account Login show/hide
  $('.ns-login-page button[data-goto-page]').click(function(e) {
    e.preventDefault();
    var gotopage = $(this).attr('data-goto-page'),
        hidepage = $(this).attr('data-hide-page');

    // console.log(gotopage, hidepage);

    $('.ns-login-page div[data-page-order="'+hidepage+'"]').hide();
    $('.ns-login-page div[data-page-order="'+gotopage+'"]').fadeIn();
  });


  // Change Invoice
  $('#ns-billing-dates').change(function() {
    // console.log(window.location.href.split('?')[0] + '?invoice_id=' + $(this).val());
    $('.full-loader').removeClass('hide');
    window.location = window.location.href.split('?')[0] + '?invoice_id=' + $(this).val();
  });

  if($('#schedule-payment-date').length) {
    $('#schedule-payment-date').fdatepicker({
  		format: 'mm/dd/yyyy',
  		disableDblClickSelection: true,
  		// leftArrow:'<<',
  		// rightArrow:'>>',
  		closeButton: false
  	});
  }

  // if($('#promise-payment-date').length) {
  //   $('#promise-payment-date').fdatepicker({
  // 		format: 'mm/dd/yyyy',
  // 		disableDblClickSelection: true,
  // 		// leftArrow:'<<',
  // 		// rightArrow:'>>',
  // 		closeButton: false
  // 	});
  // }

  // $('a[href="#accountsignout"]').click(function(e) {
  //   nsLogoutSession();
  // });


  $('.alert-banner .alert-close').click(function(e) {
    e.preventDefault();
    $('.alert-banner').toggleClass('open');
  });

  if($('div[data-account-page="main"]').length) {
    // if(window.sessionStorage.getItem('lumos_login_session_id')) {
    if(ns_session_id.length) {
      // We have a valid local storage session
      getNSSession();
    }
  }

  if($('div[data-account-page="login"]').length) {
    // if(window.sessionStorage.getItem('lumos_login_session_id')) {
    if(sessionStorage.getItem('lumos_login_session_id')) {
      // window.location.href = "/my-account/main";
    }
  }

  $('input[name="ns-address-type"]').change(function() {
    $('div[data-element-show-name="ns-address-type"] input').each(function() {
      $(this).addClass('hide');
    });

    $('input[name="ns-address-type"]:checked').each(function() {
      var showElement = $(this).attr('data-element-show');
      $('#'+showElement).removeClass('hide');
    });

    if($(this).attr('data-element-show') == 'ns-billing-address-street-apt') {
      $('#ns-billing-address-street-name').prop('required', true);
      $('#ns-billing-address-street-name').parent('.cell').removeClass('hide');

      $('#ns-billing-address-street-number').prop('required', true);
      $('#ns-billing-address-street-number').parent('.cell').removeClass('hide');

      $('#ns-billing-address-street-pobox').prop('required', false);
    } else {
      $('#ns-billing-address-street-name').prop('required', false);
      $('#ns-billing-address-street-name').parent('.cell').addClass('hide');
      $('#ns-billing-address-street-name').val('');
      $('#ns-billing-address-street-name-id').val('');

      $('#ns-billing-address-street-number').prop('required', false);
      $('#ns-billing-address-street-number').parent('.cell').addClass('hide');
      $('#ns-billing-address-street-number').val('');

      $('#ns-billing-address-street-pobox').prop('required', true);
    }
  });

  $('#ns_change_account select').change(function() {
      // console.log($(this).find('option:selected').val());
      $('.full-loader').removeClass('hide');
      switchAccount($(this).find('option:selected').val());
  });

  $('#ns_change_account_va select').change(function() {
      // console.log($(this).find('option:selected').val());
      $('.full-loader').removeClass('hide');
      switchAccountVA($(this).val());
  });

  $('button[data-toggle-edit]').click(function() {
    $('#' + $(this).attr('data-toggle-edit')).toggleClass('edit-mode');
    $('#' + $(this).attr('data-toggle-edit')).parent('.callout').toggleClass('edit-mode');

    $('button[data-toggle-edit="'+$(this).attr('data-toggle-edit')+'"]').toggleClass('hide');

    //$('#' + $(this).attr('data-toggle-edit') + ' button').toggleClass('hide');
  });


  // Open/close new payment form
  $('button[data-button-opens]').click(function(e) {
    e.preventDefault();

    var toggleElem = $(this).attr('data-button-opens');
    $('#' + toggleElem).toggleClass('hide');
    $(this).toggleClass('open');

    var toggleSelect = $(this).attr('data-toggle-select');
    $('#' + toggleSelect).prop("required", !$('#' + toggleSelect).prop("required"));
    $('#' + toggleSelect).prop("disabled", !$('#' + toggleSelect).prop("disabled"));
    $('#' + toggleSelect).val('');

    $('#' + toggleElem + ' *[data-required]').each(function() {
      $(this).prop("required", !$(this).prop("required"));
    });

    $('#new-bank-save, #new-cc-save').prop('checked', false);
    $('#new-bank-save, #new-cc-save').change();

    $('#paynow').click();
  });

  // Payment Method selector
  $('*[name="paymentmethod"]').change(function() {
    var sectionID = $(this).attr('id');

    $('button[data-button-opens]').each(function() {
      $(this).removeClass('open');

      var formID = $(this).attr('data-button-opens');
      $('#' + formID).addClass('hide');

      $('#' + formID + ' *[required]').each(function() {
        $(this).prop('required', false);
      });

      var toggleSelect = $(this).attr('data-toggle-select');
      $('#' + toggleSelect).prop("required", false);
      $('#' + toggleSelect).prop("disabled", false);
    });

    // Reset payment schedule
    $('#cardname, #bankname').val('');
    $('#cardname, #bankname').change();
    $('#new-bank-save, #new-cc-save').prop('checked', false);
    $('#paynow').click();

    $('*[data-show-on-select]').each(function() {
      $(this).addClass('hide');
    });

    $('*[data-show-on-select="'+sectionID+'"]').toggleClass('hide');

    $('#cardname, #bankname').prop('required', false);
    var selectToggle = $(this).attr('data-toggle-select');
    $('#' + selectToggle).prop("required", true);
  });

  // CC Date action
  $('#new-cc-cardexp').val($('#new-cc-cardyear').val() + $('#new-cc-cardmonth').val());

  $('#new-cc-cardmonth, #new-cc-cardyear').change(function() {
    $('#new-cc-cardexp').val($('#new-cc-cardyear').val() + $('#new-cc-cardmonth').val());
  });

  // CC Date Action - Payment Edit
  // $('#ns-payment-methods-cardexp').val($('#ns-payment-methods-year-edit').val() + $('#ns-payment-methods-month-edit').val());

  $('#ns-payment-methods-month-edit, #ns-payment-methods-year-edit').change(function() {
    $('#ns-payment-methods-cardexp').val($('#ns-payment-methods-year-edit').val() + $('#ns-payment-methods-month-edit').val());
  });

  // CC Date Action - Payment New
  $('#ns-add-cc-account-cardexp').val($('#ns-add-cc-account-cardyear').val() + $('#ns-add-cc-account-cardmonth').val());

  $('#ns-add-cc-account-cardmonth, #ns-add-cc-account-cardyear').change(function() {
    $('#ns-add-cc-account-cardexp').val($('#ns-add-cc-account-cardyear').val() + $('#ns-add-cc-account-cardmonth').val());
  });

  $('#cardname, #bankname').change(function() {
    if($(this).val().length) {
      $('#schedulepayment').prop('disabled', false);
      if($(this).attr('id') == 'cardname') {
        $('#automaticpayment').prop('disabled', false);
      } else {
        $('#automaticpayment').prop('disabled', true);
      }
    } else {
      $('#schedulepayment').prop('disabled', true);
      $('#automaticpayment').prop('disabled', true);
    }
  });

  $('#new-bank-save, #new-cc-save').change(function() {
    if($(this).prop('checked')) {
      $('#schedulepayment').prop('disabled', false);
      if($(this).attr('id') == 'new-cc-save') {
        $('#automaticpayment').prop('disabled', false);
      } else {
        $('#automaticpayment').prop('disabled', true);
      }
    } else {
      $('#schedulepayment').prop('disabled', true);
      $('#automaticpayment').prop('disabled', true);
    }
    $('#paynow').click();
  });

  $('select[data-select-set]').change(function() {
    // console.log('data-select-set');
    var selectelem = '#' + $(this).attr('data-select-set');
    $(selectelem).val('');
    $(selectelem + ' option').each(function() {
      $(this).addClass('hide');
    });
    $(selectelem + ' option[data-country="'+$(this).val()+'"]').each(function() {
      $(this).removeClass('hide');
    });
  });

  // Auto select radio for other amount
  $('input#otheramount-total').focus(function() {
    $('#otheramount').click();
  });

  // Scheduled payment show/hide options
  $('input[name="schedulepayment"]').change(function() {
    var toggleID = $(this).attr('data-toggle-show');
    $('div[data-toggler-name').each(function() {
      $(this).addClass('hide');
      $(this).find('input').each(function() {
        $(this).prop('required', false);
      });
    });
    $('#'+toggleID).removeClass('hide');
    $('#'+toggleID).find('input').each(function(){
      $(this).prop('required', true);
    });

    if($('input[name="schedulepayment"]:checked').val() == 'automaticpayment') {
      if($(this).attr('data-hide-submit') == 'true') {
        $('#review-payment').addClass('hide');
        $('#automaticpayment').prop('required', true);
      } else {
        $('#review-payment').removeClass('hide');
        $('#automaticpayment').prop('required', false);
      }
    } else {
      $('#review-payment').removeClass('hide');
      $('#automaticpayment').prop('required', false);
    }
  });

  $('input[name="selectedamount"]').change(function() {
    if($('input[name="selectedamount"]:checked').val() == 'otheramount') {
      $('#otheramount-total').prop('required', true);
      $('#totaldue-amount').prop('required', false);
    } else {
      $('#otheramount-total').prop('required', false);
      $('#totaldue-amount').prop('required', true);
    }
  });

  $('#schedule-payment-date').change(function(){
    var today = new Date();
    today.setHours(0,0,0,0);
    var selectedDate = new Date($(this).val());
    if(selectedDate <= today) {
      $('form#make-a-payment').foundation('addErrorClasses', $('#schedule-payment-date'));
    } else {
      $('form#make-a-payment').foundation('removeErrorClasses', $('#schedule-payment-date'));
    }
  });

  $('#new-bank-save').change(function() {
    $('#new-bank-nickname').prop('required', $(this).prop('checked'));
  });

  $('#new-cc-save').change(function() {
    $('#new-cc-nickname').prop('required', $(this).prop('checked'));
  });

  // Confirm Payment
  $('#ns-payment-confirm').click(function() {
    processPayment();
  });

  $('button[data-payment-edit]').click(function() {
    $('div[data-account-screen="payment-form"]').removeClass('hide');
    $('div[data-account-screen="payment-form-confirm"]').addClass('hide');
    $('div[data-account-screen="payment-form-failure"]').addClass('hide');
  });



  function processPayment() {

    $('.full-loader').removeClass('hide');

    var paymentProfileID = '';
    var customerProfileID = '';
    var paymentAmount = 0;
    var paymentMethod = '';

    if($('input[name="selectedamount"]:checked').val() == 'totaldue') {
      paymentAmount = $('#totaldue-amount').val();
    } else {
      paymentAmount = $('#otheramount-total').val();
    }

    if($('input[name=paymentmethod]:checked').val() == 'bank' && $('#bankname').val().length) {
      paymentProfileID = $('#bankname').val();
      customerProfileID = $('#bankname').find(':selected').attr('data-customer-profile-id');
    } else if($('input[name=paymentmethod]:checked').val() == 'card' && $('#cardname').val().length) {
      paymentProfileID = $('#cardname').val();
      customerProfileID = $('#cardname').find(':selected').attr('data-customer-profile-id');
    }

    if($('input[name=paymentmethod]:checked').val() == 'bank') {
      paymentMethod = 'ECHECK'
    } else if($('input[name=paymentmethod]:checked').val() == 'card') {
      paymentMethod = 'CC'
    }

    // console.log(paymentProfileID, customerProfileID, paymentAmount);

    if(paymentProfileID.length) {
      // Pay via pofileID

      switch($('input[name="schedulepayment"]:checked').val()) {
        case 'paynow':
          // Pay Now
          payWithPaymentProfile(paymentProfileID, customerProfileID, paymentAmount, paymentMethod);
          break;

        case 'schedulepayment':
          // Schedule payment
          // console.log('Schedule Payment - Existing Profile');
          payScheduleWithPaymentProfile(paymentProfileID, customerProfileID, paymentAmount, paymentMethod);
          break;

        case 'automaticpayment':
          autoPaymentProfileUpdate(paymentProfileID, customerProfileID, true, formatDateAPI($('#ns-auto-payment-date').val()))
          console.log('Automatic Payment');
          break;
      }

    } else {
      if($('input[name="paymentmethod"]:checked').val() == 'bank' && $('#new-bank-save').prop('checked')) {
        // Create Bank Profile and Pay
        // console.log('Create Bank Profile and Pay');
        createBankProfileThenPay(paymentAmount, paymentMethod);

      } else if($('input[name="paymentmethod"]:checked').val() == 'card' && $('#new-cc-save').prop('checked')) {
        // Create CC Profile and Pay
        // console.log('Create CC Profile and Pay');
        if($('input[name="schedulepayment"]:checked').val() == 'automaticpayment') {
          createCCProfileThenPay(paymentAmount, paymentMethod, true, formatDateAPI($('#ns-auto-payment-date').val()));
        } else {
          createCCProfileThenPay(paymentAmount, paymentMethod);
        }

      } else {
        // Pay via Instant Payment
        if($('input[name="paymentmethod"]:checked').val() == 'bank') {
          // Instant Bank
          // console.log('Instant Bank');
          instantBankPayment(paymentAmount);
        } else if($('input[name="paymentmethod"]:checked').val() == 'card'){
          // Instant CC
          // console.log('Instant CC');
          instantCCPayment(paymentAmount);
        } else{
          // console.log('Sneaky boi...', $('input[name="paymentmethod"]:checked').val() );

          $('div[data-account-screen="payment-form"]').removeClass('hide');
          $('div[data-account-screen="payment-form-confirm"]').addClass('hide');
          $('.full-loader').addClass('hide');
        }

      }
    }
  }

  function createBankProfileThenPay(amount, method) {
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_create_bank_payment_profile",
        sessionId: ns_session_id,
        // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
        accountId: sessionStorage.getItem('lumos_login_account_id_current'),

        FirstName: $('#new-bank-full-name').val().split(' ').slice(0, -1).join(' '),
        LastName:  $('#new-bank-full-name').val().split(' ').slice(-1).join(' '),
        NameOnAccount: $('#new-bank-full-name').val(),
        Company: $('#new-bank-company').val(),
        Address: $('#new-bank-address').val(),
        City: $('#new-bank-city').val(),
        State: $('#new-bank-state').val(),
        Zip: $('#new-bank-zip').val(),
        Country: $('#new-bank-country').val(),
        PhoneNumber: $('#new-bank-phonenumber').val(),

        RoutingNumber: $('#new-bank-routingnumber').val(),
        AccountNumber: $('#new-bank-accountnumber').val(),
        BankAccountType: $('#new-bank-account-type').val(),
        BankName: $('#new-bank-bankname').val(),

        Description: $('#new-bank-nickname').val()
      },
      success: function(result) {
        // console.log(result);
        var profileData = JSON.parse(result);

        if(profileData.Status == 'success') {

          switch($('input[name="schedulepayment"]:checked').val()) {
            case 'paynow':
            payWithPaymentProfile(profileData.Data.PaymentProfileId, profileData.Data.CustomerProfileId, amount, method);
              break;
            case 'schedulepayment':
              payScheduleWithPaymentProfile(profileData.Data.PaymentProfileId, profileData.Data.CustomerProfileId, amount, method);
              break;
          }

        } else {
          paymentFailure(profileData.Message);
        }
      },
      error: function() {
        paymentFailure('');
      }
    });
  }

  function createCCProfileThenPay(amount, method, recurring = false, recurringDate = null) {
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_create_cc_payment_profile",
        sessionId: ns_session_id,
        // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
        accountId: sessionStorage.getItem('lumos_login_account_id_current'),

        FirstName: $('#new-cc-full-name').val().split(' ').slice(0, -1).join(' '),
        LastName:  $('#new-cc-full-name').val().split(' ').slice(-1).join(' '),
        NameOnAccount: $('#new-cc-full-name').val(),
        Company: $('#new-cc-company').val(),
        Address: $('#new-cc-address').val(),
        City: $('#new-cc-city').val(),
        State: $('#new-cc-state').val(),
        Zip: $('#new-cc-zip').val(),
        Country: $('#new-cc-country').val(),
        PhoneNumber: $('#new-cc-phonenumber').val(),

        CreditCardTypeId: $('#new-cc-account-type').val(),
        CardNumber: $('#new-cc-cardnumber').val(),
        CardCode: $('#new-cc-cvv').val(),
        ExpirationDate: $('#new-cc-cardmonth').val()+$('#new-cc-cardyear').val(),

        Description: $('#new-cc-nickname').val(),

        Recurring: recurring,
        RecurringStartDate: recurringDate
      },
      success: function(result) {
        console.log(result);
        var profileData = JSON.parse(result);

        if(profileData.Status == 'success') {

          switch($('input[name="schedulepayment"]:checked').val()) {
            case 'paynow':
              payWithPaymentProfile(profileData.Data.PaymentProfileId, profileData.Data.CustomerProfileId, amount, method);
              break;
            case 'schedulepayment':
              payScheduleWithPaymentProfile(profileData.Data.PaymentProfileId, profileData.Data.CustomerProfileId, amount, method);
              break;
            case 'automaticpayment':
              var autoDate = new Date($('#ns-auto-payment-date').val());
              $('span[data-payment-action]').text('automatically set for Day '+autoDate.getDate()+' of each month');
              paymentSuccess();
              break;
          }
        } else {
          paymentFailure(profileData.Message);
        }
      },
      error: function() {
        paymentFailure('');
      }
    });
  }

  function instantBankPayment(amount) {

    var paymentDate = new Date();

    if($('input[name=schedulepayment]:checked').val() == "schedulepayment") {
      paymentDate = new Date($('#schedule-payment-date').val());
    }

    var accountType = "Checking";
    if($('#new-bank-account-type').val() == 3) {
      accountType = "Savings";
    }

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_make_payment_bank",
        sessionId: ns_session_id,

        accountId: sessionStorage.getItem('lumos_login_account_id_current'),

        FirstName: $('#new-bank-full-name').val().split(' ').slice(0, -1).join(' '),
        LastName:  $('#new-bank-full-name').val().split(' ').slice(-1).join(' '),
        NameOnAccount: $('#new-bank-full-name').val(),
        Company: $('#new-bank-company').val(),
        Address: $('#new-bank-address').val(),
        City: $('#new-bank-city').val(),
        State: $('#new-bank-state').val(),
        Zip: $('#new-bank-zip').val(),
        Country: $('#new-bank-country').val(),
        PhoneNumber: $('#new-bank-phonenumber').val(),

        RoutingNumber: $('#new-bank-routingnumber').val(),
        AccountNumber: $('#new-bank-accountnumber').val(),
        AccountType: accountType,
        BankName: $('#new-bank-bankname').val(),

        Amount: amount,

        PaymentDate: formatDateAPI(paymentDate)
      },
      success: function(result) {
        // console.log(result);
        var profileData = JSON.parse(result);

        if(profileData.Status == 'success') {
          // $('data[data-confirmation-number]').text('Your Payment Confirmation #'+profileData.Data)
          // payWithPaymentProfile(profileData.Data.PaymentProfileId, profileData.Data.CustomerProfileId, amount, method);
          paymentSuccess();
        } else {
          paymentFailure(profileData.Message);
        }
      },
      error: function() {
        paymentFailure('');
      }
    });
  }

  function instantCCPayment(amount) {
    var paymentDate = new Date();

    if($('input[name=schedulepayment]:checked').val() == "schedulepayment") {
      paymentDate = new Date($('#schedule-payment-date').val());
    }

    var accountType = "Checking";
    if($('#new-bank-account-type').val() == 3) {
      accountType = "Savings";
    }

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_make_payment_cc",
        sessionId: ns_session_id,

        accountId: sessionStorage.getItem('lumos_login_account_id_current'),

        FirstName: $('#new-cc-full-name').val().split(' ').slice(0, -1).join(' '),
        LastName:  $('#new-cc-full-name').val().split(' ').slice(-1).join(' '),
        NameOnAccount: $('#new-cc-full-name').val(),
        Company: $('#new-cc-company').val(),
        Address: $('#new-cc-address').val(),
        City: $('#new-cc-city').val(),
        State: $('#new-cc-state').val(),
        Zip: $('#new-cc-zip').val(),
        Country: $('#new-cc-country').val(),
        PhoneNumber: $('#new-cc-phonenumber').val(),

        CreditCard: $('#new-cc-cardnumber').val(),
        CardCode: $('#new-cc-cvv').val(),
        ExpirationDate: $('#new-cc-cardmonth').val() + $('#new-cc-cardyear').val(),

        Amount: amount,

        PaymentDate: formatDateAPI(paymentDate)
      },
      success: function(result) {
        console.log(result);
        var profileData = JSON.parse(result);

        if(profileData.Status == 'success') {
          // $('data[data-confirmation-number]').text('Your Payment Confirmation #'+profileData.Data)
          // payWithPaymentProfile(profileData.Data.PaymentProfileId, profileData.Data.CustomerProfileId, amount, method);
          paymentSuccess();
        } else {
          paymentFailure(profileData.Message);
        }
      },
      error: function() {
        paymentFailure('');
      }
    });
  }

  function autoPaymentProfileUpdate(profileID, customerProfileID, recurring, recurringStartDate) {
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_get_payment_profile",
        sessionId: ns_session_id,
        // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
        accountId: sessionStorage.getItem('lumos_login_account_id_current'),
        PaymentProfileId: profileID,
      },
      success: function(result) {
        var profileData = JSON.parse(result);

        if(profileData.Status == 'success') {
          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_update_payment",
              sessionId: ns_session_id,
              // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
              accountId: sessionStorage.getItem('lumos_login_account_id_current'),
              PaymentProfileId: profileID,
              CustomerProfileId: profileData.Data.CustomerProfileId,
              PaymentProfileClassId: profileData.Data.PaymentProfileClassId,
              LastFour: profileData.Data.LastFour,
              Description: profileData.Data.Description,
              Recurring: recurring,
              RecurringStartDate: recurringStartDate,
              CreditCardTypeId: profileData.Data.CreditCardTypeId
            },
            success: function(result) {
              var pData = JSON.parse(result);

              if(pData.Status == 'success') {
                var autoDate = new Date($('#ns-auto-payment-date').val());
                $('span[data-payment-action]').text('automatically set for Day '+autoDate.getDate()+' of each month');
                paymentSuccess();
              } else {
                paymentFailure(pData.message);
              }
            }
          });
        } else {
          paymentFailure(profileData.message);
        }
      }
    });
  }

  function payWithPaymentProfile(pofileID, customerProfileID, amount, method) {

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_make_payment_with_profile",
        sessionId: ns_session_id,
        // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
        accountId: sessionStorage.getItem('lumos_login_account_id_current'),
        CustomerProfileId: customerProfileID,
        PaymentProfileId: pofileID,
        Amount: amount,
        Method: method
      },
      success: function(result) {
        var billingSummary = JSON.parse(result);
        // console.log(billingSummary);
        if(billingSummary.Status == 'success') {
          paymentSuccess();
        } else {
          paymentFailure(billingSummary.message);
        }
      },
      error: function() {
        paymentFailure('');
      }
    });
  }

  function payScheduleWithPaymentProfile(pofileID, customerProfileID, amount, method) {
    // console.log(pofileID, customerProfileID, amount, method);
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_schedule_payment_with_profile",
        sessionId: ns_session_id,
        // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
        accountId: sessionStorage.getItem('lumos_login_account_id_current'),
        CustomerProfileId: customerProfileID,
        PaymentProfileId: pofileID,
        Amount: amount,
        Method: method,
        Date: formatDateAPI($('#schedule-payment-date').val())
      },
      success: function(result) {
        // console.log(result);
        var billingSummary = JSON.parse(result);

        if(billingSummary.Status == 'success') {
          $('span[data-payment-action]').text('schdeulded for ' + formatDate($('#schedule-payment-date').val()));
          paymentSuccess();
        } else {
          paymentFailure(billingSummary.message);
        }
      },
      error: function() {
        paymentFailure('');
      }
    });
  }

  function paymentSuccess() {
    $('.full-loader').addClass('hide');
    $('div[data-account-screen="payment-form-confirm"]').addClass('hide');
    $('div[data-account-screen="payment-form-success"]').removeClass('hide');

  }

  function paymentFailure(errorMessage) {

    $('strong[data-error-message]').text(errorMessage);

    $('.full-loader').addClass('hide');
    $('div[data-account-screen="payment-form-confirm"]').addClass('hide');
    $('div[data-account-screen="payment-form-failure"]').removeClass('hide');
  }

  // Add Bank Account
  // $('#add-bank-account-button').click(function(e) {
  //   e.preventDefault();
  //   $('form#make-a-payment').foundation("validateForm");
  // });

  // Logout session
  function nsLogoutSession() {
    // Set Session
    // window.sessionStorage.removeItem('lumos_login_session_id');
    // Cookies.remove('ns_login_session_id');

    // Set Acocunt ID
    // window.sessionStorage.removeItem('lumos_login_account_id_current');
    // window.sessionStorage.removeItem('lumos_login_account_id_group');
    sessionStorage.removeItem('lumos_login_account_id_current');
    sessionStorage.removeItem('lumos_login_account_id_group');

    // Send user to login page
    // window.location.href = "/my-account/";
  }

  function getNSSession() {
    // var sessionID = window.sessionStorage.getItem('lumos_login_session_id');
    // var sessionID = sessionStorage.getItem('lumos_login_session_id');

    // console.log(sessionID);

    if(ns_session_id.length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_session",
          sessionId: ns_session_id
        },
        success: function(result) {
          // console.log(result);
          ns_account_session = JSON.parse(result);

          if(ns_account_session.Status === "success") {
            // console.log("Get Username");
            getUsername();
          } else {
            window.location.href = "/my-account/";
          }
        }
      });
    } else {
      window.location.href = "/my-account/";
    }
  }

  function getUsername() {
    // console.log(ns_account_session.Data.UserId);
    if(ns_account_session.Status === "success") {

      // if(!window.sessionStorage.getItem('lumos_login_account_id_current')) {
      // if(!sessionStorage.getItem('lumos_login_account_id_group')) {
        $.ajax({
          url: '/wp-content/themes/Lumos/api_helper.php',
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          data: {
            action: "ns_username",
            sessionId: ns_session_id,
            userId: ns_account_session.Data.UserId
          },
          success: function(result) {
            ns_username = JSON.parse(result);

            if(ns_username.Status === "success") {

              var acctFound = false;

              // if(sessionStorage.getItem('lumos_login_account_id_group')) {
              //   var acctIDs = JSON.parse(Cookies.set('ns_login_account_id_group'));
              //   for(var i = 0; i < acctIDs.length; i++) {
              //     if (acctIDs[i].accountId == ns_username.Data.AccountID) {
              //       acctFound = true;
              //       break;
              //     }
              //   }
              // }
              //
              // if(!acctFound) {


              if($('#ns_change_account').length) {
                // Validate Account ID in use
                $.ajax({
                  url: '/wp-content/themes/Lumos/api_helper.php',
                  method: 'POST',
                  contentType: 'application/x-www-form-urlencoded',
                  data: {
                    action: "ns_username_delegates",
                    sessionId: ns_session_id,
                    userId: ns_account_session.Data.UserId
                  },
                  success: function(result) {
                    var ns_account_delegates = JSON.parse(result);
                    if(ns_account_delegates.Status === "success") {
                      var allowedIDs = [ns_username.Data.AccountID];
                      for(var i = 0; i < ns_account_delegates.Data.length; i++) {
                        allowedIDs.push(ns_account_delegates.Data[i].AccountId);
                      }
                      // console.log(allowedIDs, accountID, allowedIDs.includes(parseInt(accountID)));
                      if(!allowedIDs.includes(parseInt(sessionStorage.getItem('lumos_login_account_id_current')))) {
                        // Set Acocunt ID to default
                        sessionStorage.setItem('lumos_login_account_id_current', ns_username.Data.AccountID);
                      }
                    }

                    sessionStorage.setItem('lumos_login_account_id_group', JSON.stringify([{
                      accountId: ns_username.Data.AccountID,
                      name: "Primary"
                    }]));

                    setAccountSwitchOptions();
                    getBillingSummary();
                    getInvoiceSummary();
                    getPromotions();
                    getUserProfile();
                    getServices();
                    getServiceDetails();
                    getActivity();
                    getProfileInfo();
                    getPaymentHistory();
                    getOCCHistory();
                    getPaymentInfo();
                    getAssociatedAccounts();
                    getEmailAccounts();
                    getPhoneSummary();
                    getAvailableFeatures();
                    getCurrentFeatures();
                    getAutomaticPayments();
                    getPaymentMethods();
                    getBillingPreferences();
                    getStatementDelivery();
                    getEquipmentList();
                  }
                });
              }

              if($('#ns_change_account_va').length) {

                var userID = ns_username.Data.UserId;

                if(ns_username.Data.Legacy_Id !== 0) {
                  userID = ns_username.Data.Legacy_Id
                }

                $.ajax({
                  url: '/wp-content/themes/Lumos/api_helper.php',
                  method: 'POST',
                  contentType: 'application/x-www-form-urlencoded',
                  data: {
                    action: "ns_username_linked_accounts",
                    sessionId: ns_session_id,
                    userId: userID
                  },
                  success: function(result) {
                    var ns_account_delegates = JSON.parse(result);

                    if(ns_account_delegates.Status === "success") {
                      var allowedIDs = [ns_username.Data.AccountID];
                      var primaryAccount = -1;
                      var primaryAccountName = '';
                      for(var i = 0; i < ns_account_delegates.Data.length; i++) {
                        allowedIDs.push(ns_account_delegates.Data[i].account_number);
                        if(ns_account_delegates.Data[i].primary == 1) {
                          primaryAccount = ns_account_delegates.Data[i].account_number;
                          primaryAccountName = ns_account_delegates.Data[i].name;
                        }
                      }

                      if(!allowedIDs.includes(parseInt(sessionStorage.getItem('lumos_login_account_id_current')))) {
                        // Set Acocunt ID to default
                        sessionStorage.setItem('lumos_login_account_id_current', primaryAccount);
                      }
                    }

                    sessionStorage.setItem('lumos_login_account_id_group', JSON.stringify([{
                      accountId: primaryAccount,
                      name: primaryAccountName
                    }]));

                    setAccountSwitchOptions();
                    getAccountSummary();


                    setAccountProfileVA(userID);
                    if(ns_account_delegates.Status === "success") {
                      setLinkedAccountsTable(ns_account_delegates.Data);
                    }
                  }
                });
              }

              // }

              // console.log('get account');

            }
          }
        });
      // } else {
      //   // console.log('got account');
      //   setAccountSwitchOptions();
      //   getBillingSummary();
      //   getInvoiceSummary();
      //   getUserProfile();
      //   getServices();
      //   getServiceDetails();
      //   getActivity();
      //   getProfileInfo();
      //   getPaymentHistory();
      //   getOCCHistory();
      //   getPaymentInfo();
      //   getAssociatedAccounts();
      //   getEmailAccounts();
      //   getPhoneSummary();
      //   getAvailableFeatures();
      //   getCurrentFeatures();
      //   getAutomaticPayments();
      //   getPaymentMethods();
      //   getBillingPreferences();
      //   getStatementDelivery();
      //   getEquipmentList();
      // }
    }
  }

  function switchAccountVA(accountID) {

    var userID = ns_username.Data.UserId;

    if(ns_username.Data.Legacy_Id !== 0) {
      userID = ns_username.Data.Legacy_Id
    }

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_username_linked_accounts",
        userId: userID,
        sessionId: ns_session_id
      },
      success: function(result) {
        var ns_account_delegates = JSON.parse(result);

        if(ns_account_delegates.Status === "success") {

          var allowedIDs = [ns_username.Data.AccountID];

          for(var i = 0; i < ns_account_delegates.Data.length; i++) {
            allowedIDs.push(ns_account_delegates.Data[i].account_number);
          }

          console.log(allowedIDs, accountID, allowedIDs.includes(parseInt(accountID)));

          if(allowedIDs.includes(parseInt(accountID))) {
            // window.sessionStorage.setItem('lumos_login_account_id_current', accountID);
            sessionStorage.setItem('lumos_login_account_id_current', accountID);
          }
        }
        // Reload without query string
        window.location = window.location.href.split("?")[0];
      }
    });
  }

  function switchAccount(accountID) {

    // Validate Account ID
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_username_delegates",
        userId: ns_account_session.Data.UserId,
        sessionId: ns_session_id
      },
      success: function(result) {
        var ns_account_delegates = JSON.parse(result);
        if(ns_account_delegates.Status === "success") {
          var allowedIDs = [ns_username.Data.AccountID];
          for(var i = 0; i < ns_account_delegates.Data.length; i++) {
            allowedIDs.push(ns_account_delegates.Data[i].AccountId);
          }
          // console.log(allowedIDs, accountID, allowedIDs.includes(parseInt(accountID)));
          if(allowedIDs.includes(parseInt(accountID))) {
            // window.sessionStorage.setItem('lumos_login_account_id_current', accountID);
            sessionStorage.setItem('lumos_login_account_id_current', accountID);
          }
        }
        // Reload without query string
        window.location = window.location.href.split("?")[0];
      }
    });


  }

  function setAccountSwitchOptions() {
    if($('#account-select').length) {

      var accountIDs = JSON.parse(sessionStorage.getItem('lumos_login_account_id_group'));

      if(accountIDs.length < 2) {
        $.ajax({
          url: '/wp-content/themes/Lumos/api_helper.php',
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          data: {
            action: "ns_username_delegates",
            userId: ns_account_session.Data.UserId,
            sessionId: ns_session_id
          },
          success: function(result) {

            // console.log(result);

            var ns_account_delegates = JSON.parse(result);

            // console.log(ns_account_delegates);

            if(ns_account_delegates.Status === "success") {
              for(var i = 0; i < ns_account_delegates.Data.length; i++) {
                // $('#account-select').append($("<option></option>").attr("value", ns_account_delegates.Data[i].AccountId).text(ns_account_delegates.Data[i].AccountId + ' - ' + ns_account_delegates.Data[i].Name));
                accountIDs.push({
                  accountId: ns_account_delegates.Data[i].AccountId,
                  name: ns_account_delegates.Data[i].Name
                });
              }
            }

            // console.log(accountIDs);

            // window.sessionStorage.setItem('lumos_login_account_id_group', JSON.stringify(accountIDs));
            sessionStorage.setItem('lumos_login_account_id_group', JSON.stringify(accountIDs));

            setAccountSelect()
          }
        });
      } else {
        setAccountSelect();
      } // endif
    } // endif


    if($('#account-select-va').length) {

      var accountIDs = [];//JSON.parse(sessionStorage.getItem('lumos_login_account_id_group'));

      var userID = ns_username.Data.UserId;

      if(ns_username.Data.Legacy_Id !== 0) {
        userID = ns_username.Data.Legacy_Id
      }

      // if(accountIDs.length < 2) {
        $.ajax({
          url: '/wp-content/themes/Lumos/api_helper.php',
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          data: {
            action: "ns_username_linked_accounts",
            userId: userID,
            sessionId: ns_session_id
          },
          success: function(result) {

            console.log(result);

            var ns_account_delegates = JSON.parse(result);

            // console.log(ns_account_delegates);

            if(ns_account_delegates.Status === "success") {
              for(var i = 0; i < ns_account_delegates.Data.length; i++) {
                // $('#account-select').append($("<option></option>").attr("value", ns_account_delegates.Data[i].AccountId).text(ns_account_delegates.Data[i].AccountId + ' - ' + ns_account_delegates.Data[i].Name));
                accountIDs.push({
                  accountId: ns_account_delegates.Data[i].account_number,
                  name: ns_account_delegates.Data[i].name
                });
              }
            }

            // console.log(accountIDs);

            // window.sessionStorage.setItem('lumos_login_account_id_group', JSON.stringify(accountIDs));
            sessionStorage.setItem('lumos_login_account_id_group', JSON.stringify(accountIDs));

            setAccountSelect();
          }
        });
      // } else {
      //   setAccountSelect();
      // } // endif
    } // endif
  }

  function getAccountSummary() {
    console.log('VA Account Summary');
    var accountIDVA = sessionStorage.getItem('lumos_login_account_id_current');

    if($('[data-account-number-va]').length) {
      $('[data-account-number-va]').text(accountIDVA);
      $('[data-account-number-va]').parent().removeClass('hide');
    }

    if($('[data-account-section="account-summary-va"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_get_account_summary",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var accountSummary = JSON.parse(result);
          console.log(accountSummary);

          if(accountSummary.Status === 'success') {

            var $containerObj = $('[data-account-section="account-summary-va"]');

            var servicesVA = accountSummary.Data.services;

            console.log(servicesVA);

            for(var i = 0; i < servicesVA.length; i++) {
              var $serviceObj = $('[data-account-template-va]').clone();

              $serviceObj.find('[data-service-category-va]').text(servicesVA[i].service_category);
              $serviceObj.find('[data-service-phone-va]').text(servicesVA[i].phone_number);
              $serviceObj.find('[data-service-reference-va]').text(servicesVA[i].internal_service_number);

              $serviceObj.removeAttr('data-account-template-va');

              $serviceObj.removeClass('hide');

              $containerObj.append($serviceObj);
            }

            setBillPaymentLink(accountSummary.Data.pin_code);

            $containerObj.removeClass('loading');
          }
        }
      });
    }
  }

  function setAccountProfileVA(userID) {
    if($('[data-account-section="account-profile-va"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_get_account_profile",
          sessionId: ns_session_id,
          userId: userID
        },
        success: function(result) {
          var profileResult = JSON.parse(result);
          var $containerObj = $('[data-account-section="account-profile-va"]');

          console.log(profileResult);
          if(profileResult.Status == "success") {
            // SEt Display
            $('[data-account-name-va]').text(profileResult.Data.first_name + ' ' + profileResult.Data.last_name);
            $('[data-account-email-va]').text(profileResult.Data.email);
            $('[data-account-phone-va]').text(profileResult.Data.phone_number);
            $('[data-account-address-va]').text(profileResult.Data.street_address);
            $('[data-account-zip-va]').text(profileResult.Data.zip);

            // Set edit form defaults
            $('#edit-profile-first-name-va').val(profileResult.Data.first_name);
            $('#edit-profile-last-name-va').val(profileResult.Data.last_name);
            $('#edit-profile-email-va').val(profileResult.Data.email);
            $('#edit-profile-phone-va').val(profileResult.Data.phone_number);
            $('#edit-profile-address-va').val(profileResult.Data.street_address);
            $('#edit-profile-zip-va').val(profileResult.Data.zip);

            $('#edit-profile-id-va').val(profileResult.Data.id);
            $('#edit-profile-uid-va').val(profileResult.Data.uid);

            $('#add-linked-account-uid-va').val(profileResult.Data.uid);

            $('[data-toggle-account-edit-va]').click(function(e) {
              e.preventDefault();
              var $profileContainer = $('[data-edit-account-profile-va]');

              if($profileContainer.hasClass('display-mode')) {
                $profileContainer.removeClass('display-mode');
                $profileContainer.addClass('edit-mode');
              } else {
                $profileContainer.addClass('display-mode');
                $profileContainer.removeClass('edit-mode');
              }
            });

            $('form#edit-profile-va').bind("formvalid.zf.abide", function(e,target) {
              e.preventDefault();

              $('.full-loader').removeClass('hide');

              $.ajax({
                url: '/wp-content/themes/Lumos/api_helper.php',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: {
                  action: "ns_update_account_profile",
                  sessionId: ns_session_id,
                  id: $('#edit-profile-id-va').val(),
                  uid: $('#edit-profile-uid-va').val(),
                  first_name: $('#edit-profile-first-name-va').val(),
                  last_name: $('#edit-profile-last-name-va').val(),
                  email: $('#edit-profile-email-va').val(),
                  phone_number: $('#edit-profile-phone-va').val(),
                  street_address: $('#edit-profile-address-va').val(),
                  zip: $('#edit-profile-zip-va').val(),
                },
                success: function(result) {
                  var accountResult = JSON.parse(result);

                  console.log(accountResult);

                  if(accountResult.Status == "success") {
                    window.location.href = window.location.pathname;
                  } else {
                    $('form#edit-profile-va').foundation('addErrorClasses');

                    $('form#edit-profile-va').foundation('addErrorClasses', $('#edit-profile-first-name-va'));
                    $('form#edit-profile-va').foundation('addErrorClasses', $('#edit-profile-last-name-va'));
                    $('form#edit-profile-va').foundation('addErrorClasses', $('#edit-profile-email-va'));
                    $('form#edit-profile-va').foundation('addErrorClasses', $('#edit-profile-phone-va'));
                    $('form#edit-profile-va').foundation('addErrorClasses', $('#edit-profile-address-va'));
                    $('form#edit-profile-va').foundation('addErrorClasses', $('#edit-profile-zip-va'));
                  }
                }
              });

            });

          }

          $containerObj.removeClass('loading');
        }
      });
    }
  }

  function setLinkedAccountsTable(accounts) {
    console.log('Accounts: ', accounts);
    if($('[data-account-section="account-linked-accounts-va"]').length) {
      var $containerObj = $('[data-account-section="account-linked-accounts-va"]');
      for(var i = 0; i < accounts.length; i++) {
        $linkedAccountRow = $('[data-linked-account-row-va-template]').clone();

        $linkedAccountRow.removeAttr('data-linked-account-row-va-template');
        $linkedAccountRow.find('[data-linked-account-number-va]').text(accounts[i].account_number);
        $linkedAccountRow.find('[data-linked-account-name-va]').text(accounts[i].name);
        $linkedAccountRow.find('input[name="data-linked-account-name-va"]').val(accounts[i].name);
        $linkedAccountRow.find('[data-account-id-va]').attr('data-account-id-va', accounts[i].account_number);

        $linkedAccountRow.find('[data-linked-account-id-va]').attr('data-linked-account-id-va', accounts[i].id);
        $linkedAccountRow.find('[data-linked-account-user-id-va]').attr('data-linked-account-user-id-va', accounts[i].uid);
        $linkedAccountRow.find('[data-linked-account-primary-va]').attr('data-linked-account-primary-va', accounts[i].primary);

        if(accounts[i].primary == 1) {
          $linkedAccountRow.find('[data-linked-account-primary-va-label]').text('x');
          $linkedAccountRow.find('[data-linked-account-va-delete]').remove();
          $linkedAccountRow.find('.confirm-delete').remove();
        } else {
          $linkedAccountRow.find('.confirm-delete').attr('id', 'delete-reveal-' + accounts[i].id);
          $linkedAccountRow.find('.confirm-delete [data-account-number-delete]').text(accounts[i].account_number);

          $linkedAccountRow.find('.confirm-delete [data-remove-account]').click(function() {
            $('.full-loader').removeClass('hide');

            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "ns_username_linked_account_delete",
                sessionId: ns_session_id,
                id: $(this).attr('data-linked-account-id-va')
              },
              success: function(result) {
                var accountResult = JSON.parse(result);

                console.log(accountResult);

                window.location.href = window.location.pathname;
              }
            });
          });
        }

        $linkedAccountRow.removeClass('hide');

        $linkedAccountRow.find('[data-linked-account-va-edit]').click(function() {
          $(this).parents('.display-mode').addClass('edit-mode').removeClass('display-mode');
        });

        $linkedAccountRow.find('[data-linked-account-va-cancel]').click(function() {
          $(this).parents('.edit-mode').addClass('display-mode').removeClass('edit-mode');
        });

        $linkedAccountRow.find('[data-linked-account-va-save]').click(function() {
          // Do API Call and reload
          // $(this).parents('.edit-mode').addClass('display-mode').removeClass('edit-mode');

          if($('[name="data-linked-account-name-va"][data-linked-account-id-va="'+$(this).attr('data-linked-account-id-va')+'"]').val() !== '') {
            $('.full-loader').removeClass('hide');

            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "ns_username_linked_account_update",
                sessionId: ns_session_id,
                id: $(this).attr('data-linked-account-id-va'),
                account_number: $(this).attr('data-account-id-va'),
                uid: $(this).attr('data-linked-account-user-id-va'),
                name: $('[name="data-linked-account-name-va"][data-linked-account-id-va="'+$(this).attr('data-linked-account-id-va')+'"]').val(),
                primary: $(this).attr('data-linked-account-primary-va')
              },
              success: function(result) {
                var accountResult = JSON.parse(result);

                console.log(accountResult);

                if(accountResult.Status == "success") {
                  window.location.href = window.location.pathname;
                } else {
                  $('.full-loader').addClass('hide');
                }
              }
            });
          }

        });

        $('[data-account-section="account-linked-accounts-va"]').find('.linked-account-table-va tbody').append($linkedAccountRow);

        if(accounts[i].primary !== 1) {
          var deleteReveal = new Foundation.Reveal($('#delete-reveal-' + accounts[i].id));
          console.log('#delete-reveal-' + accounts[i].id, deleteReveal);
          var linkedAccountID = accounts[i].id;
          $linkedAccountRow.find('[data-linked-account-va-delete]').click(function() {
            $('#delete-reveal-' + linkedAccountID).foundation('open');
          });
        }
      }
      $containerObj.removeClass('loading');

      $('[data-open-add-linked-account-form-va]').click(function() {
        $(this).addClass('hide');
        $('[data-linked-account-form]').removeClass('hide');
      });

      $('form#add-linked-account-va').bind("formvalid.zf.abide", function(e,target) {
        e.preventDefault();

        $('.full-loader').removeClass('hide');
        $('form#add-linked-account-va [data-abide-error]').hide();

        $.ajax({
          url: '/wp-content/themes/Lumos/api_helper.php',
          method: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          data: {
            action: "ns_username_linked_account_add",
            sessionId: ns_session_id,
            account_number: $('#add-linked-account-va input[name="account_number"]').val(),
            token: $('#add-linked-account-va input[name="account_pin"]').val(),
            uid: $('#add-linked-account-va input[name="uid"]').val(),
            name: $('#add-linked-account-va input[name="account_name"]').val(),
          },
          success: function(result) {
            var accountResult = JSON.parse(result);

            console.log(accountResult);

            if(accountResult.Status == "success" && accountResult.Data !== false) {
              window.location.href = window.location.pathname;
            } else {
              $('.full-loader').addClass('hide');
              $('form#add-linked-account-va [data-abide-error]').show();
              $('form#add-linked-account-va').foundation('addErrorClasses', $('#add-linked-account-va input[name="account_number"]'));
              $('form#add-linked-account-va').foundation('addErrorClasses', $('#add-linked-account-va input[name="account_pin"]'));
            }
          }
        });

      });

    }
  }

  function setBillPaymentLink(pin) {
    if($('[data-pay-my-bill-link-va]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_get_bill_payment_link",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          AccountNumber: sessionStorage.getItem('lumos_login_account_id_current'),
          Pin: pin
        },
        success: function(result) {
          var linkResult = JSON.parse(result);
          console.log(linkResult);

          if(linkResult.Status == "success") {
            $('[data-pay-my-bill-link-va]').attr('href', linkResult.Data);
            $('[data-pay-my-bill-link-va]').parent().removeClass('loading');
          }
        }
      });
    }
  }

  function setAccountSelect() {
    // var accounts = JSON.parse(window.sessionStorage.getItem('lumos_login_account_id_group'));
    var accounts = JSON.parse(sessionStorage.getItem('lumos_login_account_id_group'));

    for(var j = 0; j < accounts.length; j++) {
      var selected = '';
      // if(accounts[j].accountId == window.sessionStorage.getItem('lumos_login_account_id_current')) {
      if(accounts[j].accountId == sessionStorage.getItem('lumos_login_account_id_current')) {
        selected = ' selected';
      }
      if($('#account-select').length) {
        $('#account-select').append($("<option"+selected+"></option>").attr("value", accounts[j].accountId).text(accounts[j].accountId + ' - ' + accounts[j].name));
      }
      if($('#account-select-va').length) {
        $('#account-select-va').append($("<option"+selected+"></option>").attr("value", accounts[j].accountId).text(accounts[j].name));
      }

    }

    $('.account-selector').removeClass('hide');
  }

  function getBillingSummary() {
    // console.log(ns_username);
    // console.log($('div[data-account-section="billing-summary"]').length);
    // only load if container element is present
    if($('div[data-account-section="billing-summary"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_billing_summary",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var billingSummary = JSON.parse(result);
          console.log(billingSummary);

          if(billingSummary.Status === 'success') {

            // console.log(billingSummary.Data.FromDate.replace(' 00:00:00', ''), billingSummary.Data.ToDate.replace(' 00:00:00', ''), billingSummary.Data.NewChargesDate.replace(' 00:00:00', ''));

            var bill_start            = convertDateForIos(billingSummary.Data.FromDate),
                bill_end              = convertDateForIos(billingSummary.Data.ToDate),
                bill_new_charges_date = convertDateForIos(billingSummary.Data.NewChargesDate),
                bill_due_date         = convertDateForIos(billingSummary.Data.DueDate),
                last_payment_date     = convertDateForIos(billingSummary.Data.LastPaymentDate),
                cut_off_date          = convertDateForIos(billingSummary.Data.CutOffDate),
                final_due_date        = convertDateForIos(billingSummary.Data.FinalDueDate),
                previous_invoice_date = convertDateForIos(billingSummary.Data.PreviousInvoiceDate);

            var defaultEmptyDate = '12/31/1969';

            // console.log(bill_start, billingSummary.Data.FromDate);

            if(billingSummary.Data.FromDate !== null && billingSummary.Data.ToDate !== null &&
               billingSummary.Data.FromDate !== ''   && billingSummary.Data.ToDate !== '' ) {

              // console.log(formatDate(bill_start), formatDate(bill_end));

              if(formatDate(bill_start) !== defaultEmptyDate && formatDate(bill_end) !== defaultEmptyDate) {
                $('#ns_bill_period_start').text(formatDate(bill_start));
                $('#ns_bill_period_end').text(formatDate(bill_end));
              } else {
                $('#ns_bill_period').addClass('hide');
              }

            } else {
              $('#ns_bill_period').addClass('hide');
            }


            if(billingSummary.Data.NewChargesDate == null || billingSummary.Data.NewChargesDate == '' || formatDate(bill_new_charges_date) == defaultEmptyDate) {
              $('span[data-new-charge-date]').addClass('hide');
            }
            $('#ns_new_charge_date').text(formatDate(bill_new_charges_date));
            $('#ns_new_charge_amount').text('$' + Number(billingSummary.Data.NewCharges).toFixed(2));

            $('#ns_past_due_date').text(formatDate(final_due_date));
            $('#ns_past_due_amount').text('$' + Number(billingSummary.Data.PastDueBalance).toFixed(2));
            if(parseInt(billingSummary.Data.PastDueBalance) === 0) {
              $('.billing_pastdue').addClass('hide');
            }

            if(billingSummary.Data.DueDate == null || billingSummary.Data.DueDate == '' || formatDate(bill_due_date) == defaultEmptyDate) {
              $('span[data-total-due-date]').addClass('hide');
            }
            $('#ns_total_due_date').text(formatDate(bill_due_date));
            $('#ns_total_due_amount').text('$' + Number(billingSummary.Data.AmountDue).toFixed(2));

            if(billingSummary.Data.LastPaymentDate !== null && billingSummary.Data.LastPaymentDate !== '' && formatDate(last_payment_date) !== defaultEmptyDate) {
              $('#ns_last_payment_date').text(formatDate(last_payment_date));
            } else {
              $('#ns_last_payment_date').addClass('hide');
            }

            $('#ns_last_payment_amount').text('$' + Number(billingSummary.Data.LastPaymentAmount).toFixed(2));

            if(parseInt(billingSummary.Data.PastDueBalance) !== 0) {
              $.ajax({
                url: '/wp-content/themes/Lumos/api_helper.php',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: {
                  action: "ns_get_promise_to_pay",
                  sessionId: ns_session_id,
                  // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
                  accountId: sessionStorage.getItem('lumos_login_account_id_current')
                },
                success: function(result) {
                  // console.log(result);
                  var ptpSummary = JSON.parse(result);

                  if(ptpSummary.Data.length) {
                    for(var p = 0; p < ptpSummary.Data.length; p++) {
                      var promiseDate = convertDateForIos(ptpSummary.Data[p].PromiseDate);
                      var todaysDate  = new Date();
                      todaysDate.setHours(0,0,0,0);
                      if(todaysDate <= promiseDate) {
                        $('[data-billing-pastdue]').append('<div class="promise-to-pay-message"><strong>Note:</strong> You made a Promise to Pay for <strong>$'+ptpSummary.Data[p].PromiseAmount+'</strong> due on or before <strong>'+formatDate(ptpSummary.Data[p].PromiseDate)+'</strong>. <br /><strong>Confirmation #: '+ptpSummary.Data[p].PromiseToPayID+'</strong> </div>');
                      }
                    }
                  }
                  // Remove loading cover
                  $('div[data-account-section="billing-summary"]').removeClass('loading');
                }
              });
            } else {
              // Remove loading cover
              $('div[data-account-section="billing-summary"]').removeClass('loading');
            }
          }
        }
      });
    } // endif
  }

  function getInvoiceSummary() {
    if($('div[data-account-page="view-bill"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_get_invoices",
          sessionId: ns_session_id,
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var invoices = JSON.parse(result);

          var monthName = [
            'Jan.',
            'Feb.',
            'Mar.',
            'Apr.',
            'May',
            'Jun.',
            'Jul.',
            'Aug.',
            'Sep.',
            'Oct.',
            'Nov.',
            'Dec.'
          ];

          if(invoices.Status == 'success') {
            var invoiceDates = [],
                invoiceCurrentID = 0,
                invoiceCurrent,
                urlParams = new URLSearchParams(window.location.search);

            if(urlParams.has('invoice_id')) {
              invoiceCurrentID = urlParams.get('invoice_id');
            }

            for(var i = 0; i < invoices.Data.length; i++) {
              var selected = '';
              if(invoiceCurrentID == 0) {
                invoiceCurrentID = invoices.Data[i].InvoiceID;
              }

              if(invoiceCurrentID == invoices.Data[i].InvoiceID) {
                invoiceCurrent = invoices.Data[i];
                selected = 'selected';
              }

              // console.log(invoiceCurrentID, invoices.Data[i].InvoiceID);
              // var invoiceDate = new Date(invoices.Data[i].InvoiceDate);
              var dateSplit = invoices.Data[i].InvoiceDate.lastIndexOf(' ');
              var invoiceDate = invoices.Data[i].InvoiceDate.substring(0, dateSplit);
              $('#ns-billing-dates').append('<option value="'+invoices.Data[i].InvoiceID+'" '+selected+'>' + invoiceDate + '</option>');
              // $('#ns-billing-dates').append('<option value="'+invoices.Data[i].InvoiceID+'" '+selected+'>' + monthName[invoiceDate.getMonth()] + ' ' + invoiceDate.getFullYear() + '</option>');
            }

            $('#ns-invoice-amount-due').text('$'+parseFloat(invoiceCurrent.AmountDue).toFixed(2));
            // $('#ns-invoice-date').text(formatDate(invoiceCurrent.InvoiceDate));
            // $('#ns-invoice-period').text(formatDate(invoiceCurrent.FromDate) + ' - ' + formatDate(invoiceCurrent.ToDate));
            // $('#ns-invoice-due-date').text(formatDate(invoiceCurrent.DueDate));

            var dateSplit = invoiceCurrent.InvoiceDate.lastIndexOf(' ');
            $('#ns-invoice-date').text(invoiceCurrent.InvoiceDate.substring(0, dateSplit));
            var dateSplit1 = invoiceCurrent.FromDate.lastIndexOf(' ');
            var dateSplit2 = invoiceCurrent.ToDate.lastIndexOf(' ');
            $('#ns-invoice-period').text(invoiceCurrent.FromDate.substring(0, dateSplit1) + ' - ' + invoiceCurrent.ToDate.substring(0, dateSplit2));
            dateSplit = invoiceCurrent.DueDate.lastIndexOf(' ');
            $('#ns-invoice-due-date').text(invoiceCurrent.DueDate.substring(0, dateSplit));

            var requestParams = "action=ns_download_invoice&sessionId="+ns_session_id+"&invoiceId="+invoiceCurrentID+"&sessionId="+ns_session_id;

            $('#ns-download-current-invoice').click(function() {
              $('.full-loader').removeClass('hide');
              $('.invoice_message').addClass('hide');
              var xhr = new XMLHttpRequest();
                  xhr.open('POST', '/wp-content/themes/Lumos/api_helper.php', true);
                  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                  xhr.responseType = 'arraybuffer';
                  xhr.onload = function(e) {
                    console.log(this.response, this.response.byteLength);
                     if (this.status == 200 && this.response.byteLength > 0) {
                       // console.log(this.response);

                       var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

                       var fURL = window.URL || window.webkitURL;
                       var blob = new Blob([this.response], {type:"application/pdf"});
                       var link = document.createElement('a');

                       // link.textContent = 'Download Invoice';
                       link.href = fURL.createObjectURL(blob);

                       //alert(link.href);
                       if(!isSafari) {
                         // Open in a new tab if not Safari - this is a Safari bug workaround
                         link.target = '_blank';
                       }

                       document.getElementById("account-invoices-container").appendChild(link);
                       // link.download = "ns_invoice_"+invoiceCurrent.InvoiceDate.split(' ')[0]+".pdf";
                       link.click();

                       // window.open(fURL.createObjectURL(blob));

                       setTimeout(function(){
                         // For Firefox it is necessary to delay revoking the ObjectURL
                         fURL.revokeObjectURL(blob);
                         document.getElementById("account-invoices-container").removeChild(link);
                       }, 100);

                     } else {
                       // alert('Only recent invoices are available to view online. Please call 336-886-3600 to request a copy of your archived invoices.');
                       $('.invoice_message').removeClass('hide');
                     }
                     $('.full-loader').addClass('hide');
                  };
              xhr.send(requestParams);
            });

          }

          $('div[data-account-section="invoices"]').removeClass('loading');
        }
      });
    }
  }

  function getPromotions() {
    if($('div[data-account-section="account-promotion"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "get_promotions",
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          // console.log(result);
          var promotions = JSON.parse(result);

          if(promotions.Data.length) {
            $('[data-account-promotion-title]').text(promotions.Data[0].Name);
            $('[data-account-promotion-description]').html(promotions.Data[0].AdditionalDescription);
            $('#ns-add-account-promotion').attr('data-account-promotion-id', promotions.Data[0].Id);

            $('[data-account-promotion-confirmation]').html(promotions.Data[0].AdditionalDescription);

            $('div[data-account-section="account-promotion"]').removeClass('loading');
          } else {
            $('div[data-account-section="account-promotion"]').addClass('hide');
          }
        }
      });
    }
  }

  $('#ns-add-account-promotion').click(function() {
    $('.full-loader').removeClass('hide');
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "add_promotion_to_account",
        ProductPromotionId: $('#ns-add-account-promotion').attr('data-account-promotion-id'),
        accountId: sessionStorage.getItem('lumos_login_account_id_current')
      },
      success: function(result) {
        console.log(result);
        var promotions = JSON.parse(result);

        if(promotions.Status === "success") {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'u_service_u'});
        } else {
          window.location.href = window.location.pathname+"?"+$.param({'status_m':'u_service_err'});
        }
      }
    });
  });

  function getUserProfile() {
    // only load if container element is present
    if($('div[data-account-section="billing-profile"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_account",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          // console.log(result);
          var accountSummary = JSON.parse(result);

          if(accountSummary.Status === 'success') {

            var profileName = accountSummary.Data.Contacts[0].NameFull;
            var profileAddress = accountSummary.Data.Contacts[0].AddressFull;
            $('#ns_my_profile_name').text(profileName);
            $('#ns_my_profile_address').text(profileAddress);

            // Remove loading cover
            $('div[data-account-section="billing-profile"]').removeClass('loading');
          } else {
            console.log(result);
          }
          // else {
          //   $('div[data-account-section="billing-profile"]').removeClass('loading');
          // }
        }
      });
    } // endif
  } // end getUserProfile()

  function getServices() {
    // only load if container element is present
    if($('div[data-account-section="services"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_services",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var services = JSON.parse(result);

          var serviceList = [
            'Internet',
            'TV',
            'Phone'
          ];

          if(services.Status === 'success') {
            // console.log(services);

            var servicesObj = {};

            for(var i = 0; i < services.Data.length; i++) {
              for(var j = 0; j < services.Data[i].ServiceItemsWithMapping.length; j++) {
                if(services.Data[i].ServiceItemsWithMapping[j].Feature !== null && services.Data[i].ServiceItemsWithMapping[j].Feature.Hide === 0) {
                  if(!(services.Data[i].ServiceItemsWithMapping[j].Feature.Type in servicesObj)) {
                    servicesObj[services.Data[i].ServiceItemsWithMapping[j].Feature.Type] = [];
                    serviceList.splice(serviceList.indexOf(services.Data[i].ServiceItemsWithMapping[j].Feature.Type), 1);
                  }
                  servicesObj[services.Data[i].ServiceItemsWithMapping[j].Feature.Type].push(services.Data[i].ServiceItemsWithMapping[j].Feature.Name);
                }
              }
            }

            $.each(servicesObj, function(key, item) {

              var accordionItem = $('<li class="accordion-item" data-accordion-item></li>');
              accordionItem.append('<a href="#" class="accordion-title">'+key+'</a>');

              var serviceDetail = $('<ul></ul>');
              for(var k = 0; k < item.length; k++) {
                serviceDetail.append('<li>'+item[k]+'</li>');
              }

              var accordionContent = $('<div class="accordion-content" data-tab-content></div>');
              accordionContent.append(serviceDetail);
              accordionItem.append(accordionContent);

              $('div[data-account-section="services"] .accordion').append(accordionItem);

            });

            // console.log(serviceList);

            // if(serviceList.length) {
            //   for(var l = 0; l < serviceList.length; l++) {
            //     $('div[data-account-section="services"]').append('<button class="button hollow services">Add '+serviceList[l]+'</button>');
            //   }
            // }

            Foundation.reInit('accordion');

            $('div[data-account-section="services"]').removeClass('loading');
          }
        }
      });
    } // endif
  } // end getServices()

  function getServiceDetails() {
    if($('div[data-account-section="account-service-internet"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_services",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var services = JSON.parse(result);

          var serviceList = {
            'Internet' : [],
            'Video' : [],
            'Voice' : []
          };

          if(services.Status === 'success') {
            var serviceData = services.Data;
            for(var d = 0; d < serviceData.length; d++) {
              $('*[data-internet-service]').text(serviceData[d].Configuration);

              switch(serviceData[d].Product.ProductTypeId) {
                case 2:
                  $('[data-bus-contact]').removeClass('hide');
                  break;
                case 1:
                default:
                  $('[data-res-contact]').removeClass('hide');
                  break;
              }

              for(var e = 0; e < serviceData[d].ServiceItemsWithMapping.length; e++) {
                var serviceMap = serviceData[d].ServiceItemsWithMapping[e];

                if(serviceMap.Feature !== null && serviceMap.Feature.Hide == 0) {
                  switch(serviceMap.Feature.Type) {
                    case 'Internet':
                      serviceList.Internet.push(serviceMap);
                      break;
                    case 'Video':
                      serviceList.Video.push(serviceMap);
                      break;
                    case 'Voice':
                      serviceList.Voice.push(serviceMap);
                      break;
                  }
                }
              }
            }

            // Internet Services
            if(serviceList.Internet.length) {
              for(var i = 0; i < serviceList.Internet.length; i++) {
                var serviceItem = '<li>'+serviceList.Internet[i].Feature.Name;

                if(serviceList.Internet[i].Feature.Name !== serviceList.Internet[i].Feature.Description) {
                  serviceItem += '<button class="help-icon" type="button" data-open="feature'+serviceList.Internet[i].Feature.Id+'">?</button>';
                  serviceItem += '<div class="reveal product-details" id="feature'+serviceList.Internet[i].Feature.Id+'" data-reveal>'+serviceList.Internet[i].Feature.Description;
                  serviceItem += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
                  serviceItem += '</div>';
                }
                serviceItem += '</li>';
                $('*[data-internet-service-features]').append(serviceItem);
              }
            } else {
              $('*[data-internet-service-features]').append('<li>No Internet services for this account.</li>');
            }

            // TV Services
            if(serviceList.Video.length) {
              for(var i = 0; i < serviceList.Video.length; i++) {
                var serviceItem = '<li>'+serviceList.Video[i].Feature.Name;

                if(serviceList.Video[i].Feature.Name !== serviceList.Video[i].Feature.Description) {
                  serviceItem += '<button class="help-icon" type="button" data-open="feature'+serviceList.Video[i].Feature.Id+'">?</button>';
                  serviceItem += '<div class="reveal product-details" id="feature'+serviceList.Video[i].Feature.Id+'" data-reveal>'+serviceList.Video[i].Feature.Description;
                  serviceItem += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
                  serviceItem += '</div>';
                }
                serviceItem += '</li>';
                $('*[data-tv-service-features]').append(serviceItem);
              }
            } else {
              $('*[data-tv-service-features]').append('<li>No TV services for this account.</li>');
            }

            // Voice Services
            if(serviceList.Voice.length) {
              for(var i = 0; i < serviceList.Voice.length; i++) {
                var serviceItem = '<li>'+serviceList.Voice[i].Feature.Name;

                if(serviceList.Voice[i].Feature.Name !== serviceList.Voice[i].Feature.Description) {
                  serviceItem += '<button class="help-icon" type="button" data-open="feature'+serviceList.Voice[i].Feature.Id+'">?</button>';
                  serviceItem += '<div class="reveal product-details" id="feature'+serviceList.Voice[i].Feature.Id+'" data-reveal>'+serviceList.Voice[i].Feature.Description;
                  serviceItem += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
                  serviceItem += '</div>';
                }
                serviceItem += '</li>';
                $('*[data-voice-service-features]').append(serviceItem);
              }
            } else {
              $('*[data-voice-service-features]').append('<li>No Phone services for this account.</li>');
            }

            // console.log(serviceList);

            $('[data-reveal]').foundation();
          }

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_available_features",
              sessionId: ns_session_id,
              accountId: sessionStorage.getItem('lumos_login_account_id_current')
            },
            success: function(result) {
              var available = JSON.parse(result);

              if(available.Status == 'success' && available.Data.length) {
                var availableFeatures = [];
                for(var a = 0; a < available.Data.length; a++) {
                  if(available.Data[a].Active == 1 && available.Data[a].Hide == 0 && available.Data[a].Price > 0 && available.Data[a].AllowUpgrade == 1) {
                  // if(available.Data[a].Hide == 0 && available.Data[a].Active == 1 && available.Data[a].Price !== 0) {
                    availableFeatures.push(available.Data[a]);

                    if(available.Data[a].Type == 'Internet') {
                      $('[data-internet-upgrades-available]').removeClass('hide');

                      var serviceItem = '<li>'+available.Data[a].Name;
                      if(available.Data[a].Name !== available.Data[a].Description) {
                        serviceItem += '<button class="help-icon" type="button" data-open="feature'+available.Data[a].Id+'">?</button>';
                        serviceItem += '<div class="reveal product-details" id="feature'+available.Data[a].Id+'" data-reveal>'+available.Data[a].Description;
                        serviceItem += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
                        serviceItem += '</div>';
                      }
                      serviceItem += '</li>';

                      $('*[data-internet-upgrades-available-list]').append(serviceItem);
                    }
                    if(available.Data[a].Type == 'Video') {
                      $('[data-tv-upgrades-available]').removeClass('hide');
                    }
                    if(available.Data[a].Type == 'Voice') {
                      $('[data-voice-upgrades-available]').removeClass('hide');
                    }
                  }
                }
                // console.log(availableFeatures);
              }

              $('[data-reveal]').foundation();

              $('div[data-account-section="account-service-internet"]').removeClass('loading');
              $('div[data-account-section="account-service-tv"]').removeClass('loading');
              $('div[data-account-section="account-service-phone"]').removeClass('loading');
              $('div[data-account-section="account-service-support"]').removeClass('loading');
            }
          });
        }
      });
    }
  } // end getServiceDetails()

  function getCurrentFeatures() {
    if($('div[data-account-section="current-services"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_services",
          sessionId: ns_session_id,
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var services = JSON.parse(result);

          // var serviceList = {
          //   'Internet' : [],
          //   'Video' : [],
          //   'Voice' : []
          // };
          var serviceList = [];
          var serviceTotal = 0;

          if(services.Status === 'success') {
            var serviceData = services.Data;
            for(var d = 0; d < serviceData.length; d++) {
              $('*[data-internet-service]').text(serviceData[d].Configuration);

              switch(serviceData[d].Product.ProductTypeId) {
                case 2:
                  $('[data-bus-contact]').removeClass('hide');
                  break;
                case 1:
                default:
                  $('[data-res-contact]').removeClass('hide');
                  break;
              }

              for(var e = 0; e < serviceData[d].ServiceItemsWithMapping.length; e++) {
                var serviceMap = serviceData[d].ServiceItemsWithMapping[e];

                if(serviceMap.Feature !== null && serviceMap.Feature.Active == 1) {
                  serviceList.push(serviceMap);
                  // switch(serviceMap.Feature.Type) {
                  //   case 'Internet':
                  //     serviceList.Internet.push(serviceMap);
                  //     break;
                  //   case 'Video':
                  //     serviceList.Video.push(serviceMap);
                  //     break;
                  //   case 'Voice':
                  //     serviceList.Voice.push(serviceMap);
                  //     break;
                  // }
                }
              }
            }

            if(serviceList.length) {
              for(var i = 0; i < serviceList.length; i++) {
                var serviceItem = '<tr><th>'+serviceList[i].Feature.Name;

                // if(serviceList[i].Feature.Name !== serviceList[i].Feature.Description) {
                //   serviceItem += '<button class="help-icon" type="button" data-open="feature-'+serviceList[i].Feature.Id+'">?</button>';
                //   serviceItem += '<div class="reveal product-details" id="feature-'+serviceList[i].Feature.Id+'" data-reveal>'+serviceList[i].Feature.Description;
                //   serviceItem += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
                //   serviceItem += '</div>';
                // }
                serviceItem += '</th>';

                serviceItem += '<td>$'+parseFloat(serviceList[i].Feature.Price).toFixed(2) + '</td></tr>';
                serviceTotal += parseFloat(serviceList[i].Feature.Price);
                $('[data-internet-service-features]').append(serviceItem);
              }

              $('[data-current-monthly-charges]').text('$'+parseFloat(serviceTotal).toFixed(2));
              $('[data-revised-monthly-charges]').text('$'+parseFloat(serviceTotal).toFixed(2));
              $('[data-revised-monthly-charges]').attr('data-start-monthly-charge', parseFloat(serviceTotal).toFixed(2));

            } else {

            }

            // Internet Services
            // if(serviceList.Internet.length) {
            //   for(var i = 0; i < serviceList.Internet.length; i++) {
            //     var serviceItem = '<li>'+serviceList.Internet[i].Feature.Name;
            //
            //     if(serviceList.Internet[i].Feature.Name !== serviceList.Internet[i].Feature.Description) {
            //       serviceItem += '<button class="help-icon" type="button" data-open="feature'+serviceList.Internet[i].Feature.Id+'">?</button>';
            //       serviceItem += '<div class="reveal product-details" id="feature'+serviceList.Internet[i].Feature.Id+'" data-reveal>'+serviceList.Internet[i].Feature.Description;
            //       serviceItem += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
            //       serviceItem += '</div>';
            //     }
            //     serviceItem += serviceList.Internet[i].Feature.Price + '</li>';
            //     $('*[data-internet-service-features]').append(serviceItem);
            //   }
            // } else {
            //   $('*[data-internet-service-features]').append('<li>No Internet services for this account.</li>');
            // }

            // TV Services
            // if(serviceList.Video.length) {
            //   for(var i = 0; i < serviceList.Video.length; i++) {
            //     var serviceItem = '<li>'+serviceList.Video[i].Feature.Name;
            //
            //     if(serviceList.Video[i].Feature.Name !== serviceList.Video[i].Feature.Description) {
            //       serviceItem += '<button class="help-icon" type="button" data-open="feature'+serviceList.Video[i].Feature.Id+'">?</button>';
            //       serviceItem += '<div class="reveal product-details" id="feature'+serviceList.Video[i].Feature.Id+'" data-reveal>'+serviceList.Video[i].Feature.Description;
            //       serviceItem += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
            //       serviceItem += '</div>';
            //     }
            //     serviceItem += '</li>';
            //     $('*[data-tv-service-features]').append(serviceItem);
            //   }
            // } else {
            //   $('*[data-tv-service-features]').append('<li>No TV services for this account.</li>');
            // }

            // Voice Services
            // if(serviceList.Voice.length) {
            //   for(var i = 0; i < serviceList.Voice.length; i++) {
            //     var serviceItem = '<li>'+serviceList.Voice[i].Feature.Name;
            //
            //     if(serviceList.Voice[i].Feature.Name !== serviceList.Voice[i].Feature.Description) {
            //       serviceItem += '<button class="help-icon" type="button" data-open="feature'+serviceList.Voice[i].Feature.Id+'">?</button>';
            //       serviceItem += '<div class="reveal product-details" id="feature'+serviceList.Voice[i].Feature.Id+'" data-reveal>'+serviceList.Voice[i].Feature.Description;
            //       serviceItem += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
            //       serviceItem += '</div>';
            //     }
            //     serviceItem += '</li>';
            //     $('*[data-voice-service-features]').append(serviceItem);
            //   }
            // } else {
            //   $('*[data-voice-service-features]').append('<li>No Phone services for this account.</li>');
            // }
          }

          // $('[data-reveal]').foundation();
          $('div[data-account-section="current-services"]').removeClass('loading');
          $('div[data-account-section="revised-services"]').removeClass('loading');
        }
      });
    }
  }

  function getAvailableFeatures() {
    if($('div[data-account-section="available-services"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_available_features",
          sessionId: ns_session_id,
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var availableFeatures = JSON.parse(result);

          console.log(availableFeatures);

          var internetItems = [];
          var tvItems       = [];
          var voiceItems    = [];
          var currGroupID   = '';

          for(var i = 0; i < availableFeatures.Data.length; i++) {
            var featureSelect = '';
            if(availableFeatures.Data[i].Active == 1 && availableFeatures.Data[i].Hide == 0 && availableFeatures.Data[i].Price > 0 && availableFeatures.Data[i].AllowUpgrade == 1) {
            // if(availableFeatures.Data[i].Active == 1 && availableFeatures.Data[i].Hide == 0 && availableFeatures.Data[i].Price > 0 && (availableFeatures.Data[i].InputType == 1 || availableFeatures.Data[i].InputType == 3) && availableFeatures.Data[i].AllowUpgrade == 1 ) {
            // if(availableFeatures.Data[i].Active == 1 && availableFeatures.Data[i].Hide == 0 && availableFeatures.Data[i].Price > 0 && (availableFeatures.Data[i].InputType == 1 || availableFeatures.Data[i].InputType == 3) && availableFeatures.Data[i].AllowUpgrade == 1 ) {

              console.log(availableFeatures.Data[i]);

              var inputType = (availableFeatures.Data[i].InputType == 1) ? 'checkbox' : 'radio',
                  inputChecked = '', //(availableFeatures.Data[i].Default == 1) ? 'checked' : ''
                  inputDisabled = (availableFeatures.Data[i].Price == 0) ? 'disabled' : '';

              if(inputType === 'radio') {
                if(currGroupID !== '' && currGroupID !== availableFeatures.Data[i].FeatureGroupId) {
                  currGroupID = availableFeatures.Data[i].FeatureGroupId;
                  featureSelect += '<hr />';
                } else if(currGroupID == '') {
                  currGroupID = availableFeatures.Data[i].FeatureGroupId;
                }
              }

              console.log(currGroupID);

              featureSelect +=  '<label for="feature_'+availableFeatures.Data[i].Id+'" class="" data-feature-type="'+availableFeatures.Data[i].FeatureTypeId+'" data-feature-sub-type="'+availableFeatures.Data[i].FeatureSubTypeId+'">';

              if(availableFeatures.Data[i].MaxQuantity > 1) {
                featureSelect += '<select id="feature_'+availableFeatures.Data[i].Id+'" name="" value="'+availableFeatures.Data[i].Id+'" data-price="'+availableFeatures.Data[i].Price+'" data-update-base-price="" data-product-feature-id="'+availableFeatures.Data[i].Id+'" data-product-id="'+availableFeatures.Data[i].ProductId+'">';
                for(var j = 0; j <= availableFeatures.Data[i].MaxQuantity; j++) {
                  featureSelect += '<option value="'+j+'">'+j+'</option>';
                }
                featureSelect += '</select>';
              } else {
                featureSelect += '<input id="feature_'+availableFeatures.Data[i].Id+'" name="feature_radio_group_'+availableFeatures.Data[i].FeatureGroupId+'" value="'+availableFeatures.Data[i].Id+'" data-price="'+availableFeatures.Data[i].Price+'" data-update-base-price="true" data-product-feature-id="'+availableFeatures.Data[i].Id+'" data-product-id="'+availableFeatures.Data[i].ProductId+'" type="'+inputType+'" '+inputChecked+' '+inputDisabled+'>';
              }

              featureSelect += '<span class="feature-desc">'+availableFeatures.Data[i].Name+'</span>';

              if(availableFeatures.Data[i].Name !== availableFeatures.Data[i].Description) {
                featureSelect += '<button class="help-icon" type="button" data-open="feature'+availableFeatures.Data[i].Id+'">?</button>';
                featureSelect += '<div class="reveal product-details" id="feature'+availableFeatures.Data[i].Id+'" data-reveal>'+availableFeatures.Data[i].Description;
                featureSelect += '<button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true">&times;</span></button>'
                featureSelect += '</div>';
              }

              featureSelect += '<span class="feature-price"><strong>$'+availableFeatures.Data[i].Price+' / mo.</strong>';
              if(availableFeatures.Data[i].MaxQuantity > 1) {
                featureSelect += '(ea.)';
              }
              featureSelect += '</span>';

              featureSelect += '</label>';

              switch(availableFeatures.Data[i].Type) {
                case 'Internet':
                  internetItems.push(featureSelect);
                  break;
                case 'TV':
                case 'Video':
                  tvItems.push(featureSelect);
                  break;
                case 'Voice':
                  voiceItems.push(featureSelect);
                  break;
              }

              // $('div[data-account-section="available-services"] [data-available-upgrades]').append(featureSelect);
            }

          }

          if(internetItems.length > 0) {
            $('div[data-account-section="available-services"] [data-available-upgrades]').append('<h5>Internet Features</h5>');
          }
          for(var i = 0; i < internetItems.length; i++) {
            $('div[data-account-section="available-services"] [data-available-upgrades]').append(internetItems[i]);
          }

          if(tvItems.length > 0) {
            $('div[data-account-section="available-services"] [data-available-upgrades]').append('<h5>TV Features</h5>');
          }
          for(var i = 0; i < tvItems.length; i++) {
            $('div[data-account-section="available-services"] [data-available-upgrades]').append(tvItems[i]);
          }

          if(voiceItems.length > 0) {
            $('div[data-account-section="available-services"] [data-available-upgrades]').append('<h5>Voice Features</h5>');
          }
          for(var i = 0; i < voiceItems.length; i++) {
            $('div[data-account-section="available-services"] [data-available-upgrades]').append(voiceItems[i]);
          }

          if(internetItems.length === 0 && tvItems.length === 0 && voiceItems.length === 0) {
            $('div[data-account-section="available-services"] [data-available-upgrades]').append('<h5>No Upgrades Currently Available</h5>');
          }

          $('[data-reveal]').foundation();

          $('div[data-account-section="available-services"]').removeClass('loading');

          $('[data-available-upgrades] input, [data-available-upgrades] select').change(function() {
            // console.log($(this));
            var addOnPrice = 0;
            $('[data-add-selected-services]').prop('disabled', true);
            $('[data-addon-service-features]').empty();
            $('[data-available-upgrades] input').each(function() {
              if($(this).prop('checked')) {
                var revisionRow = '<tr><th>'+$(this).siblings('.feature-desc').text()+'</th>';
                revisionRow     += '<td>$'+parseFloat($(this).attr('data-price')).toFixed(2)+'</td></tr>';
                $('[data-addon-service-features]').append(revisionRow);

                addOnPrice += parseFloat($(this).attr('data-price'));
              }
            });

            $('[data-available-upgrades] select').each(function() {
              if($(this).val() > 0) {
                var revisionRow = '<tr><th>('+$(this).val()+') '+$(this).siblings('.feature-desc').text()+'</th>';
                revisionRow     += '<td>$'+parseFloat($(this).attr('data-price') * $(this).val()).toFixed(2)+'</td></tr>';
                $('[data-addon-service-features]').append(revisionRow);

                addOnPrice += parseFloat($(this).attr('data-price') * $(this).val());
              }
            });

            if(addOnPrice > 0) {
              $('[data-add-selected-services]').prop('disabled', false);
            }

            addOnPrice += parseFloat($('[data-revised-monthly-charges]').attr('data-start-monthly-charge'));
            $('[data-revised-monthly-charges]').text('$' + parseFloat(addOnPrice).toFixed(2));
          });
        }
      });
    }
  }

  $('#ns-add-additional-services').click(function() {
    $('.full-loader').removeClass('hide');
    var selectedFeatures = [];
    var selectedFeaturesProductId = [];
    $('[data-available-upgrades] label').each(function() {

      var f_temp_qty = 0;
      var f_temp_featureID = 0;
      var f_temp_productID = 0;

      if($(this).find('input').length) {
        console.log('input');
        if($(this).find('input').prop('checked')) {
          f_temp_qty = 1;
          f_temp_featureID = $(this).find('input').val();
          f_temp_productID = $(this).find('input').attr('data-product-id');
        }
      } else if($(this).find('select').length) {
        console.log('select');
        if($(this).find('select').val() !== "0") {
          f_temp_qty = $(this).find('select').val();
          f_temp_featureID = $(this).find('select').attr('data-product-feature-id');
          f_temp_productID = $(this).find('select').attr('data-product-id');
        }
      }

      if(f_temp_qty > 0) {
        var f_temp = {
          "ItemID": 0,
          "ParentItemID": null,
          "ServiceID": null,
          "LocationID": 0,
          "ComponentID": null,
          "CompnentCode": null,
          "Component": null,
          "Quantity": parseInt(f_temp_qty),
          "ActivationDate": null,
          "DeactivationDate": null,
          "Status": null,
          "FeatureID": parseInt(f_temp_featureID),
          "ServiceItemAttributes": [],
          "DefaultPrice": null,
          "GroupID": null,
          "PackageID": null
        };

        var f_temp_pid = {
          "ProductID" : parseInt(f_temp_productID),
          "FeatureID": parseInt(f_temp_featureID),
        };

        selectedFeatures.push(f_temp);
        selectedFeaturesProductId.push(f_temp_pid);
      }
    });

    addSelectedFeatures(selectedFeatures, selectedFeaturesProductId);
  });

  function addSelectedFeatures(selectedFeatures, selectedFeaturesProductId) {
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        // action: "ns_current_services",
        action: "ns_current_services_mapping",
        sessionId: ns_session_id,
        accountId: sessionStorage.getItem('lumos_login_account_id_current')
      },
      success: function(result) {
        var currService = JSON.parse(result);
        // console.log(JSON.parse(result));

        var serviceIndex = 0;

        if(currService.Data.length > 0) {
          for(var i = 0; i < selectedFeatures.length; i++) {
            var pid = selectedFeaturesProductId[i].ProductID;

            for(var j = 0; j < currService.Data.length; j++) {
              if(pid === currService.Data[j].ProductID) {
                serviceIndex = j;
                break;
              }
            }
            // currService.Data[0].ServiceItems.push(selectedFeatures[i]);
            currService.Data[serviceIndex].ServiceItemsWithMapping.push(selectedFeatures[i]);
          }

          // console.log(serviceIndex);
          // console.log(currService.Data);

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_update_services",
              sessionId: ns_session_id,
              ServiceEntity: JSON.stringify(currService.Data[serviceIndex])
            },
            success: function(result) {
              console.log(result);
              var serviceResult = JSON.parse(result);

              if(serviceResult.Status === "success") {
                window.location.href = window.location.pathname+"?"+$.param({'status_m':'u_service_u'});
              } else {
                window.location.href = window.location.pathname+"?"+$.param({'status_m':'u_service_err'});
              }
            },
            error: function() {
              window.location.href = window.location.pathname+"?"+$.param({'status_m':'u_service_err'});
            }
          });

        }
      }
    });
  }

  function getPaymentHistory() {
    if($('div[data-account-section="payment-history"]').length) {
      var invoiceHeadings = [
            "Date",
            "Description",
            "Status",
            "Payment Method",
            "Confirmation #",
            "Amount",
            ""
          ],
          invoiceList = [];

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_billing_invoice",
          sessionId: ns_session_id,
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          // console.log(result);
          var invoices = JSON.parse(result);

          if(invoices.Status === 'success') {
            if(invoices.Data.length > 0) {
              for(var i = 0; i < invoices.Data.length; i++) {
                // var invoiceDate = new Date(invoices.Data[i].InvoiceDate);
                // var invoiceDate = invoices.Data[i].InvoiceDate.split(' ')[0];
                var dateSplit = invoices.Data[i].InvoiceDate.lastIndexOf(' ');
                var invoiceDate = invoices.Data[i].InvoiceDate.substring(0, dateSplit);
                invoiceList.push([
                  invoiceDate,
                  "Bill",
                  "Due",
                  "",
                  "",
                  "$"+parseFloat(invoices.Data[i].AmountDue).toFixed(2),
                  '<button data-invoice="'+invoices.Data[i].InvoiceID+'" data-invoice-date="'+invoiceDate+'">Download</button>'
                ]);
              }

            } else {
              // $('div[data-account-section="payment-history"]').removeClass('loading');
            }
          } else {
            // $('div[data-account-section="payment-history"]').removeClass('loading');
          }

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_billing_payment_history",
              sessionId: ns_session_id,
              // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
              accountId: sessionStorage.getItem('lumos_login_account_id_current')
            },
            success: function(result) {
              console.log(result);
              var paymentInfo = JSON.parse(result);

              if(paymentInfo.Status === 'success') {
                if(paymentInfo.Data.length > 0) {

                  for(var p = 0; p < paymentInfo.Data.length; p++) {

                    // var paymentDate = paymentInfo.Data[p].Date.split(' ')[0];
                    var dateSplit = paymentInfo.Data[p].Date.lastIndexOf(' ');
                    var paymentDate = paymentInfo.Data[p].Date.substring(0, dateSplit);

                    var cancelButton = '';
                    if(paymentInfo.Data[p].Status == 'Scheduled') {
                      cancelButton = '<button class="red-button" data-cancel-id="'+paymentInfo.Data[p].Id+'" data-cancel-amount="'+parseFloat(paymentInfo.Data[p].Amount).toFixed(2)+'" data-cancel-date="'+paymentDate+'">Cancel</button>';
                    }

                    invoiceList.push([
                      paymentDate,
                      "Payment",
                      paymentInfo.Data[p].Status,
                      paymentInfo.Data[p].PaymentMethod,
                      paymentInfo.Data[p].TransactionID,
                      "-$"+parseFloat(paymentInfo.Data[p].Amount).toFixed(2),
                      cancelButton
                    ]);
                  }

                  // Sort data by date
                  invoiceList.sort(function(a, b) {
                    if (a > b) {
                        return -1;
                    }
                    if (b > a) {
                        return 1;
                    }
                    return 0;
                  });

                }
              }

              $('div[data-account-section="payment-history"]').removeClass('loading');

              $('div[data-payment-history]').append(createTable(invoiceHeadings, invoiceList, 'responsive-card-table'));

              $('button[data-cancel-id]').click(function(e) {
                var paymentID = $(this).attr('data-cancel-id');
                var paymentAmount = $(this).attr('data-cancel-amount');
                var paymentDate = $(this).attr('data-cancel-date');

                $('[data-cancel-payment-amount]').text('$'+paymentAmount);
                $('[data-cancel-payment-date]').text(paymentDate);
                $('#scheduledPaymentID').val(paymentID);

                $('#cancel-payment').foundation('open');
              });

              // click action for invoice Download
              $('button[data-invoice]').click(function(e) {
                var invoiceID = $(this).attr('data-invoice');
                var invoiceDate = $(this).attr('data-invoice-date');
                $('.full-loader').removeClass('hide');

                // console.log('download invoice');

                var requestParams = "action=ns_download_invoice&sessionId="+ns_session_id+"&invoiceId="+invoiceID+"&sessionId="+ns_session_id;

                var xhr = new XMLHttpRequest();
                    xhr.open('POST', '/wp-content/themes/Lumos/api_helper.php', true);
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    xhr.responseType = 'arraybuffer';
                    xhr.onload = function(e) {
                      if (this.status == 200 && this.response.byteLength > 0) {
                        // console.log(this.response);

                        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

                        var fURL = window.URL || window.webkitURL;
                        var blob = new Blob([this.response], {type:"application/pdf"});
                        var link = document.createElement('a');

                        // link.textContent = 'Download Invoice';
                        link.href = fURL.createObjectURL(blob);

                        //alert(link.href);
                        if(!isSafari) {
                          // Open in a new tab if not Safari - this is a Safari bug workaround
                          link.target = '_blank';
                        }

                        // link.download = "ns_invoice_"+invoiceCurrent.InvoiceDate.split(' ')[0]+".pdf";
                        link.click();

                        // window.open(fURL.createObjectURL(blob));

                        setTimeout(function(){
                          // For Firefox it is necessary to delay revoking the ObjectURL
                          fURL.revokeObjectURL(blob);

                        }, 100);

                      } else {
                        // alert('Only recent invoices are available to view online. Please call 336-886-3600 to request a copy of your archived invoices.');
                        $('.invoice_message').removeClass('hide');
                      }
                      $('.full-loader').addClass('hide');
                    };
                xhr.send(requestParams);

              });

            }
          });
        }
      });
    }
  }

  function getOCCHistory() {
    if($('div[data-account-section="occ-history"]').length) {
      var occHeadings = [
            "Date",
            "Description",
            "Amount",
            "Status"
          ],
          occList = [];

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_billing_occ_history",
          sessionId: ns_session_id,
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          // console.log(result);
          var occData = JSON.parse(result);

          if(occData.Status === 'success') {
            if(occData.Data.length > 0) {
              for(var i = 0; i < occData.Data.length; i++) {
                // var invoiceDate = new Date(invoices.Data[i].InvoiceDate);
                // var occDate = occData.Data[i].Date.split(' ')[0];
                var dateSplit = occData.Data[i].Date.lastIndexOf(' ');
                var occDate = occData.Data[i].Date.substring(0, dateSplit);
                occList.push([
                  formatDate(occDate),
                  occData.Data[i].Description,
                  '$'+parseFloat(occData.Data[i].Amount).toFixed(2),
                  occData.Data[i].Status
                ]);
              }
            } else {

            }

            $('div[data-occ-history]').append(createTable(occHeadings, occList, 'responsive-card-table'));

            $('div[data-account-section="occ-history"]').removeClass('loading');

          } // endif
        }
      });
    }
  }


  function getActivity() {
    if($('div[data-account-section="open-activities"]').length) {

      var serviceHeadings = [
            "Activity Type",
            "Service Order ID",
            "Created Date",
            "Service",
            "Stage",
            "Status"
          ],
          serviceOrderList = [],
          ticketHeadings = [
            "Activity Type",
            "Ticket ID",
            "Created Date",
            "Reported Trouble",
            "Stage",
            "Status"
          ],
          ticketList = [];

      // Service orders
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_service_order",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var serviceOrders = JSON.parse(result);

          if(serviceOrders.Status === 'success') {
            // console.log(serviceOrders);
            if(serviceOrders.Data.length > 0) {
              for(var i = 0; i < serviceOrders.Data.length; i++) {
                serviceOrderList.push([
                  "Service Order",
                  serviceOrders.Data[i].ServiceOrderID,
                  serviceOrders.Data[i].CreateDate,
                  serviceOrders.Data[i].Template,
                  serviceOrders.Data[i].Stage,
                  serviceOrders.Data[i].Status
                ]);
              }

              // Sort data by date
              serviceOrderList.sort(function(a, b) {
                return  b[1] - a[1];
              });

              // console.log(serviceOrderList);

              $('div[data-service-activity]').append(createTable(serviceHeadings, serviceOrderList, 'responsive-card-table'));
            } else {
              $('div[data-service-activity]').hide();
            }
          } // endif

          // Trouble tickets
          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_trouble_ticket",
              sessionId: ns_session_id,
              // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
              accountId: sessionStorage.getItem('lumos_login_account_id_current')
            },
            success: function(result) {
              var tickets = JSON.parse(result);

              if(tickets.Status === 'success') {
                if(tickets.Data.length > 0) {
                  for(var i = 0; i < tickets.Data.length; i++) {
                    ticketList.push([
                      "Service Order",
                      tickets.Data[i].TroubleTicketID,
                      tickets.Data[i].CreateDate,
                      tickets.Data[i].ReportedTrouble,
                      tickets.Data[i].Stage,
                      tickets.Data[i].Status
                    ]);
                  }

                  // Sort data by date
                  ticketList.sort(function(a, b) {
                    return  b[1] - a[1];
                  });

                  $('div[data-trouble-tickets]').append(createTable(ticketHeadings, ticketList, 'responsive-card-table'));

                } else if(serviceOrders.Data.length <= 0) {
                  $('div[data-account-section="open-activities"]').hide();
                }

                $('div[data-account-section="open-activities"]').removeClass('loading');
              }
            } // end success
          }); // end ajax
        } // end success
      }); // end ajax
    } // endif
  } // end getActivity()

  function getProfileInfo() {
    if($('div[data-account-section="profile-information"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_account",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var profileInfo = JSON.parse(result);

          console.log(profileInfo);

          if(profileInfo.Status === 'success') {

            // Billing Information
            var billingContactIndex = 0;
            for(;billingContactIndex < profileInfo.Data.Contacts.length; billingContactIndex++) {
              if(profileInfo.Data.Contacts[billingContactIndex].Type == "Billing") {
                break;
              }
            }

            var billingContact = profileInfo.Data.Contacts[billingContactIndex];

            $('#ns_account_id').val(billingContact.AccountID);
            $('#ns_contact_id').val(billingContact.ContactID);

            $('#ns-billing-name').text(billingContact.NameFull);

            $('#ns-billing-name-full').val(billingContact.NameFull);
            $('#ns-billing-name-prefix').val(billingContact.NamePrefix);
            $('#ns-billing-name-first').val(billingContact.NameFirst);
            $('#ns-billing-name-middle').val(billingContact.NameMiddle);
            $('#ns-billing-name-last').val(billingContact.NameLast);
            $('#ns-billing-name-suffix').val(billingContact.NameSuffix);

            for(var a = 0; a < profileInfo.Data.Username.length; a++) {
              if(profileInfo.Data.Username[a].Id == ns_account_session.Data.UserId) {
                $('#ns-password-user-id').val(profileInfo.Data.Username[a].Id);
                $('#ns-password-username').val(profileInfo.Data.Username[a].Username);
                $('#ns-username').text(profileInfo.Data.Username[a].Username);
                break;
              }
            }

            $('#ns-password-account-id').val(billingContact.AccountID);

            if(billingContact.Phone) {
              var nsphone = formatPhone(billingContact.Phone);
              if(billingContact.PhoneExt) {
                nsphone = nsphone + ' x' + billingContact.PhoneExt;
              }

              $('div[data-display-for="ns-phone ns-phone-ext"]').text(nsphone);
            }

            $('#ns-phone').val(billingContact.Phone);
            if(billingContact.PhoneExt !== null && billingContact.PhoneExt.length) {
              $('#ns-phone-ext').val(billingContact.PhoneExt.trim());
            }

            if(billingContact.PhoneAlt) {
              var nsphonealt = formatPhone(billingContact.PhoneAlt);
              if(billingContact.PhoneAltExt) {
                nsphonealt = nsphonealt + ' x' + billingContact.PhoneAltExt;
              }

              $('div[data-display-for="ns-phone-alt ns-phone-ext-alt"]').text(nsphonealt);
            }

            $('#ns-phone-alt').val(billingContact.PhoneAlt);
            if(billingContact.PhoneAltExt !== null && billingContact.PhoneAltExt.length) {
              $('#ns-phone-ext-alt').val(billingContact.PhoneAltExt.trim());
            }


            $('div[data-display-for="ns-email-address"]').text(billingContact.Email);
            $('#ns-email-address').val(billingContact.Email);

            // Set Billing Info

            $('div[data-display-for="ns-billing-address"]').text(billingContact.AddressFull);
            // $('#ns-billing-address').val(profileInfo.Data.Contacts[0].AddressFull);

            $('#ns-billing-address-zip').val(billingContact.AddressZip);

            $('#ns-billing-address-city-id').val(billingContact.AddressCityID);
            $('#ns-billing-address-state-id').val(billingContact.AddressStateID);
            getAddressCity($('#ns-billing-address-city'), billingContact.AddressCityID, billingContact.AddressStateID);

            $('#ns-billing-address-street-name-id').val(billingContact.AddressStreetID);
            getAddressStreet($('#ns-billing-address-street-name'), billingContact.AddressStreetID);

            $('#ns-billing-address-street-number').val(billingContact.AddressHouseNum);

            if(billingContact.AddressApartmentNum !== null) {
              $('#ns-billing-address-street-apt').val(billingContact.AddressApartmentNum);
            }

            if(billingContact.AddressPoBox !== null) {
              $('#ns-billing-address-street-pobox').val(billingContact.AddressPoBox);
              $('#ns-billing-address-street-pobox').click();
            }


            // Set Security Pin Data
            $('div[data-display-for="ns-security-pin"]').text(String(profileInfo.Data.SecurityPin).replace(/[\S]/g, "*"));
            $('#ns-security-pin').val(profileInfo.Data.SecurityPin);

            $('div[data-account-section="profile-information"]').removeClass('loading');
            $('div[data-account-section="profile-password"]').removeClass('loading');

            // Set Security Questions
            if(profileInfo.Data.SecurityQuestions.length) {
              $('#ns-security-question').text(profileInfo.Data.SecurityQuestions[0].Question);
              $('#ns-security-question-current').text(profileInfo.Data.SecurityQuestions[0].Question);
              $('#ns-security-question-account-id').val(billingContact.AccountID);
              $('#ns-security-question-id').val(profileInfo.Data.SecurityQuestions[0].SecurityQuestionID);
            }


            // Set Question Options
            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "ns_get_security_question_templates",
              },
              success: function(result) {
                var templateData = JSON.parse(result);

                for(var t = 0; t < templateData.Data.length; t++) {
                  $('#ns-security-question-new').append('<option>'+templateData.Data[t].Question+'</option>');
                }
              }
            });

            $('div[data-account-section="profile-question"]').removeClass('loading');

            var authorizedUserHeader = [
                  'First Name',
                  'Last Name',
                  'Email Address',
                  // ''
                ],
                authorizedUserList = [];

            for(var i = 1; i < profileInfo.Data.Contacts.length; i++) {
              authorizedUserList.push([
                profileInfo.Data.Contacts[i].NameFirst,
                profileInfo.Data.Contacts[i].NameLast,
                profileInfo.Data.Contacts[i].Email,
              ]);
            }

            $('div[data-authorized-users]').append(createTable(authorizedUserHeader, authorizedUserList, 'responsive-card-table authorized-list'));

            var authorizedUserHeaderEdit = [
                  'Current Authorized Users',
                  ''
                ],
                authorizedUserListEdit = [];

            for(var i = 1; i < profileInfo.Data.Contacts.length; i++) {
              authorizedUserListEdit.push([
                profileInfo.Data.Contacts[i].NameFull,
                '<div class="grid-x"><div class="small-6 cell text-right">'+
                  '<button data-edit="'+profileInfo.Data.Contacts[i].ContactID+'" data-contact="'+btoa(JSON.stringify(profileInfo.Data.Contacts[i]))+'">Edit</button>'+
                '</div> '+
                '<div class="small-6 cell text-right">'+
                  '<button data-delete="'+profileInfo.Data.Contacts[i].ContactID+'" data-open="remove-contact" data-name="'+profileInfo.Data.Contacts[i].NameFull+'" data-email="'+profileInfo.Data.Contacts[i].Email+'">Remove</button>'+
                '</div>'
              ]);
            }

            $('div[data-authorized-users-edit]').append(createTable(authorizedUserHeaderEdit, authorizedUserListEdit, 'responsive-card-table authorized-list'));

            $('div[data-authorized-users-edit] button[data-edit]').click(function() {

            });

            // Toggle contact edit
            $('div[data-authorized-users-edit] button[data-edit]').click(function() {
              var contactData = JSON.parse(atob($(this).attr('data-contact')));
              // console.log(contactData);

              $('#ns-auth-user-firstname-edit').val(contactData.NameFirst);
              $('#ns-auth-user-mi-edit').val(contactData.NameMiddle);
              $('#ns-auth-user-lastname-edit').val(contactData.NameLast);
              $('#ns-auth-user-suffix-edit').val(contactData.NameSuffix);

              $('#ns-auth-user-contact-number-edit').val(contactData.Phone);
              $('#ns-auth-user-contact-number-ext-edit').val(contactData.PhoneExt);
              $('#ns-auth-user-alt-contact-number-edit').val(contactData.PhoneAlt);
              $('#ns-auth-user-alt-contact-number-ext-edit').val(contactData.PhoneAltExt);

              $('#ns-auth-user-email-edit').val(contactData.Email);

              $('#ns-auth-user-ssn-edit').val(contactData.SSN);

              $('#ns-auth-user-address-city-id-edit').val(contactData.AddressCityID);
              $('#ns-auth-user-address-state-id-edit').val(contactData.AddressStateID);
              $('#ns-auth-user-address-zip-edit').val(contactData.AddressZip);
              $('#ns-auth-user-address-street-name-id-edit').val(contactData.AddressStreetID);
              $('#ns-auth-user-address-street-number-edit').val(contactData.AddressHouseNum);
              $('#ns-auth-user-address-street-apt-edit').val(contactData.AddressApartmentNum);
              $('#ns-auth-user-address-street-pobox-edit').val(contactData.AddressPoBox);

              $('#ns-auth-user-contact-id-edit').val(contactData.ContactID);

              getAddressStreet($('#ns-auth-user-address-street-name-edit'), contactData.AddressStreetID);
              getAddressCity($('#ns-auth-user-address-city-edit'), contactData.AddressCityID, contactData.AddressStateID);


              $('div[data-edit-authorized-users]').toggleClass('hide');
              $('div[data-edit-authorized-user]').toggleClass('hide');
            });

            // Delete contact confirmation
            $('div[data-authorized-users-edit] button[data-delete]').click(function() {
              var contactName = $(this).attr('data-name'),
                  contactEmail = $(this).attr('data-email')
                  contactID = $(this).attr('data-delete');

              $('#ns-delete-contact-name').text(contactName);
              $('#ns-delete-contact-email').text(contactEmail);
              $('#ns-delete-contact-id').attr('data-remove-contact-id', contactID);
            });

            $('div[data-account-section="profile-authorized-users"]').removeClass('loading');
          }

          // console.log('resolved profile');

        }
      });
    }
  }

  function getAddressStreet($selectElem, defaultValue) {
    if(defaultValue !== null) {
      $selectElem.prop('disabled', true);
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_get_street",
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          streetId: defaultValue
        },
        success: function(result) {
          // console.log(result);
          var cityData = JSON.parse(result);

          if(cityData.Status === 'success') {
            $selectElem.val(cityData.Data.Street.trim() + " " + cityData.Data.StreetSuffix.trim());
          }
          $selectElem.prop('disabled', false);
        }
      });
    } else {
      $selectElem.val('');
    }
  }

  function getAddressCity($selectElem, defaultValueCity, defaultValueState) {
    if(defaultValueCity !== null && defaultValueState !== null) {
      $selectElem.prop('disabled', true);
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_get_city",
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          cityId: defaultValueCity
        },
        success: function(result) {
          var cityData = JSON.parse(result);

          if(cityData.Status === 'success') {
            $selectElem.val(cityData.Data.City.trim());
            // Get State
            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "ns_get_state",
                // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
                stateId: defaultValueState
              },
              success: function(result) {
                var stateData = JSON.parse(result);

                $selectElem.val($selectElem.val() + ', ' + stateData.Data[0].StateAbbreviation.trim());

                $selectElem.prop('disabled', false);
              }
            });
          }
        }
      });
    } else {
      $selectElem.val('');
    }
  }

  function saveProfileInfo() {
    if($('div[data-account-section="profile-information"]').length) {

    }
  }

  function getAutomaticPayments() {
    if($('div[data-account-section="monthly-payment"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_payment_profile",
          sessionId: ns_session_id,
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var paymentProfile = JSON.parse(result);

          if(paymentProfile.Data.length) {
            for(var i = 0; i < paymentProfile.Data.length; i++) {
              var bankProfile = paymentProfile.Data[i].BankProfile;
              var ccProfile   = paymentProfile.Data[i].CreditCardProfile;

              // if(bankProfile != null) {
              //   $('#bankname').append('<option value="'+paymentProfile.Data[i].PaymentProfileId+'" data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'">'+paymentProfile.Data[i].Description+' - '+bankProfile.AccountNumber+'</option>')
              // }

              if(paymentProfile.Data[i].Recurring) {
                $('div[data-automatic-exists]').removeClass('hide');
                $('div[data-automatic-setup]').addClass('hide');

                var recurringDate = convertDateForIos(paymentProfile.Data[i].RecurringStartDate);

                $('span[data-recurring-payment-method]').text(paymentProfile.Data[i].Description + ' -  Credit Card ending in ' + paymentProfile.Data[i].LastFour)
                $('span[data-recurring-payment-schedule]').text('Paid on Day ' + recurringDate.getDate() + ' of each month.');

                $('#ns-auto-payment-profile-id-disable').val(paymentProfile.Data[i].PaymentProfileId);
                $('#ns-auto-payment-customer-id-disable').val(paymentProfile.Data[i].CustomerProfileId);
                $('#ns-auto-payment-last-four-disable').val(paymentProfile.Data[i].LastFour);
                $('#ns-auto-payment-payment-profile-class-id-disable').val(paymentProfile.Data[i].PaymentProfileClassId);
                $('#ns-auto-payment-description-disable').val(paymentProfile.Data[i].Description);
                $('#ns-auto-payment-credit-card-type-disable').val(paymentProfile.Data[i].CreditCardTypeId);

                $('#automatic-payment-disable').removeClass('hide');

                break;
              }

              if(ccProfile != null) {
                var dataElements = {
                  "data-customer-profile-id": paymentProfile.Data[i].CustomerProfileId,
                  "data-cc-expiration": paymentProfile.Data[i].ExpirationDate,
                  "data-cc-lastfour": paymentProfile.Data[i].LastFour,
                  "data-payment-profile-class-id": paymentProfile.Data[i].PaymentProfileClassId,
                  "data-cc-description": paymentProfile.Data[i].Description,
                  "data-cc-type-id": paymentProfile.Data[i].CreditCardTypeId
                };

                var optionString = '';
                $.each(dataElements, function(index, value) {
                  optionString += index + '="' + value + '" ';
                });

                $('#ns-auto-payment-method-card').append('<option value="'+paymentProfile.Data[i].PaymentProfileId+'" '+optionString+'>'+paymentProfile.Data[i].Description+' - '+ccProfile.CardNumber+'</option>')
              }

            }
          } else {
            $('#automatic-payment').addClass('hide');
            $('#automatic-payment-add-payment').removeClass('hide');
          }

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_bank_draft_check",
              sessionId: ns_session_id,
              AccountID: sessionStorage.getItem('lumos_login_account_id_current')
            },
            success: function(result) {
              var bankDraft = JSON.parse(result);

              if(bankDraft.length) {
                $('div[data-automatic-exists]').removeClass('hide');
                $('div[data-automatic-setup]').addClass('hide');

                var recurringDate = convertDateForIos(bankDraft[0].StartDate);

                $('span[data-recurring-payment-method]').text('Bank Account ACH Draft')
                $('span[data-recurring-payment-schedule]').text('Paid on Day ' + recurringDate.getDate() + ' of each month.');

                $('#ns-auto-payment-bank-id').val(bankDraft[0].BankAccountID);

                $('#automatic-payment-bank-disable').removeClass('hide');
              }
            }
          });

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_payment_cycle",
              sessionId: ns_session_id,
              accountId: sessionStorage.getItem('lumos_login_account_id_current')
            },
            success: function(result) {
              var paymentCycle = JSON.parse(result);

              if(paymentCycle.Status == 'success') {
                if($('#ns-auto-payment-date').length) {

                  var billingDays = paymentCycle.Data.CardDebitDays;

                  var nowTemp = new Date();
                  var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

                  $('#ns-auto-payment-date').fdatepicker({
                		format: 'mm/dd/yyyy',
                		disableDblClickSelection: true,
                		// leftArrow:'<<',
                		// rightArrow:'>>',
                		closeButton: false,
                    onRender: function (date) {
                      var disabled = 'disabled';
                      if(date.valueOf() > now.valueOf()) {
                        disabled = '';

                        if(billingDays.indexOf(date.getDate()+1) !== -1) {
                          disabled = '';
                        } else {
                          disabled = 'disabled';
                        }
                      }
                      return disabled;
                    }
                	});

                  var cycleDates = {
                    "1": 10,
                    "2": 15,
                    "3": 20,
                    "4": 25,
                    "5": 1,
                    "6": 5
                  };

                  // var nextBill = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), cycleDates[paymentCycle.Data.CycleID], 0, 0, 0, 0);
                  //
                  // if(nextBill.valueOf() < now.valueOf()) {
                  //   nextBill = new Date(nowTemp.getFullYear(), (nowTemp.getMonth()+1), cycleDates[paymentCycle.Data.CycleID], 0, 0, 0, 0);
                  // }
                  //
                  // $('#ns-auto-payment-date-bank').val((nextBill.getMonth()+1)+'/'+nextBill.getDate()+'/'+nextBill.getFullYear());

                  $('#ns-auto-payment-date-bank').fdatepicker({
                    format: 'mm/dd/yyyy',
                    disableDblClickSelection: true,
                    // leftArrow:'<<',
                    // rightArrow:'>>',
                    closeButton: false,
                    onRender: function (date) {
                      var disabled = 'disabled';
                      if(date.valueOf() > now.valueOf()) {
                        disabled = '';

                        // if(billingDays.indexOf(date.getDate()+1) !== -1) {
                        if(cycleDates[paymentCycle.Data.CycleID] == date.getDate()+1) {
                          disabled = '';
                        } else {
                          disabled = 'disabled';
                        }
                      }
                      return disabled;
                    }
                  });

                  $('#ns-auto-payment-bank-cycle-id').val(paymentCycle.Data.CycleID);
                  $('#ns-auto-payment-bank-account-id').val(sessionStorage.getItem('lumos_login_account_id_current'));
                }

                $('div[data-account-section="monthly-payment"]').removeClass('loading');
              }
            }
          });



        }
      });
    }
  }

  function getPaymentMethods() {
    if($('div[data-account-section="payment-method-form"]').length) {
      $('*[data-payment-method-account]').text('for ' + sessionStorage.getItem('lumos_login_account_id_current'));

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_payment_profile",
          sessionId: ns_session_id,
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var paymentProfile = JSON.parse(result);

          if(paymentProfile.Data.length) {

            // New Row Type
            for(var i = 0; i < paymentProfile.Data.length; i++) {
              var bankProfile = paymentProfile.Data[i].BankProfile;
              var ccProfile   = paymentProfile.Data[i].CreditCardProfile;

              if(bankProfile != null) {
                var bankOption = '<tr class="payment-row">';
                bankOption += '<td>Bank Account: ' + paymentProfile.Data[i].Description+' - '+bankProfile.AccountNumber + '</td>';
                bankOption += '<td class="text-right action-buttons">';
                bankOption += '<button class="" data-action="edit" data-profile-id="'+paymentProfile.Data[i].PaymentProfileId+'" data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'" data-nickname="'+paymentProfile.Data[i].Description+'" data-type="bank"><span class="text">Edit</span><i class="fas fa-pen icon"></i></button>';
                bankOption += ' ';
                bankOption += '<button class="" data-action="remove" data-profile-id="'+paymentProfile.Data[i].PaymentProfileId+'" data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'" data-nickname="'+paymentProfile.Data[i].Description+'" data-type="bank" data-open="remove-payment-account"><span class="text">Remove</span><span class="icon remove">×</span></button>';
                bankOption += '</td>';
                bankOption += '</tr>';
                bankOption += '<tr class="payment-form-container" data-edit-container><td colspan="2"></td></tr>';
                $('[data-payment-list-table]').append(bankOption);

                var bankFormOption = '<option value="'+paymentProfile.Data[i].PaymentProfileId+'" data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'" data-nickname="'+paymentProfile.Data[i].Description+'" data-type="bank">Bank Account: '+paymentProfile.Data[i].Description+' - '+bankProfile.AccountNumber+'</option>';
                $('#ns-payment-methods-list-edit').append(bankFormOption);
                $('#ns-payment-methods-list-remove').append(bankFormOption);
              }

              if(ccProfile != null) {
                var ccOption = '<tr class="payment-row">';
                ccOption += '<td>Credit Card: ' + paymentProfile.Data[i].Description+' - '+ccProfile.CardNumber + '</td>';
                ccOption += '<td class="text-right action-buttons">';
                ccOption += '<button class="" data-action="edit" data-profile-id="'+paymentProfile.Data[i].PaymentProfileId+'"data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'" data-nickname="'+paymentProfile.Data[i].Description+'" data-type="cc"><span class="text">Edit</span><i class="fas fa-pen icon"></i></button>';
                ccOption += ' ';
                ccOption += '<button class="" data-action="remove" data-profile-id="'+paymentProfile.Data[i].PaymentProfileId+'"data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'" data-nickname="'+paymentProfile.Data[i].Description+'" data-type="cc" data-open="remove-payment-account"><span class="text">Remove</span><span class="icon remove">×</span></button>';
                ccOption += '</td>';
                ccOption += '</tr>';
                ccOption += '<tr class="payment-form-container" data-edit-container><td colspan="2"></td></tr>';
                $('[data-payment-list-table]').prepend(ccOption);


                var ccFormOption = '<option value="'+paymentProfile.Data[i].PaymentProfileId+'"data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'" data-nickname="'+paymentProfile.Data[i].Description+'" data-type="cc">Credit Card: '+paymentProfile.Data[i].Description+' - '+ccProfile.CardNumber+'</option>';
                $('#ns-payment-methods-list-edit').prepend(ccFormOption);
                $('#ns-payment-methods-list-remove').prepend(ccFormOption);
              }
            }

            $('[data-payment-list-table] button[data-action="edit"]').click(function() {
              // console.log($(this).parents('tr'));
              $('#ns-payment-methods-list-edit').val($(this).attr('data-profile-id'));
              $('#ns-payment-methods-list-edit').trigger('change');
              $(this).parents('tr').next($('[data-edit-container]')).find('td').append($('#edit-method'));
              // $('#edit-method').insertAfter();
              $('#edit-method').removeClass('hide');
            });

            $('[data-payment-list-table] button[data-action="remove"]').click(function() {
              $('#ns-payment-methods-list-remove').val($(this).attr('data-profile-id'));
              $('*[data-delete-payment-account-name]').text($('#ns-payment-methods-list-remove option:selected').text());
              $('#ns-payent-methods-confirm-remove').attr('data-remove-profile-id', $('#ns-payment-methods-list-remove option:selected').val());
            });

            // for(var i = 0; i < paymentProfile.Data.length; i++) {
            //   var bankProfile = paymentProfile.Data[i].BankProfile;
            //   var ccProfile   = paymentProfile.Data[i].CreditCardProfile;
            //
            //   if(bankProfile != null) {
            //     var bankOption = '<option value="'+paymentProfile.Data[i].PaymentProfileId+'" data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'" data-nickname="'+paymentProfile.Data[i].Description+'" data-type="bank">Bank Account: '+paymentProfile.Data[i].Description+' - '+bankProfile.AccountNumber+'</option>';
            //     $('#ns-payment-methods-list-edit').append(bankOption);
            //     $('#ns-payment-methods-list-remove').append(bankOption);
            //   }
            //
            //   if(ccProfile != null) {
            //     var ccOption = '<option value="'+paymentProfile.Data[i].PaymentProfileId+'"data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'" data-nickname="'+paymentProfile.Data[i].Description+'" data-type="cc">Credit Card: '+paymentProfile.Data[i].Description+' - '+ccProfile.CardNumber+'</option>';
            //     $('#ns-payment-methods-list-edit').prepend(ccOption);
            //     $('#ns-payment-methods-list-remove').prepend(ccOption);
            //   }
            // }

            $('#ns-payment-methods-list-edit').change(function() {
              $('#ns-payment-methods-nickname-edit').val($(this).find('option:selected').attr('data-nickname'));
              if($(this).find('option:selected').attr('data-type') == 'cc') {
                $('div[data-edit-credit-card]').removeClass('hide');
                $('div[data-edit-credit-card] input, div[data-edit-credit-card] select').each(function() {
                  if($(this)[0].hasAttribute('data-required')) {
                    $(this).prop('required', true);
                  }
                });
              } else {
                $('div[data-edit-credit-card]').addClass('hide');
                $('div[data-edit-credit-card] input, div[data-edit-credit-card] select').each(function() {
                  if($(this)[0].hasAttribute('data-required')) {
                    $(this).prop('required', false);
                  }
                });
              }
            });

            $("#ns-payment-methods-list-edit").val($("#ns-payment-methods-list-edit option:first").val());
            $('#ns-payment-methods-list-remove').val($("#ns-payment-methods-list-remove option:first").val());
            $('#ns-payment-methods-list-edit').change();

            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "ns_account",
                sessionId: ns_session_id,
                accountId: sessionStorage.getItem('lumos_login_account_id_current')
              },
              success: function(result) {
                var accountData = JSON.parse(result);

                if(accountData.Status == 'success') {
                  $('#ns-add-bank-full-name').val(accountData.Data.Name);
                  $('#ns-add-cc-full-name').val(accountData.Data.Name);

                  for(var b = 0; b < accountData.Data.Contacts.length; b++) {
                    if(accountData.Data.Contacts[b].Type == "Billing") {
                      $('#ns-add-bank-account-zip').val(accountData.Data.Contacts[b].AddressZip);
                      $('#ns-add-cc-account-zip').val(accountData.Data.Contacts[b].AddressZip);

                      $('#ns-add-bank-account-phonenumber').val(accountData.Data.Contacts[b].Phone);
                      $('#ns-add-cc-account-phonenumber').val(accountData.Data.Contacts[b].Phone);

                      $.ajax({
                        url: '/wp-content/themes/Lumos/api_helper.php',
                        method: 'POST',
                        contentType: 'application/x-www-form-urlencoded',
                        data: {
                          action: "ns_get_street",
                          streetId: accountData.Data.Contacts[b].AddressStreetID
                        },
                        success: function(result) {
                          console.log(result);
                          var cityData = JSON.parse(result);

                          if(cityData.Status === 'success') {
                            $('#ns-add-bank-account-address').val(accountData.Data.Contacts[b].AddressHouseNum + " " + cityData.Data.Street.trim() + " " + cityData.Data.StreetSuffix.trim());
                            $('#ns-add-cc-account-address').val(accountData.Data.Contacts[b].AddressHouseNum + " " + cityData.Data.Street.trim() + " " + cityData.Data.StreetSuffix.trim());
                          }
                        }
                      });

                      $.ajax({
                        url: '/wp-content/themes/Lumos/api_helper.php',
                        method: 'POST',
                        contentType: 'application/x-www-form-urlencoded',
                        data: {
                          action: "ns_get_city",
                          cityId: accountData.Data.Contacts[b].AddressCityID
                        },
                        success: function(result) {
                          var cityData = JSON.parse(result);

                          if(cityData.Status === 'success') {
                            $('#ns-add-bank-account-city').val(cityData.Data.City.trim());
                            $('#ns-add-cc-account-city').val(cityData.Data.City.trim());
                          }
                        }
                      });

                      $.ajax({
                        url: '/wp-content/themes/Lumos/api_helper.php',
                        method: 'POST',
                        contentType: 'application/x-www-form-urlencoded',
                        data: {
                          action: "ns_get_state",
                          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
                          stateId: accountData.Data.Contacts[b].AddressStateID
                        },
                        success: function(result) {
                          var stateData = JSON.parse(result);

                          $('#ns-add-bank-account-state').val(stateData.Data[0].StateAbbreviation.trim());
                          $('#ns-add-cc-account-state').val(stateData.Data[0].StateAbbreviation.trim());
                        }
                      });

                      break;
                    }
                  }
                }
              }
            });


          } else {
            $('#edit-method-wrapper').addClass('hide');
            $('#remove-method-wrapper').addClass('hide');
          }

          $('#ns-add-bank-account-routingnumber').attr('data-account', sessionStorage.getItem('lumos_login_account_id_current'));

          $('div[data-account-section="payment-method-form"]').removeClass('loading');
        }
      });
    }
  }

  function getPaymentInfo() {

    if($('div[data-account-section="promise-summary"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_promise_to_pay_date",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var billingSummary = JSON.parse(result);

          if($('div[data-account-screen="promise-form"]').length) {
            if(billingSummary.Data.PastDueBalance > 0) {
              $('span[data-promise-amount]').text(billingSummary.Data.PastDueBalance.toFixed(2));
              $('#promise-amount').val(billingSummary.Data.PastDueBalance.toFixed(2));
            } else if(billingSummary.Data.AmountDue != null) {
              $('span[data-promise-amount]').text(billingSummary.Data.AmountDue.toFixed(2));
              $('#promise-amount').val(billingSummary.Data.AmountDue.toFixed(2));
            } else {
              $('span[data-promise-amount]').text('0.00');
              $('#promise-amount').val(0);
            }

            if(billingSummary.Data.PTPDate != null) {
              $('span[data-promise-before]').text(formatDate(billingSummary.Data.PTPDate));
              $('#promise-payment-date').val(billingSummary.Data.PTPDate);
            } else {
              $('#promise-to-pay').addClass('hide');
              $('#no-promise-to-pay').removeClass('hide');
            }
          }

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_get_promise_to_pay",
              sessionId: ns_session_id,
              // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
              accountId: sessionStorage.getItem('lumos_login_account_id_current')
            },
            success: function(result) {
              var ptpSummary = JSON.parse(result);

              if(ptpSummary.Data.length) {
                for(var p = 0; p < ptpSummary.Data.length; p++) {
                  var promiseDate = convertDateForIos(ptpSummary.Data[p].PromiseDate);
                  var todaysDate  = new Date();
                  todaysDate.setHours(0,0,0,0);
                  if(todaysDate <= promiseDate) {
                    $('[data-account-screen="promise-form-locked"] [data-p2p-amount]').text('$'+ptpSummary.Data[p].PromiseAmount);
                    $('[data-account-screen="promise-form-locked"] [data-p2p-date]').text(formatDate(ptpSummary.Data[p].PromiseDate));
                    $('[data-account-screen="promise-form-locked"] [data-p2p-confirmation]').text(ptpSummary.Data[p].PromiseToPayID);

                    $('[data-account-screen="promise-form-locked"]').removeClass('hide');
                    $('[data-account-screen="promise-form"]').addClass('hide');
                    break;
                  }
                }
              }

              $('div[data-account-section="promise-summary"]').removeClass('loading');
            },
            error: function() {
              $('div[data-account-section="promise-summary"]').removeClass('loading');
            }
          });



        }
      });
    }

    if($('div[data-account-section="payment-summary"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_billing_summary",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var billingSummary = JSON.parse(result);

          var monthArray = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ];

          $('#new-bank-routingnumber').attr('data-account', sessionStorage.getItem('lumos_login_account_id_current'));

          if(billingSummary.Data.DueDate != null) {
            var dueDate = convertDateForIos(billingSummary.Data.DueDate);
            $('#ns-billing-due-on').text(formatDate(dueDate));
            $('#due-calendar-graphic *[data-month]').text(monthArray[dueDate.getMonth()]);
            $('#due-calendar-graphic *[data-date]').text(dueDate.getDate());
            $('#due-calendar-graphic *[data-year]').text(dueDate.getFullYear());
          } else {
            $('#ns-billing-due-on').text('no payment due');
            $('#due-calendar-graphic').addClass('hide');
          }

          if(billingSummary.Data.AmountDue != null) {
            $('#ns-billing-amount-due').text(billingSummary.Data.AmountDue.toFixed(2));
            $('span[data-total-due]').text(billingSummary.Data.AmountDue.toFixed(2));
            $('#totaldue-amount').val(billingSummary.Data.AmountDue);
          } else {
            $('#ns-billing-amount-due').text('0.00');
            $('span[data-total-due]').text('0.00');
            $('#totaldue-amount').val(0);
          }

          if(billingSummary.Data.PastDueBalance > 0) {
            $('div[data-past-due-notice]').removeClass('hide');
            var finalDueDate = convertDateForIos(billingSummary.Data.FinalDueDate);
            $('#ns-billing-pastdue-on').text(formatDate(finalDueDate));
            $('#ns-billing-pastdue-on-2').text(formatDate(finalDueDate));
            $('#ns-billing-amount-pastdue').text(billingSummary.Data.PastDueBalance.toFixed(2))
          }

          if(billingSummary.Data.PastDueBalance > 0) {
            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "ns_get_promise_to_pay",
                sessionId: ns_session_id,
                // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
                accountId: sessionStorage.getItem('lumos_login_account_id_current')
              },
              success: function(result) {
                var ptpSummary = JSON.parse(result);

                if(ptpSummary.Data.length) {
                  for(var p = 0; p < ptpSummary.Data.length; p++) {
                    var promiseDate = convertDateForIos(ptpSummary.Data[p].PromiseDate);
                    var todaysDate  = new Date();
                    todaysDate.setHours(0,0,0,0);
                    if(todaysDate <= promiseDate) {
                      $('[data-past-due-notice]').append('<div class="promise-to-pay-message"><strong>Note:</strong> You made a Promise to Pay for <strong>$'+ptpSummary.Data[p].PromiseAmount+'</strong> due on or before <strong>'+formatDate(ptpSummary.Data[p].PromiseDate)+'</strong>. <br /><strong>Confirmation #: '+ptpSummary.Data[p].PromiseToPayID+'</strong> </div>');
                    }
                  }
                }

                $('div[data-account-section="payment-summary"]').removeClass('loading');
              }
            });
          } else {
            $('div[data-account-section="payment-summary"]').removeClass('loading');
          }

          if($('div[data-account-screen="payment-form"]').length) {
            $.ajax({
              url: '/wp-content/themes/Lumos/api_helper.php',
              method: 'POST',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                action: "ns_payment_profile",
                sessionId: ns_session_id,
                accountId: sessionStorage.getItem('lumos_login_account_id_current')
              },
              success: function(result) {
                var paymentProfile = JSON.parse(result);

                if(paymentProfile.Data.length) {
                  for(var i = 0; i < paymentProfile.Data.length; i++) {
                    var bankProfile = paymentProfile.Data[i].BankProfile;
                    var ccProfile   = paymentProfile.Data[i].CreditCardProfile;

                    if(paymentProfile.Data[i].Recurring) {
                      var recurringDate = convertDateForIos(paymentProfile.Data[i].RecurringStartDate);

                      var autopaymentText = paymentProfile.Data[i].Description + ' -  Credit Card ending in ' + paymentProfile.Data[i].LastFour;
                      var autopaymentDate = 'Paid on Day ' + recurringDate.getDate() + ' of each month.';

                      $('*[data-automatic-not-enrolled]').addClass('hide');
                      $('span[data-automatic-payment-info]').html(autopaymentText + '<br>' + autopaymentDate);
                      $('*[data-automatic-enrolled]').removeClass('hide');

                      $('#automaticpayment').attr('data-hide-submit', 'true');
                    }

                    if(bankProfile != null) {
                      $('#bankname').append('<option value="'+paymentProfile.Data[i].PaymentProfileId+'" data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'">'+paymentProfile.Data[i].Description+' - '+bankProfile.AccountNumber+'</option>')
                    }

                    if(ccProfile != null) {
                      $('#cardname').append('<option value="'+paymentProfile.Data[i].PaymentProfileId+'"data-customer-profile-id="'+paymentProfile.Data[i].CustomerProfileId+'">'+paymentProfile.Data[i].Description+' - '+ccProfile.CardNumber+'</option>')
                    }

                  }

                  // Auto select saved payment method
                  // if($('#bankname option').length > 1) {
                  //   $('#bankaccount').trigger('click');
                  //   $("#bankname option:eq(1)").attr("selected", "selected");
                  // } else if($('#cardname option').length > 1) {
                  //   $('#creditcardaccount').trigger('click');
                  //   $("#cardname option:eq(1)").attr("selected", "selected");
                  // }
                }

                // bank_draft
                $.ajax({
                  url: '/wp-content/themes/Lumos/api_helper.php',
                  method: 'POST',
                  contentType: 'application/x-www-form-urlencoded',
                  data: {
                    action: "ns_bank_draft_check",
                    sessionId: ns_session_id,
                    AccountID: sessionStorage.getItem('lumos_login_account_id_current')
                  },
                  success: function(result) {
                    console.log(result);
                    var bankDraft = JSON.parse(result);

                    if(bankDraft.length) {
                      var recurringDate = convertDateForIos(bankDraft[0].StartDate);

                      var autopaymentText = "Bank Account ACH Draft";
                      var autopaymentDate = 'Paid on Day ' + recurringDate.getDate() + ' of each month.';

                      $('*[data-automatic-not-enrolled]').addClass('hide');
                      $('span[data-automatic-payment-info]').html(autopaymentText + '<br>' + autopaymentDate);
                      $('*[data-automatic-enrolled]').removeClass('hide');

                      $('#automaticpayment').attr('data-hide-submit', 'true');

                    }
                  }
                });

                $.ajax({
                  url: '/wp-content/themes/Lumos/api_helper.php',
                  method: 'POST',
                  contentType: 'application/x-www-form-urlencoded',
                  data: {
                    action: "ns_payment_cycle",
                    sessionId: ns_session_id,
                    accountId: sessionStorage.getItem('lumos_login_account_id_current')
                  },
                  success: function(result) {
                    console.log(result);
                    var paymentCycle = JSON.parse(result);

                    if(paymentCycle.Status == 'success') {
                      if($('#ns-auto-payment-date').length) {

                        var billingDays = paymentCycle.Data.CardDebitDays;

                        var nowTemp = new Date();
                        var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

                        $('#ns-auto-payment-date').fdatepicker({
                          format: 'mm/dd/yyyy',
                          disableDblClickSelection: true,
                          // leftArrow:'<<',
                          // rightArrow:'>>',
                          closeButton: false,
                          onRender: function (date) {
                            var disabled = 'disabled';
                            if(date.valueOf() > now.valueOf()) {
                              disabled = '';

                              if(billingDays.indexOf(date.getDate()+1) !== -1) {
                                disabled = '';
                              } else {
                                disabled = 'disabled';
                              }
                            }
                            return disabled;
                          }
                        });
                      }
                    }
                  }
                });

                $.ajax({
                  url: '/wp-content/themes/Lumos/api_helper.php',
                  method: 'POST',
                  contentType: 'application/x-www-form-urlencoded',
                  data: {
                    action: "ns_account",
                    sessionId: ns_session_id,
                    accountId: sessionStorage.getItem('lumos_login_account_id_current')
                  },
                  success: function(result) {
                    // console.log(result);
                    var accountData = JSON.parse(result);

                    if(accountData.Status == 'success') {
                      $('#new-bank-full-name').val(accountData.Data.Name);
                      $('#new-cc-full-name').val(accountData.Data.Name);

                      for(var b = 0; b < accountData.Data.Contacts.length; b++) {
                        if(accountData.Data.Contacts[b].Type == "Billing") {
                          $('#new-bank-address').val(accountData.Data.Contacts[b].AddressHouseNum);
                          $('#new-cc-address').val(accountData.Data.Contacts[b].AddressHouseNum);

                          $('#new-bank-zip').val(accountData.Data.Contacts[b].AddressZip);
                          $('#new-cc-zip').val(accountData.Data.Contacts[b].AddressZip);

                          // $('#new-bank-phonenumber').val(accountData.Data.Contacts[b].Phone);
                          // $('#new-cc-phonenumber').val(accountData.Data.Contacts[b].Phone);

                          $.ajax({
                            url: '/wp-content/themes/Lumos/api_helper.php',
                            method: 'POST',
                            contentType: 'application/x-www-form-urlencoded',
                            data: {
                              action: "ns_get_street",
                              streetId: accountData.Data.Contacts[b].AddressStreetID
                            },
                            success: function(result) {
                              // console.log(result);
                              var cityData = JSON.parse(result);

                              if(cityData.Status === 'success') {
                                $('#new-bank-address').val(accountData.Data.Contacts[b].AddressHouseNum + " " + cityData.Data.Street.trim() + " " + cityData.Data.StreetSuffix.trim());
                                $('#new-cc-address').val(accountData.Data.Contacts[b].AddressHouseNum + " " + cityData.Data.Street.trim() + " " + cityData.Data.StreetSuffix.trim());
                              }
                            }
                          });

                          $.ajax({
                            url: '/wp-content/themes/Lumos/api_helper.php',
                            method: 'POST',
                            contentType: 'application/x-www-form-urlencoded',
                            data: {
                              action: "ns_get_city",
                              cityId: accountData.Data.Contacts[b].AddressCityID
                            },
                            success: function(result) {
                              var cityData = JSON.parse(result);

                              if(cityData.Status === 'success') {
                                $('#new-bank-city').val(cityData.Data.City.trim());
                                $('#new-cc-city').val(cityData.Data.City.trim());
                              }
                            }
                          });

                          $.ajax({
                            url: '/wp-content/themes/Lumos/api_helper.php',
                            method: 'POST',
                            contentType: 'application/x-www-form-urlencoded',
                            data: {
                              action: "ns_get_state",
                              // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
                              stateId: accountData.Data.Contacts[b].AddressStateID
                            },
                            success: function(result) {
                              // console.log(result);
                              var stateData = JSON.parse(result);

                              // console.log(stateData.Data);

                              $('#new-bank-state').val(stateData.Data.StateAbbreviation.trim());
                              $('#new-cc-state').val(stateData.Data.StateAbbreviation.trim());
                            }
                          });

                          break;
                        }
                      }

                    }

                    $('div[data-account-section="payment-information"]').removeClass('loading');

                  }
                });

              }
            });
          }
        }
      });
    }
  }

  function getAssociatedAccounts() {
    if($('div[data-account-section="associated-accounts"]').length) {

      var accountIDs = JSON.parse(sessionStorage.getItem('lumos_login_account_id_group')),
          accountList = [];

      for(var i = 0; i < accountIDs.length; i++) {
        accountList.push(accountIDs[i].accountId);
      }

      $('#add-assoc-account-user-id').val(ns_account_session.Data.UserId);

      console.log(ns_account_session.Data);

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_username_delegates",
          userId: ns_account_session.Data.UserId,
          sessionId: ns_session_id
        },
        success: function(result) {
          var userInfo = JSON.parse(result);

          // console.log(accountInfo);

          var accountList = userInfo.Data;

          for(var j = 0; j < accountList.length; j++) {
            var $accTemplate = $('[data-associated-account-template]').clone();
            var accUsernameID = accountList[j].Id;

            $accTemplate.removeAttr('data-associated-account-template');
            $accTemplate.removeClass('hide');

            $accTemplate.find('form').attr('id', 'acct-edit-'+accUsernameID);

            $accTemplate.find('.acct-edit-toggle').attr('data-accordion-target', 'acct-edit-panel-'+accUsernameID);
            $accTemplate.find('.acc-remove').attr('data-delegate-id', accUsernameID);
            $accTemplate.find('.acc-remove').attr('data-delegate-account-id', accountList[j].AccountId);

            $accTemplate.find('.close-btn').attr('data-accordion-target', 'acct-edit-panel-'+accUsernameID);

            $accTemplate.find('[data-accordion]').attr('id', 'acct-edit-panel-'+accUsernameID);

            $accTemplate.find('[data-account-id]').text(accountList[j].AccountId);
            $accTemplate.find('[data-account-name]').text(accountList[j].Name);

            $accTemplate.find('#account-nickname').val(accountList[j].Name);

            $accTemplate.find('#account-nickname').attr('id', 'account-nickname-'+accUsernameID);
            $accTemplate.find('label[for="account-nickname"]').attr('for', 'account-nickname-'+accUsernameID);
            $accTemplate.find('#account-nickname').attr('aria-errormessage', 'account-nickname-error-'+accUsernameID);
            $accTemplate.find('#account-nickname-error').attr('id', 'account-nickname-error-'+accUsernameID);

            if(accountList[j].Default) {
              $accTemplate.find('#display-default-y').prop('checked', true);
            } else {
              $accTemplate.find('#display-default-n').prop('checked', true);
            }

            $accTemplate.find('#display-default-y').attr('id', 'display-default-y-'+accUsernameID);
            $accTemplate.find('label[for="display-default-y"]').attr('for', 'display-default-y-'+accUsernameID);

            $accTemplate.find('#display-default-n').attr('id', 'display-default-n-'+accUsernameID);
            $accTemplate.find('label[for="display-default-n"]').attr('for', 'display-default-n-'+accUsernameID);

            $accTemplate.find('input[name="Id"]').val(accountList[j].Id);
            $accTemplate.find('input[name="UserId"]').val(accountList[j].UserId);
            $accTemplate.find('input[name="AccountId"]').val(accountList[j].AccountId);

            $('[data-accounts-body]').append($accTemplate);

            $accTemplate.find('.acct-edit-toggle').each(function(index, element) {
              $(element).click(function() {
                var target = $('#' + $(element).attr('data-accordion-target')).find('.accordion-content');
                var closebtn = $(element).siblings('.close-btn');
                if (($(target).attr('aria-hidden') === 'true')) {
                  $(target).attr('aria-hidden', 'false').slideToggle(250);
                  $(element).hide();
                  $(element).siblings('.action').hide();
                  $(closebtn).show();
                }
              });
            });

            $accTemplate.find('.close-btn').each(function(index, element) {
              $(element).click(function() {
                var target = $('#' + $(element).attr('data-accordion-target')).find('.accordion-content');
                if (($(target).attr('aria-hidden') === 'false') || ($(target).attr('aria-hidden') === 'undefined')) {
                  $(target).attr('aria-hidden', 'true').slideToggle(250);
                  $(element).siblings('.action').show();
                  $(element).hide();
                }
              });
            });

            $accTemplate.find('.acc-remove').click(function() {
              var delegate_id = $(this).attr('data-delegate-id'),
                  delegate_account_id = $(this).attr('data-delegate-account-id');

              $('#user-delegate-id').val(delegate_id);
              $('[data-delegate-delete-account-id]').text(delegate_account_id);
            });

            var editForm = new Foundation.Abide($('form#acct-edit-'+accUsernameID));

            // Edit Associated Account
            $('form#acct-edit-'+accUsernameID+' input[type="submit"]').click(function(e) {
              e.preventDefault();
              // console.log($(this).parents('form'));
              $(this).parents('form').foundation("validateForm");
            });

            $('form#acct-edit-'+accUsernameID).bind("formvalid.zf.abide", function(e, target) {

              $('.full-loader').removeClass('hide');

              var formData = $(this).serializeArray();
              var formDataObj = {};
              $(formData).each(function(index, obj){
                formDataObj[obj.name] = obj.value;
              });
              formDataObj.action = 'ns_edit_username_delegates';
              formDataObj.sessionId = ns_session_id;
              // console.log(formDataObj);

              var $currentForm = $(this);

              $.ajax({
                url: '/wp-content/themes/Lumos/api_helper.php',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: formDataObj,
                success: function(result) {
                  var delegateInfo = JSON.parse(result);

                  if(delegateInfo.Status == 'success') {
                    // console.log(delegateInfo);
                    localStorage.removeItem('ns_login_account_id_group');
                    window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_accounts_e'});
                  } else {
                    $currentForm.find('div[data-abide-error]').show();
                    $('.full-loader').addClass('hide');
                  }

                }
              });

            });
          }

          Foundation.reInit('reveal');

          $('[data-associated-acounts-header]').text($('[data-associated-acounts-header]').text() + ' for Primary Account #' + ns_username.Data.AccountID);
          $('div[data-account-section="associated-accounts"]').removeClass('loading');

        }
      });
    }
  } // end getAssociatedAccounts()

  function getEmailAccounts() {
    if($('div[data-account-section="email-accounts"]').length) {

      $('form[data-add-email-account] input[name="AccountID"]').val(sessionStorage.getItem('lumos_login_account_id_current'));

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_email_addresses",
          accountId: sessionStorage.getItem('lumos_login_account_id_current'),
          sessionId: ns_session_id
        },
        success: function(result) {
          var emailInfo = JSON.parse(result);

          // console.log(accountInfo);

          var emailList = emailInfo.Data;

          for(var j = 0; j < emailList.length; j++) {
            var $emailTemplate = $('[data-email-account-template]').clone();
            var emailID = emailList[j].EmailID;

            $emailTemplate.removeAttr('data-email-account-template');
            $emailTemplate.removeClass('hide');

            $emailTemplate.find('form').attr('id', 'email-edit-'+emailID);

            $emailTemplate.find('[name="EmailID"]').val(emailID);

            $emailTemplate.find('.acct-edit-toggle').attr('data-accordion-target', 'email-edit-panel-'+emailID);
            $emailTemplate.find('.acc-remove').attr('data-email-id', emailID);
            $emailTemplate.find('.acc-remove').attr('data-email', emailList[j].Email);

            $emailTemplate.find('.close-btn').attr('data-accordion-target', 'email-edit-panel-'+emailID);

            $emailTemplate.find('[data-accordion]').attr('id', 'email-edit-panel-'+emailID);

            $emailTemplate.find('[data-email-address]').text(emailList[j].Email);
            $emailTemplate.find('[data-email-name]').text(emailList[j].Name);
            $emailTemplate.find('[data-email-quota]').text(emailList[j].Quota);
            $emailTemplate.find('[data-email-status]').text(emailList[j].Status);

            $emailTemplate.find('#email-address-0').val(emailList[j].Email.split('@')[0]);
            $emailTemplate.find('#email-address-0').attr('id', 'email-address-'+emailID);
            $emailTemplate.find('label[for="email-address-0"]').attr('for', 'email-address-'+emailID);

            $emailTemplate.find('#email-password-change-n-0').attr('data-email-id', emailID);
            $emailTemplate.find('#email-password-change-n-0').attr('id', 'email-password-change-n-'+emailID);
            $emailTemplate.find('label[for="email-password-change-n-0"]').attr('for', 'email-password-change-n-'+emailID);
            $emailTemplate.find('#email-password-change-y-0').attr('data-email-id', emailID);
            $emailTemplate.find('#email-password-change-y-0').attr('id', 'email-password-change-y-'+emailID);
            $emailTemplate.find('label[for="email-password-change-y-0"]').attr('for', 'email-password-change-y-'+emailID);

            $emailTemplate.find('#email-password-new-0').attr('aria-errormessage', 'email-password-hint-'+emailID);
            $emailTemplate.find('#email-password-new-0').attr('id', 'email-password-new-'+emailID);
            $emailTemplate.find('#email-password-hint-0').attr('id', 'email-password-hint-'+emailID);

            $emailTemplate.find('#email-password-new-retype-0').attr('aria-errormessage', 'email-password-retype-hint-'+emailID);
            $emailTemplate.find('#email-password-new-retype-0').attr('data-equalto', 'email-password-new-'+emailID);
            $emailTemplate.find('#email-password-new-retype-0').attr('id', 'email-password-new-retype-'+emailID);
            $emailTemplate.find('#email-password-retype-hint-0').attr('id', 'email-password-retype-hint-'+emailID);

            $emailTemplate.find('#email-address-domain-0').val('@'+emailList[j].Email.split('@')[1]);
            $emailTemplate.find('#email-address-domain-0').attr('id', 'email-address-domain-'+emailID);

            $emailTemplate.find('#email-name-0').val(emailList[j].Name);
            $emailTemplate.find('#email-name-0').attr('id', 'email-name-'+emailID);
            $emailTemplate.find('label[for="email-name-0"]').attr('for', 'email-name-'+emailID);

            $emailTemplate.find('#email-aliases-0').val(emailList[j].Aliases);
            $emailTemplate.find('#email-aliases-0').attr('id', 'email-aliases-'+emailID);
            $emailTemplate.find('label[for="email-aliases-0"]').attr('for', 'email-aliases-'+emailID);

            $emailTemplate.find('#email-forwards-0').val(emailList[j].Forward);
            $emailTemplate.find('#email-forwards-0').attr('id', 'email-forwards-'+emailID);
            $emailTemplate.find('label[for="email-forwards-0"]').attr('for', 'email-forwards-'+emailID);

            var endDate = Date.parse(emailList[j].VacationMessageStop),
                todaysDate = new Date(),
                unexpiredMessage = false;

            if(endDate > todaysDate) {
              unexpiredMessage = true
            }



            if((emailList[j].VacationMessageActive !== null && emailList[j].VacationMessageActive === 1) ||
               unexpiredMessage) {
              $emailTemplate.find('#email-vacation-y-0').prop('checked', true);
              $emailTemplate.find('[data-away-message-settings]').show();
              $emailTemplate.find('#email-vacation-start-0').prop('required', true);
              $emailTemplate.find('#email-vacation-stop-0').prop('required', true);
              $emailTemplate.find('#email-vacation-message-0').prop('required', true);
            } else {
              $emailTemplate.find('#email-vacation-n-0').prop('checked', true);
              $emailTemplate.find('[data-away-message-settings]').hide();
              $emailTemplate.find('#email-vacation-start-0').prop('required', false);
              $emailTemplate.find('#email-vacation-stop-0').prop('required', false);
              $emailTemplate.find('#email-vacation-message-0').prop('required',false);
            }

            $emailTemplate.find('#email-vacation-n-0').attr('id', 'email-vacation-n-'+emailID);
            $emailTemplate.find('label[for="email-vacation-n-0"]').attr('for', 'email-vacation-n-'+emailID);

            $emailTemplate.find('#email-vacation-y-0').attr('id', 'email-vacation-y-'+emailID);
            $emailTemplate.find('label[for="email-vacation-y-0"]').attr('for', 'email-vacation-y-'+emailID);



            $emailTemplate.find('[name="VacationMessageActive"]').change(function(){
              var $parentContainer = $(this).parents('[data-parent-email-container]');

              console.log($(this).parents('fieldset').find('[type="radio"]:checked').val());
              console.log($parentContainer.find('[data-away-message-settings]'));

              if($(this).parents('fieldset').find('[type="radio"]:checked').val() === '1') {
                $parentContainer.find('[data-away-message-settings]').show();
                $parentContainer.find('input[name="VacationMessageStart"]').prop('required', true);
                $parentContainer.find('input[name="VacationMessageStop"]').prop('required', true);
                $parentContainer.find('textarea[name="VacationMessage"]').prop('required', true);
              } else {
                $parentContainer.find('[data-away-message-settings]').hide();
                $parentContainer.find('input[name="VacationMessageStart"]').prop('required', false);
                $parentContainer.find('input[name="VacationMessageStop"]').prop('required', false);
                $parentContainer.find('textarea[name="VacationMessage"]').prop('required', false);
              }
            });



            $emailTemplate.find('#email-vacation-start-0').fdatepicker({
          		format: 'yyyy-mm-dd hh:ii:ss',
          		disableDblClickSelection: true,
          		closeButton: false,
              pickTime: true,
              use12Hour: true
          	});

            $emailTemplate.find('#email-vacation-stop-0').fdatepicker({
          		format: 'yyyy-mm-dd hh:ii:ss',
          		disableDblClickSelection: true,
          		closeButton: false,
              pickTime: true,
              use12Hour: true
          	});

            console.log(emailList[j]);

            if(emailList[j].VacationMessageStart == null || emailList[j].VacationMessageStop == null) {
              // $emailTemplate.find('#email-vacation-start-0').prop('disabled', true);
              // $emailTemplate.find('#email-vacation-stop-0').prop('disabled', true);
            } else {
              // $emailTemplate.find('#email-vacation-date-on-0').prop('checked', true);
              $emailTemplate.find('#email-vacation-start-0').val(emailList[j].VacationMessageStart);
              $emailTemplate.find('#email-vacation-start-0').prop('disabled', true);
              $emailTemplate.find('#email-vacation-stop-0').val(emailList[j].VacationMessageStop)
              $emailTemplate.find('#email-vacation-stop-0').prop('disabled', true);
              $emailTemplate.find('#email-vacation-message-0').prop('disabled', true);
            }

            $emailTemplate.find('#email-vacation-start-0').attr('id', 'email-vacation-start-'+emailID);
            $emailTemplate.find('label[for="email-vacation-start-0"]').attr('for', 'email-vacation-start-'+emailID);
            $emailTemplate.find('#email-vacation-stop-0').attr('id', 'email-vacation-stop-'+emailID);
            $emailTemplate.find('label[for="email-vacation-stop-0"]').attr('for', 'email-vacation-stop-'+emailID);

            // $emailTemplate.find('#email-vacation-date-on-0').attr('data-email-id', emailID);
            // $emailTemplate.find('#email-vacation-date-on-0').attr('id', 'email-vacation-date-on-'+emailID);

            $emailTemplate.find('#email-vacation-message-0').val(emailList[j].VacationMessage);
            $emailTemplate.find('#email-vacation-message-0').attr('id', 'email-vacation-message-'+emailID);
            $emailTemplate.find('label[for="email-vacation-message-0"]').attr('for', 'email-vacation-message-'+emailID);

            $emailTemplate.find('#email-vacation-date-on-'+emailID).click(function() {

              var eID = $(this).attr('data-email-id');
              var checked = $(this).prop('checked');
              var $parentContainer = $(this).parents('[data-parent-email-container]');

              console.log(checked);
              console.log($parentContainer.find('#email-vacation-start-'+eID));
              console.log($parentContainer.find('#email-vacation-stop-'+eID));

              if(checked) {
                $parentContainer.find('#email-vacation-start-'+eID).prop('disabled', false);
                $parentContainer.find('#email-vacation-stop-'+eID).prop('disabled', false);
              } else {
                $parentContainer.find('#email-vacation-start-'+eID).prop('disabled', true);
                $parentContainer.find('#email-vacation-start-'+eID).val('');
                $parentContainer.find('#email-vacation-stop-'+eID).prop('disabled', true);
                $parentContainer.find('#email-vacation-stop-'+eID).val('');
              }
            });



            if(emailList[j].ForwardKeepCopy == 0) {
              $emailTemplate.find('#email-forward-n-0').prop('checked', true);
            } else {
              $emailTemplate.find('#email-forward-y-0').prop('checked', true);
            }

            $emailTemplate.find('#email-forward-n-0').attr('id', 'email-forward-n-'+emailID);
            $emailTemplate.find('label[for="email-forward-n-0"]').attr('for', 'email-forward-n-'+emailID);
            $emailTemplate.find('#email-forward-y-0').attr('id', 'email-forward-y-'+emailID);
            $emailTemplate.find('label[for="email-forward-y-0"]').attr('for', 'email-forward-y-'+emailID);

            $('[data-emails-body]').append($emailTemplate);

            $emailTemplate.find('.acct-edit-toggle').each(function(index, element) {
              $(element).click(function() {
                var target = $('#' + $(element).attr('data-accordion-target')).find('.accordion-content');
                var closebtn = $(element).siblings('.close-btn');
                if (($(target).attr('aria-hidden') === 'true')) {
                  $(target).attr('aria-hidden', 'false').slideToggle(250);
                  $(element).hide();
                  $(element).siblings('.action').hide();
                  $(closebtn).show();
                }
              });
            });

            $emailTemplate.find('.close-btn').each(function(index, element) {
              $(element).click(function() {
                var target = $('#' + $(element).attr('data-accordion-target')).find('.accordion-content');
                if (($(target).attr('aria-hidden') === 'false') || ($(target).attr('aria-hidden') === 'undefined')) {
                  $(target).attr('aria-hidden', 'true').slideToggle(250);
                  $(element).siblings('.action').show();
                  $(element).hide();
                }
              });
            });

            $emailTemplate.find('.acc-remove').click(function() {
              var email_id = $(this).attr('data-email-id'),
                  email_account = $(this).attr('data-email');

              $('#email-remove-id').val(email_id);
              $('[data-email-account]').text(email_account);
            });

            var editForm = new Foundation.Abide($('form#email-edit-'+emailID));

            $emailTemplate.find('[data-new-password]').attr('data-email-id', emailID);

            // Email Password change options
            $emailTemplate.find('input[name="email-password-change"]').change(function() {
              // console.log($emailTemplate.find('[data-new-password]'));

              var e_id = $(this).attr('data-email-id');

              if($(this).val() == '0') {
                console.log('zero', e_id);
                $('#email-password-new-'+e_id).prop('disabled', true);
                $('#email-password-new-retype-'+e_id).prop('disabled', true);
                $('#email-password-new-'+e_id).val('');
                $('#email-password-new-retype-'+e_id).val('');

                $(this).parents('form').find('[data-new-password][data-email-id="'+e_id+'"]').addClass('hide');
              } else {
                console.log('one', e_id);
                $('#email-password-new-'+e_id).prop('disabled', false);
                $('#email-password-new-retype-'+e_id).prop('disabled', false);

                $(this).parents('form').find('[data-new-password][data-email-id="'+e_id+'"]').removeClass('hide');
              }
            });

            // Edit Email Account
            $('form#email-edit-'+emailID+' input[type="submit"]').click(function(e) {
              e.preventDefault();
              // console.log($(this).parents('form'));
              $(this).parents('form').foundation("validateForm");
            });

            $('form#email-edit-'+emailID).bind("formvalid.zf.abide", function(e, target) {

              $('.full-loader').removeClass('hide');

              var formData = $(this).serializeArray();
              var formDataObj = {};
              $(formData).each(function(index, obj){
                formDataObj[obj.name] = obj.value;
              });
              formDataObj.action = 'ns_edit_email_address';
              formDataObj.sessionId = ns_session_id;

              if(formDataObj.VacationMessageActive === "0") {
                formDataObj.VacationMessageStart = null;
                formDataObj.VacationMessageStop = null;
                formDataObj.VacationMessage = null;
              }
              console.log(formDataObj);

              var $currentForm = $(this);

              $.ajax({
                url: '/wp-content/themes/Lumos/api_helper.php',
                method: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: formDataObj,
                success: function(result) {
                  console.log(result);
                  var emailInfo = JSON.parse(result);

                  if(emailInfo.Status == 'success') {
                    // console.log(emailInfo);
                    window.location.href = window.location.pathname+"?"+$.param({'status_m':'a_emails_e'});
                  } else {
                    $currentForm.find('div[data-abide-error]').text(emailInfo.Message);
                    $currentForm.find('div[data-abide-error]').show();
                    $('.full-loader').addClass('hide');
                    $([document.documentElement, document.body]).animate({
                      scrollTop: $currentForm.offset().top - ($('.top-nav').outerHeight() || 0)
                    }, 400);
                  }
                  // $('.full-loader').addClass('hide');
                }
              });
            });
          }

          Foundation.reInit('reveal');

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_email_address_domains",
              sessionId: ns_session_id,
              accountId: sessionStorage.getItem('lumos_login_account_id_current')
            },
            success: function(result) {
              var emailDomains = JSON.parse(result);

              if(emailDomains.Status == 'success' && emailDomains.Data.length > 0) {
                for(var d = 0; d < emailDomains.Data.length; d++) {
                  $('select[name="email-domain"]').each(function() {
                    $(this).append('<option value="@'+emailDomains.Data[d].Domain+'">@'+emailDomains.Data[d].Domain+'</option>');
                  });
                }
              }
            }
          });

          $('div[data-account-section="email-accounts"]').removeClass('loading');
        }
      });
    }
  } // end getEmailAccounts()

  function getBillingPreferences() {
    if($('div[data-account-section="statement-delivery"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_account",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var accountInfo = JSON.parse(result);

          if(accountInfo.Status == 'success') {
            switch(accountInfo.Data.StatementDelivery) {
              case 'Letters':
                $('*[data-delivery-letters]').removeClass('hide');
                break;
              case 'Electronic':
                $('*[data-delivery-electronic]').removeClass('hide');
                break;
            }
          }

          $('div[data-account-section="statement-delivery"]').removeClass('loading');
        }
      });
    }
    if($('div[data-account-section="automatic-payment-status"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_get_payment_profile",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current'),
          PaymentProfileId: $('#ns-payment-methods-list-edit').val(),
        },
        success: function(result) {
          var paymentProfileInfo = JSON.parse(result);
          var autoPaymentStatus = false;

          if(paymentProfileInfo.Status == 'success') {
            for(var i = 0; i < paymentProfileInfo.Data.length; i++) {
              if(paymentProfileInfo.Data[i].Recurring) {
                var recurringDate = convertDateForIos(paymentProfileInfo.Data[i].RecurringStartDate);

                $('*[data-enrollment-method]').html(paymentProfileInfo.Data[i].Description + ' <br/>Credit Card ending in ' + paymentProfileInfo.Data[i].LastFour);
                $('*[data-enrollment-schedule]').text('Paid on Day ' + recurringDate.getDate() + ' of each month.');

                autoPaymentStatus = true;
                break;
              }
            }
          }

          $.ajax({
            url: '/wp-content/themes/Lumos/api_helper.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              action: "ns_bank_draft_check",
              sessionId: ns_session_id,
              AccountID: sessionStorage.getItem('lumos_login_account_id_current')
            },
            success: function(result) {
              console.log(result);
              var bankDraft = JSON.parse(result);

              if(bankDraft.length) {
                var recurringDate = convertDateForIos(bankDraft[0].StartDate);

                var autopaymentText = 'Bank Account ACH Draft';

                $('*[data-enrollment-method]').html(autopaymentText);
                $('*[data-enrollment-schedule]').text('Paid on Day ' + recurringDate.getDate() + ' of each month.');

                autoPaymentStatus = true;
              }

              if(autoPaymentStatus) {
                $('*[data-automatic-enrolled]').removeClass('hide');
              } else {
                $('*[data-automatic-notenrolled]').removeClass('hide');
              }

              $('div[data-account-section="automatic-payment-status"]').removeClass('loading');


            }
          });
        }
      });
    }
    if($('div[data-account-section="scheduled-payments"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_billing_payment_history",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var paymentInfo = JSON.parse(result);

          var paymentPendingCount = 0;
          for(var p = 0; p < paymentInfo.Data.length; p++) {
            if(paymentInfo.Data[p].Status == 'Pending') {
              paymentPendingCount++;
            }
          }

          $('*[data-scheduled-payment-count]').text(paymentPendingCount);

          if(paymentPendingCount > 1 || paymentPendingCount == 0) {
            $('*[data-scheduled-plural]').text('s');
          }

          $('div[data-account-section="scheduled-payments"]').removeClass('loading');
        }
      });
    }
  } // end getBillingPreferences()

  $('#phone-log-date').change(function() {
    $('div[data-account-section="phone-history"]').addClass('loading');
    $('#phone-log-table tbody').html('');
    $('#phone-log-error').addClass('hide');
    getPhoneSummary();
  });

  function getPhoneSummary() {
    // if($('div[data-account-section="phone-summary"]').length) {
    //
    // }
    // Call History
    if($('div[data-account-section="phone-history"]').length) {
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_phone_history_date",
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current'),
          yearMonth: $('#phone-log-date').val(),
          sessionId: ns_session_id
        },
        success: function(result) {
          // console.log(result);
          var phoneInfo = JSON.parse(result);

          // console.log(result, sessionStorage.getItem('lumos_login_account_id_current'), ns_session_id);

          if(phoneInfo.Status == 'success') {
            if(phoneInfo.Data.length > 0) {
              $('#phone-log-error').addClass('hide');
              $('#phone-log-table').removeClass('hide');
              for(var p = 0; p < phoneInfo.Data.length; p++) {

                var phoneTableRow = '<tr>';
                phoneTableRow += '<td data-label="Phone">'+phoneInfo.Data[p].ToPhone+'</td>';
                phoneTableRow += '<td data-label="Carrier">'+phoneInfo.Data[p].Carrier+'</td>';
                phoneTableRow += '<td data-label="Call type">'+phoneInfo.Data[p].CallType+'</td>';
                // phoneTableRow += '<td data-label="Calls" align="center">'+phoneInfo.Data[p].Calls+'</td>';
                phoneTableRow += '<td data-label="Minutes" align="center">'+phoneInfo.Data[p].Minutes+'</td>';
                phoneTableRow += '<td data-label="Amount" align="right">$'+parseFloat(phoneInfo.Data[p].Amount).toFixed(2)+'</td>';
                phoneTableRow += '<td data-label="Actions" align="center"><a id="phone-'+phoneInfo.Data[p].Id+'" href="#" data-open="call-details-modal" '

                phoneTableRow += 'data-date-time="'+phoneInfo.Data[p].DateTime+'"';
                phoneTableRow += 'data-from-city="'+phoneInfo.Data[p].FromCity+'"';
                phoneTableRow += 'data-from-state="'+phoneInfo.Data[p].FromState+'"';
                phoneTableRow += 'data-to-city="'+phoneInfo.Data[p].ToCity+'"';
                phoneTableRow += 'data-to-state="'+phoneInfo.Data[p].ToState+'"';
                phoneTableRow += 'data-to-phone="'+phoneInfo.Data[p].ToPhone+'"';
                phoneTableRow += 'data-minutes="'+phoneInfo.Data[p].Minutes+'"';
                phoneTableRow += 'data-amount="$'+parseFloat(phoneInfo.Data[p].Amount).toFixed(2)+'"';

                phoneTableRow += '>Details</a></td>';
                phoneTableRow += '</tr>';

                $('#phone-log-table tbody').append(phoneTableRow);

                $('#phone-'+phoneInfo.Data[p].Id).click(function(e) {
                  e.preventDefault();
                  $('#phone-date-time').text($(this).attr('data-date-time'));
                  $('#phone-from-city').text($(this).attr('data-from-city'));
                  $('#phone-from-state').text($(this).attr('data-from-state'));
                  $('#phone-to-city').text($(this).attr('data-to-city'));
                  $('#phone-to-state').text($(this).attr('data-to-state'));
                  $('#phone-to-phone').text($(this).attr('data-to-phone'));
                  $('#phone-minutes').text($(this).attr('data-minutes'));
                  $('#phone-amount').text($(this).attr('data-amount'));
                });
              }
            } else {
              $('#phone-log-error').removeClass('hide');
              $('#phone-log-table').addClass('hide');
            }
          }

          $('div[data-account-section="phone-history"]').removeClass('loading');
        },
        error: function(results) {
          $('#phone-log-error').removeClass('hide');
          $('#phone-log-table').addClass('hide');
          $('div[data-account-section="phone-history"]').removeClass('loading');
          console.log(results);
        }

      });
    }
  }

  function getStatementDelivery() {
    if($('div[data-account-section="statement-options"]').length) {

      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_account",
          sessionId: ns_session_id,
          // accountId: window.sessionStorage.getItem('lumos_login_account_id_current')
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          var accountInfo = JSON.parse(result);

          if(accountInfo.Status == 'success') {

            var fullDeliveryAddress = '',
                emailDeliveryAddress = '',
                billingContactIndex = 0;
            for(var i = 0; i < accountInfo.Data.Contacts.length; i++) {
              if(accountInfo.Data.Contacts[i].Type == 'Billing') {
                fullDeliveryAddress = accountInfo.Data.Contacts[i].AddressFull;
                emailDeliveryAddress = accountInfo.Data.Contacts[i].Email;
                break;
              }
              billingContactIndex++;
            }

            $('*[data-statement-delivery-full-address]').text(fullDeliveryAddress);
            $('*[data-statement-delivery-email]').text(emailDeliveryAddress);

            var billingContact = accountInfo.Data.Contacts[billingContactIndex];

            $('input[name="AccountID"]').val(billingContact.AccountID);
            $('input[name="ContactID"]').val(billingContact.ContactID);

            $('input[name="NameFull"]').val(billingContact.NameFull);
            $('input[name="NamePrefix"]').val(billingContact.NamePrefix);
            $('input[name="NameFirst"]').val(billingContact.NameFirst);
            $('input[name="NameMiddle"]').val(billingContact.NameMiddle);
            $('input[name="NameLast"]').val(billingContact.NameLast);
            $('input[name="NameSuffix"]').val(billingContact.NameSuffix);

            $('input[name="Phone"]').val(billingContact.Phone);
            $('input[name="PhoneExt"]').val(billingContact.PhoneExt);

            $('input[name="PhoneAlt"]').val(billingContact.PhoneAlt);
            $('input[name="PhoneAltExt"]').val(billingContact.PhoneAltExt);

            $('input[name="Email"]').val(billingContact.Email);
            $('#ns-billing-email').val(emailDeliveryAddress);

            $('input[name="ns-address-zip"]').val(billingContact.AddressZip);

            $('input[name="ns-address-city-id"]').val(billingContact.AddressCityID);
            $('input[name="ns-address-state-id"]').val(billingContact.AddressStateID);

            getAddressCity($('#ns-billing-address-city'), billingContact.AddressCityID, billingContact.AddressStateID);

            $('input[name="ns-address-street-name-id"]').val(billingContact.AddressStreetID);

            getAddressStreet($('#ns-billing-address-street-name'), billingContact.AddressStreetID);

            $('input[name="ns-address-street-number"]').val(billingContact.AddressHouseNum);

            if(billingContact.AddressApartmentNum !== null) {
              $('input[name="name="ns-address-street-apt""]').val(billingContact.AddressApartmentNum);
            }

            if(billingContact.AddressPoBox !== null) {
              $('input[name="ns-address-street-pobox"]').val(billingContact.AddressPoBox);
              $('#ns-billing-address-street-pobox').click();
            }

            switch(accountInfo.Data.StatementDelivery) {

              case 'Letters':
                $('*[data-statement-address]').removeClass('hide');
                $('*[data-statment-delivery-method]').text('By mail with a paper statement');
                $('#ns-statment-by-mail').prop('checked', true);
                break;

              case 'Electronic':
                $('*[data-statement-email]').removeClass('hide');
                $('*[data-statment-delivery-method]').text('By email with an online statement');
                $('#ns-statment-by-email').prop('checked', true);
                break;
            }
          }

          $('div[data-account-section="statement-options"]').removeClass('loading');
        }
      });
    }
  }

  function getEquipmentList() {
    if($('div[data-account-section="equipment-list"]').length) {
      $('.full-loader').removeClass('hide');
      $.ajax({
        url: '/wp-content/themes/Lumos/api_helper.php',
        method: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: {
          action: "ns_equipment_list",
          sessionId: ns_session_id,
          accountId: sessionStorage.getItem('lumos_login_account_id_current')
        },
        success: function(result) {
          // console.log(result);
          var equipInfo = JSON.parse(result);

          if(equipInfo.Status == 'success') {
            for(var e = 0; e < equipInfo.Data.length; e++) {
              var $equipTemp = $('.equipment-template').clone();
              $equipTemp.removeClass('equipment-template');

              $equipTemp.find('[data-status]').text(equipInfo.Data[e].Status);
              if(equipInfo.Data[e].Status == 'INACTIVE') {
                $equipTemp.find('[data-status]').removeClass('active');
                $equipTemp.find('[data-status]').addClass('inactive');
              }

              console.log(equipInfo.Data[e].Description, equipInfo.Data[e].ImageData);

              if(equipInfo.Data[e].ImageData !== null) {
                $equipTemp.find('[data-equipment-image]').attr('src', equipInfo.Data[e].ImageData);
              } else {
                $equipTemp.find('[data-equipment-image]').addClass('hide');
              }

              $equipTemp.find('[data-equipment-class]').text(equipInfo.Data[e].EquipmentClass);

              switch (equipInfo.Data[e].EquipmentClass) {
                // For just internet
                case 'ONT':
                case 'Data Modem':
                  $equipTemp.find('[data-for-internet]').removeClass('hide');
                  break;
                // For just tv
                // case '':
                //   $equipTemp.find('[data-for-TV]').removeClass('hide');
                //   break;
                // For both tv and Internet
                case 'Set Top Box':
                  $equipTemp.find('[data-for-internet]').removeClass('hide');
                  $equipTemp.find('[data-for-TV]').removeClass('hide');
                  break;
                default:

              }

              $equipTemp.find('[data-equipment-description]').text(equipInfo.Data[e].Description);

              if(equipInfo.Data[e].Serial != null) {
                $equipTemp.find('[data-equipment-serial]').text(equipInfo.Data[e].Serial);
              } else {
                $equipTemp.find('[data-equipment-serial-label]').addClass('hide');
                $equipTemp.find('[data-equipment-serial]').addClass('hide');
              }

              if(equipInfo.Data[e].InstallDate != null) {
                $equipTemp.find('[data-equipment-install]').text(formatDate(equipInfo.Data[e].InstallDate));
              } else {
                $equipTemp.find('[data-equipment-install-label]').addClass('hide');
                $equipTemp.find('[data-equipment-install]').addClass('hide');
              }

              $equipTemp.removeClass('hide');

              $('*[data-equipment-list]').append($equipTemp);
            }
          }

          $('.full-loader').addClass('hide');
        }
      });
    }
  }

  function createTable(headerArray, dataArray, tableClasses = '') {
    // Generate Table Head
    var $tableBase      = $('<table class="'+tableClasses+'"></table>');
        $tableHead = $('<thead></thead>'),
        $tableHeadRow = $('<tr></tr>');

    for(var j = 0; j < headerArray.length; j++) {
      $tableHeadRow.append('<th>'+headerArray[j]+'</th>');
    }
    $tableHead.append($tableHeadRow)
    $tableBase.append($tableHead);

    // Generate Table Body
    var $tableBody= $('<tbody></tbody>');

    for(var k = 0; k < dataArray.length; k++) {
      var $tableBodyRow = $('<tr></tr>');
      for(var l = 0; l < dataArray[k].length; l++) {
        $tableBodyRow.append('<td data-label="'+headerArray[l]+'">'+dataArray[k][l]+'</td>');
      }
      $tableBody.append($tableBodyRow);
    }

    $tableBase.append($tableBody);

    return $tableBase;
  }

});
