function GetSortOrder(prop) {
    return function(a, b) {
        if (parseInt(a[prop]) > parseInt(b[prop])) {
            return 1;
        } else if (parseInt(a[prop]) < parseInt(b[prop])) {
            return -1;
        }
        return 0;
    }
}

jQuery(document).ready(function($) {

  // console.log('send request');

  var apiData = {
    action: 'get_tv_listings'
  }

  if(localStorage.getItem('lumos_login_account_id_current') && typeof ns_session_id !== 'undefined') {
    apiData.action = 'get_account_tv_listings';
    apiData.accountId = localStorage.getItem('lumos_login_account_id_current');
    apiData.sessionId = ns_session_id;
  }

  // console.log(apiData);

  if($('#ns_tv_listing_table').length) {
    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: apiData,
      // url: 'https://www.northstate.net/api/TVChannel/GetAll',
      // method: 'GET',
      // crossDomain: true,
      // dataType: 'json',
      success: function(result) {

        // console.log(result);

        var response = $.parseJSON(result);

        if(response.Status === "success") {

          var tvListings = response.Data;

          // console.log(tvListings, result);

          tvListings.sort(GetSortOrder("Number"));

          $.each(tvListings, function(key, value) {
            if(value['Tier'] !== null) {
              var tier = value['Tier'];
              if(tier == null) {
                tier = value['Genre'];
              }
              // $('#ns_tv_listing_table tbody').append('<tr><td>'+value['Number']+'</td><td>'+value['Name']+'</td><td>'+value['Genre']+'</td><td>'+tier+'</td></tr>');
              var tvRow = '<tr>';

              tvRow += '<td class="text-center"><strong>'+value['Number']+'</strong></td>';
              if(value['LogoData'] !== null) {
                tvRow += '<td><img src="data:image/png;base64, '+value['LogoData']+'" class="channel-icon" /> '+value['Name']+'</td>';
              } else {
                // tvRow += '<td><img src="https://via.placeholder.com/42" class="channel-icon" /> '+value['Name']+'</td>';
                tvRow += '<td>'+value['Name']+'</td>';
              }
              tvRow += '<td>'+value['Genre']+'</td><td>'+tier+'</td>';
              tvRow += '</tr>';

              $('#ns_tv_listing_table tbody').append(tvRow);
            }
          });

          var tvTable = $('#ns_tv_listing_table').DataTable({
              "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]]
          });

          var genreFilter = tvTable.column( 2 ).data().unique().sort(),
              tierFilter = tvTable.column( 3 ).data().unique();

              genreFilter.unshift('All Genres');
              tierFilter.unshift('All Tiers');

          // console.log(genreFilter, tierFilter);

          $.each(genreFilter, function(key, value) {
            // var $gf = $('<label></label>'),
            //     val = value,
            //     selected = '';
            // if(value === 'All Genres') {
            //   val = '';
            //   selected = 'checked';
            // }
            // $gf.append('<input type="radio" value="'+val+'" name="filter_genre" '+selected+' />');
            // $gf.append('<span>'+value+'</span>');
            // $('#ns_tv_filter_genre').append($gf);
            //
            // $gf.find('input').on( 'change', function () {
            //   tvTable
            //     .column( 2 )
            //     .search( $(this).val() )
            //     .draw();
            // });

            var val = value,
                selected = '';

            if(value === 'All Genres') {
              value = 'Genre/Category';
              val = '';
              selected = 'selected';
            }

            var $gf = $('<option value="'+val+'" '+selected+'>'+value+'</option>');

            $('#ns_tv_filter_genre').append($gf);
          });

          $('#ns_tv_filter_genre').on( 'change', function () {
            tvTable
              .column( 2 )
              .search( $(this).val() )
              .draw();
          });

          $.each(tierFilter, function(key, value) {
            // var $tf = $('<label></label>'),
            //     val = value,
            //     selected = '';
            // if(value === 'All Tiers') {
            //   val = '';
            //   selected = 'checked';
            // }
            // $tf.append('<input type="radio" value="'+val+'" name="filter_tier" '+selected+' />');
            // $tf.append('<span>'+value+'</span>');
            // $('#ns_tv_filter_tier').append($tf);
            //
            // $tf.find('input').on( 'change', function () {
            //   tvTable
            //     .column( 3 )
            //     .search( $(this).val() )
            //     .draw();
            // });

            var val = value,
                selected = '';

            if(value === 'All Tiers') {
              value = 'Channel Tier';
              val = '';
              selected = 'selected';
            }

            if(value === 'Fiber TV') {
              $('[data-fibertv-download]').removeClass('hide');
            }

            if(value === 'NorthState TV') {
              $('[data-nstv-download]').removeClass('hide');
            }

            var $tf = $('<option value="'+val+'" '+selected+'>'+value+'</option>');

            $('#ns_tv_filter_tier').append($tf);
          });

          $('#ns_tv_filter_tier').on( 'change', function () {
            tvTable
              .column( 3 )
              .search( $(this).val() )
              .draw();
          });

          $('.ns_tv_listing .loading-overlay').addClass('hide');

          $('select[name="ns_tv_listing_table_length"]').val('25');
          $('select[name="ns_tv_listing_table_length"]').change();

          // tvTable.columns().flatten().each( function ( colIdx ) {
          //   // Create the select list and search operation
          //   if(colIdx === 2 || colIdx === 3) {
          //     var select = $('<select />')
          //     .prependTo(tvTable.column(colIdx).header())
          //     .on( 'change', function () {
          //       tvTable
          //         .column( colIdx )
          //         .search( $(this).val() )
          //         .draw();
          //     });
          //
          //     if(colIdx === 2) {
          //       select.append( $('<option value="">All Genres</option>') );
          //     } else   if(colIdx === 3) {
          //       select.append( $('<option value="">All Tiers</option>') );
          //     }
          //
          //
          //     // Get the search data for the first column and add to the select list
          //     tvTable
          //       .column( colIdx )
          //       .cache( 'search' )
          //       .sort()
          //       .unique()
          //       .each( function ( d ) {
          //         select.append( $('<option value="'+d+'">'+d+'</option>') );
          //       });
          //   }
          //
          // });
        }
      }
    });
  }


});
