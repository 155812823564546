jQuery(document).ready(function($) {

  if($('.account-screen').length) {
    var uri = window.location.toString();
  	if (uri.indexOf("?") > 0 && uri.indexOf("status_m") > 0) {
  	    var clean_uri = uri.substring(0, uri.indexOf("?"));
  	    window.history.replaceState({}, document.title, clean_uri);
  	}
  }

  $('#ns-new-password-submit').click(function(e) {
    e.preventDefault();
    $('form#ns-reset-password-form').foundation("validateForm");
  });

  $('form#ns-reset-password-form').bind("formvalid.zf.abide", function(e,target) {

    e.preventDefault();

    $('.full-loader').removeClass('hide');

    $.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: 'ns_reset_password_with_token',
        ResetToken: $('#ns-new-password-token').val(),
        Password: $('#ns-new-password').val()
      },
      success: function(result) {
        console.log(result);
        var ns_password_reset = JSON.parse(result);

        if(ns_password_reset.Status !== "success") {
          $('form#ns-reset-password-form').foundation('addErrorClasses', $('#ns-new-password'));
          $('form#ns-reset-password-form').parent('.callout').removeClass('loading submit');

          $('.full-loader').addClass('hide');
        } else if(ns_password_reset.Status === "success") {
          $('div[data-section-page="password-change-form"]').addClass('hide');
          $('div[data-section-page="password-change-success"]').removeClass('hide');

          $('.full-loader').addClass('hide');
        }
      }
    });

  });

});
