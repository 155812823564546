// Abide patterns
// Foundation.Abide.defaults.patterns['phoneNumber'] = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/; // Phone
Foundation.Abide.defaults.patterns['phoneNumber'] = /^((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})$/;
// Foundation.Abide.defaults.patterns['pinNumber']  = /^(?=.*[0-9]).{4,6}$/
Foundation.Abide.defaults.patterns['pinNumber']  = /^([1-9](?=.*[0-9]).{3,5})$/
Foundation.Abide.defaults.patterns['ssNumber']  = /^([0-9]{9})$/
// Foundation.Abide.defaults.patterns['strongPassword'] = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])(?=.*[0-9]).{7,20}$/; // Strong Password
Foundation.Abide.defaults.patterns['strongPassword'] = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{7,20}$/;
Foundation.Abide.defaults.patterns['zipCode'] = /^[0-9]{5}(?:-[0-9]{4})?$/;
Foundation.Abide.defaults.patterns['emailUsername'] = /^[a-zA-Z0-9.-]+$/;


function pinNumberAccount($el, required, parent) {
  var b = $el.val();
  var numbers = "0123456789";
  //If reverse sequence is also needed to be checked
  var numbersRev = "9876543210";

  // console.log(b, /^([1-9](?=.*[0-9]).{3,5})$/.test(b), numbers.indexOf(String(b)), numbersRev.indexOf(String(b)));

  //Returns false, if the number is in sequence
  return (/^([1-9](?=.*[0-9]).{3,5})$/.test(b) && numbers.indexOf(String(b)) === -1 && numbersRev.indexOf(String(b)) === -1);
}

Foundation.Abide.defaults.validators['pinNumberAccount'] = pinNumberAccount;

function usernameValidation($el, required, parent) {
  if(!required) return true;

  var usernameStatus = false;

  if($el.val().length) {

    jQuery.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      async: false,
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "check_username",
        username: $el.val().trim()
      },
      success: function(result) {
        // console.log(result);
        var response = jQuery.parseJSON(result);
        // console.log(response);
        if(response.Status === 'success') {
          if(response.Data) {
            // console.log('already exists');
            // Username Already Exists
            jQuery('#account_service_email_address_hint').text('This username already exists in our system');
            usernameStatus = false;
          } else {
            // console.log('avaiable');
            // Username Does not exist and can be registered
            jQuery('#account_service_email_address_hint').text(jQuery('#account_service_email_address_hint').attr('data-message-orig'));
            usernameStatus = true;
          }
        } else {
          usernameStatus = false;
        }
      },
      error: function(result) {
        // console.log(result);
        usernameStatus = false;
      }
    });
  }

  return usernameStatus;
}

Foundation.Abide.defaults.validators['username_validation'] = usernameValidation;

function dateforwardValidation($el, required, parent) {
  if(!required) return true;

  if($el.val().length) {
    var today = new Date();
    today.setHours(0,0,0,0);
    var selectedDate = new Date($el.val());
    // console.log(today, selectedDate);

    if(selectedDate <= today) {
      return false;
    } else {
      return true;
    }
  }
}

Foundation.Abide.defaults.validators['dateforward_validation'] = dateforwardValidation;

function routingValidation($el, required, parent) {
  if(!required) return true;

  var validRouting = false;

  if($el.val().length) {

    jQuery.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      async: false,
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_validate_routing",
        sessionId: ns_session_id,
        AccountID: $el.attr('data-account'),
        RoutingNumber: $el.val()
      },
      success: function(result) {
        // console.log('success', result);
        var response = jQuery.parseJSON(result);
        // console.log($el.attr('data-account'), $el.val(), result);
        if(response.Status === 'success') {
          if(response.Data) {
            // console.log('Data true');
            validRouting = true;
          }
        }
      },
      error: function() {
        console.log('error');
        validRouting = false;
      }
    });
  }

  return validRouting;
}
Foundation.Abide.defaults.validators['routingnumber_validation'] = routingValidation;

function routingDraftValidation($el, required, parent) {
  if(!required) return true;

  var validRouting = false;

  if($el.val().length) {

    jQuery.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      async: false,
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_bank_draft_check_routing",
        RoutingNumber: $el.val()
      },
      success: function(result) {
        // console.log('success', result);
        var response = jQuery.parseJSON(result);
        // console.log($el.attr('data-account'), $el.val(), result);
        if(response.Status === 'success') {
          if(response.Data.length) {
            // console.log('Data true');
            validRouting = true;
          }
        }
      },
      error: function() {
        console.log('error');
        validRouting = false;
      }
    });

    if(!validRouting) {
      if(jQuery('#bank-routing-error').length) {
        jQuery('#bank-routing-error').html('We couldn\'t find your bank\'s routing number in our system. Please contact us at <a href="tel:8665425900">866.542.5900</a> for futher assistance.' );
      }
    }
  }

  return validRouting;
}
Foundation.Abide.defaults.validators['routingnumber_draft_validation'] = routingDraftValidation;


function ccluhnValidation($el, required, parent) {
  if(!required) return true;

  var validCC = false;

  if($el.val().length) {

    var firstDigit = $el.val().charAt(0);

    if(jQuery('#new-cc-account-type').length || jQuery('#ns-add-cc-account-type').length || jQuery('#ns-credit-check-cc-type')) {
      switch(firstDigit) {
        // American Express
        case "3":
          jQuery('#new-cc-account-type').val(1);
          jQuery('#new-cc-cvv').attr('maxlength', 4);

          jQuery('#ns-add-cc-account-type').val(1);
          jQuery('#ns-add-cc-account-cvv').attr('maxlength', 4);

          jQuery('#ns-credit-check-cc-type').val(1);
          jQuery('#ns-credit-check-cc-cvv').attr('maxlength', 4);
          break;
        // Discover
        case "6":
          jQuery('#new-cc-account-type').val(2);
          jQuery('#new-cc-cvv').attr('maxlength', 3);

          jQuery('#ns-add-cc-account-type').val(2);
          jQuery('#ns-add-cc-account-cvv').attr('maxlength', 3);

          jQuery('#ns-credit-check-cc-type').val(2);
          jQuery('#ns-credit-check-cc-cvv').attr('maxlength', 3);
          break;
        // MASTERCARD
        case "5":
        case "2":
          jQuery('#new-cc-account-type').val(3);
          jQuery('#new-cc-cvv').attr('maxlength', 3);

          jQuery('#ns-add-cc-account-type').val(3);
          jQuery('#ns-add-cc-account-cvv').attr('maxlength', 3);

          jQuery('#ns-credit-check-cc-type').val(3);
          jQuery('#ns-credit-check-cc-cvv').attr('maxlength', 3);
          break;
          // VISA
        case "4":
          jQuery('#new-cc-account-type').val(4);
          jQuery('#new-cc-cvv').attr('maxlength', 3);

          jQuery('#ns-add-cc-account-type').val(4);
          jQuery('#ns-add-cc-account-cvv').attr('maxlength', 3);

          jQuery('#ns-credit-check-cc-type').val(4);
          jQuery('#ns-credit-check-cc-cvv').attr('maxlength', 3);
          break;
        default:
          jQuery('#new-cc-account-type').val('');
          jQuery('#new-cc-cvv').attr('maxlength', 4);

          jQuery('#ns-add-cc-account-type').val('');
          jQuery('#ns-add-cc-account-cvv').attr('maxlength', 4);

          jQuery('#ns-credit-check-cc-type').val('');
          jQuery('#ns-credit-check-cc-cvv').attr('maxlength', 4);
      }
    }

    $el.val($el.val().replace(/[^0-9]/g, ''));

    jQuery.ajax({
      url: '/wp-content/themes/Lumos/api_helper.php',
      method: 'POST',
      async: false,
      contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "ns_validate_cc_luhn",
        CardNumber: $el.val(),
      },
      success: function(result) {
        // console.log(result);
        var response = jQuery.parseJSON(result);
        if(response.Status === 'success') {
          if(response.Data) {
            // console.log('Data true');
            validCC = true;
          }
        }
      },
      error: function() {
        validCC = false;
      }
    });
  }

  // console.log(validCC);
  return validCC;
}
Foundation.Abide.defaults.validators['ccluhn_validation'] = ccluhnValidation;

function paymentAmountValidation($el, required, parent) {
  // console.log('Payment Amount Validator');
  if(!required) return true;

  if($el.val() < 0.01) {
    return false;
  } else {
    return true;
  }
}

Foundation.Abide.defaults.validators['paymentamount_validation'] = paymentAmountValidation;

function ccPaymentValidation($el, required, parent) {
  if(!required) return true;

  var todaysDate = new Date();
  var todayMonth = String(todaysDate.getMonth() + 1);
  if (todayMonth.length < 2) todayMonth = '0' + todayMonth;

  var todayYear = todaysDate.getFullYear();
      todayYear = String(todayYear).substring(2,4)

  // console.log($el.val(), todayYear+todayMonth);

  if(parseInt($el.val()) < parseInt(todayYear+todayMonth)) {
    return false;
  } else {
    return true;
  }
}

Foundation.Abide.defaults.validators['ccdate_validation'] = ccPaymentValidation;

jQuery(document).foundation();

function urlParamNS(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results==null) {
       return null;
    }
    return decodeURI(results[1]) || 0;
}

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}
