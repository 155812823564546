
var checkAddressLS;
var setAddressCookieData;

var au_CitiesObj = [];
var au_StreetsObj = [];
var au_HouseNumberObj = [];
var au_ApartmentNumberObj = [];

var au_zipcodes = [];
var au_cities = [];
var au_streets = [];
var au_housenumbers = [];
var au_apartmentnumbers = [];

if(!adjustDdSize){
  var adjustDdSize = function(){
    //console.log("calling adjustDdSize");
    var $this = jQuery(this);
    $this.parent().find('.tt-dropdown-menu').width($this.css('width'));
  };
}

function billingTypeahead(zipElem, zipURL, cityElem, cityURL, streetElem, streetURL, streetNumElem, streetNumURL, aptNoElem, aptNoURL, auth) {
  var $ = jQuery;

  $.ajax({
    url: zipURL,
    type: 'GET',
    async: false,
    dataType: "json",
    beforeSend: function (xhr){
      xhr.setRequestHeader('Authorization', auth);
    },
    success: function(result){
      //console.log("fetched zip codes");
      au_zipcodes = result.Data;
      // console.log(au_zipcodes);
    }
  });

  setupAddressDropDownTypeahead(zipElem, 'zip', au_zipcodes);

  $(zipElem).on('keydown',adjustDdSize);
  $(cityElem).on('keydown',adjustDdSize);
  $(streetElem).on('keydown',adjustDdSize);
  $(streetNumElem).on('keydown',adjustDdSize);
  $(aptNoElem).on('keydown',adjustDdSize);

  $(zipElem).on("blur change",function(event){
    // console.log("zip " + event.type);
    $(cityElem).val('');
    $(streetElem).val('');
    $(streetNumElem).val('');
    $(aptNoElem).val('');

    if(event.type == "change"){
      return false;
    }

    var val = $(this).val();
    populateZip(val, cityElem, cityURL, auth);
  });

  $(cityElem).on("blur change",function(event){
    // console.log("city " + event.type);
    $(streetElem).val('');
    $(streetNumElem).val('');
    $(aptNoElem).val('');

    if(event.type == "change"){
      return false;
    }

    var val = $(this).val();
    populateCity(val, streetElem, streetURL, auth);
  });

  $(streetElem).on("blur change",function(event){
    // console.log("streetName " + event.type);
    $(streetNumElem).val('');
    $(aptNoElem).val('');

    if(event.type == "change"){
      return false;
    }

    var val = $(this).val();
    populateStreet(val, aptNoElem, aptNoURL, auth);
  });
}

function populateZip(Zip, cityElem, cityURL, auth) {
  var $ = jQuery;
  if(Zip.length === 5) {
    au_cities.length = 0;
    $.ajax({
      url: cityURL + Zip,
      async: true,
      beforeSend: function (xhr){
        xhr.setRequestHeader('Authorization', auth);
      },
      success: function(response) {
        var Cities = response.Data;

        if(Cities !== null) {
          for(var c in Cities) {
            if(typeof Cities[c].CityID !== 'undefined') {
              au_CitiesObj[Cities[c].City.toString().trim() + ', ' + Cities[c].StateAbbreviation.toString().trim()] = Cities[c];
              au_cities.push(Cities[c].City.toString().trim() + ', ' + Cities[c].StateAbbreviation.toString().trim());
            }
          }

          // console.log(au_cities);

          setupAddressDropDownTypeahead(cityElem, 'value', au_cities);
          $(cityElem).trigger('focus');
        } else {
          console.log('error','No cities found for the given zip...');
        }
      }
    });
  }
}

function populateCity(City, streetsElem, streetsURL, auth) {
  var $ = jQuery;
  if(City.length !== 0) {
    au_streets.length = 0;
    City = City.toUpperCase();
    $.ajax({
      url: streetsURL,
      type: 'POST',
      dataType: "json",
      async: true,
      beforeSend: function (xhr){
        xhr.setRequestHeader('Authorization', auth);
      },
      data: { City: JSON.stringify(au_CitiesObj[City]) },
      success: function(response) {
        Streets = response.Data;
        if(Streets !== null) {
          for(var s in Streets) {
            if(typeof Streets[s].StreetID !== 'undefined') {
              var street = '';
              street += Streets[s].PreDirectional === null ? '' : Streets[s].PreDirectional.toString().trim() + ' ';
              street += Streets[s].Street.toString().trim();
              street += Streets[s].StreetSuffix === null ? '' : ' ' + Streets[s].StreetSuffix.toString().trim();
              street += Streets[s].PostDirectional === null ? '' : ' ' + Streets[s].PostDirectional.toString().trim();

              au_StreetsObj[street] = Streets[s];
              au_streets.push(street);
            }
          }

          setupAddressDropDownTypeahead(streetsElem, 'streets', au_streets);
          $(streetsElem).trigger('focus');
        }
        else {
          console.log('error','No streets found for the given city...');
        }
      }
    });
  }

}

function populateStreet(Street, streetNumElem, streetNumURL, auth) {
  var $ = jQuery;
  if(Street.length !== 0) {
    Street = Street.toUpperCase();
    au_housenumbers.length = 0;
    $.ajax({
      url: streetNumURL,
      type: 'POST',
      dataType: "json",
      async: true,
      beforeSend: function (xhr){
        xhr.setRequestHeader('Authorization', auth);
      },
      data: { action: 'GetHouseNumbersByStreet', Street: JSON.stringify(au_StreetsObj[Street]) },
      success: function(response) {
        HouseNumbers = response.Data;
        if(HouseNumbers !== null) {
          for(var h in HouseNumbers) {
            if(typeof HouseNumbers[h].HouseNum !== 'undefined') {
              if(HouseNumbers[h].HouseNumSuffix !== null) {
                HouseNumbers[h].HouseNum = HouseNumbers[h].HouseNum + ' ' + HouseNumbers[h].HouseNumSuffix;
              }

              au_HouseNumberObj[HouseNumbers[h].HouseNum] = HouseNumbers[h];
              au_housenumbers.push(HouseNumbers[h].HouseNum);
            }
          }

          setupAddressDropDownTypeahead(streetNumElem, 'housenumbers', au_housenumbers);
          $(streetNumElem).trigger('focus');
        }
        else {
          console.log('error','No house numbers found for the given street...');
        }
      }
    });
  }
}

function populateHouseNumber(HouseNum, aptNoElem, aptNoURL, auth) {
  if(HouseNum.length !== 0) {
    au_apartmentnumbers.length = 0;
    $.ajax({
      url: aptNoURL,
      type: 'POST',
      dataType: "json",
      async: false,
      beforeSend: function (xhr){
        xhr.setRequestHeader('Authorization', auth);
      },
      data: { action: 'GetApartmentNumbersByHouseNumber', HouseNumber: JSON.stringify(au_HouseNumberObj[HouseNum]) },
      success: function(response) {
        ApartmentNumbers = response.Data;
        if(ApartmentNumbers !== null) {
          for(var h in ApartmentNumbers) {
            if(typeof ApartmentNumbers[h].ApartmentNum !== 'undefined') {
              au_ApartmentNumberObj[ApartmentNumbers[h].ApartmentNum] = ApartmentNumbers[h];
              au_apartmentnumbers.push(ApartmentNumbers[h].ApartmentNum);
            }
          }

          setupAddressDropDownTypeahead(aptNoElem, 'apartmentnumbers', au_apartmentnumbers);
          $(aptNoElem).trigger('focus');
        }
        else {
            console.log('error','No house numbers found for the given street...');
        }
      }
    });
  }
}

function setupAddressDropDownTypeahead(selector, dataSetName, dataSet) {
  //console.log("setupAddressDropDownTypeahead:",selector, dataSetName, dataSet);
  jQuery(selector).typeahead('destroy');
  jQuery(selector).typeahead({
    hint: true,
    highlight: true,
    minLength: 0,
    limit: 5
  },{
    name: dataSetName,
    source: substringMatcher(dataSet)
  });

  // console.log(dataSetName, dataSet);


  jQuery(selector).on('focus',function(){
    if($(this).val().length > 0){
      return false;
    }
    //console.log(selector +" focused")
    var ev = $.Event('keydown');
    ev.keyCode = ev.which = 40;
    $(selector).trigger(ev);
    return true;
  });
}

jQuery(document).ready(function($) {

  var lsLabel_full_address   				= 'lumos_address_full_address',
			lsLabel_place_id	    			  = 'lumos_address_place_id',
			lsLabel_location_id     			= 'lumos_location_id',
			lsLabel_address_id     				= 'lumos_address_id',
			lsLabel_address_status 				= 'lumos_address_status',
			lsLabel_address_check_decline = 'lumos_address_check_decline',
			lsLabel_address_city       		= 'lumos_address_city',
			lsLabel_address_city_id       = 'lumos_address_city_id',
      lsLabel_address_state         = 'lumos_address_state',
			lsLabel_address_state_id      = 'lumos_address_state_id',
			lsLabel_address_street     		= 'lumos_address_street',
			lsLabel_address_street_id     = 'lumos_address_street_id',
			lsLabel_address_zip           = 'lumos_address_zip',
			lsLabel_address_house_num     = 'lumos_address_house_num',
			lsLabel_address_apt_num       = 'lumos_address_apt_num',
      lsLabel_address_multigig      = 'lumos_address_multigig',
      lsLabel_address_market        = 'lumos_address_market';

  // open/close address checker
  $('#address-checker-close, #availability-status, #availability-status-mobile, .address-checker-overlay, .address-checker-mobile, a[href*="#checkaddress"]').click(function(e) {
    e.preventDefault();
    toggleAddressChecker();
    // $('#address-checker').toggleClass('open');
    // logNSEvent('ecomm - button click - Open Address Checker from Header');
  });

  $('a[href="#check-address"]').click(function(e) {
    e.preventDefault();
    toggleAddressChecker();
    // $('#address-checker').toggleClass('open');
    // logNSEvent('ecomm - button click - Open Address Checker from Multigig Prompt');
  });

  // Learn more link closes checker
  // $('a[href$=#learn-more-about-fiber]').click(function() {
	// 	$('#address-checker').removeClass('open');
	// });

  // If closed, stash cookie to show that it should be closed on next load
  $('#address-checker-close, .address-checker-overlay').click(function(e) {
		localStorage.setItem(lsLabel_address_check_decline, 1);
	});

  // Open address checker if no cookie to keep it closed is present
  if(!localStorage.getItem(lsLabel_address_check_decline)) {
		if($('#availability-status').length) {
			$('#address-checker').addClass('open');
		}
	}

  var scriptBase = '/wp-content/themes/Lumos/assets/scripts/aquitemplates';

  var getUrl = window.location;
  var baseUrl = getUrl.protocol + "//" + getUrl.host;

  if($('.aqui-container').length) {
    var aquicontainer = $('.aqui-container').aqui({
      websiteBase: baseUrl
    });

    aquicontainer.aqui.defaults.templates.google_search_form = scriptBase+'/google-search-form.html';
    aquicontainer.aqui.defaults.templates.dropdown_search_form = scriptBase+'/dropdown-search-form.html';

    aquicontainer.aqui.defaults.templates.results.account_exist_fiber_available     = scriptBase+'/results_templates/account-exist-fiber-available.html';
    aquicontainer.aqui.defaults.templates.results.account_exist_fiber_not_available = scriptBase+'/results_templates/account-exist-fiber-not-available.html';
    aquicontainer.aqui.defaults.templates.results.fiber_available_congrat           = scriptBase+'/results_templates/fiber-available-congrat.html';
    aquicontainer.aqui.defaults.templates.results.fiber_available_congrat_lumos     = scriptBase+'/results_templates/fiber-available-congrat-lumos.html';
    aquicontainer.aqui.defaults.templates.results.fiber_coming_soon                 = scriptBase+'/results_templates/fiber-coming-soon.html';
    aquicontainer.aqui.defaults.templates.results.low_tier_fiber_not_available      = scriptBase+'/results_templates/low-tier-fiber-not-available.html';
    aquicontainer.aqui.defaults.templates.results.no_services_planned               = scriptBase+'/results_templates/no-services-planned.html';
    aquicontainer.aqui.defaults.templates.results.no_services                       = scriptBase+'/results_templates/no-services.html';
    aquicontainer.aqui.defaults.templates.results.address_not_found                 = scriptBase+'/results_templates/address-not-found.html';

    aquicontainer.aqui.defaults.getFiber_url = "/shop-fiber?address=ADDRESSTOKEN";

    if($('#address_service_billing_different_zip').length ||
       $('#address_service_billing_different_city').length ||
       $('#address_service_billing_different_street_name').length ||
       $('#address_service_billing_different_street_number').length ||
       $('#address_service_billing_different_apartment').length) {

       billingTypeahead(
         '#address_service_billing_different_zip',
         aquicontainer.aqui.defaults.zipcodes_url,

         '#address_service_billing_different_city',
         aquicontainer.aqui.defaults.citiesbyzip_url,

         '#address_service_billing_different_street_name',
         aquicontainer.aqui.defaults.streetsbycity_url,

         '#address_service_billing_different_street_number',
         aquicontainer.aqui.defaults.housenumbersbystreet_url,

         '#address_service_billing_different_apartment',
         aquicontainer.aqui.defaults.aptnumbersbyhousenumber_url,

         aquicontainer.aqui.defaults.auth_header
       );
    }

    if($('*[data-address-builder] input[name="ns-address-zip"]').length ||
       $('*[data-address-builder] input[name="ns-address-city"]').length ||
       $('*[data-address-builder] input[name="ns-address-street-name"]').length ||
       $('*[data-address-builder] input[name="ns-address-street-number"]').length ||
       $('*[data-address-builder] input[name="ns-address-street-apt"]').length) {

       billingTypeahead(
         '*[data-address-builder] input[name="ns-address-zip"]',
         aquicontainer.aqui.defaults.zipcodes_url,

         '*[data-address-builder] input[name="ns-address-city"]',
         aquicontainer.aqui.defaults.citiesbyzip_url,

         '*[data-address-builder] input[name="ns-address-street-name"]',
         aquicontainer.aqui.defaults.streetsbycity_url,

         '*[data-address-builder] input[name="ns-address-street-number"]',
         aquicontainer.aqui.defaults.housenumbersbystreet_url,

         '*[data-address-builder] input[name="ns-address-street-apt"]',
         aquicontainer.aqui.defaults.aptnumbersbyhousenumber_url,

         aquicontainer.aqui.defaults.auth_header
       );
    }

    if($('*[data-address-builder-auth] input[name="ns-address-zip"]').length ||
       $('*[data-address-builder-auth] input[name="ns-address-city"]').length ||
       $('*[data-address-builder-auth] input[name="ns-address-street-name"]').length ||
       $('*[data-address-builder-auth] input[name="ns-address-street-number"]').length ||
       $('*[data-address-builder-auth] input[name="ns-address-street-apt"]').length) {

       billingTypeahead(
         '*[data-address-builder-auth] input[name="ns-address-zip"]',
         aquicontainer.aqui.defaults.zipcodes_url,

         '*[data-address-builder-auth] input[name="ns-address-city"]',
         aquicontainer.aqui.defaults.citiesbyzip_url,

         '*[data-address-builder-auth] input[name="ns-address-street-name"]',
         aquicontainer.aqui.defaults.streetsbycity_url,

         '*[data-address-builder-auth] input[name="ns-address-street-number"]',
         aquicontainer.aqui.defaults.housenumbersbystreet_url,

         '*[data-address-builder-auth] input[name="ns-address-street-apt"]',
         aquicontainer.aqui.defaults.aptnumbersbyhousenumber_url,

         aquicontainer.aqui.defaults.auth_header
       );
    }

  }

  // Account Address ID Fields
  $('*[data-address-builder] input[name="ns-address-city"]').on('typeahead:selected', function(event, result) {
    // console.log(result, au_CitiesObj[result.value]);
    $('*[data-address-builder] input[name="ns-address-city-id"]').val(au_CitiesObj[result.value].CityID);
    $('*[data-address-builder] input[name="ns-address-state-id"]').val(au_CitiesObj[result.value].StateID);
  });

  $('*[data-address-builder] input[name="ns-address-street-name"]').on('typeahead:selected', function(event, result) {
    $('*[data-address-builder] input[name="ns-address-street-name-id"]').val(au_StreetsObj[result.value].StreetID);
  });

  // Secondary
  $('*[data-address-builder-auth] input[name="ns-address-city"]').on('typeahead:selected', function(event, result) {
    // console.log(result, au_CitiesObj[result.value]);
    $('*[data-address-builder-auth] input[name="ns-address-city-id"]').val(au_CitiesObj[result.value].CityID);
    $('*[data-address-builder-auth] input[name="ns-address-state-id"]').val(au_CitiesObj[result.value].StateID);
  });

  $('*[data-address-builder-auth] input[name="ns-address-street-name"]').on('typeahead:selected', function(event, result) {
    $('*[data-address-builder-auth] input[name="ns-address-street-name-id"]').val(au_StreetsObj[result.value].StreetID);
  });

  // console.log(aquicontainer.aqui.stage1_defaults.websiteBase);

  // Different Billing Address ID fields
  $('#address_service_billing_different_city').on('typeahead:selected', function(event, result) {
    // console.log(result, au_CitiesObj[result.value]);
    $('#address_service_billing_different_city_id').val(au_CitiesObj[result.value].CityID);
    $('#address_service_billing_different_state_id').val(au_CitiesObj[result.value].StateID);
  });

  $('#address_service_billing_different_street_name').on('typeahead:selected', function(event, result) {
    $('#address_service_billing_different_street_name_id').val(au_StreetsObj[result.value].StreetID);
  });

  $("#address_service_billing_different input[placeholder]").on('focus', function(){
      $(this).attr("data-placeholder", $(this).attr("placeholder"));
      $(this).attr("placeholder", "");
  });

  $("#address_service_billing_different input[placeholder]").on('blur', function(){
      $(this).attr("placeholder", $(this).attr("data-placeholder"));
  });


  checkAddressLS = function() {

		if(localStorage.getItem(lsLabel_full_address) && localStorage.getItem(lsLabel_full_address).length) {

			// console.log(localStorage.getItem(lsLabel_full_address));

      // Adjust for the show-for-cookie field
      $('[data-show-for-cookie]').each(function() {
        var dataCookie = $(this).attr('data-show-for-cookie');

        if(localStorage.getItem(dataCookie) && localStorage.getItem(dataCookie) !== 'null') {
          $(this).removeClass('hide');
          // Alert
          $('[data-cookie-alert="'+dataCookie+'"]').addClass('hide');
        } else {
          $(this).addClass('hide');
          if($(this).find('input').prop('checked')) {
            if($(this).prev().find('input').length) {
              $(this).prev().find('input').prop('checked', true).change();
            }
            // Alert
            $('[data-cookie-alert="'+dataCookie+'"]').removeClass('hide');
          }
        }
      });

      $('.show-for-feac').each(function() {
        $(this).addClass('show');
      });

      $('.hide-for-feac').each(function() {
        $(this).addClass('hide');
      });

      $('[data-hide-for-cookie]').each(function() {
        var dataCookie = $(this).attr('data-hide-for-cookie');

        console.log(dataCookie);
        console.log(localStorage.getItem(dataCookie));

        if(localStorage.getItem(dataCookie) && localStorage.getItem(dataCookie) !== 'null') {
          $(this).addClass('hide');
          console.log('set hide');
        } else {
          console.log('remove hide');
          $(this).removeClass('hide');
        }
      });

			$('#availability-status .full-address').text(localStorage.getItem(lsLabel_full_address));
			$('#availability-status .check').css({'display':'none'});
			$('#availability-status .avail-icon.alert').css({'display':'none'});

      $('.button.next-step').each(function() {
        $(this).attr('data-state', localStorage.getItem('lumos_address_state'));
      });

      // send state data to gtm
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'data-state': localStorage.getItem('lumos_address_state')});

      if(localStorage.getItem(lsLabel_address_status) !== 'false') {
				$('#availability-status .avail-icon.no').css({'display': 'none'});
				$('#availability-status .avail-icon.yes').css({'display': 'inline-block'});

        if($('#account_service_geocoded').length) {
          $('#account_service_geocoded').val(localStorage.getItem(lsLabel_full_address));
          $('#service_address_final').text(localStorage.getItem(lsLabel_full_address));

          $('#account_service_check_address').text('Change Address');
          // $('#address-checker').removeClass('open');
        }

			} else {
				$('#availability-status .avail-icon.no').css({'display': 'inline-block'});
				$('#availability-status .avail-icon.yes').css({'display': 'none'});

        if($('#account_service_geocoded').length) {
					$('#account_service_geocoded').val( '' );
					$('#account_service_check_address').text('Set Address');
				}
			}

			$('#availability-status .avail-icon.edit').css({'display': 'inline-block'});

		} else {
			$('#availability-status .avail-icon.alert').css({'display': 'inline-block'});
			$('#availability-status .avail-icon.no').css({'display': 'none'});
			$('#availability-status .avail-icon.yes').css({'display': 'none'});

			$('#availability-status .check').css({'display':'inline-block'});
			$('#availability-status .full-address').text('');

			$('#availability-status .avail-icon.edit').css({'display': 'none'});

      $('.show-for-feac').each(function() {
        $(this).removeClass('show');
      });

      $('.hide-for-feac').each(function() {
        $(this).removeClass('hide');
      });
		}
	}

  setAddressCookieData = function(addressData) {

    if(addressData.SupportsGigabit || addressData.LumosService) {
      // localStorage.setItem(lsLabel_place_id, placeID);
      localStorage.setItem(lsLabel_location_id, addressData.LocationID);
      localStorage.setItem(lsLabel_address_id, addressData.Id);
      localStorage.setItem(lsLabel_full_address, addressData.FullAddress);
      localStorage.setItem(lsLabel_address_status, true);

      localStorage.setItem(lsLabel_address_city, addressData.City);
      localStorage.setItem(lsLabel_address_city_id, addressData.CityID);
      localStorage.setItem(lsLabel_address_state, addressData.State);
      localStorage.setItem(lsLabel_address_state_id, addressData.StateID);
      localStorage.setItem(lsLabel_address_street, addressData.Address);
      localStorage.setItem(lsLabel_address_street_id, addressData.StreetID);
      localStorage.setItem(lsLabel_address_zip, addressData.Zip);
      localStorage.setItem(lsLabel_address_house_num , addressData.HouseNum);
      localStorage.setItem(lsLabel_address_apt_num, addressData.ApartmentNum);

      localStorage.setItem(lsLabel_address_check_decline, 1);

      localStorage.setItem(lsLabel_address_multigig, addressData.MultiGIG);

      $('[data-multigig-notice]').addClass('hide');

    } else {

      // localStorage.setItem(lsLabel_place_id, placeID);
      localStorage.setItem(lsLabel_location_id, '-1');
      localStorage.setItem(lsLabel_address_id, '-1');
      localStorage.setItem(lsLabel_address_status, false);

      localStorage.setItem(lsLabel_address_check_decline, 1);

    }

    checkAddressLS();
  }

  if(urlParamNS('address')) {
    var addressData = aquicontainer.aqui.fetchAddrById(urlParamNS('address'));
    console.log(addressData);

    window.history.replaceState({}, document.title, window.location.href.split('?')[0]);

    setAddressCookieData(addressData);
  }

	checkAddressLS();

  if(window.location.hash == '#checkaddress') {
    toggleAddressChecker();
    // $('#address-checker').toggleClass('open');
  }

});
