(function($) {

  var ns_exit_intent_shown = false;
  var can_mouseout = true;
  var can_clickout = true;

  // console.log('exit intent loaded');

  if ($('#order-info').length && !localStorage.getItem('ns_exit_intent_shown')) {
    setTimeout(() => {
      $('.exit-intent-popup, .close-exit-intent').on('click', function(e) {
        closeExitIntent();
      });

      $(document).on('keydown', function(e) {
        var shouldExit =
          (e.target.className === 'close' || // user clicks on the close icon
          e.keyCode === 27); // user hits escape

        if (shouldExit) {
          console.log('hide popup');
          closeExitIntent();
        }
      });

      $(document).on('mouseout', function(e) {
        if(e.clientY < 10 && !ns_exit_intent_shown && can_mouseout) {
          console.log('show popup');
          can_mouseout = false;
          if($('#order-step-one').length) {
            showExitIntent();
          }
        }
      });

      $('a:not([target="_blank"]):not([href^="#"]):not([href^="tel:"]):not(.ga-availability-button)').on('click', function(e) {
        if(!ns_exit_intent_shown && can_clickout) {


          can_clickout = false;
          setTimeout(function() {
            can_clickout = true;
          }, 5000);

          if($('#order-step-one').length) {
            e.preventDefault();
            showExitIntent();

            var linkHREF = $(this).attr('href');
            var leaveClick = $('.close-exit-intent.exit-leave').on('click', function() {
              window.location = linkHREF;
            });
          }
        }
      });
    }, 0);
  }

  function showExitIntent() {
    // Only show if order processing isn't doing it's thing
    if(!$('#orderSubmitModal').hasClass('active')) {
      if($('[data-group-type="internet"] input[type="radio"][data-exit-message]:checked').length) {
        var exitMessage = decodeURIComponent($('[data-group-type="internet"] input[type="radio"]:checked').attr('data-exit-message').replace(/\+/g, '%20'));
        $('.exit-intent-popup [data-exit-message]').html(exitMessage);
        $('.exit-intent-popup .order-savings').removeClass('hide');
      } else {
        $('.exit-intent-popup .order-savings').addClass('hide');
      }

      $('.exit-intent-popup').addClass('visible');
    }
    // ns_exit_intent_shown = true;
  }

  function closeExitIntent() {
    $('.exit-intent-popup').removeClass('visible');
  }

})(jQuery);
