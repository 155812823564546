jQuery(document).ready(function($) {

  // Remove the doing_wp_cron thing
  setTimeout(function() {
    history.replaceState && history.replaceState(
      null, '', location.pathname + location.search.replace(/[\?&]doing_wp_cron=[^&]+/, '').replace(/^&/, '?')
    );
  }, 500);

  $('.button.help').click(function(e) {
    e.preventDefault();
  });

  // $('a:empty').each(function() {
  //   $(this).remove();
  // });

  if(window.location.hash) {
    console.log(window.location.hash);
    if($(window.location.hash).length) {
      $('html, body').animate({
        scrollTop: $(window.location.hash).offset().top - ($('.top-nav').outerHeight()  || 0) - ($('.submenublock').outerHeight()  || 0)
      }, 500);
    }
  }

  if(urlParamNS('tune_offer_id')) {
    sessionStorage.setItem('tune_offer_id', urlParamNS('tune_offer_id'));
  }

});


function formatDate(objDate) {
  var d = objDate;
  // console.log(d);
  if(!(objDate instanceof Date)) {
    d = new Date(objDate);
  }
  // console.log(d);
  return (d.getMonth()+1) + '/' + d.getDate() + '/' + d.getFullYear();
} // end formatDate()

function convertDateForIos(date) {
  var arr = date.split(/[- :]/);
  date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
  return date;
}

function formatDateAPI(date) {
  var d = date;
  if(!(date instanceof Date)) {
    d = new Date(date);
  }
  var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes(),
      second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day].join('-') + ' ' + [hour, minute, second].join(':');
}

function formatPhone(phone) {
  var phone_format = String(phone);
  phone_format = phone_format.replace(/\D[^\.]/g, "");
  phone_format = phone_format.slice(0,3)+"-"+phone_format.slice(3,6)+"-"+phone_format.slice(6);

  return phone_format;
} // formatPhone(phone)

function countProperties (obj) {
  var count = 0;

  for (var property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      count++;
    }
  }
  return count;
}

function isCookiesEnabled(){
  var cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled){
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
  }
  return cookieEnabled;
}


// (function(){
//   // Save the original method in a private variable
//   var _privateLog = console.log;
//   // Redefine console.log method with a custom function
//   console.log = function (message) {
//     // Here execute something with the given message or arguments variable
//     // alert("Our Custom Log Says: " + message);
//     // if(typeof hj !== "undefined") {
//     //   // Fire HotJar event
//     //   var eventLog = 'ConsoleLog: ' + JSON.stringify(message);
//     //   hj('event', eventLog);
//     // }
//
//     /**
//       Note: If you want to preserve the same action as the original method does
//       then use the following line :
//
//       we use apply to invoke the method on console using the original arguments.
//       Simply calling _privateLog(message) would fail because LOG depends on the console
//      */
//     _privateLog.apply(console, arguments);
//   };
// })();
