jQuery(document).ready(function($) {

  var myAccountURL = 'https://qa-www.northstate.net/';

  $.ajax({
      url:  '/wp-content/themes/Lumos/api_helper.php',
      type: 'GET',
      // dataType: "json",
      async: false,
      data: {
          "action": "get_ns_myaccount_url"
      },
      success: function(result){
        // console.log(result);
        myAccountURL = result;
      }
  });

  var authurl = myAccountURL+"api/user/Authenticate";//?logIn="+username+"&password="+password;
  var accountpageurl = myAccountURL+"MyAccount";



  // if(Cookies.get('account_logged_in')) {
  //
  //   var accountpageurl = "https://qa-www.northstate.net/MyAccount/";
  //
  //   $('a[href="#ns-account-login"], .off-canvas-menu a[href="#ns-account-login"]').text('My Account');
  //
  //   $('a[href="#ns-account-login"]').attr('href', accountpageurl);
  //   $('.off-canvas-menu a[href="#ns-account-login"]').attr('href', accountpageurl);
  //
  // } else {

    $('.off-canvas-menu a[href$="#ns-account-login"]').click(function(e) {
      if(window.location.href.indexOf("/my-account/") > -1) {
        // Don't do anything
      } else {
        e.preventDefault();
        $('#off-canvas').foundation('close');
      }

    });

    $('a[href$="#ns-account-login"]').click(function(e) {
      if(window.location.href.indexOf("/my-account/") > -1) {
        // Don't do anything
      } else {
        e.preventDefault();

        $('#ns-account-login').css({'top': ($('#utility-nav').outerHeight() || 0) + ($('#wpadminbar').outerHeight() || 0) + ($('.customer-alert').outerHeight() || 0)});

        $('#ns-account-login').toggleClass('open');
      }
    });

    $(document).click(function(e) {
      // console.log(e.target);
      // console.log($(e.target).closest(".ns-login-page").length)
      if(!$(e.target).closest(".ns-login-page").length && !$(e.target).closest('a[href*="#ns-account-login"]').length) {
        $('#ns-account-login').removeClass('open');
      }
    });

  // }

  $('.ns-account-login-overlay').click(function() {
    $('#ns-account-login').toggleClass('open');
  });

  function loginAndRedirect(username, password) {

    var loginURL = authurl + "?logIn=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password);

    $.ajax({
      type: "GET",
      url: loginURL,
      dataType: 'jsonp',
      xhrFields: {
           withCredentials: true
      },
      crossDomain: true
    }).done(function() {
      alert("success");
    }).fail(function() {
      sessionStorage.setItem('account_logged_in', true);
      $("#btnLogIn, #btnLogIn_block").text("Connecting...");
      window.location.href = accountpageurl;
    });
  }


  $("#btnLogIn, #btnLogIn_block").on("click", function() {

    var $btnLogin = $(this);

    $btnLogin.parents('form').siblings('.loading').removeClass('hide');

    var defaultButtonText = $(this).text();

    var username = "",
        password = "";

    if($(this).attr('id') === "btnLogIn") {
      username = $("#authUsername").val();
      password = $("#authPassword").val();
    } else {
      username = $("#authUsername_block").val();
      password = $("#authPassword_block").val();
    }

    // console.log(username, password);

    var loginURL = authurl + "?logIn=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password);

    // console.log(loginURL);

    if(username !== "" && password !== "") {
      $(this).prop('disabled', true);
      $(this).text("Please wait...");

      $.get( loginURL, function( data ) {
        if(data === true) {
          // console.log(username, password);
          loginAndRedirect(username, password);
        } else {
          $btnLogin.parents('form').siblings('.loading').addClass('hide');
          // alert("auth failed");
          sessionStorage.setItem('account_logged_in', false);
          $('#nsAccountAuthenticateForm div[data-abide-error], #nsAccountAuthenticateForm_block div[data-abide-error]').show();
          $("#btnLogIn, #btnLogIn_block").prop('disabled', false);
          $("#btnLogIn, #btnLogIn_block").text(defaultButtonText);
        }
      });
    } else {
      $btnLogin.parents('form').siblings('.loading').addClass('hide');
    }
  });


  $("#btnForgotPass, #btnForgotPass_block").on("click", function() {

    var $btnForgot = $(this);

    $btnForgot.parents('form').siblings('.loading').removeClass('hide');

    var defaultButtonText = $(this).text();

    $(this).prop('disabled', true);
    $(this).text("Please wait...");

    var username = "";

    if($(this).attr('id') === "btnForgotPass") {
      username = $("#authUsernameForgot").val();
    } else {
      username = $("#authUsernameForgot_block").val();
    }

    $.ajax({
			url: '/wp-content/themes/Lumos/api_helper.php',
			method: 'POST',
			contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "forgot_password",
        username : username
      },
			success: function(result) {
        $btnForgot.parents('form').siblings('.loading').addClass('hide');
        var response = $.parseJSON(result);
        // console.log(response.Data);
        if(response.Data) {
          $('#nsAccountForgotPasswordForm div[data-abide-error], #nsAccountForgotPasswordForm_block div[data-abide-error]').hide();
          $('#nsAccountForgotPasswordForm div[data-abide-success], #nsAccountForgotPasswordForm_block div[data-abide-success]').show();
          $("#btnForgotPass, #btnForgotPass_block").prop('disabled', false);
          $("#btnForgotPass, #btnForgotPass_block").text(defaultButtonText);
          $('#authUsernameForgot, #authUsernameForgot_block').val('');
        } else {
          $('#nsAccountForgotPasswordForm div[data-abide-success], #nsAccountForgotPasswordForm_block div[data-abide-success]').hide();
          $('#nsAccountForgotPasswordForm div[data-abide-error], #nsAccountForgotPasswordForm_block div[data-abide-error]').show();
          $("#btnForgotPass, #btnForgotPass_block").prop('disabled', false);
          $("#btnForgotPass, #btnForgotPass_block").text(defaultButtonText);
        }
      },
      error: function(result) {
        $btnForgot.parents('form').siblings('.loading').addClass('hide');
        $('#nsAccountForgotPasswordForm div[data-abide-success], #nsAccountForgotPasswordForm_block div[data-abide-success]').hide();
        $('#nsAccountForgotPasswordForm div[data-abide-error], #nsAccountForgotPasswordForm_block div[data-abide-error]').show();
        $("#btnForgotPass, #btnForgotPass_block").prop('disabled', false);
        $("#btnForgotPass, #btnForgotPass_block").text(defaultButtonText);
      }
    });

  });

  $("#btnForgotUsername, #btnForgotUsername_block").on("click", function() {

    var $btnForgot = $(this);

    $btnForgot.parents('form').siblings('.loading').removeClass('hide');

    var defaultButtonText = $(this).text();

    $(this).prop('disabled', true);
    $(this).text("Please wait...");

    var account = "",
        pin     = "";

    if($(this).attr('id') === "btnForgotUsername") {
      account = $("#forgot_accNum").val();
      pin     = $("#forgot_pin").val();
    } else {
      account = $("#forgot_accNum_block").val();
      pin     = $("#forgot_pin_block").val();
    }

    $.ajax({
			url: '/wp-content/themes/Lumos/api_helper.php',
			method: 'POST',
			contentType: 'application/x-www-form-urlencoded',
      data: {
        action: "forgot_username",
        account : account,
        pin : pin
      },
			success: function(result) {
        $btnForgot.parents('form').siblings('.loading').addClass('hide');
        // console.log(result);
        var response = $.parseJSON(result);
        // console.log(response.Data);
        if(response.Data) {
          $('#nsAccountForgotUsernameForm div[data-abide-error], #nsAccountForgotUsernameForm_block div[data-abide-error]').hide();
          $('#nsAccountForgotUsernameForm div[data-abide-success], #nsAccountForgotUsernameForm_block div[data-abide-success]').show();
          $("#btnForgotUsername, #btnForgotUsername_block").prop('disabled', false);
          $("#btnForgotUsername, #btnForgotUsername_block").text(defaultButtonText);
          $('#forgot_accNum, #forgot_accNum_block').val('');
          $('#forgot_pin, #forgot_pin_block').val('');
        } else {
          $('#nsAccountForgotUsernameForm div[data-abide-success], #nsAccountForgotUsernameForm_block div[data-abide-success]').hide();
          $('#nsAccountForgotUsernameForm div[data-abide-error], #nsAccountForgotUsernameForm_block div[data-abide-error]').show();
          $("#btnForgotUsername, #btnForgotUsername_block").prop('disabled', false);
          $("#btnForgotUsername, #btnForgotUsername_block").text(defaultButtonText);
        }
      },
      error: function(result) {
        $btnForgot.parents('form').siblings('.loading').addClass('hide');

        $('#nsAccountForgotUsernameForm div[data-abide-success], #nsAccountForgotUsernameForm_block div[data-abide-success]').hide();
        $('#nsAccountForgotUsernameForm div[data-abide-error], #nsAccountForgotUsernameForm_block div[data-abide-error]').show();
        $("#btnForgotUsername, #btnForgotUsername_block").prop('disabled', false);
        $("#btnForgotUsername, #btnForgotUsername_block").text(defaultButtonText);
      }
    });
  });

  $('.ns-account-login-form .page-button').click(function(e) {
    e.preventDefault();
    var pageJump = $(this).attr('data-goto-page');
    $('.login-page').each(function() {
      $(this).addClass('hide');
    });

    $('.login-page[data-page-order='+pageJump+']').removeClass('hide');
  });

  $('.ns-account-login-form .backToLogin').click(function(e) {
    e.preventDefault();
    $('.login-page').each(function() {
      $(this).addClass('hide');
    });

    $('.login-page[data-page-order=1]').removeClass('hide');
  });

  $('.acct-edit-toggle').each(function(index, element) {
    $(element).click(function() {
      var target = $('#' + $(element).attr('data-accordion-target')).find('.accordion-content');
      var closebtn = $(element).siblings('.close-btn');
      if (($(target).attr('aria-hidden') === 'true')) {
        $(target).attr('aria-hidden', 'false').slideToggle(250);
        $(element).hide();
        $(element).siblings('.action').hide();
        $(closebtn).show();
      }
    });
  });

  $('.close-btn').each(function(index, element) {
    $(element).click(function() {
      var target = $('#' + $(element).attr('data-accordion-target')).find('.accordion-content');
      if (($(target).attr('aria-hidden') === 'false') || ($(target).attr('aria-hidden') === 'undefined')) {
        $(target).attr('aria-hidden', 'true').slideToggle(250);
        $(element).siblings('.action').show();
        $(element).hide();
      }
    });
  });

  function showRadioContent() {
    $('[data-toggle-radio]').each(function(key, element) {
      var contentToggle = $(element).attr('data-toggle-radio');
      var checked = $(element).is(':checked');

      if (checked) {
        $('#' + contentToggle).removeClass('hide');
        $(this).closest('.method-wrapper').addClass('open');
      }
      else {
        $('#' + contentToggle).addClass('hide');
        $(this).closest('.method-wrapper').removeClass('open');
      }
    });
  }

  showRadioContent();

  $('[data-toggle-radio]').click(function() {
    showRadioContent();
  });

  $('.tabs-title > a').click(function() {
    var radio = $(this).find('input[type="radio"]');
    if (radio.length > 0) {
      $(radio).prop('checked', true);
    }
  });

  if($('.account-show-nc').length) {
    if(typeof ns_session_type !== 'undefined' && ns_session_type == "NC") {
      $('.account-show-nc').removeClass('hide');
    } else {
      $('.account-show-nc').addClass('hide');
    }

  }

});
