jQuery(document).ready(function($) {
  $('.business-slider').on('init breakpoint', function (event, slick, direction) {
    // console.log($('.business-slider .slick-slide').length);
    // check to see if there are one or less slides
    if (!($('.business-slider .slick-slide').length > 1)) {
      // remove dots
      $('.business-slider .slick-dots').hide();
    }
  });

  $('.business-slider').slick({
    dots: true,
    arrots: false,
    slide: '.business-slide',
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    cssEase: 'ease-out',
    responsive: [{
      breakpoint: 640,
      settings: {
        fade: false,
      }
    }]
  });

  $('.business-slider .to-next-section').click(function(e) {
    e.preventDefault();

    var $nextElement = $(this).parents('div.business-slider').next('div');

    $('html, body').animate({
        scrollTop: $nextElement.offset().top - ($('.top-nav').outerHeight() || 0)
    }, 700);
  });

});
