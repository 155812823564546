var $ = jQuery;

if(!adjustDdSize){
    var adjustDdSize = function(){
        //console.log("calling adjustDdSize");
        var $this = $(this);
        $this.parent().find('.tt-dropdown-menu').width($this.css('width'));
    };
}

if(!substringMatcher){
    var substringMatcher = function(strs) {
        return function findMatches(q, cb) {
            var matches, substringRegex;

            // an array that will be populated with substring matches
            matches = [];

            // regex used to determine if a string contains the substring `q`
            substrRegex = new RegExp(q, 'i');

            // iterate through the pool of strings and for any string that
            // contains the substring `q`, add it to the `matches` array
            $.each(strs, function(i, str) {
              if (substrRegex.test(str)) {
                // the typeahead jQuery plugin expects suggestions to a
                // JavaScript object, refer to typeahead docs for more info
                matches.push({ value: str });
              }
            });

            cb(matches);
        };
    };
}

// if(!ga) {
//     // for clients not implementing Google Analytics.
//     var ga = function() {};
// }



(function ( $ ) {

    $.fn.lookupServiceAvailability = function(options) {

      var addressQualBase = 'https://addressqualificationdev.nscom.com',
          addressQualCreds = 'Basic YXFsaWI6ZDk5NWtBMSNyMiE=';

        $.ajax({
            url:  '/wp-content/themes/Lumos/api_helper.php',
            type: 'GET',
            dataType: "json",
            async: false,
            data: {
                "action": "get_ns_address_val_creds"
            },
            success: function(result){
                // console.log(result);

                addressQualBase = result.url;
                addressQualCreds = result.access;

                // console.log(addressQualBase, addressQualCreds);
            }
        });

        $.fn.lookupServiceAvailability.defaults = {
            // scriptBase: "https://cdnqa.nscom.com/js/aqui/",
            scriptBase: "https://cdn.nscom.com/js/aqui/",
            typeahead_url: "https://cdnjs.cloudflare.com/ajax/libs/corejs-typeahead/0.10.3/typeahead.jquery.min.js",
            addressQualificationBase: addressQualBase,
            auth_header: addressQualCreds,
            typeahead_opts: {
                hint: true,
                highlight: true,
                minLength: 3
            },
            statuses: {

            }
        }

        $.fn.lookupServiceAvailability.aptOptions = [];
        $.fn.lookupServiceAvailability.AccountID = null;
        $.fn.lookupServiceAvailability.SelectedAddress = null;

        options = $.extend( {}, $.fn.lookupServiceAvailability.defaults, options );

        var elem = this;

        $.fn.lookupServiceAvailability.aptSelectedHandler = function(selection){
            //console.log(selection);
            $.fn.lookupServiceAvailability.SelectedAddress = selection.addrdata;
        }

        var bindGoogleAddressTypeAhead = function(elem){
            return $(elem).typeahead(
                options.typeahead_opts,
                {
                    source: function (query, process) {
                        //console.log(query);
                        query = query.replace(/\ /g, "+");
                        return $.getJSON(options.scriptBase + '/googleplacelookup.php', { action: "autocomplete", query: query }, function (data) {
                            outdata = [];
                            if(data.status == "OK"){
                                outdata = $.map(data.predictions, function(obj, idx){
                                    return {place_id: obj.place_id, value: obj.description.replace(/, United States/,'').replace(/, USA/,''), terms: obj.terms}
                                });
                            }
                            else{
                                 $(elem).trigger("noMatchOnGooglePlaces", query);

                            }
                            return process(outdata);
                        });
                    }
                }
            );
        }

        $.fn.lookupServiceAvailability.googleAddressTypeAheadSelectedHandler = function(elem, event, selection){
            //console.log(selection);
            //console.log(options);
            var place_id = typeof selection !== 'undefined' ? selection.place_id : 'xxxxxxxx';

            $.ajax({
                url:  options.scriptBase + '/googleplacelookup.php',
                type: 'GET',
                dataType: "json",
                async: false,
                data: {
                    "action": "geocode",
                    "query": selection.value
                },
                success: function(result){
                    place_id = result.results.length > 0 ? result.results[0].place_id : 'xxxxx';
                    //console.log(place_id);
                }
            });

            return $.ajax({
                url:  options.addressQualificationBase +'/GetAddressByGooglePlaceId/' + place_id,
                async: false,
                type: 'GET',
                dataType: "json",
                beforeSend: function (xhr){
                    xhr.setRequestHeader('Authorization', options.auth_header);
                },
                success: function(result){
                    if(result.Status == "success"){
                        addressData = result.Data;
                        //console.log("res: ",result);
                        if(addressData.length > 1){
                            $.fn.lookupServiceAvailability.aptOptions = $.map(result.Data, function(val, idx){
                                return {addrdata: val, value: val.ApartmentNum}
                            });

                            // set the SelectedAddress to the result with no ApartmentNum
                            var addr = _.filter(result.Data, function(addr){ return addr.ApartmentNum == null; });
                            $.fn.lookupServiceAvailability.SelectedAddress = addr[0];
                        }
                        else{
                            $.fn.lookupServiceAvailability.SelectedAddress = addressData[0];
                        }
                        if(addressData){
                            $(elem).trigger("foundAddressByGooglePlaceId", [addressData]);
                        }
                    }
                },
                error: function(){
                    $(elem).trigger("addressByGooglePlaceIdNotFound", selection);
                }
            });
        }

        $.when(jQuery().typeahead ||  $.getScript( options.typeahead_url))
        .done(function(){
            bindGoogleAddressTypeAhead(elem)
            .on('typeahead:selected', function (event, selection) {
                //console.log('typeahead:selected:start');
                $("#loading-icon").show(); $("#checkaddress").attr("disabled", "disabled");
                $.fn.lookupServiceAvailability.googleAddressTypeAheadSelectedHandler(elem, event, selection);
                $("#loading-icon").hide(); $("#checkaddress").removeAttr("disabled");
                //console.log('typeahead:selected:stop');
                // console.log(selection.terms[3].value);
                $("#checkaddress").attr('data-state', selection.terms[3].value);
            })
            .on('typeahead:opened', function (event){
                //console.log("typeahead:opened");
            });
        });

        return this.each(function() {
            var elem = $(this);
        });

    }

    $.fn.aqui = function(options) {
      var addressQualBase = 'https://addressqualificationdev.nscom.com',
          addressQualCreds = 'Basic YXFsaWI6ZDk5NWtBMSNyMiE=';

      $.ajax({
          url:  '/wp-content/themes/Lumos/api_helper.php',
          type: 'GET',
          dataType: "json",
          async: false,
          data: {
              "action": "get_ns_address_val_creds"
          },
          success: function(result){
              // console.log(result);

              addressQualBase = result.url;
              addressQualCreds = result.access;

              // console.log(addressQualBase, addressQualCreds);
          }
      });

      $.fn.aqui.stage1_defaults = {
          // scriptBase: "https://cdnqa.nscom.com/js/aqui",
          scriptBase: "https://cdn.nscom.com/js/aqui",
          // addressQualificationBase: "https://addressqualificationdev.nscom.com",
          addressQualificationBase: addressQualBase,
          websiteBase: "https://northstate.smallfootprint.net"
      };

        stage1_options = $.extend( {}, $.fn.aqui.stage1_defaults, options );

        $.fn.aqui.defaults = {
            scriptBase: stage1_options.scriptBase,
            addressQualificationBase: stage1_options.addressQualificationBase,
            address_id: null,
            typeahead_url: "https://cdnjs.cloudflare.com/ajax/libs/corejs-typeahead/0.10.3/typeahead.jquery.min.js",
            fastclick_url: "https://cdnjs.cloudflare.com/ajax/libs/fastclick/1.0.6/fastclick.min.js",
            inputmask_url: "https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.3/jquery.mask.min.js",
            geolocation_url: "https://cdnjs.cloudflare.com/ajax/libs/jQuery-Geolocation/1.0.50/jquery.geolocation.min.js",
            validaion_url: "https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.15.0/jquery.validate.min.js",
            // auth_header: 'Basic YXFsaWI6ZDk5NWtBMSNyMiE=',
            auth_header: addressQualCreds,
            street_field: "",
            underscore_url: "https://cdnjs.cloudflare.com/ajax/libs/underscore.js/1.8.3/underscore-min.js",
            zipcodes_url: stage1_options.addressQualificationBase + "/GetZipCodes/",
            citiesbyzip_url: stage1_options.addressQualificationBase + "/GetCitiesByZip/",
            streetsbycity_url: stage1_options.addressQualificationBase + "/GetStreetsByCity/",
            housenumbersbystreet_url: stage1_options.addressQualificationBase + "/GetHouseNumbersByStreet/",
            aptnumbersbyhousenumber_url: stage1_options.addressQualificationBase + "/GetApartmentNumbersByHouseNumber/",
            addressbyId_url: stage1_options.addressQualificationBase + "/GetAddressById/",
            informRequest_url: stage1_options.addressQualificationBase + "/InformRequest/",
            // getFiber_url: stage1_options.websiteBase + "/ShopFiber?page=PAGETOKEN&address=ADDRESSTOKEN&product=PRODUCTTOKEN",
            // myAccount_url: stage1_options.websiteBase + "/MyAccount/Authenticate",
            getFiber_url: stage1_options.websiteBase + "/shop-fiber?page=PAGETOKEN&address=ADDRESSTOKEN&product=PRODUCTTOKEN",
            myAccount_url: stage1_options.websiteBase + "/account-login",
            statuses: {

            },
            templates: {
                dropdown_search_form: stage1_options.scriptBase + '/dropdown-search-form.html',
                address_lookup_result: stage1_options.scriptBase + '/address-lookup-result.html',
                google_search_form: stage1_options.scriptBase + '/google-search-form.html',
                cookies_not_enabled: '/wp-content/themes/Lumos/assets/scripts/aquitemplates/cookies-not-enabled.html',
                results: {
                    account_exist_fiber_available: stage1_options.scriptBase + '/results_templates/account-exist-fiber-available.html',
                    account_exist_fiber_not_available: stage1_options.scriptBase + '/results_templates/account-exist-fiber-not-available.html',
                    fiber_available_congrat: stage1_options.scriptBase + '/results_templates/fiber-available-congrat.html',
                    fiber_available_congrat_lumos: stage1_options.scriptBase + '/results_templates/fiber-available-congrat-lumos.html',
                    fiber_coming_soon: stage1_options.scriptBase + '/results_templates/fiber-coming-soon.html',
                    low_tier_fiber_not_available: stage1_options.scriptBase + '/results_templates/low-tier-fiber-not-available.html',
                    no_services_planned: stage1_options.scriptBase + '/results_templates/no-services-planned.html',
                    no_services: stage1_options.scriptBase + '/results_templates/no-services.html',
                    address_not_found: stage1_options.scriptBase + '/results_templates/address-not-found.html'
                }
            },
            messages: {
                serviceRqCommingSoonMsg: "<p>Thank you for your interest in North State’s fiber service. We have added your contact information to our Fiber Updates list and we will notify you when that service is available in your area.<\/p>",
                serviceRqFiberNotAvailableMsg: "<p>Thank you for your interest in North State’s fiber service. We have added your contact information to our Fiber Updates list and we will notify you when that service is available in your area.<\/p>",
                //serviceRqCooperAvailableMsg: "<p>Thank you for your interest in North State’s fiber service. We have added your contact information to our Fiber Updates list and we will notify you when that service is available in your area.<\/p>",
                serviceRqCooperAvailableMsg: "<p>Your request has been received. A Customer Service Representative will contact you soon to discuss the services that are available in your area. Thank you for choosing North State.<\/p>",
                serviceRqBusinessMsg: "<p>Your request has been received. A Customer Service Representative will contact you soon to discuss the services that are available to your business. Thank you for choosing North State.<\/p>",
                prospectRqMsg: "<p>Thank you for your interest in North State’s fiber service. We will contact you about service.<\/p>"
            },
            informRequestSource: null,
        };

        var tmpl_cache = {};

        if(options){
            if(typeof options.templates !== 'undefined' && typeof options.templates.results !== 'undefined') {
                    options.templates.results = $.extend({}, $.fn.aqui.defaults.templates.results, options.templates.results);
            }
            if(typeof options.templates !== 'undefined') {
                options.templates = $.extend({}, $.fn.aqui.defaults.templates, options.templates);
            }
            if(typeof options.messages !== 'undefined') {
                options.messages = $.extend({}, $.fn.aqui.defaults.messages, options.messages);
            }
        }

    	options = $.extend( {}, $.fn.aqui.defaults, options );

        var elem = this;

        $.fn.aqui.selector = $(this).selector;

        var bindAptTypeAhead = function(aptElem) {

            $(aptElem).typeahead('destroy');
            return  $(aptElem).typeahead(
                {
                    hint: true,
                    highlight: true,
                    minLength: 0
                },
                {
                    source: function(query, process){
                        //console.log("APTS", $(elem).find("#street1").lookupServiceAvailability.aptOptions);

                        x = $.map( $(elem).find("#street1").lookupServiceAvailability.aptOptions, function(val, idx){
                            if(val.value == null){
                                val.value = "";
                            }
                            return {value: val.value, addrdata: val.addrdata};
                        });

                        x = _.sortBy(x , 'value');

                        process(
                            x
                        );
                    }
                });
        }

        var ShowCheckAddressMessage = function(selector, html, elem) {
            $(elem).html(html);

            $(".check-message").removeClass("active");
            $(".get-more-info-form").removeClass("active");
            setTimeout(function() {
                $(selector).addClass("active")
            }, 500);
            $("#check-address").parent().removeClass("active");
            $(".styled-form.bottom-form ").removeClass("active");
            $(elem).trigger("checkAddressMessageRendered", selector);
        };

        var getTemplatePartial = function(template_url) {
            //console.log("fetching partial:", template_url);
            var fetchedContent = null;
            $.ajax({
                url: template_url,
                method: 'GET',
                async: false,
                dataType: 'html',
                success: function(data){
                    fetchedContent = data;
                },
                error: function(){
                    alert('unabled to fetch template from ' + template_url);
                }
            });
            return fetchedContent;
        }

        var ComposeFullAddress = function(n, t) {
            var i = "";
            return t != undefined && t != "" ? i = "PO Box " + t : (n.HouseNumber != null && (i = n.HouseNumber.HouseNum), n.Street != null && (i = i + (n.Street.PreDirectional == null ? "" : " " + n.Street.PreDirectional) + " " + n.Street.Street + (n.Street.StreetSuffix == null ? "" : " " + n.Street.StreetSuffix) + (n.Street.PostDirectional == null ? "" : " " + n.Street.PostDirectional)), n.ApartmentNum != null && (i = i.trim() + " " + n.ApartmentNum.ApartmentNum)), n.City != null && (i = i.trim() + ", " + n.City.City + "," + (n.City.StateAbbreviation == null ? "" : " " + n.City.StateAbbreviation)), n.Zip != null && n.Zip != 0 && (i = i.trim() + " " + n.Zip), i
        };

        $.fn.aqui.informRequest = function(formData,msg) {
            d = new Date()
            formData.Timestamp = d.toISOString();
            if(!options.informRequestSource){
              var href = $(location).attr("href");
              if(href.length > 200){
                href = href.substring(0, 200) + '..';
              }
              options.informRequestSource = href;
            }
            formData.Source = options.informRequestSource;
            //console.log(formData);

            $.ajax({
              url: options.informRequest_url,
              async: false,
              type: "POST",
              data: JSON.stringify(formData),
              contentType: "application/json",
              beforeSend: function (xhr){
                xhr.setRequestHeader('Authorization', options.auth_header);
              },
              success: function(response) {
                addr = response.Data;
                //console.log(addr);
  	            if(typeof msg === 'function') {
  		            msg();
  	            } else {
                	$("#informSuccessMessage .message").html(msg);
                }

  	            $(".check-message").removeClass("active");
                $(".get-more-info-form").removeClass("active");
                $(".get-more-info-form select").each(function() {
                  $(this).find("option:first").prop("selected", !0).change()
                });
                $("#informSuccessMessage").addClass("active");
              }
            });
        }

        $.fn.aqui.hubspotRequest = function(formData, msg) {
          var hsFormData = {
            "fields": [
              {
                "name": "firstname",
                "value": formData.FirstName
              },
              {
                "name": "lastname",
                "value": formData.LastName
              },
              {
                "name": "phone",
                "value": formData.Phone
              },
              {
                "name": "email",
                "value": formData.Email
              },
              {
                "name": "address",
                "value": formData.AddressStreet
              },
              {
                "name": "city",
                "value": formData.AddressCity
              },
              {
                "name": "state",
                "value": formData.AddressState
              },
              {
                "name": "segment",
                "value": formData.Segment
              },
            ],
            "legalConsentOptions": {
              "consent": { // Include this object when GDPR options are enabled
                "consentToProcess": true,
                "text": "I agree to allow Example Company to store and process my personal data.",
                "communications": [
                  {
                    "value": true,
                    "subscriptionTypeId": 999,
                    "text": "I agree to receive marketing communications from Example Company."
                  }
                ]
              }
            }
          };

          $.ajax({
            type: "POST",
            url: "https://api.hsforms.com/submissions/v3/integration/submit/20327025/faaf2155-3914-4f0e-9e03-ee722d466a87",
            cache: false,
            contentType: 'application/json',
            processData: false,
            data: JSON.stringify(hsFormData),
            success: function (data) {
              console.log('Hubspot Submission was successful.');
              console.log(data);

              if(typeof msg === 'function') {
                msg();
              } else {
                $("#informSuccessMessage .message").html(msg);
              }

              $(".check-message").removeClass("active");
              $(".get-more-info-form").removeClass("active");
              $(".get-more-info-form select").each(function() {
                $(this).find("option:first").prop("selected", !0).change()
              });
              $("#informSuccessMessage").addClass("active");
            }
          });
        }

        $.fn.aqui.checkAddress = function(elem, addr, altaddr = '') {
            //console.log("checkAddress addr", addr);
            var tmpl_opts = {};
            var url = options.templates.address_lookup_result;
            var html = '';
            if(addr) {
                var compiled_template = null;
                $.loadTemplate(url).then(function(data){
                    compiled_template = _.template(data);
                    var fiber_url = options.getFiber_url
                    .replace("PAGETOKEN", "shop")
                    .replace("PRODUCTTOKEN", 1)
                    .replace("ADDRESSTOKEN", addr.Id);

                    tmpl_opts = {
                        getFiber_url: fiber_url,
                        myAccount_url: options.myAccount_url
                    };
                })
                .then(function(){
                    var predirection = addr.hasOwnProperty("PreDirectional") ? addr.PreDirectional == null ? "" : addr.PreDirectional + " " :  "";
                    var postdirection = (addr.PostDirectional == null ? "" : " " + addr.PostDirectional);
                    r = (predirection) + addr.StreetName + (addr.StreetSuffix == null ? "" : " " + addr.StreetSuffix) + postdirection;

                    console.log(addr);

                    localStorage.removeItem('lumos_address_market');

                    if((addr.FiberNIDBit == 0 || addr.FiberNIDBit == null) && (addr.LumosSamID !== null && addr.LumosService !== null && addr.LumosService !== '')) {
                      if(addr.LumosService.toLowerCase() == 'fiber') {
                        // tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.fiber_available_congrat);
                        // tmpl_opts["AddressID"] = addr.Id;
                        // tmpl_opts["AddressStreet"] = addr.HouseNum + ' ' + r;
                        // tmpl_opts["AddressCity"] = addr.City;
                        // tmpl_opts["AddressState"] = addr.State;
                        // html = compiled_template(tmpl_opts);
                        // html = _.template(html)(tmpl_opts);
                        // ShowCheckAddressMessage("#fiber-available-congrat", html, elem);

                        localStorage.setItem('lumos_address_market', 'va');
                        logNSEvent('ecomm - address checker - Service Available: ' + $.fn.aqui.fetchAddrById(addr.Id).FullAddress);
                        tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.fiber_available_congrat);
                        tmpl_opts["AddressID"] = addr.Id;
                        tmpl_opts["AddressStreet"] = addr.HouseNum + ' ' + r;
                        tmpl_opts["AddressCity"] = addr.City;
                        tmpl_opts["AddressState"] = addr.State;
                        html = compiled_template(tmpl_opts);
                        html = _.template(html)(tmpl_opts);
                        ShowCheckAddressMessage("#fiber-available-congrat", html, elem);

                      } else {
                        tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.low_tier_fiber_not_available);
                        tmpl_opts["serviceRqCooperAvailableMsg"] = options.messages.serviceRqCooperAvailableMsg;
                        tmpl_opts["AddressID"] = addr.Id;
                        tmpl_opts["AddressStreet"] = addr.HouseNum + ' ' + r;
                        tmpl_opts["AddressCity"] = addr.City;
                        tmpl_opts["AddressState"] = addr.State;
                        html = compiled_template(tmpl_opts);
                        html = _.template(html)(tmpl_opts);
                        ShowCheckAddressMessage("#low-tier-fiber-not-available", html, elem);
                      }
                    } else {
                      if(addr.AccountID != 0 && addr.AccountID != null){
                          if(addr.FiberNIDBit && addr.SupportsGigabit){
                              logNSEvent('ecomm - address checker - Account Exists: ' + $.fn.aqui.fetchAddrById(addr.Id).FullAddress);
                              tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.account_exist_fiber_available);
                              html = compiled_template(tmpl_opts);
                              html = _.template(html)(tmpl_opts);
                              ShowCheckAddressMessage("#account-exist-fiber-available", html, elem);
                          }
                          else{
                              logNSEvent('ecomm - address checker - Account Exists/Fiber Not Available: ' + $.fn.aqui.fetchAddrById(addr.Id).FullAddress);
                              tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.account_exist_fiber_not_available);
                              html = compiled_template(tmpl_opts);
                              html = _.template(html)(tmpl_opts);
                              ShowCheckAddressMessage("#account-exist-fiber-not-available", html, elem);
                          }
                      } else {
                          if(addr.FiberNIDBit && addr.SupportsGigabit) {
                              localStorage.setItem('lumos_address_market', 'nc');
                              logNSEvent('ecomm - address checker - Service Available: ' + $.fn.aqui.fetchAddrById(addr.Id).FullAddress);
                              tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.fiber_available_congrat);
                              tmpl_opts["AddressID"] = addr.Id;
                              tmpl_opts["AddressStreet"] = addr.HouseNum + ' ' + r;
                              tmpl_opts["AddressCity"] = addr.City;
                              tmpl_opts["AddressState"] = addr.State;
                              html = compiled_template(tmpl_opts);
                              html = _.template(html)(tmpl_opts);
                              ShowCheckAddressMessage("#fiber-available-congrat", html, elem);
                          } else {
                              var futureFiber = '';
                              if(addr.FutureFiberServices !== null) {
                                futureFiber = addr.FutureFiberServices;
                              }

                              if(addr.FiberNIDBit && !addr.SupportsGigabit || !addr.FiberNIDBit && futureFiber.toLowerCase().indexOf("gbps available") >= 0) {
                                  logNSEvent('ecomm - address checker - Fiber Coming Soon: ' + $.fn.aqui.fetchAddrById(addr.Id).FullAddress);
                                  tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.fiber_coming_soon);
                                  tmpl_opts["serviceRqCommingSoonMsg"] = options.messages.serviceRqCommingSoonMsg;
                                  tmpl_opts["AddressID"] = addr.Id;
                                  tmpl_opts["AddressStreet"] = addr.HouseNum + ' ' + r;
                                  tmpl_opts["AddressCity"] = addr.City;
                                  tmpl_opts["AddressState"] = addr.State;
                                  html = compiled_template(tmpl_opts);
                                  html = _.template(html)(tmpl_opts);
                                  ShowCheckAddressMessage("#fiber-coming-soon", html, elem);
                              } else {
                                  if(!addr.CopperNIDBit || addr.FiberNIDBit || addr.SupportsGigabit) {
                                      if(addr.FiberNIDBit || addr.SupportsGigabit || addr.CopperNIDBit) {
                                          logNSEvent('ecomm - address checker - No Service Planned: ' + $.fn.aqui.fetchAddrById(addr.Id).FullAddress);
                                          tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.no_services_planned);
                                          tmpl_opts["AddressID"] = addr.Id;
                                          tmpl_opts["AddressStreet"] = addr.HouseNum + ' ' + r;
                                          tmpl_opts["AddressCity"] = addr.City;
                                          tmpl_opts["AddressState"] = addr.State;
                                          html = compiled_template(tmpl_opts);
                                          html = _.template(html)(tmpl_opts);
                                          ShowCheckAddressMessage("#no-services-planned", html, elem);
                                      } else {
                                          logNSEvent('ecomm - address checker - No Services: ' + $.fn.aqui.fetchAddrById(addr.Id).FullAddress);
                                          tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.no_services);
                                          tmpl_opts["serviceRqFiberNotAvailableMsg"] = options.messages.serviceRqFiberNotAvailableMsg;
                                          tmpl_opts["AddressID"] = addr.Id;
                                          tmpl_opts["AddressStreet"] = addr.HouseNum + ' ' + r;
                                          tmpl_opts["AddressCity"] = addr.City;
                                          tmpl_opts["AddressState"] = addr.State;
                                          html = compiled_template(tmpl_opts);
                                          html = _.template(html)(tmpl_opts);
                                          ShowCheckAddressMessage("#no-services", html, elem);
                                      }
                                  } else {
                                      logNSEvent('ecomm - address checker - Low Tier Service Not Available: ' + $.fn.aqui.fetchAddrById(addr.Id).FullAddress);
                                      tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.low_tier_fiber_not_available);
                                      tmpl_opts["serviceRqCooperAvailableMsg"] = options.messages.serviceRqCooperAvailableMsg;
                                      tmpl_opts["AddressID"] = addr.Id;
                                      tmpl_opts["AddressStreet"] = addr.HouseNum + ' ' + r;
                                      tmpl_opts["AddressCity"] = addr.City;
                                      tmpl_opts["AddressState"] = addr.State;
                                      html = compiled_template(tmpl_opts);
                                      html = _.template(html)(tmpl_opts);
                                      ShowCheckAddressMessage("#low-tier-fiber-not-available", html, elem);
                                  }
                              }
                          }
                      }
                    }

                    if($(".checkPhoneUS").length > 0){
                        $(".checkPhoneUS").mask("000-000-0000");
                    }

                    $("span[data-ADDRESS]").text(addr.HouseNum + " " + r.toUpperCase());
                    $("span[data-CITY]").text(addr.City.toUpperCase());

                    $('#fiber-available-congrat').addClass(addr.State.toUpperCase());
                    $('#fiber-available-congrat').attr('data-state', addr.State.toUpperCase());

                    $(".change-address").on("click", function(){
                        logNSEvent('ecomm - button click - Change Address');
                        console.log("change-address");
                        $.fn.aqui.loadGoogleSearchForm(elem);
                    });

                });
            } else {
                var compiled_template = null;
                $.loadTemplate(url).then(function(data){
                    compiled_template = _.template(data);

                    tmpl_opts = {
                        myAccount_url: options.myAccount_url
                    };

                }).then(function(){

                  var aptNum = ($('#aptNo').val().length ? ' (Apt:'+ $('#aptNo').val() +')' : ' ');
                  logNSEvent('ecomm - address checker - No Services At Address: ' + $('#houseNo').val() + ' ' + $('#streetName').val() + ' ' + $('#city').val() + ' ' + $('#zip').val() + aptNum);

                  console.log('alt addr:', altaddr);

                  tmpl_opts["result_template"] = getTemplatePartial(options.templates.results.address_not_found);
                  tmpl_opts["serviceRqFiberNotAvailableMsg"] = options.messages.serviceRqFiberNotAvailableMsg;
                  tmpl_opts["AddressStreet"] = altaddr.streetAddress.trim()
                  tmpl_opts["AddressCity"] = altaddr.city.trim();
                  tmpl_opts["AddressState"] = altaddr.state.trim();
                  console.log(tmpl_opts);
                  html = compiled_template(tmpl_opts);
                  html = _.template(html)(tmpl_opts);
                  ShowCheckAddressMessage("#address-not-found", html, elem);
                  $(".change-address").on("click", function(){
                      logNSEvent('ecomm - button click - Change Address');
                      console.log("change-address");
                      $.fn.aqui.loadGoogleSearchForm(elem);
                  });
                });
            }

            $(".change-address").on("click", function(){
                logNSEvent('ecomm - button click - Change Address');
                console.log("change-address");
                $.fn.aqui.loadGoogleSearchForm(elem);
            });

        };

        $.fn.aqui.fetchAddrById = function(addressId)
        {
            var addr = null;
            $.ajax({
                url: options.addressbyId_url + addressId,
                async: false,
                beforeSend: function (xhr){
                    xhr.setRequestHeader('Authorization', options.auth_header);
                },
                success: function(response) {
                    addr = response.Data;
                }
            });
            return addr;
        }

        $.fn.aqui.loadDropDownSearchForm = function(elem) {
            var url = options.templates.dropdown_search_form;

            $.loadTemplate(url).then(function(data){
                $(elem).html(_.template(data));

                //console.log(options);

                $.ajax({
                    url: options.zipcodes_url,
                    type: 'GET',
                    async: false,
                    dataType: "json",
                    beforeSend: function (xhr){
                        xhr.setRequestHeader('Authorization', options.auth_header);
                    },
                    success: function(result){
                        //console.log("fetched zip codes");
                        zipcodes = result.Data;
                    }
                });

                setupAddressDropDownTypeahead('#zip', 'zip', zipcodes);

                cities = [];

                function debounce(func, wait, immediate) {
                    var timeout;
                    return function() {
                        var context = this, args = arguments;
                        var later = function() {
                            timeout = null;
                            if (!immediate) func.apply(context, args);
                        };
                        var callNow = immediate && !timeout;
                        clearTimeout(timeout);
                        timeout = setTimeout(later, wait);
                        if (callNow) func.apply(context, args);
                    };
                };


                $('#zip').on('keydown',adjustDdSize);
                $('#city').on('keydown',adjustDdSize);
                $('#streetName').on('keydown',adjustDdSize);
                $('#houseNo').on('keydown',adjustDdSize);
                $('#aptNo').on('keydown',adjustDdSize);

                $('#zip').on("blur change",function(event){
                    //console.log("zip " + event.type);
                    $('#city').val('');
                    $('#streetName').val('');
                    $('#houseNo').val('');
                    $('#aptNo').val('');
                    if(event.type == "change"){
                        return false;
                    }
                    var val = $(this).val();
                    populateZip(val);
                });

                $('#city').on("blur change",function(event){
                    //console.log("city " + event.type);
                    $('#streetName').val('');
                    $('#houseNo').val('');
                    $('#aptNo').val('');
                    if(event.type == "change"){
                        return false;
                    }
                    var val = $(this).val();
                    populateCity(val);
                });

                $('#streetName').on("blur change",function(event){
                    //console.log("streetName " + event.type);
                    $('#houseNo').val('');
                    $('#aptNo').val('');
                    if(event.type == "change"){
                        return false;
                    }
                    var val = $(this).val();
                    populateStreet(val);
                });

                $('#houseNo').on("blur change",function(event){
                    //console.log("houseNo " + event.type);
                    $('#aptNo').val('');
                    if(event.type == "change"){
                        return false;
                    }
                    var val = $(this).val();
                    populateHouseNumber(val);
                });

                $("form#check-address input[placeholder]").on('focus', function(){
                    $(this).data("placeholder", $(this).attr("placeholder"));
                    $(this).attr("placeholder", "");
                });

                $("form#check-address input[placeholder]").on('blur', function(){
                    $(this).attr("placeholder", $(this).data("placeholder"));
                });

                $('#dropdown_checkaddress').on('click', function() {
                    aptoNum = $('#aptNo').val();
                    aptaddr = ApartmentNumberObj[aptoNum];

                    if($('#aptNo').val().length == 0 || typeof aptaddr === 'undefined') {
                        HouseNum = $('#houseNo').val();
                        houseaddr = HouseNumberObj[HouseNum];
                        if(houseaddr) {
                            addressObj = $.fn.aqui.fetchAddrById(houseaddr.Id);
                            $.fn.lookupServiceAvailability.SelectedAddress = addressObj;
                            var altaddr = '';
                            if(!addressObj) {
                              var citystate = $('#city').val().split(',');
                              var city = '';
                              var state = '';
                              if(citystate.length > 1) {
                                city = citystate[0];
                                state = citystate[1];
                              }
                              altaddr = {
                                "streetAddress" : $('#houseNo').val() + ' ' + $('#streetName').val(),
                                "city" : city,
                                "state" : state
                              };
                            }
                            $.fn.aqui.checkAddress(elem, addressObj, altaddr);
                        } else {
                          var citystate = $('#city').val().split(',');
                          var city = '';
                          var state = '';
                          if(citystate.length > 1) {
                            city = citystate[0];
                            state = citystate[1];
                          }
                          var altaddr  = {
                            "streetAddress" : $('#houseNo').val() + ' ' + $('#streetName').val(),
                            "city" : city,
                            "state" : state
                          };
                          $.fn.aqui.checkAddress(elem, null, altaddr);
                        }
                    } else {
                        addressObj = $.fn.aqui.fetchAddrById(aptaddr.Id);
                        var altaddr = '';
                        if(!addressObj) {
                          var citystate = $('#city').val().split(',');
                          var city = '';
                          var state = '';
                          if(citystate.length > 1) {
                            city = citystate[0];
                            state = citystate[1];
                          }
                          altaddr = {
                            "streetAddress" : $('#houseNo').val() + ' ' + $('#streetName').val(),
                            "city" : city,
                            "state" : state
                          };
                        }
                        $.fn.lookupServiceAvailability.SelectedAddress = addressObj;
                        $.fn.aqui.checkAddress(elem, addressObj, altaddr);
                    }
                });
                //json = {"CityID":"130097","City":"GREENSBORO","StateID":"1038","State":"NORTH CAROLINA","StateAbbreviation":"NC","Zip":"27401","MetroAreaID":"100006"};
            });

            var HouseNumberObj = [];
            var CitiesObj = [];
            var StreetsObj = [];
            var ApartmentNumberObj = [];

            var zipcodes = [];
            var cities = [];
            var streets = [];
            var housenumbers = [];
            var apartmentnumbers = [];

            function setupAddressDropDownTypeahead(selector, dataSetName, dataSet) {

                //console.log("setupAddressDropDownTypeahead:",selector, dataSetName, dataSet);
                $(selector).typeahead('destroy');
                $(selector).typeahead(
                {
                    hint: true,
                    highlight: true,
                    minLength: 0,
                    limit: 5
                },
                {
                    name: dataSetName,
                    source: substringMatcher(dataSet)
                });

                $(selector).on('typeahead:selected', function (event, selection) {
                  var citystate = $('#city').val().split(',');
                  var state = '';
                  if(citystate.length > 1) {
                    state = citystate[1].trim();
                    $('#dropdown_checkaddress').attr('data-state', state);
                  }
                });

                $(selector).one('focus',function(){
                    if($(this).val().length > 0){
                        return false;
                    }
                    //console.log(selector +" focused")
                    var ev = $.Event('keydown');
                    ev.keyCode = ev.which = 40;
                    $(selector).trigger(ev);
                    return true;
                });


            }

            function populateZip(Zip)
            {
                if(Zip.length === 5) {
                    cities.length = 0;
                    $.ajax({
                        url: options.citiesbyzip_url + Zip,
                        async: true,
                        beforeSend: function (xhr){
                            xhr.setRequestHeader('Authorization', options.auth_header);
                        },
                        success: function(response) {
                            Cities = response.Data;

                            if(Cities !== null) {
                                for(var c in Cities) {
                                    if(typeof Cities[c].CityID !== 'undefined') {
                                        CitiesObj[Cities[c].City.toString().trim() + ', ' + Cities[c].StateAbbreviation.toString().trim()] = Cities[c];
                                        cities.push(Cities[c].City.toString().trim() + ', ' + Cities[c].StateAbbreviation.toString().trim());
                                    }
                                }

                                setupAddressDropDownTypeahead('#city', 'cities', cities);
                                 $('#city').trigger('focus');
                            }
                            else {
                                showAlert('error','No cities found for the given zip...');
                            }
                        }
                    });
                }
            }

            function populateCity(City)
            {
                if(City.length !== 0) {
                    streets.length = 0;
                    City = City.toUpperCase();
                    $.ajax({
                        url: options.streetsbycity_url,
                        type: 'POST',
                        dataType: "json",
                        async: true,
                        beforeSend: function (xhr){
                            xhr.setRequestHeader('Authorization', options.auth_header);
                        },
                        data: { City: JSON.stringify(CitiesObj[City]) },
                        success: function(response) {
                            Streets = response.Data;
                            if(Streets !== null) {
                                for(var s in Streets) {
                                    if(typeof Streets[s].StreetID !== 'undefined') {
                                        var street = '';
                                        street += Streets[s].PreDirectional === null ? '' : Streets[s].PreDirectional.toString().trim() + ' ';
                                        street += Streets[s].Street.toString().trim();
                                        street += Streets[s].StreetSuffix === null ? '' : ' ' + Streets[s].StreetSuffix.toString().trim();
                                        street += Streets[s].PostDirectional === null ? '' : ' ' + Streets[s].PostDirectional.toString().trim();

                                        StreetsObj[street] = Streets[s];
                                        streets.push(street);
                                    }
                                }

                                setupAddressDropDownTypeahead('#streetName', 'streets', streets);
                                $('#streetName').trigger('focus');
                            }
                            else {
                                showAlert('error','No streets found for the given city...');
                            }
                        }
                    });
                }
            }

            function populateStreet(Street)
            {
                if(Street.length !== 0) {
                    Street = Street.toUpperCase();
                    housenumbers.length = 0;
                    $.ajax({
                        url: options.housenumbersbystreet_url,
                        type: 'POST',
                        dataType: "json",
                        async: true,
                        beforeSend: function (xhr){
                            xhr.setRequestHeader('Authorization', options.auth_header);
                        },
                        data: { action: 'GetHouseNumbersByStreet', Street: JSON.stringify(StreetsObj[Street]) },
                        success: function(response) {
                            HouseNumbers = response.Data;
                            if(HouseNumbers !== null) {
                                for(var h in HouseNumbers) {
                                    if(typeof HouseNumbers[h].HouseNum !== 'undefined') {
                                        if(HouseNumbers[h].HouseNumSuffix !== null) {
                                            HouseNumbers[h].HouseNum = HouseNumbers[h].HouseNum + ' ' + HouseNumbers[h].HouseNumSuffix;
                                        }

                                        HouseNumberObj[HouseNumbers[h].HouseNum] = HouseNumbers[h];
                                        housenumbers.push(HouseNumbers[h].HouseNum);
                                    }
                                }

                                setupAddressDropDownTypeahead('#houseNo', 'housenumbers', housenumbers);
                                $('#houseNo').trigger('focus');
                            }
                            else {
                                showAlert('error','No house numbers found for the given street...');
                            }
                        }
                    });
                }
            }

            function populateHouseNumber(HouseNum)
            {
                if(HouseNum.length !== 0) {
                    apartmentnumbers.length = 0;
                    $.ajax({
                        url: options.aptnumbersbyhousenumber_url,
                        type: 'POST',
                        dataType: "json",
                        async: false,
                        beforeSend: function (xhr){
                            xhr.setRequestHeader('Authorization', options.auth_header);
                        },
                        data: { action: 'GetApartmentNumbersByHouseNumber', HouseNumber: JSON.stringify(HouseNumberObj[HouseNum]) },
                        success: function(response) {
                            ApartmentNumbers = response.Data;
                            if(ApartmentNumbers !== null) {
                                for(var h in ApartmentNumbers) {
                                    if(typeof ApartmentNumbers[h].ApartmentNum !== 'undefined') {
                                        ApartmentNumberObj[ApartmentNumbers[h].ApartmentNum] = ApartmentNumbers[h];
                                        apartmentnumbers.push(ApartmentNumbers[h].ApartmentNum);
                                    }
                                }

                                setupAddressDropDownTypeahead('#aptNo', 'apartmentnumbers', apartmentnumbers);
                                $('#aptNo').trigger('focus');
                            }
                            else {
                                //showAlert('error','No house numbers found for the given street...');
                            }
                        }
                    });
                }
            }

        }

        $.fn.aqui.loadGoogleSearchForm = function(elem) {


            function reverseGeocodeMyPosition(position) {
                var latlng = position.coords.latitude + "," + position.coords.longitude;

                $.getJSON(options.scriptBase + '/googleplacelookup.php', { action: "reverse_geocode", latlng: latlng }, function (data) {
                    if(data.place_id){
                        $("#street1").val(data.formatted_address.replace(/, USA/,''));
                        selection = {place_id: data.place_id, value: data.formatted_address, terms: []};
                        $.fn.lookupServiceAvailability.googleAddressTypeAheadSelectedHandler(elem, null, selection)
                        .then(function(){
                            addressData = $.fn.lookupServiceAvailability.aptOptions;
                            foundAddressByGooglePlaceIdHandler(elem, null, addressData);
                        });
                    }
                    else{
                        alert("Accurate geo-location information unavailable.");
                    }


                    $(elem).find("#geolocate-icon").removeClass("fa-refresh").removeClass("fa-spin").addClass("fa-map-marker");
                });

            }

            function noLocation(error) {
                alert("No location info available. Error code: " + error.code);
                $(elem).find("#geolocate-icon").removeClass("fa-refresh").removeClass("fa-spin").addClass("fa-map-marker");
            }

            function foundAddressByGooglePlaceIdHandler(elem, event, addressData) {
                //console.log("foundAddressByGooglePlaceIdHandler:", addressData);
                if($(elem).find("#street1").lookupServiceAvailability.aptOptions.length > 1){
                    //console.log($(this).lookupServiceAvailability.aptOptions.length);
                    $(elem).find(".apt-col").show();
                    $(elem).addClass("includes-apt");

                    bindAptTypeAhead($(elem).find("#aptNo").get(0))
                    .on('typeahead:selected', function (event, selection) {
                        //console.log('typeahead:selected');
                        //console.log(elem, event, selection);
                        $.fn.lookupServiceAvailability.aptSelectedHandler(selection);
                    });

                    $('#aptNo').one('keydown',adjustDdSize);
                    $('#aptNo').on('focus',function(){
                        //console.log('hello');
                        var ev = $.Event('keydown');
                        ev.keyCode = ev.which = 40;
                        $('#aptNo').trigger(ev);
                        return true;
                    });

                }
                else{
                    $(elem).find(".apt-elem").hide();
                    $(elem).removeClass("includes-apt");
                }
            }

            var url = options.templates.google_search_form;

            // if(!isCookiesEnabled()) {
            //   url = options.templates.cookies_not_enabled;
            // }

            $.loadTemplate(url).then(function(data){ $(elem).html(_.template(data)) })
            .done(function(){

                $(elem).find('[data-show-element]').click(function() {
                  $('#'+$(this).attr('data-show-element')).toggleClass('hide');
                });

                $(elem).removeClass("includes-apt");
                $(elem).find("#street1").lookupServiceAvailability({
                    scriptBase: options.scriptBase,
                    addressQualificationBase: options.addressQualificationBase,
                    typeahead_url: options.typeahead_url
                })
                .on("foundAddressByGooglePlaceId", function(event, addressData){
                    foundAddressByGooglePlaceIdHandler(elem, event, addressData);
                })
                .on("addressByGooglePlaceIdNotFound", function(event, selection){
                    //console.log("addressByGooglePlaceIdNotFound:", selection);
                });

                if(Cookies.get('lumos_address_form_count') && parseInt(Cookies.get('lumos_address_form_count')) >= 5) {
                  console.log('disable form');
                  $(elem).find('#street1').prop('disabled', true);
                  $(elem).find('#checkaddress').prop('disabled', true);
                  $(elem).find("#geolocate-button").prop('disabled', true);
                  $(elem).find('#disabled-form-message').removeClass('hide');
                } else {

                  $(elem).find('#street1').one('keydown',adjustDdSize);

                  $(elem).find('#street1').on('change', function(){
                   //console.log("change");
                  });

                  $(elem).find("#checkaddress").on("click", function(){
                      var minutes = 5;
                      var expires = (1 / 24 / 60) * minutes;

                      //console.log("clicked checkaddress");
                      if(Cookies.get('lumos_address_form_count')) {
                        var submissionCount = parseInt(Cookies.get('lumos_address_form_count'));
                        submissionCount++;
                        Cookies.set('lumos_address_form_count', submissionCount, { expires: expires });
                      } else {
                        Cookies.set('lumos_address_form_count', 1, expires, { expires: expires }); // 5 minutes
                      }

                      if($(elem).find("#street1").lookupServiceAvailability.SelectedAddress){
                          $.fn.aqui.checkAddress(elem, $(elem).find("#street1").lookupServiceAvailability.SelectedAddress);
                      }
                      else{
                        logNSEvent('ecomm - address checker - Show Second Address Form: No Service Address: ' + $('#street1').val());
                        $.fn.aqui.loadDropDownSearchForm(elem);
                      }

                  });

                  $(elem).find("#geolocate-button").on("click", function(){
                      $.geolocation.get({win: reverseGeocodeMyPosition, fail: noLocation});
                      $(elem).find("#geolocate-icon").removeClass("fa-map-marker").addClass("fa-refresh").addClass("fa-spin");
                      logNSEvent('ecomm - button click - Address Checker: Geolocate Button');
                  });

                }




            });
        }

        $.createCache = function( requestFunction ) {
            var cache = {};
            return function( key, callback ) {
                if ( !cache[ key ] ) {
                    //console.log("cache  miss!");
                    cache[ key ] = $.Deferred(function( defer ) {
                         //console.log("storing in cache");
                        requestFunction( defer, key );
                    }).promise();
                }
                return cache[ key ].done( callback );
            };
        };

        $.loadTemplate = $.createCache(function( defer, url ) {
            //console.log("loadTemplate with " + url);
            return $.ajax({
                    url: url,
                    method: 'GET',
                    async: false,
                    dataType: 'html',
                    success: defer.resolve,
                    error: defer.reject
                });
        });

        $.when(
            typeof _  == "function" || $.getScript(options.underscore_url),
            jQuery().typeahead ||  $.getScript(options.typeahead_url),
            jQuery().geolocation ||  $.getScript(options.geolocation_url),
            jQuery().maskWatchers ||  $.getScript(options.inputmask_url),
            typeof FastClick == "function" || $.getScript(options.fastclick_url),
            jQuery.validator || $.getScript(options.validaion_url)
        )
        .done(function(){
            //console.log(options.typeahead_url);
            if(options.address_id){
                addrobj = $.fn.aqui.fetchAddrById(options.address_id);
                //console.log(options.address_id, addrobj);
                $.fn.lookupServiceAvailability.SelectedAddress = addrobj;
                $.fn.aqui.checkAddress(elem, addrobj);
            }
            else{
                $.fn.aqui.loadGoogleSearchForm(elem);

                //$.fn.aqui.loadDropDownSearchForm(elem);
            }
        });

        return this.each(function() {
            var elem = $(this);
        });
    }

    if($('#street1-header').length) {
      $('#street1-header').lookupServiceAvailability({
          scriptBase: "https://cdn.nscom.com/js/aqui",
          addressQualificationBase: 'https://addressqualificationdev.nscom.com',
          typeahead_url: "https://cdnjs.cloudflare.com/ajax/libs/corejs-typeahead/0.10.3/typeahead.jquery.min.js"
      })
      .on("foundAddressByGooglePlaceId", function(event, addressData){
        function foundAddressByGooglePlaceIdHandler(elem, event, addressData) {
            //console.log("foundAddressByGooglePlaceIdHandler:", addressData);

            var bindAptTypeAhead = function(aptElem) {

                $(aptElem).typeahead('destroy');
                return  $(aptElem).typeahead(
                  {
                    hint: true,
                    highlight: true,
                    minLength: 0
                  },
                  {
                    source: function(query, process){
                        //console.log("APTS", $(elem).find("#street1").lookupServiceAvailability.aptOptions);

                      x = $.map( $(elem).find("#street1-header").lookupServiceAvailability.aptOptions, function(val, idx){
                        if(val.value == null){
                          val.value = "";
                        }
                        return {value: val.value, addrdata: val.addrdata};
                      });

                      x = _.sortBy(x , 'value');

                      process(
                          x
                      );
                    }
                  });
            }

            if($(elem).find("#street1-header").lookupServiceAvailability.aptOptions.length > 1){
                //console.log($(this).lookupServiceAvailability.aptOptions.length);
                $(elem).find(".apt-col").show();
                $(elem).addClass("includes-apt");

                bindAptTypeAhead($(elem).find("#aptNo").get(0))
                .on('typeahead:selected', function (event, selection) {
                    //console.log('typeahead:selected');
                    //console.log(elem, event, selection);
                    $.fn.lookupServiceAvailability.aptSelectedHandler(selection);
                });

                $('#aptNo-header').one('keydown',adjustDdSize);
                $('#aptNo-header').on('focus',function(){
                    //console.log('hello');
                    var ev = $.Event('keydown');
                    ev.keyCode = ev.which = 40;
                    $('#aptNo-header').trigger(ev);
                    return true;
                });

            }
            else{
                $(elem).find(".apt-elem").hide();
                $(elem).removeClass("includes-apt");
            }
        }

        foundAddressByGooglePlaceIdHandler($('.header-address-checker'), event, addressData);

      })
      .on("addressByGooglePlaceIdNotFound", function(event, selection){
          //console.log("addressByGooglePlaceIdNotFound:", selection);
      });

      $("#checkaddress-header").on("click", function(){
          //console.log("clicked checkaddress");
          // $('#address-checker').toggleClass('open');
          toggleAddressChecker();

          var minutes = 5;
          var expires = (1 / 24 / 60) * minutes;

          if(Cookies.get('lumos_address_form_count')) {
            var submissionCount = parseInt(Cookies.get('lumos_address_form_count'));
            submissionCount++;
            Cookies.set('lumos_address_form_count', submissionCount, { expires: expires });
          } else {
            Cookies.set('lumos_address_form_count', 1, { expires: expires }); // 5 minutes
          }

          if($("#street1-header").lookupServiceAvailability.SelectedAddress){
            $.fn.aqui.checkAddress($('.aqui-container'), $("#street1-header").lookupServiceAvailability.SelectedAddress);
          }
          else{
            logNSEvent('ecomm - address checker - Show Second Address Form: No Service Address: ' + $('#street1').val());
            $.fn.aqui.loadDropDownSearchForm($('.aqui-container'));
          }

      });
    }

}( jQuery ));
